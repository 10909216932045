import { FC } from 'react';
import { Stack, Typography, TextField, Button } from '@mui/material';
import { useUsersControllerMailResetPasswordMutation } from 'src/app/services/generatedApi';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppSelector } from 'src/app/reduxCustomHooks';

const initialValues = {
  email: '',
};
const validationSchema = yup.object().shape({
  email: yup.string().required('Email is required!'),
});

const ForgetPassword: FC = () => {
  const hasToken = useAppSelector((state) => state.auth.token);

  const navigate = useNavigate();

  const [sendEmail, { isLoading }] =
    useUsersControllerMailResetPasswordMutation();

  const onSubmit = ({ email }: { email: string }) => {
    if (!email) return;
    sendEmail({
      mailResetPasswordDto: {
        email,
        callbackUrl: window.location.origin + '/forgotPassword/',
        // callbackUrl: 'https://lms-eosin.vercel.app/forgotPassword/',
      },
    })
      .unwrap()
      .then(() => {
        toast.success('Email sent successfully');
        navigate('/auth/login');
      })
      .catch(({ data }) => {
        toast.error(data);
      });
    formik.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  if (hasToken) {
    return <Navigate to="/" />;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack rowGap={5} alignItems="center">
        <Typography
          fontWeight={700}
          variant="title1"
          whiteSpace="nowrap"
          color="secondary.main"
        >
          Enter your email address
        </Typography>
        <TextField
          fullWidth
          label="Email"
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          error={Boolean(formik.errors.email && formik.touched.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <Stack
          direction="row"
          columnGap={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Link
            to="/auth/login"
            style={{ textDecoration: 'none', width: '100%' }}
          >
            <Button fullWidth variant="outlined" size="large">
              Cancel
            </Button>
          </Link>
          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            loading={isLoading}
            size="large"
          >
            Send Email
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};

export default ForgetPassword;
