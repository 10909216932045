import { FC } from 'react';
import { PlayArrowRounded } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAppSelector } from 'src/app/reduxCustomHooks';

type PreviewButtonPropsType = { id: string };

export const PreviewButton: FC<PreviewButtonPropsType> = ({ id }) => {
  const { isAdmin, selectedCompanyId } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    selectedCompanyId: state.auth.adminSelectedCompanyId,
  }));

  return (
    <Button
      variant="contained"
      fullWidth
      startIcon={<PlayArrowRounded />}
      component={RouterLink}
      to={
        isAdmin && !selectedCompanyId
          ? `/admin/base-courses/${id}/preview`
          : `/course-store/${id}/preview`
      }
    >
      Preview
    </Button>
  );
};
