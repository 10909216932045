import { useMemo } from 'react';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import {
  useWorkspacesControllerGetCompanyWorkspacesQuery,
  useWorkspacesControllerGetMyCompanyWorkspacesQuery,
} from 'src/app/services/generatedApi';

export const useMyAccessGroup = () => {
  const { isAdmin, selectedCompanyId, isManager } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    selectedCompanyId: state.auth.adminSelectedCompanyId,
    isManager: state.auth.isManager,
  }));

  const { data: groupsByAdmin, isLoading: loadingGroupsByAdmin } =
    useWorkspacesControllerGetCompanyWorkspacesQuery(
      { id: selectedCompanyId! },
      { skip: !isAdmin || !selectedCompanyId }
    );
  const { data: groupsByManager, isLoading: loadingGroupsByManager } =
    useWorkspacesControllerGetMyCompanyWorkspacesQuery(undefined as any, {
      skip: isAdmin || !isManager,
    });

  const myAccessGroups = useMemo(
    () => groupsByAdmin || groupsByManager || [],
    [groupsByManager, groupsByAdmin]
  );

  return {
    myAccessGroups,
    isLoading: loadingGroupsByAdmin || loadingGroupsByManager,
  };
};
