import { FC, useState } from 'react';
import { IconButton, Stack, TableRow, Tooltip } from '@mui/material';
import { ExitToApp, SettingsSuggest } from '@mui/icons-material';
import { toast } from 'react-toastify';
import {
  CompanyUsageRo,
  useCompaniesControllerDeleteMutation,
} from 'src/app/services/generatedApi';
import { DeleteDialog } from 'src/components/atoms/DeleteDialog';
import { Trash2 } from 'src/components/atoms/svg/TrashSvg';
import { StyledTableCell } from 'src/components/molecules/StyledTableCell';
import { useAppDispatch } from 'src/app/reduxCustomHooks';
import { setAdminSelectedCompanyIdAction } from 'src/app/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { EditLimitDialog } from './EditLimitsDialog';

type CompanyTableRowPropsType = {
  row: {
    id: string;
    name: string;
    owner: string;
    users: number;
    limits: CompanyUsageRo;
  };
  index: number;
};

export const CompanyTableRow: FC<CompanyTableRowPropsType> = ({
  row,
  index,
}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const { id, name, owner, users, limits } = row;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deleteCompany, { isLoading }] = useCompaniesControllerDeleteMutation();

  const submitDeleteCompany = () => {
    deleteCompany({ idDto: { id } })
      .unwrap()
      .then(() => {
        toast.success('Company deleted successfully');
        setOpenDelete(false);
      })
      .catch(({ data }) => toast.error(data));
  };

  return (
    <>
      <TableRow
        sx={{ backgroundColor: index % 2 === 0 ? 'white' : '#ecf9f4' }}
        role="checkbox"
        tabIndex={-1}
      >
        <StyledTableCell component="th" scope="row">
          {name}
        </StyledTableCell>
        <StyledTableCell>{owner}</StyledTableCell>
        <StyledTableCell>{users}</StyledTableCell>
        <StyledTableCell align="center">
          <Stack direction="row" justifyContent="end" spacing={3}>
            <IconButton
              onClick={() => {
                dispatch(setAdminSelectedCompanyIdAction(id));
                navigate('/');
              }}
              size="small"
            >
              <Tooltip title="Work as Manager">
                <ExitToApp fontSize="small" />
              </Tooltip>
            </IconButton>
            <IconButton size="small" onClick={(e) => setOpenEdit(true)}>
              <Tooltip title="Edit Limits">
                <SettingsSuggest fontSize="small" />
              </Tooltip>
            </IconButton>
            <IconButton onClick={() => setOpenDelete(true)} size="small">
              <Trash2 fontSize="small" />
            </IconButton>
          </Stack>
        </StyledTableCell>
      </TableRow>
      <DeleteDialog
        openDialog={openDelete}
        handleClose={() => setOpenDelete(false)}
        submitDelete={submitDeleteCompany}
        loading={isLoading}
        type="Company"
        name={name}
      />
      <EditLimitDialog
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        id={id}
        initialValues={limits}
        name={name}
      />
    </>
  );
};
