import { AddShoppingCartRounded } from '@mui/icons-material';
import { Checkbox, Stack, Typography } from '@mui/material';
import { FC } from 'react';

type AddToStoreBoxPropsType = {
  inStore: boolean;
  setFieldValue: (field: string, value: any) => void;
};

export const AddToStoreBox: FC<AddToStoreBoxPropsType> = ({
  inStore,
  setFieldValue,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        mt: 3,
        px: 2,
        py: 1,
        border: 1,
        borderRadius: 2,
        borderColor: inStore ? 'primary.main' : 'secondary.main',
        cursor: 'pointer',
      }}
      onClick={() => setFieldValue('inStore', !inStore)}
    >
      <AddShoppingCartRounded
        color={inStore ? 'primary' : 'secondary'}
        fontSize="large"
        sx={{ m: 1 }}
      />
      <Stack>
        <Typography
          variant="text0"
          fontWeight="bold"
          color={inStore ? 'primary.main' : 'secondary.main'}
        >
          Add to store
        </Typography>
        <Typography
          variant="text2"
          color={inStore ? 'primary.main' : 'secondary.main'}
        >
          Add this course to store for companies
        </Typography>
      </Stack>
      <Checkbox checked={inStore} />
    </Stack>
  );
};
