import {
  Box,
  Checkbox,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { ChangeEvent, FC, MouseEvent } from 'react';
import { StyledTableCell } from 'src/components/molecules/StyledTableCell';
import { visuallyHidden } from '@mui/utils';
import { GroupDataType, Order } from './GroupsTable';

type HeadCell = { id: keyof GroupDataType; label: string };

const headCells: readonly HeadCell[] = [
  { id: 'name', label: 'Name' },
  { id: 'users', label: 'Number of users' },
  { id: 'createdAt', label: 'Created At' },
];

type GroupsTableProps = {
  numSelected: number;
  onRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof GroupDataType
  ) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
};

export const GroupsTableHead: FC<GroupsTableProps> = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) => {
  const createSortHandler =
    (property: keyof GroupDataType) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ whiteSpace: 'nowrap' }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell></StyledTableCell>
      </TableRow>
    </TableHead>
  );
};
