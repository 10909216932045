import { MutableRefObject } from 'react';
import { useDrop } from 'react-dnd';
import { ImageFieldRo, TextFieldDto } from 'src/app/services/generatedApi';

export const useDropArgument = (
  addTextField: (data: TextFieldDto) => void,
  addImageField: (
    data: Omit<ImageFieldRo, 'filePath'> & {
      file: File;
    }
  ) => void,
  selectId: (id?: number) => void,
  containerRef: MutableRefObject<HTMLDivElement | null>
) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'ARGUMENT',
    drop: (item: any, monitor) => {
      selectId(undefined);
      const itemPosition = monitor.getSourceClientOffset();

      const containerRect = containerRef.current?.getBoundingClientRect() || {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      };

      const dropPosition = itemPosition
        ? {
            x: (itemPosition.x - containerRect.left) / containerRect.width,
            y: (itemPosition.y - containerRect.top) / containerRect.height,
          }
        : { x: 0.1, y: 0.1 };

      if (item.type) {
        addTextField({
          inputType: item.type,
          fontSize: 24,
          color: '#000',
          bold: false,
          location: dropPosition,
          rotation: 0,
          ...(item.text && { text: item.text }),
        });
      } else {
        monitor.getDropResult();
        addImageField({
          url: item.url,
          file: item.file,
          size: { width: 0.3, height: 0.3 / item.ratio },
          location: dropPosition,
          rotation: 0,
        });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return { canDrop, isOver, drop };
};
