import { FC, useState } from 'react';
import { Stack, Typography, Button, Paper, Box } from '@mui/material';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import {
  BackupOutlined,
  CloudDoneOutlined,
  UploadFile,
} from '@mui/icons-material';
import { CustomLinearProgress } from '../molecules/CustomLeanerProgress';

/* const dropzoneOptions = {
    maxSize: supportedSize * (1024 * 1024),
    accept: { "application/pdf": [".pdf"] },
    multiple: true,
  }; */

// Supported Size as !MB
export const supportedSize = 200;

type DropzonePropsType = {
  onChange: (file: File) => void;
  mode?: 'CREATE' | 'EDIT' | 'DUPLICATE';
  course?: { name: string; createdAt: string; image: string };
};

const Dropzone: FC<DropzonePropsType> = ({ onChange, mode, course }) => {
  const [file, setFile] = useState<File | null>(null);

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    fileRejections.map(({ file, errors }) =>
      // eslint-disable-next-line array-callback-return
      errors.map(({ code }) => {
        if (code === ErrorCode.FileTooLarge) {
          toast.error(
            `${file.name} file size is larger than ${supportedSize} MB`
          );
        }
      })
    );

    setFile(acceptedFiles[0]);
    onChange(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'application/zip': ['.zip'] },
    useFsAccessApi: false,
  });

  const uploading = false;

  if (file) {
    return (
      <Stack spacing={2} width="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1" fontWeight="bold">
            File Uploaded
          </Typography>
          <Stack>
            <Button
              variant="contained"
              onClick={() => setFile(null)}
              disabled={mode !== 'CREATE'}
            >
              Choose another
            </Button>
          </Stack>
        </Stack>
        <Stack
          component={Paper}
          alignItems="center"
          spacing={1}
          borderRadius={1}
          py={uploading ? 2 : 5}
          width="100%"
          {...getRootProps()}
        >
          <Stack py={uploading ? 0 : 3} alignItems="center" spacing={1}>
            <CloudDoneOutlined sx={{ fontSize: 50 }} color="primary" />
            <Typography>{file.name}</Typography>
            <Typography>{(file.size / 1048576).toFixed(2)} MB</Typography>
          </Stack>
          {uploading && (
            <Stack
              direction="row"
              alignItems="end"
              spacing={2}
              width="100%"
              px={4}
            >
              <UploadFile color="primary" fontSize="large" />
              <Stack
                direction="row"
                spacing={2}
                width="100%"
                alignItems="center"
              >
                <Box width="100%">
                  <CustomLinearProgress variant="determinate" value={30} />
                </Box>
                <Typography>30%</Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  } else {
    return (
      <>
        <Typography variant="subtitle1" fontWeight="bold">
          Upload File
        </Typography>
        <Button
          fullWidth
          sx={{ border: '2px dashed #aaa' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          {...getRootProps()}
        >
          <Stack alignItems="center" rowGap={0} borderRadius={1} px={1} py={3}>
            <BackupOutlined sx={{ fontSize: 50 }} />
            <Typography color="secondary.dark" my={2}>
              Drop files here or click to upload
            </Typography>
            <Typography color="secondary.dark" fontWeight={300} fontSize={13}>
              Maximum supported file size: {supportedSize} MB
            </Typography>
            <Typography color="secondary.dark" fontWeight={300} fontSize={13}>
              Supported file type: .zip
            </Typography>
          </Stack>
          <input {...getInputProps()} />
        </Button>
      </>
    );
  }
};

export default Dropzone;
