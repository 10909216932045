import { Assignment, RemoveRedEye } from '@mui/icons-material';
import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { Edit } from 'src/components/atoms/svg/EditSvg';
import { CertificateDialog } from './CertificateDialog';

type CertTableRowPropsType = {
  id: string;
  name: string;
  selected: boolean;
  index: number;
  handleSelect: (index: number) => void;
};

export const CertTableRow: FC<CertTableRowPropsType> = ({
  id,
  name,
  selected,
  index,
  handleSelect,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const [certMode, setCertMode] = useState<
    'UPDATE_CERTIFICATE' | 'PREVIEW_CERTIFICATE'
  >('UPDATE_CERTIFICATE');

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          borderRadius: 2,
          px: 1.5,
          py: 1,
          cursor: 'pointer',
          '&:hover': {
            bgcolor: selected ? 'primary.light' : 'customGray.light',
          },
        }}
        bgcolor={selected ? 'primary.light' : 'inherit'}
        onClick={() => handleSelect(index)}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <Avatar
            sx={{
              bgcolor: selected ? 'white' : 'primary.main',
              width: 27,
              height: 27,
            }}
            variant="rounded"
          >
            <Assignment
              fontSize="small"
              color={selected ? 'primary' : 'inherit'}
            />
          </Avatar>
          <Typography>{name}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            size="small"
            sx={{ p: 1 }}
            onClick={(e) => {
              e.stopPropagation();
              setCertMode('UPDATE_CERTIFICATE');
              setOpenDialog(true);
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            sx={{ p: 1 }}
            onClick={(e) => {
              e.stopPropagation();
              setCertMode('PREVIEW_CERTIFICATE');
              setOpenDialog(true);
            }}
          >
            <RemoveRedEye fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>
      <CertificateDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        certId={id}
        mode={certMode}
      />
    </>
  );
};
