import { Button, Grid, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useBaseCoursesControllerGetAllQuery } from 'src/app/services/generatedApi';
import { CreateBaseCourseCard } from 'src/pages/admin/course/courseStore/CreateBaseCourseCard';
import { BaseCourseCard } from '../courseCard/BaseCourseCard';
import emptySvg from 'src/assets/svg/Empty.svg';
import { BaseCourseCardLoading } from '../courseCard/CourseCardLoading';
import { Link } from 'react-router-dom';

export const BaseCoursesList: FC<{ limit?: number }> = ({ limit }) => {
  const { data: baseCourses = [], isLoading } =
    useBaseCoursesControllerGetAllQuery();

  const courses = [...baseCourses].reverse();

  return (
    <Grid container spacing={2} height="100%">
      {isLoading ? (
        [...Array(3)].map((_, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <BaseCourseCardLoading />
          </Grid>
        ))
      ) : baseCourses.length === 0 ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={3}
          sx={{ width: '100%', pt: 4 }}
        >
          <img src={emptySvg} alt="empty courses" />
          <Typography variant="title3" color="primary">
            There is no course in your list.
          </Typography>
          {!limit && (
            <Link
              to="/admin/base-courses/create"
              style={{ textDecoration: 'none' }}
            >
              <Button variant="contained" sx={{ px: 4 }}>
                Create New Course
              </Button>
            </Link>
          )}
        </Stack>
      ) : (
        (limit ? courses.slice(0, limit) : courses).map((baseCourse) => (
          <Grid item xs={12} sm={6} lg={4} key={baseCourse.id}>
            <BaseCourseCard baseCourse={baseCourse} />
          </Grid>
        ))
      )}
      {!limit && baseCourses.length > 0 && (
        <Grid item xs={12} sm={6} lg={4}>
          <CreateBaseCourseCard />
        </Grid>
      )}
    </Grid>
  );
};
