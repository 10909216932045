import { FC, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { Stack, TextField, styled } from '@mui/material';

dayjs.extend(isBetweenPlugin);

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  lineHeight: 1,
  width: 30,
  height: 30,
  marginLeft: 7,
  marginRight: 7,
  marginBottom: 12,
  ...(dayIsBetween && {
    '&.Mui-selected': { color: theme.palette.common.white, borderRadius: 4 },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
})) as React.ComponentType<CustomPickerDayProps>;

type CustomDayPropsType = {
  currentDate: string | null;
  setSelectedDate: (date: string) => void;
};

const CustomDatePicker: FC<CustomDayPropsType> = ({
  currentDate,
  setSelectedDate,
}) => {
  const today = dayjs().format('YYYY-MM-DD');
  const [value, setValue] = useState<Dayjs | null>(dayjs(today));
  useEffect(() => {
    if (!currentDate) return;
    setValue(dayjs(currentDate));
  }, [currentDate]);

  const renderWeekPickerDay = (
    date: Dayjs,
    selectedDates: Array<Dayjs | null>,
    pickersDayProps: PickersDayProps<Dayjs>
  ) => {
    if (!value) return <PickersDay {...pickersDayProps} />;

    const start = value.startOf('week');
    const end = value.endOf('week');

    const dayIsBetween = date.isBetween(start, end, null, '[]');
    const isFirstDay = date.isSame(start, 'day');
    const isLastDay = date.isSame(end, 'day');

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <Stack
      alignItems="center"
      py={2}
      sx={{
        '& .MuiDayPicker-weekDayLabel': { width: 40 },
        '& .MuiPickersCalendarHeader-label': { color: 'primary.main' },
        '& .MuiDialogActions-root': { justifyContent: 'center' },
        '& .MuiButton-root': { width: '100%', fontSize: 16 },
        '& .MuiDayPicker-slideTransition': { minHeight: 222 },
        '& .MuiPickerStaticWrapper-root': {
          border: 1,
          borderColor: 'secondary.dark',
          borderRadius: 2,
          p: 1,
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          componentsProps={{ actionBar: { actions: ['today'] } }}
          displayStaticWrapperAs="desktop"
          label="Week picker"
          value={value}
          onChange={(newValue) => {
            const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
            setSelectedDate(formattedDate);
            setValue(newValue);
          }}
          renderDay={renderWeekPickerDay}
          renderInput={(params) => <TextField {...params} />}
          inputFormat="'Week of' MMM d"
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default CustomDatePicker;
