import { LocalGroceryStore } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { FC } from 'react';

type CourseStoreSvgPropsType = { selected: boolean };

export const CourseStoreSvg: FC<SvgIconProps & CourseStoreSvgPropsType> = ({
  selected,
  ...props
}) => {
  return (
    <LocalGroceryStore
      {...props}
      sx={{
        ...props.sx,
        color: selected ? 'primary.main' : 'rgba(255, 255, 255, 0.75)',
        fontSize: 30,
      }}
    />
  );
};
