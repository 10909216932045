import { Divider, Paper, Skeleton, Stack } from '@mui/material';
import { FC } from 'react';

export const CourseCardLoading: FC<{ isMyCourse?: boolean }> = ({
  isMyCourse,
}) => {
  return (
    <Stack
      spacing={3}
      component={Paper}
      elevation={2}
      py={2}
      alignItems="center"
    >
      <Skeleton animation="wave" variant="rounded" width="70%" height={25} />
      <Skeleton animation="wave" variant="rounded" height={180} width={300} />
      <Skeleton animation="wave" variant="rounded" width="50%" height={25} />
      {isMyCourse && (
        <Stack direction="row" spacing={3}>
          <Skeleton animation="wave" height={14} width={30} />
          <Divider sx={{ maxHeight: 22 }} orientation="vertical" flexItem />
          <Skeleton animation="wave" height={14} width={30} />
          <Divider sx={{ maxHeight: 22 }} orientation="vertical" flexItem />
          <Skeleton animation="wave" height={14} width={30} />
        </Stack>
      )}
      <Stack width="80%" spacing={1}>
        <Skeleton animation="wave" height={14} width="100%" />
        <Skeleton animation="wave" height={14} width="80%" />
      </Stack>
      <Skeleton animation="wave" variant="rounded" width="92%" height={35} />
    </Stack>
  );
};

export const BaseCourseCardLoading: FC = () => {
  return (
    <Stack spacing={2} component={Paper}>
      <Skeleton variant="rounded" height={200} />
      <Stack p={1} spacing={2}>
        <Stack spacing={0.5} pb={2}>
          <Skeleton width="60%" />
          <Skeleton />
        </Stack>
        <Skeleton variant="rounded" width="100%" height={35} />
      </Stack>
    </Stack>
  );
};
