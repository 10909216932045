import { EnrolledUserRo } from 'src/app/services/generatedApi';

export const enrollUserStatesMap: {
  [key in EnrolledUserRo['state']]: { text: string };
} = {
  ENROLLED: {
    text: 'Enrolled',
  },
  REGISTERED: {
    text: 'Registered',
  },
  IN_PROGRESS: {
    text: 'In progress',
  },
  COMPLETED: {
    text: 'Completed',
  },
  FAILED: {
    text: 'Failed',
  },
};
