import { Grid, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  useCoursesControllerGetUserCoursesQuery,
  useUsersControllerFindQuery,
} from 'src/app/services/generatedApi';
import { CourseCardLoading } from '../../course/courseCard/CourseCardLoading';
import emptySvg from 'src/assets/svg/Empty.svg';
import { MyCourseCard } from '../../course/courseCard/MyCourseCard';

export const ViewUserCourses: FC = () => {
  const { userId } = useParams();
  const { data: userCourses = [], isLoading } =
    useCoursesControllerGetUserCoursesQuery({ id: userId! }, { skip: !userId });

  const { data: user } = useUsersControllerFindQuery(
    { id: userId! },
    { skip: !userId }
  );

  return (
    <Grid container spacing={2} height="100%">
      {isLoading ? (
        [...Array(3)].map((_, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <CourseCardLoading isMyCourse />
          </Grid>
        ))
      ) : userCourses.length === 0 ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={3}
          sx={{ width: '100%', pt: 4 }}
        >
          <img src={emptySvg} alt="empty courses" />
          <Typography variant="title3" color="primary">
            There is no course in your list.
          </Typography>
        </Stack>
      ) : (
        userCourses.map((course) => (
          <Grid item xs={12} sm={6} lg={4} key={course.id}>
            <MyCourseCard
              myCourse={course}
              isAdminView
              user={user?.find((user) => user.id === userId)}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
};
