import { FC } from 'react';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from './utils/createEmotionCache';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './configs/theme';
import { ToastContainer } from 'react-toastify';
import Router from './routes/Router';
import './assets/global.css';

import 'react-toastify/dist/ReactToastify.min.css';

const App: FC = () => {
  return (
    <CacheProvider value={createEmotionCache()}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
        <ToastContainer limit={3} autoClose={10000} />
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
