import { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { CustomTimeline } from '../../history/CustomTimeline';
import { useUserActivityControllerGetUserActivitiesQuery } from 'src/app/services/generatedApi';
import { useParams } from 'react-router-dom';
import CustomDatePicker from 'src/components/atoms/CustomDatePicker';

export const ViewUserHistory: FC = () => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [currentDate, setCurrentDate] = useState<string | null>(null);

  const { userId } = useParams();

  const { data: activity = [], isLoading } =
    useUserActivityControllerGetUserActivitiesQuery(
      { id: userId! },
      { skip: !userId }
    );

  return (
    <Stack
      direction={{ xs: 'column-reverse', md: 'row' }}
      py={{ xs: 3, md: 0 }}
    >
      <Stack>
        <CustomDatePicker
          currentDate={currentDate}
          setSelectedDate={setSelectedDate}
        />
      </Stack>
      <Stack width="100%">
        <CustomTimeline
          selectedDate={selectedDate}
          setCurrentDate={setCurrentDate}
          activity={activity}
          isLoading={isLoading}
        />
      </Stack>
    </Stack>
  );
};
