import { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import {
  UpdateBaseCourseDto,
  useBaseCoursesControllerGetByIdQuery,
  useBaseCoursesControllerUpdateMutation,
  useMediaControllerUploadThumbnailMutation,
} from 'src/app/services/generatedApi';
import { toast } from 'react-toastify';
import { CustomStepper } from 'src/components/organisms/course/createCourseSteps/CustomStepper';
import { useParams } from 'react-router-dom';
import { DescriptionStep } from 'src/components/organisms/course/createCourseSteps/DescriptionStep';
import { FileAndArgumentsStep } from 'src/components/organisms/course/createCourseSteps/FileAndArgsStep';
import { CertificateStep } from 'src/components/organisms/course/createCourseSteps/SetCertStep';

const createCourseSteps = ['Details *', 'Session *', 'Certificate'];

export type EditCourseFieldType = { key: string; value: any }[];

const EditBaseCourse: FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const { baseCourseId } = useParams() as { baseCourseId: string };

  const { data: course, isLoading: loadingCourse } =
    useBaseCoursesControllerGetByIdQuery(
      { id: baseCourseId! },
      { skip: !baseCourseId }
    );

  const [activeStep, setActiveStep] = useState(0);

  const [initialData, setInitialData] = useState<UpdateBaseCourseDto>({
    id: '',
    courseArguments: [],
    name: '',
    description: '',
    inStore: false,
    resource: [],
  });

  useEffect(() => {
    if (!course) return;
    setInitialData((prev) => {
      if (prev.id !== '') return prev;
      return {
        ...prev,
        id: baseCourseId,
        courseArguments: course.courseArguments,
        resource: course.resource.map(({fileKey, ...r}) => {
          return { ...r, path: { path: fileKey, mode: 'S3' } };
        }),
      };
    });
  }, [baseCourseId, course]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (fields: EditCourseFieldType) => {
    fields.forEach((item) => {
      setInitialData((prevState) => ({ ...prevState, [item.key]: item.value }));
    });
  };

  const [uploadImage] = useMediaControllerUploadThumbnailMutation();

  const [editBaseCourse, { isLoading: loadingEdit }] =
    useBaseCoursesControllerUpdateMutation();

  const submitEditCourse = () => {
    if (file) {
      const formData = new FormData();
      const options = { id: baseCourseId, mode: 'BASE_COURSE_THUMBNAIL' };
      formData.append('image', file);
      formData.append('options', JSON.stringify(options));
      uploadImage({ body: formData as any })
        .unwrap()
        .then()
        .catch(({ data }) => toast.error(data));
    }

    editBaseCourse({ updateBaseCourseDto: initialData })
      .unwrap()
      .then(() => {
        toast.success('Course updated successfully');
        handleNext();
      })
      .catch(({ data }) => toast.error(data));
  };

  return (
    <Stack py={1} height="100%">
      <CustomStepper steps={createCourseSteps} activeStep={activeStep} />
      <Stack py={4} height="100%">
        {course && baseCourseId && (
          <>
            {activeStep === 0 ? (
              <DescriptionStep
                mode="EDIT"
                goNext={handleNext}
                selectedCourse={course}
                loading={loadingCourse}
                fillFormData={handleChange}
                image={file}
                setImage={setFile}
                resource={initialData.resource}
              />
            ) : activeStep === 1 ? (
              <FileAndArgumentsStep
                mode="EDIT"
                goBack={handleBack}
                fillFormData={handleChange}
                selectedCourse={course}
                isLoading={loadingEdit}
                submit={submitEditCourse}
                args={initialData.courseArguments || []}
                file={null}
              />
            ) : (
              <CertificateStep
                initialValue={course.certificate}
                baseCourseId={baseCourseId}
              />
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default EditBaseCourse;
