import { FC, useMemo, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  Container,
  Autocomplete,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { passwordValidatorRegex } from 'src/utils/formValidator';
import {
  useUsersControllerCreateMutation,
  useCompaniesControllerGetAllQuery,
} from 'src/app/services/generatedApi';
import { toast } from 'react-toastify';
import { LmsSwitch } from 'src/components/molecules/LmsSwitch';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const passValidationHandler = (value: string) =>
  !value ? false : passwordValidatorRegex.test(value);

const managerValidationSchema = yup.object().shape({
  username: yup.string().required('Username is required!'),
  firstName: yup.string().required('First name is required!'),
  lastName: yup.string().required('Last name is required!'),
  email: yup.string().required('Email is required!'),
  password: yup
    .string()
    .test('Password validation', 'Password is not valid', (value) =>
      passValidationHandler(value as string)
    ),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), undefined],
      'Repeating the new password is incorrect'
    )
    .required('Confirm Password is required and must be match'),
  companyName: yup.string(),
  isManager: yup.boolean(),
});
const adminValidationSchema = yup.object().shape({
  companyName: yup.string(),
});

const initialValues = {
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  companyName: '',
  isManager: false,
};

const NewUser: FC = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { isAdmin, selectedCompanyId } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    selectedCompanyId: state.auth.adminSelectedCompanyId,
  }));

  const { data: companies = [] } = useCompaniesControllerGetAllQuery(
    undefined as any,
    { skip: !isAdmin }
  );

  const validationSchema = useMemo(
    () =>
      isAdmin
        ? managerValidationSchema.concat(adminValidationSchema)
        : managerValidationSchema,
    [isAdmin]
  );

  const [createUser, { isLoading }] = useUsersControllerCreateMutation();

  const onSubmit = (values: typeof initialValues) => {
    createUser({
      userCreateDto: {
        username: values.username,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        isManager: values.isManager,
        ...(isAdmin && {
          companyName: selectedCompanyId
            ? companies.find((company) => company.id === selectedCompanyId)
                ?.name
            : values.companyName,
        }),
      },
    })
      .unwrap()
      .then(() => {
        toast.success('User created successfully');
        if (selectedCompanyId || !isAdmin) {
          navigate('/company/users');
        } else {
          navigate('/admin/users');
        }
      })
      .catch();

    formik.setSubmitting(false);
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Container maxWidth="md">
        <Grid2 container spacing={4}>
          <Grid2 xs={12}>
            <Typography variant="title2" fontWeight="bold">
              Create New User
            </Typography>
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <TextField
              fullWidth
              label="Username"
              id="username"
              name="username"
              type="username"
              onChange={formik.handleChange}
              value={formik.values.username}
              error={Boolean(formik.errors.username && formik.touched.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={Boolean(formik.errors.email && formik.touched.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <TextField
              fullWidth
              label="First name"
              {...formik.getFieldProps('firstName')}
              error={Boolean(
                formik.errors.firstName && formik.touched.firstName
              )}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last name"
              {...formik.getFieldProps('lastName')}
              error={Boolean(formik.errors.lastName && formik.touched.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <TextField
              fullWidth
              label="Password"
              {...formik.getFieldProps('password')}
              error={Boolean(formik.errors.password && formik.touched.password)}
              helperText={formik.touched.password && formik.errors.password}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <TextField
              fullWidth
              label="Confirm Password"
              {...formik.getFieldProps('confirmPassword')}
              error={Boolean(
                formik.errors.confirmPassword && formik.touched.confirmPassword
              )}
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid2>
          <Grid2 xs={12} sm={6}>
            <Stack
              height="100%"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                px: 2,
                py: 1,
                border: 1,
                borderColor: formik.values.isManager
                  ? 'primary.main'
                  : 'secondary.light',
                borderRadius: 1,
              }}
            >
              <Typography
                color={
                  formik.values.isManager ? 'primary.main' : 'secondary.main'
                }
              >
                Is Manager
              </Typography>
              <LmsSwitch
                id="isManager"
                name="isManager"
                checked={formik.values.isManager}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                color="primary"
              />
            </Stack>
          </Grid2>
          {isAdmin && !selectedCompanyId && (
            <Grid2 xs={12} sm={6}>
              <Autocomplete
                disablePortal
                freeSolo
                options={companies.map((company) => company.name)}
                value={formik.values.companyName}
                onChange={(e, value) =>
                  formik.setFieldValue('companyName', value)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder="Company Name"
                    onChange={(e) =>
                      formik.setFieldValue('companyName', e.target.value)
                    }
                  />
                )}
              />
            </Grid2>
          )}
          <Grid2
            xs={12}
            container
            spacing={1}
            alignItems="center"
            justifyContent="end"
            pt={4}
          >
            <Grid2>
              <Button
                variant="outlined"
                sx={{ color: 'secondary.main' }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Grid2>
            <Grid2>
              <LoadingButton
                component="button"
                type="submit"
                loading={isLoading}
                variant="contained"
              >
                Submit
              </LoadingButton>
            </Grid2>
          </Grid2>
        </Grid2>
      </Container>
    </form>
  );
};

export default NewUser;
