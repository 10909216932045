import { FC, useState, useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import {
  ResourceDto,
  useMediaControllerUploadResourceMutation,
} from 'src/app/services/generatedApi';
import { CustomUploadFile } from 'src/components/atoms/CustomUploadFile';

const MAXIMUM_FILE_SIZE = 20;

type AddResourceDialogPropsType = {
  openDialog: boolean;
  handleClose: () => void;
  addResource: (resource: ResourceDto) => void;
};

export const AddResourceDialog: FC<AddResourceDialogPropsType> = ({
  openDialog,
  handleClose,
  addResource,
}) => {
  const [name, setName] = useState('');
  const [file, setFile] = useState<File | null>(null);

  const [sendFile, { isLoading }] = useMediaControllerUploadResourceMutation();

  const handleSelectFile = async (event: any) => {
    const file = event.target.files[0];
    if (file.size > 1024 * 1024 * MAXIMUM_FILE_SIZE) {
      toast.error(`Please select file under ${MAXIMUM_FILE_SIZE} MB`);
      return;
    }
    setFile(file);
  };

  const onClose = () => {
    setName('');
    setFile(null);
    handleClose();
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, []);

  const submit = async () => {
    if (!file || !name) return;
    const formData = new FormData();
    formData.append('file', file);

    try {
      const res = await sendFile({ body: formData as any }).unwrap();
      const newResource = {
        name,
        path: { path: res.fileKey, mode: 'S3' as 'S3' | 'LOCAL' | 'URL' },
        size: file.size,
      };
      addResource(newResource);
      onClose();
    } catch (err) {
      console.error('Error uploading image:', err);
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      maxWidth="xs"
      PaperProps={{ sx: { borderRadius: 2, width: '100%' } }}
    >
      <DialogTitle align="center">Add New Resources</DialogTitle>
      <DialogContent>
        <DialogContentText>Resources name</DialogContentText>
        <TextField
          ref={inputRef}
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogContent>
        <CustomUploadFile
          file={file}
          MAXIMUM_FILE_SIZE={20}
          handleSelectFile={handleSelectFile}
          fileType="pdf"
          accept="application/pdf"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={!name || !file}
          onClick={submit}
        >
          Add Resource
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
