import { FC, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useCoursesControllerEnrollUsersMutation } from 'src/app/services/generatedApi';
import { SelectUserMenu } from '../enrollUser/SelectUserMenu';

type InviteUserDialogPropsType = {
  courseId: string;
  initializedSelectedUsersId: string[];
  openDialog: boolean;
  handleClose: () => void;
};

export const InviteUserDialog: FC<InviteUserDialogPropsType> = ({
  courseId,
  initializedSelectedUsersId,
  openDialog,
  handleClose,
}) => {
  const [enrollUsers, { isLoading: loadingEnroll }] =
    useCoursesControllerEnrollUsersMutation();

  const [selectedUsersId, setSelectedUsersId] = useState<string[]>([]);

  const submit = () => {
    enrollUsers({ enrollUserDto: { courseId, userIds: selectedUsersId } })
      .unwrap()
      .then(() => {
        toast.success('Users successfully enroll');
        handleClose();
      })
      .catch(({ data }) => toast.error(data));
  };

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle align="center" fontWeight={700}>
        Select Users to invite
      </DialogTitle>

      <DialogContent>
        <SelectUserMenu
          enrolledUsers={initializedSelectedUsersId}
          selectedUsersId={selectedUsersId}
          setSelectedUsersId={setSelectedUsersId}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          loading={loadingEnroll}
          variant="contained"
          sx={{ px: 3 }}
          onClick={submit}
        >
          Invite
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
