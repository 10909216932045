import { FC, useState } from 'react';
import {
  Button,
  Container,
  Divider,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Search } from 'src/components/atoms/svg/SearchSvg';
import { CertTableRow } from './CertTableRow';
import { CertificateDialog } from './CertificateDialog';
import emptySvg from 'src/assets/svg/Empty.svg';

type SelectCertificateTablePropsType = {
  certsList: { id: string; name: string; selected: boolean }[];
  loadingCerts: boolean;
  handleSelectCert: (index: number) => void;
};

export const SelectCertificateTable: FC<SelectCertificateTablePropsType> = ({
  certsList,
  loadingCerts,
  handleSelectCert,
}) => {
  const [search, setSearch] = useState('');
  const lowerSearch = search.toLowerCase();
  const [openDialog, setOpenDialog] = useState(false);

  const filteredCerts = certsList?.filter((cert) =>
    cert.name.toLocaleLowerCase().includes(lowerSearch)
  );
  return (
    <>
      <Container maxWidth="md" sx={{ margin: 'auto !important', py: 3 }}>
        {!loadingCerts && certsList.length === 0 ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minWidth: 400 }}
          >
            <img src={emptySvg} width={150} height={150} alt="empty courses" />
            <Typography variant="subtitle1" color="primary">
              No Certificate Found !
            </Typography>
          </Stack>
        ) : (
          <>
            <Stack direction="row" spacing={1}>
              <TextField
                fullWidth
                type="search"
                InputProps={{
                  sx: { px: 1 },
                  startAdornment: <Search sx={{ mr: 1 }} />,
                }}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search by name ..."
              />
              <Button variant="contained" sx={{ px: 4 }}>
                Search
              </Button>
            </Stack>
            <Divider sx={{ my: 2 }} />
          </>
        )}
        {loadingCerts ? (
          <Stack spacing={1.5}>
            {[...Array(5)].map((_, index) => (
              <Skeleton key={index} variant="rounded" height={45} />
            ))}
          </Stack>
        ) : (
          <Stack spacing={1.5}>
            {filteredCerts.map(({ id, name, selected }, index) => (
              <CertTableRow
                key={index}
                id={id}
                name={name}
                selected={selected}
                index={index}
                handleSelect={handleSelectCert}
              />
            ))}
          </Stack>
        )}

        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 2, py: 1.2 }}
          onClick={() => setOpenDialog(true)}
        >
          Generate New Certificate
        </Button>
      </Container>
      <CertificateDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        mode="CREATE_CERTIFICATE"
      />
    </>
  );
};
