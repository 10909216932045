import { FC, useCallback } from 'react';
import { Paper, Skeleton, Stack, Typography } from '@mui/material';
import {
  EnrolledUserRo,
  useCoursesControllerFindMyCoursesQuery,
} from 'src/app/services/generatedApi';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

type DashboardTotalCoursesPropsType = {};

export const DashboardTotalCourses: FC<DashboardTotalCoursesPropsType> = () => {
  const { data: userCourses, isLoading } =
    useCoursesControllerFindMyCoursesQuery();

  const getListLength = useCallback(
    (state: EnrolledUserRo['state']) => {
      return userCourses?.filter(
        (course) => course.enrolledData.state === state
      ).length;
    },
    [userCourses]
  );

  const totalCoursesItems = [
    { number: getListLength('ENROLLED'), text: 'Enrolled Courses' },
    { number: getListLength('REGISTERED'), text: 'Registered Courses' },
    { number: getListLength('IN_PROGRESS'), text: 'In Progress' },
    { number: getListLength('COMPLETED'), text: 'Completed Courses' },
    { number: 0, text: 'Passed Quizzes' },
  ];

  return (
    <>
      <Typography variant="title1" fontWeight={700} color="secondary.dark">
        Total Courses
      </Typography>
      <Grid2 container spacing={2} pl={3} py={2}>
        {totalCoursesItems.map(({ number, text }, index) => (
          <Grid2 component={Paper} key={index}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              borderRadius={1}
              bgcolor="customGray.text0"
              color="white"
              width={{ xs: 70, lg: 90 }}
              height={{ xs: 70, lg: 90 }}
              sx={{ px: 3, pb: 1.5 }}
            >
              <Typography fontSize={{ xs: 25, md: 30 }} fontWeight="bold">
                {isLoading ? (
                  <Skeleton
                    variant="rectangular"
                    height={25}
                    width={25}
                    sx={{
                      bgcolor: 'secondary.light',
                      borderRadius: 1,
                      my: 1.5,
                    }}
                  />
                ) : (
                  number
                )}
              </Typography>
              <Typography variant="text2" fontWeight="bold" textAlign="center">
                {text}
              </Typography>
            </Stack>
          </Grid2>
        ))}
      </Grid2>
    </>
  );
};
