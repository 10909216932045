import { FC, Fragment } from 'react';
import { Skeleton, Stack, Typography } from '@mui/material';
import { useLocation, Link as RouterLink, useParams } from 'react-router-dom';
import { dashboardTemplateRouteType } from '../templates/DashboardTemplate';
import {
  useBaseCoursesControllerGetByIdQuery,
  useCoursesControllerGetCourseByIdQuery,
  useCoursesControllerGetMyCourseByIdQuery,
  useUsersControllerGetUserProfileByIdQuery,
} from 'src/app/services/generatedApi';

type BreadCrumbsPropsType = {
  routes: dashboardTemplateRouteType[];
};

export const BreadCrumbs: FC<BreadCrumbsPropsType> = ({ routes }) => {
  const { pathname } = useLocation();

  const pathItems = pathname.split('/');

  const params = useParams() as {
    courseId?: string;
    baseCourseId?: string;
    myCourseId?: string;
    userId?: string;
  };

  const { data: companyCourse, isLoading: getCourseLoading } =
    useCoursesControllerGetCourseByIdQuery(
      { id: params.courseId! },
      { skip: !params.courseId }
    );

  const { data: baseCourse, isLoading: getBaseCourseLoading } =
    useBaseCoursesControllerGetByIdQuery(
      { id: params.baseCourseId! },
      { skip: !params.baseCourseId }
    );

  const { data: myCourse, isLoading: getMyCourseLoading } =
    useCoursesControllerGetMyCourseByIdQuery(
      { id: params.myCourseId! },
      { skip: !params.myCourseId }
    );

  const { data: user, isLoading: getUserLoading } =
    useUsersControllerGetUserProfileByIdQuery(
      { id: params.userId! },
      { skip: !params.userId }
    );

  const getName = () => {
    if (params.courseId && companyCourse) return companyCourse.name;
    if (params.userId && user) return `${user.firstName} ${user.lastName}`;
    if (params.baseCourseId && baseCourse) return baseCourse.name;
    if (params.myCourseId && myCourse) return myCourse.name;
    return ' - ';
  };

  return (
    <Stack direction="row" spacing={1} pb={2}>
      {getBaseCourseLoading ||
      getCourseLoading ||
      getMyCourseLoading ||
      getUserLoading ? (
        <Stack direction="row" alignItems="center" spacing={1}>
          {[...Array(3)].map((_, index) => (
            <Fragment key={index}>
              <Typography variant="subtitle2">
                <Skeleton width={60} />
              </Typography>
              {index < 2 && <Typography variant="subtitle2">/</Typography>}
            </Fragment>
          ))}
        </Stack>
      ) : (
        <>
          {routes.map(({ title, url, autoUrl }, index) => (
            <Stack key={index} direction="row" alignItems="center" spacing={1}>
              <RouterLink
                style={{ textDecoration: 'none' }}
                to={
                  !autoUrl && !url
                    ? '#'
                    : !autoUrl
                    ? url!
                        .split('/')
                        .map((item) =>
                          item.startsWith(':')
                            ? (params as any)[item.slice(1)]
                            : item
                        )
                        .join('/')
                    : '/' + pathItems.slice(0, index + 1).join('/')
                }
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    cursor: url ? 'pointer' : 'default',
                    borderColor: 'transparent',
                    color: 'black',
                    '&:hover': { color: url && 'customGray.text2' },
                  }}
                >
                  {title === 'Name' ? getName() : title}
                </Typography>
              </RouterLink>
              {routes.length - 1 > index && (
                <Typography variant="subtitle2">/</Typography>
              )}
            </Stack>
          ))}
        </>
      )}
    </Stack>
  );
};
