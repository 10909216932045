import { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  CompanyUsageRo,
  useCompaniesControllerUpdateCompanyLicenseMutation,
} from 'src/app/services/generatedApi';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toast } from 'react-toastify';

type EditLimitDialogPropsType = {
  open: boolean;
  onClose: () => void;
  id: string;
  initialValues: CompanyUsageRo;
  name: string;
};

export const EditLimitDialog: FC<EditLimitDialogPropsType> = ({
  open,
  onClose,
  id,
  initialValues,
  name,
}) => {
  const [value, setValue] = useState<Dayjs | null>(
    dayjs(initialValues.expirationTime)
  );

  const [maxUsers, setMaxUsers] = useState(initialValues.maxUsers);

  const [editLimits, { isLoading: loadingEdit }] =
    useCompaniesControllerUpdateCompanyLicenseMutation();

  const submitHandler = () => {
    if (!value || !maxUsers) return;

    editLimits({
      updateCompanyLimitDto: {
        id,
        maxPages: 0,
        maxUsers,
        expirationTime: value.format('YYYY-MM-DD HH:mm:ss'),
      },
    })
      .unwrap()
      .then(() => {
        toast.success('Company limits updated successfully');
        onClose();
      })
      .catch(({ data }) => toast.error(data));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle align="center" noWrap>
        Edit Company {name}'s Limits
      </DialogTitle>
      <DialogContent>
        <Stack alignItems="center" spacing={1} px={3} py={1}>
          <TextField
            fullWidth
            type="number"
            label="Max Users"
            value={maxUsers}
            onChange={(e) => setMaxUsers(Number(e.target.value))}
            sx={{ my: 1 }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Expiration Time"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={loadingEdit}
          variant="contained"
          color="primary"
          component="button"
          type="submit"
          disabled={!value || !maxUsers}
          onClick={submitHandler}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
