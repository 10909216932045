import { IconButton, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTicketControllerDeleteTicketMutation } from 'src/app/services/generatedApi';
import { DeleteDialog } from 'src/components/atoms/DeleteDialog';
import { Trash } from 'src/components/atoms/svg/TrashSvg';
import { StyledTableCell } from 'src/components/molecules/StyledTableCell';
import { convertDate } from 'src/utils/date';

type TicketTableRowPropsType = {
  row: {
    id: string;
    title: string;
    subject: string;
    dateCreated: string;
    status: 'OPEN' | 'IN_PROGRESS' | 'CLOSED';
  };
  index: number;
};

export const TicketTableRow: FC<TicketTableRowPropsType> = ({ index, row }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [deleteTicket, { isLoading }] =
    useTicketControllerDeleteTicketMutation();

  const submitDeleteTicket = () => {
    deleteTicket({ id: row.id })
      .unwrap()
      .then(() => toast.success('Ticket deleted successfully'))
      .catch(({ data }) => toast.error(data));
  };

  return (
    <>
      <TableRow
        component={Link}
        to={`ticket/${row.id}`}
        hover
        sx={{
          cursor: 'pointer',
          textDecoration: 'none',
          backgroundColor: index % 2 === 0 ? 'white' : '#ecf9f4',
        }}
      >
        <StyledTableCell component="th" scope="row">
          {row.title}
        </StyledTableCell>
        <StyledTableCell>{row.subject}</StyledTableCell>
        <StyledTableCell>
          {convertDate(row.dateCreated, 'DD/MM/YYYY')}
        </StyledTableCell>
        <StyledTableCell>{row.status}</StyledTableCell>
        <StyledTableCell>
          <IconButton
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpenDeleteDialog(true);
            }}
          >
            <Trash fontSize="small" />
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <DeleteDialog
        openDialog={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        type="Ticket"
        name={row.title}
        submitDelete={submitDeleteTicket}
        loading={isLoading}
      />
    </>
  );
};
