import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { StackedAreaChart } from 'src/components/atoms/StackedAreaChart';

type DashboardReportPropsType = {};

export const DashboardReport: FC<DashboardReportPropsType> = () => {
  return (
    <>
      <Stack direction="row" alignItems="end" spacing={2} py={2}>
        <Typography variant="title1" fontWeight={700} color="secondary.dark">
          Enrollment Report
        </Typography>
      </Stack>
      <StackedAreaChart />
    </>
  );
};
