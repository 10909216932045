import { FC, useEffect, useRef, useState } from 'react';
import { Skeleton, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { UserActivityRo } from 'src/app/services/generatedApi';
import { StackStyle } from './constants';
import { LogBox } from './LogBox';

type CustomTimelinePropsType = {
  selectedDate: string | null;
  setCurrentDate: (date: string) => void;
  activity: UserActivityRo[];
  isLoading: boolean;
};

export const CustomTimeline: FC<CustomTimelinePropsType> = ({
  selectedDate,
  setCurrentDate,
  activity,
  isLoading,
}) => {
  const [selected, setSelected] = useState(0);
  const [isJumping, setIsJumping] = useState(false);
  const timeline = useRef<HTMLDivElement>();
  const itemsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    if (!timeline.current || !activity || isJumping) return;

    const onScroll = (e: any) => {
      for (let i = 0; i < itemsRef.current.length; i++) {
        const itemRef = itemsRef.current[i];
        const headBounding = itemRef.getBoundingClientRect();

        if (headBounding.top > 200) {
          setCurrentDate(dayjs(activity[i].createdAt).format('YYYY-MM-DD'));
          setSelected(i);
          return;
        }
      }
    };

    timeline.current.addEventListener('scroll', onScroll);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeline.current?.removeEventListener('scroll', onScroll);
    };
  }, [activity, isJumping, setCurrentDate]);

  const selectItem = (index: number) => {
    setIsJumping(true);
    const scrollY = itemsRef.current[index].offsetTop;
    timeline?.current?.scrollTo({
      top: scrollY - 40,
      left: 0,
      behavior: 'smooth',
    });
    setTimeout(() => setIsJumping(false), 500);
  };

  useEffect(() => {
    if (!selectedDate || !activity || !activity) return;
    const itemsDate = activity
      .map((item) => dayjs(item.createdAt).format('YYYY-MM-DD'))
      .sort();

    let selectedItemIndex = 0;

    for (let i = 0; i < itemsDate.length; i++) {
      selectedItemIndex = Math.max(0, i - 1);
      if (itemsDate[i] > selectedDate) break;
    }

    selectItem(selectedItemIndex);
  }, [activity, selectedDate]);

  if (isLoading)
    return (
      <Stack px={5} spacing={2} maxHeight={500}>
        {[...Array(4)].map((_, index) => (
          <Stack direction="row" px={5} py={2} spacing={5} key={index}>
            <Skeleton variant="rounded" width={40} />
            <Skeleton variant="rounded" width={2} height={80} />
            <Stack py={2} spacing={1}>
              <Skeleton variant="rounded" width={60} />
              <Skeleton variant="rounded" height={6} width={300} />
            </Stack>
            <Skeleton variant="rounded" width={80} height={30} />
          </Stack>
        ))}
      </Stack>
    );

  return (
    <Stack sx={StackStyle} ref={timeline}>
      {activity?.map(({ method, createdAt: date, callee }, index) => {
        return (
          <LogBox
            key={index}
            callee={callee}
            date={date}
            index={index}
            itemsRef={itemsRef}
            method={method}
            selectItem={selectItem}
            selected={selected}
          />
        );
      })}
    </Stack>
  );
};
