import { PhotoOutlined } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';

type SetCourseImagePropsType = {
  picture: string | null;
  image: File | null;
  selectImageError: boolean;
  handleSelectImage: (event: any) => Promise<void>;
};

export const SetCourseImage: FC<SetCourseImagePropsType> = ({
  picture,
  image,
  selectImageError,
  handleSelectImage,
}) => {
  return (
    <>
      <Typography sx={{ mt: 1 }}>Course Image</Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        border="2px dashed"
        borderColor={selectImageError ? 'error.light' : 'secondary.light'}
        borderRadius={1}
      >
        {picture ? (
          <img
            src={picture}
            alt="coursePicture"
            style={{
              width: '80px',
              height: '80px',
              padding: 5,
            }}
          />
        ) : (
          <PhotoOutlined color="primary" fontSize="large" sx={{ m: 2 }} />
        )}

        {image ? (
          <Stack alignItems="start" sx={{ maxWidth: '50%' }}>
            <Typography
              variant="subtitle2"
              sx={{
                width: '100%',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {image.name}
            </Typography>
            <Typography variant="text0">
              {(image.size / 1048576).toFixed(2)} MB
            </Typography>
          </Stack>
        ) : picture ? (
          <Stack alignItems="start">
            <Typography variant="text0" fontWeight="bold">
              You can edit image
            </Typography>
            <Typography variant="text2">
              SVG, PNG, JPG or Jpeg (12MB)
            </Typography>
          </Stack>
        ) : (
          <Stack alignItems="start">
            <Typography variant="text1" noWrap>
              Upload a picture for the course.
            </Typography>
            <Typography variant="text2">
              SVG, PNG, JPG or Jpeg (12MB)
            </Typography>
          </Stack>
        )}
        <Stack px={1}>
          <Button
            size="small"
            component="label"
            variant="outlined"
            sx={{ borderRadius: 5 }}
          >
            Browse
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              onChange={handleSelectImage}
              hidden
            />
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
