import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { PostAdd } from '@mui/icons-material';

type CustomUploadFilePropsType = {
  file: File | null;
  MAXIMUM_FILE_SIZE: number;
  handleSelectFile: (event: any) => void;
  fileType: string;
  accept: string;
};

export const CustomUploadFile: FC<CustomUploadFilePropsType> = ({
  file,
  MAXIMUM_FILE_SIZE,
  handleSelectFile,
  fileType,
  accept,
}) => {
  return (
    <Stack
      alignItems="center"
      spacing={2}
      border="2px dashed"
      borderColor="secondary.light"
      py={1.6}
      borderRadius={1}
      color="customGray.text0"
    >
      <PostAdd color="primary" sx={{ fontSize: 50 }} />
      <Stack alignItems="center">
        {file ? (
          <>
            <Typography variant="subtitle2" noWrap maxWidth={250}>
              {file.name}
            </Typography>
            <Typography variant="text0">
              {(file.size / (1024 * 1024)).toFixed(2)} MB
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="text1" noWrap>
              Upload your resource file.
            </Typography>
            <Typography variant="text2">
              {fileType.toUpperCase()} ({MAXIMUM_FILE_SIZE}MB)
            </Typography>
          </>
        )}
      </Stack>
      <Button
        size="small"
        component="label"
        variant="contained"
        sx={{ borderRadius: 3, mx: 1, px: 2 }}
      >
        Browse
        <input type="file" accept={accept} onChange={handleSelectFile} hidden />
      </Button>
    </Stack>
  );
};
