import type { FC } from 'react';
import { Stack, useTheme } from '@mui/material';
import BeatLoader from 'react-spinners/BeatLoader';

const PageLoading: FC = () => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      minHeight="100%"
    >
      <BeatLoader color={theme.palette.primary.main} loading size={50} />
    </Stack>
  );
};

export default PageLoading;
