import { LoadingButton } from '@mui/lab';
import { FC } from 'react';
import { toast } from 'react-toastify';
import { useWorkspacesControllerInviteUserToWorkspaceMutation } from 'src/app/services/generatedApi';

type AddUserButtonPropsType = { groupId: string; userId: string };

export const AddUserButton: FC<AddUserButtonPropsType> = ({
  groupId,
  userId,
}) => {
  const [addUser, { isLoading: loadingAddUser }] =
    useWorkspacesControllerInviteUserToWorkspaceMutation();

  const addUserOnClick = (userId: string) => {
    addUser({
      inviteUsersDto: { workspaceId: groupId, workspaceUsers: [userId] },
    })
      .unwrap()
      .then()
      .catch(({ data }) => toast.error(data));
  };

  return (
    <LoadingButton
      size="small"
      variant="contained"
      loading={loadingAddUser}
      onClick={() => addUserOnClick(userId)}
      sx={{ width: { xs: 94, sm: 111 } }}
    >
      Add
    </LoadingButton>
  );
};
