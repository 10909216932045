import { FC, useEffect, useMemo, useState } from 'react';
import { Button, IconButton, Stack } from '@mui/material';
import { Home } from 'src/components/atoms/svg/HomeSvg';
import { AdminSidebarButton } from 'src/components/atoms/SidebarButton';
import { Help } from 'src/components/atoms/svg/HelpSvg';
import { Logout } from 'src/components/atoms/svg/LogoutSvg';
import { Logo } from 'src/components/atoms/svg/LogoSvg';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/app/reduxCustomHooks';
import { logoutAction } from 'src/app/slices/authSlice';
import { CompanySvg } from 'src/components/atoms/svg/CompanySvg';
import { ManageHistorySvg } from 'src/components/atoms/svg/ManageHistorySvg';
import { ManageAccountSvg } from 'src/components/atoms/svg/ManageAccountSvg';
import { AdminPanelSvg } from 'src/components/atoms/svg/AdminPanelSvg';
import { CourseStoreSvg } from 'src/components/atoms/svg/CourseStoreSvg';
import { MyCoursesSvg } from 'src/components/atoms/svg/MyCoursesSvg';

const sidebarItems = [
  { title: 'home', icon: Home, path: '/' },
  { title: 'my-courses', icon: MyCoursesSvg, path: '/my-courses' },
  {
    title: 'company',
    icon: CompanySvg,
    path: '/company/courses',
    subRoutes: [
      { title: 'Courses', path: '/company/courses' },
      { title: 'Users', path: '/company/users' },
      { title: 'Groups', path: '/company/groups' },
      { title: 'Email Templates', path: '/company/email-templates' },
    ],
  },
  { title: 'course-store', icon: CourseStoreSvg, path: '/course-store' },
  { title: 'history', icon: ManageHistorySvg, path: '/history' },
  {
    title: 'setting',
    icon: ManageAccountSvg,
    path: '/setting/profile',
    subRoutes: [
      { title: 'Profile', path: '/setting/profile' },
      { title: 'Notifications', path: '/setting/notifications' },
    ],
  },
  {
    title: 'admin',
    icon: AdminPanelSvg,
    path: '/admin/companies',
    subRoutes: [
      { title: 'Companies', path: '/admin/companies' },
      { title: 'Users', path: '/admin/users' },
      { title: 'Base Courses', path: '/admin/base-courses' },
      { title: 'Email Template', path: '/admin/email-templates' },
    ],
  },
  { title: 'support', icon: Help, path: '/support' },
];

export const AdminSidebar: FC = () => {
  const [selectedButton, setSelectedButton] = useState('home');

  const { isAdmin, selectedCompanyId } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    selectedCompanyId: state.auth.adminSelectedCompanyId,
  }));

  const { pathname } = useLocation();

  const current = useMemo(
    () => sidebarItems.find((item) => item.title === selectedButton),
    [selectedButton]
  );

  useEffect(() => {
    if (pathname === '/') setSelectedButton('home');
    if (pathname.includes('company')) setSelectedButton('company');
    if (pathname.includes('history')) setSelectedButton('history');
    if (pathname.includes('setting')) setSelectedButton('setting');
    if (pathname.includes('admin')) setSelectedButton('admin');
    if (pathname.includes('support')) setSelectedButton('support');
    if (pathname.includes('my-courses')) setSelectedButton('my-courses');
    if (pathname.includes('course-store')) setSelectedButton('course-store');
  }, [pathname]);

  const dispatch = useAppDispatch();

  const listByAuthorize = useMemo(() => {
    if (isAdmin) {
      if (selectedCompanyId) {
        return sidebarItems.filter(
          (item) => item.title !== 'support' && item.title !== 'admin'
        );
      } else {
        return sidebarItems.filter(
          (item) => item.title !== 'support' && item.title !== 'company'
        );
      }
    } else
      return sidebarItems.filter(
        (item) => item.title !== 'support' && item.title !== 'admin'
      );
  }, [isAdmin, selectedCompanyId]);

  return (
    <Stack direction="row" display={{ xs: 'none', md: 'flex' }}>
      <Stack
        bgcolor="secondary.dark"
        justifyContent="space-between"
        py={4}
        px={1}
      >
        <Stack spacing={5} alignItems="center">
          <Logo fontSize="large" />
          <Stack spacing={3} pt={3}>
            {listByAuthorize.map((item, index) => (
              <AdminSidebarButton
                key={index}
                title={item.title}
                icon={item.icon}
                path={item.path}
                isSelected={selectedButton === item.title}
              />
            ))}
          </Stack>
        </Stack>
        <Stack spacing={1.5}>
          {sidebarItems
            .filter((item) => item.title === 'support')
            .map((item, index) => (
              <AdminSidebarButton
                key={index}
                title={item.title}
                icon={item.icon}
                path={item.path}
                isSelected={selectedButton === item.title}
              />
            ))}
          <IconButton
            color="secondary"
            onClick={() => dispatch(logoutAction())}
          >
            <Logout />
          </IconButton>
        </Stack>
      </Stack>
      <Stack
        sx={{
          transition: 'all 1s',
          WebkitTransition: 'all 1s',
          width: current?.subRoutes ? 120 : 0,
          backgroundColor: '#3F3F3F',
          py: 4,
          overflow: 'hidden',
        }}
      >
        {current?.subRoutes?.map(({ title, path }, index) => (
          <Button
            key={index}
            sx={{
              transition: 'all 1s',
              WebkitTransition: 'all 1s',
              color: 'white',
              py: 2,
              borderRadius: 0,
              backgroundColor: pathname.includes(path) ? '#6F6F6F' : '#3F3F3F',
              textDecoration: 'none',
              '&:hover': {
                backgroundColor: pathname.includes(path)
                  ? '#6F6F6F'
                  : 'secondary.dark',
              },
              whiteSpace: 'nowrap',
            }}
            component={RouterLink}
            to={path}
          >
            {title}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
};
