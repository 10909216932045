import { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Typography } from '@mui/material';
import { useCoursesControllerGetMyCourseByIdQuery } from 'src/app/services/generatedApi';
import { useCustomCoursesControllerGenerateCertificateMutation } from 'src/app/services/api';
import { CourseLicenseSvg } from 'src/components/atoms/svg/CourseLicenseSvg';
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';

const CourseResult: FC = () => {
  const [generateCertificate, { isLoading }] =
    useCustomCoursesControllerGenerateCertificateMutation();

  const { myCourseId } = useParams();

  const { data: myCourse } = useCoursesControllerGetMyCourseByIdQuery(
    { id: myCourseId! },
    { skip: !myCourseId }
  );

  const submitHandler = () => {
    if (!myCourse) return;
    generateCertificate({ idDto: { id: myCourse.enrolledData.id } }).then(
      (res: any) => {
        if (res.data) {
          const blob = new Blob([res.data], { type: 'images/png' });
          saveAs(blob, 'certificate.png');
        }
      }
    );
  };

  return (
    <Grid container alignItems="center" spacing={4}>
      <Grid item xs>
        <Typography variant="title0" fontWeight={800}>
          Congratulations
        </Typography>
        <Stack px={3} spacing={2} py={5}>
          <Typography variant="title1" fontWeight="bold">
            Course completed
          </Typography>
        </Stack>
        <Typography variant="subtitle1" fontFamily="sans-serif">
          Congratulation.
        </Typography>
        <Typography variant="subtitle1" fontFamily="sans-serif">
          You have successfully completed this course
        </Typography>
        <Typography variant="subtitle1" fontFamily="sans-serif">
          Now you can download the license of this course.
        </Typography>
        <Typography variant="subtitle1" fontFamily="sans-serif">
          Or go to the dashboard and start other courses.
        </Typography>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="end"
          spacing={2}
          py={4}
        >
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={submitHandler}
          >
            Download Certificate
          </LoadingButton>
        </Stack>
      </Grid>
      <Grid item xs>
        <Stack display={{ xs: 'none', md: 'flex' }}>
          <CourseLicenseSvg sx={{ minWidth: 500, minHeight: 500 }} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CourseResult;
