import { ChangeEvent, FC, MouseEvent, useState } from 'react';
import {
  Box,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { LmsTablePagination } from 'src/components/atoms/LmsTablePagination';
import { WorkspaceRo } from 'src/app/services/generatedApi';
import { GroupTableRow } from './GroupTableRow';
import { GroupsTableToolbar } from './GroupsTableToolbar';
import { GroupsTableHead } from './GroupsTableHead';

export type GroupDataType = {
  id: string;
  name: string;
  users: number;
  description: string;
  createdAt: string;
};

const createData = (
  id: string,
  name: string,
  users: number,
  description: string,
  createdAt: string
): GroupDataType => {
  return {
    id,
    name,
    users,
    description,
    createdAt,
  };
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

export type Order = 'asc' | 'desc';

function getComparator<Key extends keyof GroupDataType>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type GroupsTablePropsType = {
  groups: WorkspaceRo[];
  isLoading: boolean;
};

export const GroupsTable: FC<GroupsTablePropsType> = ({
  groups,
  isLoading,
}) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof GroupDataType>('name');
  const [selected, setSelected] = useState<string[]>([]);
  const rowsPerPage = 5;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const rows =
    groups
      ?.filter((group) =>
        group.name.toLowerCase().includes(search.toLowerCase())
      )
      .map((group) => {
        return createData(
          group.id,
          group.name,
          group.workspaceUsers.length,
          group.description,
          group.createdAt
        );
      }) || [];

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof GroupDataType
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (e: any, value: number) => setPage(value);

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <Box px={8} sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <GroupsTableToolbar
          loading={isLoading}
          selected={selected}
          removeSelection={() => setSelected([])}
          search={search}
          setSearch={setSearch}
        />
        <TableContainer>
          {isLoading ? (
            <Stack spacing={0.5} p={1} width="100%">
              {[...Array(6)].map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={45}
                  width="100%"
                  sx={{ bgcolor: 'secondary.light', borderRadius: 1 }}
                />
              ))}
            </Stack>
          ) : (
            <Table sx={{ minWidth: 750 }} size="medium">
              <GroupsTableHead
                numSelected={selected?.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
              />
              <TableBody>
                {stableSort<GroupDataType>(rows, getComparator(order, orderBy))
                  .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    return (
                      <GroupTableRow
                        key={index}
                        row={row}
                        index={index}
                        isItemSelected={isItemSelected}
                        handleClick={handleClick}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <LmsTablePagination
          rows={rows}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </Box>
  );
};
