import { BusinessCenter } from '@mui/icons-material';
import { Chip, IconButton, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { useUsersControllerGetUserProfileQuery } from 'src/app/services/generatedApi';
import { NotificationMenu } from 'src/components/atoms/NotificationMenu';
import { SelectCompany } from 'src/components/atoms/SelectCompany';
import { Menu } from 'src/components/atoms/svg/MenuSvg';
import { Person } from 'src/components/atoms/svg/PersonSvg';

type HeaderPropsType = { title: string };

export const Header: FC<HeaderPropsType> = ({ title }) => {
  const { name, isAdmin, isManager } = useAppSelector((state) => ({
    name: state.auth.user?.firstName,
    isAdmin: state.auth.isAdmin,
    isManager: state.auth.isManager,
  }));

  const { data: user } = useUsersControllerGetUserProfileQuery();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      p={2}
      alignItems="center"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton sx={{ display: { xs: 'flex', md: 'none' } }}>
          <Menu />
        </IconButton>
        <Stack>
          <Typography variant="title1" fontWeight={900} color="secondary.main">
            {title}
          </Typography>
          {pathname === '/' && (
            <Typography variant="text1" color="secondary.light">
              {`Welcome ${name}`}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={2}>
        {isAdmin && <SelectCompany />}
        {!isAdmin && isManager && user && (
          <Chip
            icon={
              <BusinessCenter color={isManager ? 'primary' : 'secondary'} />
            }
            label={`${user.company?.name} Company`}
            clickable={isManager}
            onClick={() => navigate('/company/courses')}
            sx={{
              bgcolor: isManager ? 'primary.light' : 'secondary.light',
              px: 0.5,
              py: 2.1,
            }}
          />
        )}
        <NotificationMenu />
        <IconButton component={Link} to="/setting/profile">
          <Person />
        </IconButton>
      </Stack>
    </Stack>
  );
};
