import { FC, MouseEvent, useState } from 'react';
import {
  Box,
  Checkbox,
  IconButton,
  Stack,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { GroupOutlined } from '@mui/icons-material';
import { StyledTableCell } from '../../molecules/StyledTableCell';
import { Edit } from 'src/components/atoms/svg/EditSvg';
import { CreateGroupDialog } from './CreateGroupDialog';
import { ManageUsersDialog } from './ManageUsersDialog';
import { convertDate } from 'src/utils/date';
import { Trash2 } from 'src/components/atoms/svg/TrashSvg';
import { DeleteDialog } from 'src/components/atoms/DeleteDialog';
import { useWorkspacesControllerDeleteWorkspaceMutation } from 'src/app/services/generatedApi';
import { toast } from 'react-toastify';

export const GroupTableRow: FC<{
  row: {
    id: string;
    name: string;
    users: number;
    description: string;
    createdAt: string;
  };
  isItemSelected: boolean;
  index: number;
  handleClick: (event: MouseEvent<unknown>, name: string) => void;
}> = ({ row, isItemSelected, index, handleClick }) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openManageUsersDialog, setOpenManageUsersDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { id, name, users, description, createdAt } = row;

  const [deleteGroup, { isLoading: loadingDeleteGroup }] =
    useWorkspacesControllerDeleteWorkspaceMutation();

  const submitDeleteGroup = () => {
    deleteGroup({ id })
      .unwrap()
      .then(() => {
        toast.success('Group deleted successfully');
        setOpenDeleteDialog(false);
      })
      .catch(({ data }) => toast.error(data));
  };

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: index % 2 === 0 ? 'white' : '#ecf9f4',
          // rgba(0, 0, 0, 0.04)
          cursor: 'pointer',
        }}
        hover
        onClick={(event) => handleClick(event, row.name)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.name}
        selected={isItemSelected}
      >
        <StyledTableCell padding="checkbox">
          <Checkbox color="primary" checked={isItemSelected} />
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          <Tooltip
            arrow
            title={
              <Box p={0.5}>
                <Typography variant="text2">{description}</Typography>
              </Box>
            }
          >
            <Typography sx={{ width: 'fit-content' }}>{name}</Typography>
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell>{users}</StyledTableCell>
        <StyledTableCell>
          {convertDate(createdAt, 'DD/MM/YYYY')}
        </StyledTableCell>
        <StyledTableCell align="center">
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
          >
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setOpenManageUsersDialog(true);
              }}
            >
              <GroupOutlined fontSize="small" />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setOpenEditDialog(true);
              }}
              color="secondary"
            >
              <Edit fontSize="small" />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setOpenDeleteDialog(true);
              }}
            >
              <Trash2 fontSize="small" />
            </IconButton>
          </Stack>
        </StyledTableCell>
      </TableRow>
      <CreateGroupDialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        groupId={row.id}
        initialValues={{ name, description }}
      />
      <ManageUsersDialog
        open={openManageUsersDialog}
        handleClose={() => setOpenManageUsersDialog(false)}
        groupId={row.id}
      />
      <DeleteDialog
        openDialog={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        submitDelete={submitDeleteGroup}
        type="Group"
        name={row.name}
        loading={loadingDeleteGroup}
      />
    </>
  );
};
