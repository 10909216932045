import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type HomeSvgPropsType = { selected: boolean };

export const Home: FC<SvgIconProps & HomeSvgPropsType> = ({
  selected,
  ...props
}) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx }}>
      <path
        d="M23.3553 10.4388C23.3548 10.4383 23.3542 10.4377 23.3537 10.4372L13.5626 0.647461C13.1452 0.22998 12.5904 0 12.0001 0C11.4099 0 10.8551 0.229797 10.4376 0.647278L0.65158 10.4321C0.648284 10.4354 0.644988 10.4388 0.641692 10.4421C-0.215329 11.304 -0.213864 12.7024 0.645903 13.5621C1.0387 13.955 1.5575 14.1826 2.11218 14.2064C2.1347 14.2086 2.15741 14.2097 2.1803 14.2097H2.57054V21.4144C2.57054 22.84 3.73063 24 5.1568 24H8.98739C9.37561 24 9.69059 23.6852 9.69059 23.2969V17.6484C9.69059 16.9979 10.2198 16.4687 10.8705 16.4687H13.1298C13.7805 16.4687 14.3097 16.9979 14.3097 17.6484V23.2969C14.3097 23.6852 14.6245 24 15.0129 24H18.8435C20.2697 24 21.4298 22.84 21.4298 21.4144V14.2097H21.7916C22.3816 14.2097 22.9365 13.9799 23.3542 13.5624C24.2149 12.7013 24.2153 11.3005 23.3553 10.4388V10.4388Z"
        fill={selected ? '#27B67C' : 'rgba(255, 255, 255, 0.75)'}
      />
    </SvgIcon>
  );
};

export const HomeAdmin: FC<SvgIconProps & HomeSvgPropsType> = ({
  selected,
  ...props
}) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx }}>
      <path
        d="M6.00001 20.9999C5.44773 20.9999 5.00001 20.5522 5.00001 19.9999L5 10.5589L3.66437 11.7473C3.28334 12.086 2.71546 12.0804 2.34176 11.7529L2.2526 11.6643C1.91391 11.2833 1.91954 10.7154 2.24707 10.3417L2.33565 10.2525L10.6713 2.84307C11.3845 2.20912 12.438 2.17183 13.1912 2.7312L13.3287 2.84307L21.6644 10.2525C22.0772 10.6194 22.1143 11.2515 21.7474 11.6643C21.4087 12.0453 20.8441 12.1063 20.4346 11.8249L20.3356 11.7473L19 10.5599L19 19.9999C19 20.5128 18.614 20.9354 18.1166 20.9932L18 20.9999H6.00001ZM12 4.33694L6.98025 8.80112C6.99321 8.86537 7.00001 8.93186 7.00001 8.99994L7 18.9999H17L17 8.99994C17 8.93151 17.0069 8.86469 17.02 8.80013L12 4.33694Z"
        fill={selected ? '#27B67C' : '#F3F3F3'}
      />
    </SvgIcon>
  );
};
