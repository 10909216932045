import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useNotificationControllerCreateNotificationMutation } from 'src/app/services/generatedApi';
import { toast } from 'react-toastify';
import { useCompanyUsers } from 'src/constant/useCompanyUsers';
import { useMyAccessGroup } from '../course/enrollUser/useMyAccessGroups';

type SendNotificationDialogPropsType = {
  mode: 'Group' | 'User';
  openDialog: boolean;
  onClose: () => void;
  selectedUsers: string[];
  removeSelection: () => void;
};

export const SendNotificationDialog: FC<SendNotificationDialogPropsType> = ({
  mode,
  openDialog,
  onClose,
  selectedUsers,
  removeSelection,
}) => {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const { users } = useCompanyUsers({ filter: '' });

  const { myAccessGroups } = useMyAccessGroup();

  const handleClose = () => {
    setTitle('');
    setText('');
    onClose();
  };

  const MAX_ACCEPTABLE_CHARACTER_LENGTH = 10000;
  const hasTextOverflow = useMemo(
    () => text.length > MAX_ACCEPTABLE_CHARACTER_LENGTH,
    [text.length]
  );

  const [sendNotifToSelectedUsers, { isLoading: loadingAdminSendNotif }] =
    useNotificationControllerCreateNotificationMutation();

  const submitSendNotification = () => {
    if (!title || !text) return;
    const convertedUsers =
      mode === 'User'
        ? users
            .filter((user) => selectedUsers.includes(user.username))
            .map((user) => user.id)
        : Array.from(
            new Set(
              myAccessGroups
                .filter((group) => selectedUsers.includes(group.name))
                .flatMap((group) => group.workspaceUsers.map((id) => id))
            )
          );

    sendNotifToSelectedUsers({
      createNotificationDto: { title, text, to: convertedUsers },
    })
      .unwrap()
      .then(() => {
        toast.success('Notifications sent successfully.');
        removeSelection();
        handleClose();
      })
      .catch(({ data }) => toast.error(data));
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperProps={{ sx: { width: '100%', borderRadius: 4, py: 1, px: 2 } }}
      maxWidth="md"
      fullWidth
    >
      <Stack>
        <DialogTitle align="center" fontWeight={700}>
          Send notification
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center" sx={{ pb: 3 }}>
            Please enter choose a title for your notification and then enter
            your custom message
          </DialogContentText>
          <Stack rowGap={2}>
            <TextField
              fullWidth
              value={title}
              onChange={({ target: { value } }) => setTitle(value)}
              label="Notification title"
              inputProps={{ enterKeyHint: 'next' }}
            />
            <TextField
              error={hasTextOverflow}
              fullWidth
              rows={10}
              value={text}
              multiline
              onChange={({ target: { value } }) => setText(value)}
              label="Notification text"
              inputProps={{ enterKeyHint: 'send' }}
              helperText="Please do not write message more than '100000' character"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            width="100%"
            spacing={1}
          >
            <Button
              variant="outlined"
              sx={{ color: 'secondary.main', px: 5 }}
              color="secondary"
              onClick={() => {
                removeSelection();
                handleClose();
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={loadingAdminSendNotif}
              disabled={hasTextOverflow || !title || !text}
              onClick={submitSendNotification}
              variant="contained"
              color="primary"
              autoFocus
              component="button"
              sx={{ color: 'white', px: 5 }}
            >
              Send
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
