import {
  FC,
  ReactElement,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Container, Dialog, Grid, Slide } from '@mui/material';
import { CertificateDialogPropsType } from './types';
import { TransitionProps } from '@mui/material/transitions';
import { DndProvider } from 'react-dnd';
import { CertificateMenu } from './editor/konva-dnd/CertificateMenu';
import { CertificateStage } from './editor/konva-dnd/CertificateStage';
import {
  ImageFieldRo,
  TextFieldDto,
  useBaseCoursesControllerGetByIdQuery,
  useCertificatesControllerGetByIdQuery,
  useCertificatesControllerUpdateMutation,
  useCoursesControllerGetCourseByIdQuery,
  useMediaControllerCreateCertificateMutation,
  useMediaControllerUploadImageMutation,
} from 'src/app/services/generatedApi';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CertificateDialogAppBar } from './CertificateDialogAppBar';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'src/app/reduxCustomHooks';

const Transition = forwardRef(
  (props: TransitionProps & { children: ReactElement }, ref) => (
    <Slide direction="up" ref={ref} {...props} />
  )
);

export const CertificateDialog: FC<CertificateDialogPropsType> = ({
  open,
  handleClose,
  ...others
}) => {
  const [stageWidth, setStageWidth] = useState(0);
  const [aspectRatio, setAspectRatio] = useState<number>(16 / 9);
  const [background, setBackground] = useState<{
    file?: File;
    base64: string;
  }>();
  const [name, setName] = useState('');
  const [uploadedImageFields, setUploadedImageFields] = useState<
    ImageFieldRo[]
  >([]);
  const [notUploadedImageFields, setNotUploadedImageFields] = useState<
    (Omit<ImageFieldRo, 'filePath'> & { file: File })[]
  >([]);
  const [textFields, setTextFields] = useState<TextFieldDto[]>([]);

  const isAdmin = useAppSelector((state) => state.auth.isAdmin);

  const preview = others.mode === 'PREVIEW_CERTIFICATE';

  const certId = useMemo(() => {
    if (
      others.mode === 'PREVIEW_CERTIFICATE' ||
      others.mode === 'UPDATE_CERTIFICATE'
    ) {
      return others.certId;
    }
  }, [others]);

  const { data: initialCertificate } = useCertificatesControllerGetByIdQuery(
    { id: certId! },
    { skip: !certId }
  );

  const { baseCourseId, courseId } = useParams();

  const { data: baseCourse } = useBaseCoursesControllerGetByIdQuery(
    { id: baseCourseId! },
    { skip: !baseCourseId || !isAdmin }
  );

  const { data: course } = useCoursesControllerGetCourseByIdQuery(
    { id: courseId! },
    { skip: !courseId }
  );

  useEffect(() => {
    if (!initialCertificate) return;
    setName(initialCertificate.name);
    setUploadedImageFields(initialCertificate.imageFields);
    setTextFields(initialCertificate.textFields);
    setBackground({ base64: initialCertificate.backgroundUrl });
    setAspectRatio(
      (initialCertificate.size?.width || 1) /
        (initialCertificate.size?.height || 1)
    );
  }, [initialCertificate]);

  const [updateCertificate, { isLoading: loadingUpdate }] =
    useCertificatesControllerUpdateMutation();

  const [createCertificate, { isLoading: loadingCreateCertificate }] =
    useMediaControllerCreateCertificateMutation();

  const [uploadImage, { isLoading: uploadingImage }] =
    useMediaControllerUploadImageMutation();

  const uploadImageItem = async (
    image: Omit<ImageFieldRo, 'filePath'> & { file: File }
  ): Promise<ImageFieldRo> => {
    const formData = new FormData();

    formData.append('image', image.file);

    const res = await uploadImage({ body: formData as any }).unwrap();

    return {
      location: { x: image.location.x, y: image.location.y },
      size: { width: image.size.width, height: image.size.height },
      rotation: image.rotation,
      filePath: { path: res, mode: 'S3' },
      url: image.url,
    };
  };

  const convertImageFieldToDto = (list: ImageFieldRo[]) => {
    const newList = list.map((image) => {
      const { url, ...rest } = image;
      return rest;
    });

    return newList;
  };

  const loadingSubmit =
    loadingUpdate || loadingCreateCertificate || uploadingImage;

  const submitHandler = async () => {
    const uploadedImages = [];

    if (notUploadedImageFields.length > 0) {
      for (let i = 0; i < notUploadedImageFields.length; i++) {
        try {
          const newImage = await uploadImageItem(notUploadedImageFields[i]);
          uploadedImages.push(newImage);
        } catch (err) {
          console.error('Error uploading image: ', err);
        }
      }
    }

    if (
      others.mode === 'UPDATE_CERTIFICATE' &&
      certId &&
      initialCertificate &&
      background
    ) {
      let backgroundFilePath = initialCertificate.backgroundPath;
      if (background.file) {
        const formData = new FormData();
        formData.append('image', background.file);
        const newBackgroundPath = await uploadImage({
          body: formData as any,
        }).unwrap();
        backgroundFilePath = {
          mode: 'S3',
          path: newBackgroundPath,
        };
      }
      updateCertificate({
        id: certId,
        updateCertificateDto: {
          name,
          background: backgroundFilePath,
          imageFields: [
            ...convertImageFieldToDto(uploadedImages),
            ...convertImageFieldToDto(uploadedImageFields),
          ],
          textFields,
          size: { width: stageWidth, height: stageWidth / aspectRatio },
        },
      })
        .unwrap()
        .then(() => {
          toast.success('Certificate updated successfully');
          handleClose();
        })
        .catch((err) => toast.error(err?.data || err));
      return;
    }

    if (
      others.mode === 'CREATE_BASE_COURSE_CERTIFICATE' &&
      baseCourseId &&
      background
    ) {
      const formData = new FormData();
      background.file && formData.append('background', background.file);
      const certificateDetail = {
        name,
        size: { width: stageWidth, height: stageWidth / aspectRatio },
      };
      formData.append('certificate', JSON.stringify(certificateDetail));
      formData.append('textFields', JSON.stringify(textFields));
      formData.append('imageFields', JSON.stringify(uploadedImages));
      formData.append('baseCourseId', baseCourseId);

      createCertificate({ body: formData as any })
        .unwrap()
        .then(() => {
          toast.success('Certificate created successfully');
          handleClose();
        })
        .catch(({ data }) => toast.error(data));

      return;
    }

    if (others.mode === 'CREATE_CERTIFICATE' && background) {
      const formData = new FormData();
      background.file && formData.append('background', background.file);
      const certificateDetail = {
        name,
        size: { width: stageWidth, height: stageWidth / aspectRatio },
      };
      formData.append('certificate', JSON.stringify(certificateDetail));
      formData.append('textFields', JSON.stringify(textFields));
      formData.append('imageFields', JSON.stringify(uploadedImages));

      createCertificate({ body: formData as any })
        .unwrap()
        .then(() => {
          toast.success('Certificate created successfully');
          handleClose();
        })
        .catch(({ data }) => toast.error(data));

      return;
    }
  };

  const baseCourseArguments: string[] = useMemo(() => {
    if (baseCourse)
      return baseCourse.courseArguments.map((arg) => {
        return arg.name;
      });
    if (course)
      return course.baseCourse.courseArguments.map((arg) => {
        return arg.name;
      });
    else return [];
  }, [baseCourse, course]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: { maxHeight: '100vh', overflow: 'hidden' },
      }}
    >
      <CertificateDialogAppBar
        onClose={handleClose}
        disableSubmit={!name || !background}
        loadingSubmit={loadingSubmit}
        submitHandler={submitHandler}
        preview={preview}
      />
      <Container sx={{ pt: 2, height: 'calc(100% - 50px)' }}>
        <DndProvider backend={HTML5Backend}>
          <Grid container spacing={2} sx={{ height: '100%' }}>
            {!preview && (
              <Grid item xs={12} sm={4} sx={{ height: '100%' }}>
                <CertificateMenu
                  aspectRatio={aspectRatio}
                  setAspectRatio={setAspectRatio}
                  setBackground={setBackground}
                  background={background}
                  setName={setName}
                  name={name}
                  baseCourseArguments={baseCourseArguments}
                  imageFields={[...uploadedImageFields]}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={preview ? 12 : 8} sx={{ height: '100%' }}>
              <CertificateStage
                stageWidth={stageWidth}
                setStageWidth={setStageWidth}
                aspectRatio={aspectRatio}
                background={background}
                imageFields={[
                  ...uploadedImageFields,
                  ...notUploadedImageFields,
                ]}
                textFields={textFields}
                onChangeTextField={(index: number, newData: TextFieldDto) => {
                  const newTextFields = [...textFields];
                  newTextFields[index] = newData;
                  setTextFields(newTextFields);
                }}
                onChangeImageField={(index: number, newData: any) => {
                  if (index < uploadedImageFields.length) {
                    const newUploadedImagesField = [...uploadedImageFields];
                    newUploadedImagesField[index] = newData;
                    setUploadedImageFields(newUploadedImagesField);
                  } else {
                    index -= uploadedImageFields.length;
                    const newNotUploadedImagesField = [
                      ...notUploadedImageFields,
                    ];
                    newNotUploadedImagesField[index] = newData;
                    setNotUploadedImageFields(newNotUploadedImagesField);
                  }
                }}
                addTextField={(data: TextFieldDto) =>
                  setTextFields((prev) => [...prev, data])
                }
                addImageField={(
                  data: Omit<ImageFieldRo, 'filePath'> & {
                    file: File;
                  }
                ) => setNotUploadedImageFields((prev) => [...prev, data])}
                removeImageField={(index) => {
                  if (index < uploadedImageFields.length) {
                    setUploadedImageFields((prev) =>
                      prev.filter((_, i) => i !== index)
                    );
                  } else {
                    index -= uploadedImageFields.length;
                    setNotUploadedImageFields((prev) =>
                      prev.filter((_, i) => i !== index)
                    );
                  }
                }}
                removeTextField={(index) =>
                  setTextFields((prev) => prev.filter((_, i) => i !== index))
                }
                preview={preview}
              />
            </Grid>
          </Grid>
        </DndProvider>
      </Container>
    </Dialog>
  );
};
