import { FC } from 'react';
import {
  Button,
  Container,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import {
  useEmailTemplatesControllerGetAllCompanyQuery,
  useEmailTemplatesControllerGetAllQuery,
} from 'src/app/services/generatedApi';
import emptySvg from 'src/assets/svg/Empty.svg';
import { EmailTemplateCard } from 'src/components/organisms/emailTemplate/EmailTemplateCard';
import { useAppSelector } from 'src/app/reduxCustomHooks';

const EmailTemplates: FC = () => {
  const isAdmin = useAppSelector((state) => state.auth.isAdmin);

  const { data: allEmailTemplates, isLoading: loadingAllTemplates } =
    useEmailTemplatesControllerGetAllQuery(undefined as any, {
      skip: !isAdmin,
    });

  const { data: companyEmailTemplates, isLoading: loadingCompanyTemplates } =
    useEmailTemplatesControllerGetAllCompanyQuery();

  const isLoading = loadingAllTemplates || loadingCompanyTemplates;

  const emailTemplates = allEmailTemplates || companyEmailTemplates;

  return (
    <Container maxWidth="md" sx={{ height: '100%' }}>
      <Stack
        pt={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle0">Templates</Typography>
        <Button
          variant="outlined"
          startIcon={<Add />}
          component={Link}
          to="new-template"
        >
          New
        </Button>
      </Stack>
      <Stack p={2} spacing={4}>
        {isLoading ? (
          [...Array(3)].map((_, index) => (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              component={Paper}
              elevation={2}
              sx={{ borderRadius: 2, p: 2 }}
              key={index}
            >
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                spacing={3}
              >
                <Skeleton variant="rounded" width={30} height={30} />
                <Stack>
                  <Skeleton width={100} />
                  <Skeleton width={200} />
                </Stack>
              </Stack>
              <Skeleton variant="rounded" width={120} height={40} />
            </Stack>
          ))
        ) : emailTemplates?.length === 0 ? (
          <Stack alignItems="center">
            <img src={emptySvg} width={200} height={200} alt="empty courses" />
            <Typography variant="subtitle0" color="primary">
              There is no template in your list
            </Typography>
          </Stack>
        ) : (
          emailTemplates?.map(({ id, title, description }, index) => (
            <EmailTemplateCard
              key={index}
              id={id}
              title={title}
              description={description}
            />
          ))
        )}
      </Stack>
    </Container>
  );
};

export default EmailTemplates;
