import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';

const Icon: FC<{ isSelected: boolean; ItemIcon: any }> = ({
  isSelected,
  ItemIcon,
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '30px',
        height: '30px',
        '&:before': isSelected
          ? {
              background:
                'radial-gradient(50% 50% at 50% 50%, rgba(0, 191, 134, 0.5) 0%, rgba(0, 191, 134,0.4) 100%)',
              filter: 'blur(10px)',
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              content: '""',
            }
          : 'none',
      }}
    >
      <ItemIcon
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        selected={isSelected}
      />
    </Box>
  );
};

type SidebarButtonPropsType = {
  title: string;
  path: string;
  icon: any;
  isSelected: boolean;
};

export const SidebarButton: FC<SidebarButtonPropsType> = ({
  title,
  path,
  icon,
  isSelected,
}) => {
  const formattedTitle =
    title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();

  return (
    <RouterLink to={path} style={{ textDecoration: 'none', color: 'white' }}>
      <ListItemButton sx={{ pl: 2, width: 150 }}>
        <ListItemIcon sx={{ minWidth: 45 }}>
          <Icon isSelected={isSelected} ItemIcon={icon} />
        </ListItemIcon>
        <ListItemText
          color="white"
          primary={formattedTitle}
          sx={{ whiteSpace: 'nowrap' }}
        />
      </ListItemButton>
    </RouterLink>
  );
};

type AdminSidebarButtonPropsType = {
  title: string;
  icon: any;
  path: string;
  isSelected: boolean;
};

export const AdminSidebarButton: FC<AdminSidebarButtonPropsType> = ({
  title,
  icon,
  path,
  isSelected,
}) => {
  const convertedTitle = title
    .replace('-', ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return (
    <Tooltip title={convertedTitle} placement="right" arrow>
      <Button fullWidth color="secondary" component={RouterLink} to={path}>
        <Icon isSelected={isSelected} ItemIcon={icon} />
      </Button>
    </Tooltip>
  );
};
