import { AxiosRequestConfig } from 'axios';
import {
  CoursesControllerGenerateCertificateApiArg,
  CoursesControllerGenerateCertificateApiResponse,
  MediaControllerCreateBaseCourseApiArg,
  MediaControllerCreateBaseCourseApiResponse,
  enhancedApi,
} from './generatedApi';

const addTagTypes = [
  'User',
  'Certificate',
  'RateAndReview',
  'BaseCourse',
  'CompanyCourse',
  'CourseStore',
  'MyCourse',
  'Group',
  'Notification',
  'Company',
  'Report',
  'Ticket',
  'EmailTemplate',
];

export const api = enhancedApi
  .injectEndpoints({
    endpoints: (build) => ({
      customMediaControllerCreateBaseCourse: build.mutation<
        MediaControllerCreateBaseCourseApiResponse,
        MediaControllerCreateBaseCourseApiArg & {
          abortController: AbortController;
          onUploadProgress: AxiosRequestConfig['onUploadProgress'];
        }
      >({
        query: ({ body, abortController, onUploadProgress }) => ({
          url: `/api/v1/media`,
          method: 'POST',
          body,
          abortController,
          onUploadProgress,
        }),
      }),
      customCoursesControllerGenerateCertificate: build.mutation<
        CoursesControllerGenerateCertificateApiResponse,
        CoursesControllerGenerateCertificateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/course/generate-certificate`,
          method: 'POST',
          body: queryArg.idDto,
          responseType: 'blob',
        }),
      }),
    }),
  })
  .enhanceEndpoints({
    addTagTypes,
    endpoints: {
      usersControllerFind: { providesTags: () => ['User'] },
      usersControllerGetUserProfile: { providesTags: () => ['User'] },
      usersControllerGetUserProfileById: { providesTags: () => ['User'] },
      usersControllerGetCompanyUsers: { providesTags: () => ['User'] },

      companiesControllerGetAll: { providesTags: () => ['Company'] },
      companiesControllerGetById: { providesTags: () => ['Company'] },

      coursesControllerGetMyCompanyAllCourses: {
        providesTags: () => ['CompanyCourse'],
      },
      coursesControllerGetCompanyAllCourses: {
        providesTags: () => ['CompanyCourse'],
      },
      coursesControllerGetUserCourses: { providesTags: () => ['MyCourse'] },
      coursesControllerFindMyCourses: { providesTags: () => ['MyCourse'] },
      coursesControllerGetMyCourseById: { providesTags: () => ['MyCourse'] },
      coursesControllerGetCourseById: { providesTags: () => ['CompanyCourse'] },

      baseCoursesControllerGetAll: { providesTags: () => ['BaseCourse'] },
      baseCoursesControllerGetById: { providesTags: () => ['BaseCourse'] },
      baseCoursesControllerGetStore: { providesTags: () => ['CourseStore'] },

      certificatesControllerGetAll: { providesTags: () => ['Certificate'] },
      certificatesControllerGetById: { providesTags: () => ['Certificate'] },

      rateAndReviewControllerFindAll: { providesTags: () => ['RateAndReview'] },
      rateAndReviewControllerGetAverageRate: {
        providesTags: () => ['RateAndReview'],
      },
      rateAndReviewControllerGetOne: { providesTags: () => ['RateAndReview'] },

      notificationControllerGetUnseenNotification: {
        providesTags: () => ['Notification'],
      },
      notificationControllerGetPreviewUnseenNotification: {
        providesTags: () => ['Notification'],
      },
      notificationControllerGetUnseenNotificationCount: {
        providesTags: () => ['Notification'],
      },
      notificationControllerGetNotification: {
        providesTags: () => ['Notification'],
      },

      workspacesControllerGetWorkspaceById: {
        providesTags: () => ['User', 'Group'],
      },
      workspacesControllerGetMyCompanyWorkspaces: {
        providesTags: () => ['Group'],
      },
      workspacesControllerGetCompanyWorkspaces: {
        providesTags: () => ['Group'],
      },

      ticketControllerGetTickets: { providesTags: () => ['Ticket'] },
      ticketControllerGetTicketById: { providesTags: () => ['Ticket'] },
      ticketControllerGetMyTickets: { providesTags: () => ['Ticket'] },

      emailTemplatesControllerGetAll: { providesTags: () => ['EmailTemplate'] },
      emailTemplatesControllerFindOne: {
        providesTags: () => ['EmailTemplate'],
      },

      reportControllerGetAllEnrollments: {
        providesTags: () => ['Report'],
      },

      usersControllerCreate: { invalidatesTags: () => ['User'] },
      usersControllerUpdate: { invalidatesTags: () => ['User'] },
      usersControllerDelete: { invalidatesTags: () => ['User', 'Company'] },
      usersControllerAssignRole: { invalidatesTags: () => ['User'] },
      usersControllerUpdateMyProfile: { invalidatesTags: () => ['User'] },

      companiesControllerCreate: { invalidatesTags: () => ['Company', 'User'] },
      companiesControllerDelete: { invalidatesTags: () => ['Company'] },

      mediaControllerCreateBaseCourse: {
        invalidatesTags: () => ['BaseCourse'],
      },
      mediaControllerCreateCertificate: {
        invalidatesTags: () => ['Certificate', 'BaseCourse', 'CompanyCourse'],
      },
      mediaControllerBatchCreateUser: { invalidatesTags: () => ['User'] },

      coursesControllerCreate: {
        invalidatesTags: () => ['BaseCourse', 'CompanyCourse'],
      },
      coursesControllerDelete: {
        invalidatesTags: () => ['BaseCourse', 'CompanyCourse'],
      },
      coursesControllerAdminLaunchCourse: {
        invalidatesTags: () => ['CompanyCourse', 'MyCourse'],
      },
      coursesControllerEnrollUsers: {
        invalidatesTags: () => ['CompanyCourse', 'MyCourse', 'Report'],
      },
      coursesControllerUpdate: { invalidatesTags: () => ['CompanyCourse'] },
      coursesControllerSetCertificate: {
        invalidatesTags: () => ['CompanyCourse'],
      },
      coursesControllerChangeEnrolledUserState: {
        invalidatesTags: () => ['CompanyCourse', 'Report'],
      },
      coursesControllerRemove: { invalidatesTags: () => ['CompanyCourse'] },
      coursesControllerGenerateCertificate: {
        invalidatesTags: () => ['CompanyCourse', 'Certificate'],
      },
      coursesControllerSetFinish: { invalidatesTags: () => ['MyCourse'] },

      baseCoursesControllerSetInStore: {
        invalidatesTags: () => ['BaseCourse', 'CourseStore'],
      },
      baseCoursesControllerSetCertificate: {
        invalidatesTags: () => ['BaseCourse'],
      },
      baseCoursesControllerUpdate: { invalidatesTags: () => ['BaseCourse'] },
      baseCoursesControllerDelete: { invalidatesTags: () => ['BaseCourse'] },

      certificatesControllerUpdate: { invalidatesTags: () => ['Certificate'] },
      certificatesControllerRemove: { invalidatesTags: () => ['Certificate'] },

      rateAndReviewControllerCreate: {
        invalidatesTags: () => ['RateAndReview'],
      },
      rateAndReviewControllerUpdate: {
        invalidatesTags: () => ['RateAndReview'],
      },

      notificationControllerCreateMyCompanyNotification: {
        invalidatesTags: () => ['Notification'],
      },
      notificationControllerCreateNotification: {
        invalidatesTags: () => ['Notification'],
      },
      notificationControllerCreateCompanyNotification: {
        invalidatesTags: () => ['Notification'],
      },
      notificationControllerSeenNotification: {
        invalidatesTags: () => ['Notification'],
      },
      notificationControllerArchiveNotification: {
        invalidatesTags: () => ['Notification'],
      },

      workspacesControllerAdminCreateCompanyWorkspace: {
        invalidatesTags: () => ['Group'],
      },
      workspacesControllerCreateCompanyWorkspace: {
        invalidatesTags: () => ['Group'],
      },
      workspacesControllerUpdateWorkspace: { invalidatesTags: () => ['Group'] },
      workspacesControllerDeleteWorkspace: { invalidatesTags: () => ['Group'] },
      workspacesControllerInviteUserToWorkspace: {
        invalidatesTags: () => ['Group', 'User'],
      },
      workspacesControllerDeleteWorkspaceUser: {
        invalidatesTags: () => ['Group', 'User'],
      },
      usersControllerSuspend: { invalidatesTags: () => ['User'] },
      usersControllerActivate: { invalidatesTags: () => ['User'] },

      mediaControllerUploadImage: {
        invalidatesTags: () => ['CompanyCourse', 'BaseCourse'],
      },
      mediaControllerUploadThumbnail: {
        invalidatesTags: () => ['CompanyCourse', 'BaseCourse'],
      },

      ticketControllerCreateTicket: { invalidatesTags: () => ['Ticket'] },
      ticketControllerAddTicketMessage: { invalidatesTags: () => ['Ticket'] },
      ticketControllerDeleteTicket: { invalidatesTags: () => ['Ticket'] },
      ticketControllerChangeTicketStatus: { invalidatesTags: () => ['Ticket'] },

      emailTemplatesControllerCreate: {
        invalidatesTags: () => ['EmailTemplate'],
      },
      emailTemplatesControllerUpdate: {
        invalidatesTags: () => ['EmailTemplate'],
      },
      emailTemplatesControllerRemove: {
        invalidatesTags: () => ['EmailTemplate'],
      },
      emailTemplatesControllerDelete: {
        invalidatesTags: () => ['EmailTemplate'],
      },
      coursesControllerUnEnrollUsers: {
        invalidatesTags: () => ['CompanyCourse', 'Report'],
      },
    },
  });

/* import { AxiosRequestConfig } from 'axios';
import {
  CoursesControllerGenerateCertificateApiArg,
  CoursesControllerGenerateCertificateApiResponse,
  MediaControllerCreateBaseCourseApiArg,
  MediaControllerCreateBaseCourseApiResponse,
  api as generatedApi,
} from './generatedApi';

export const api = generatedApi
  .injectEndpoints({
    endpoints: (build) => ({
      customMediaControllerCreateBaseCourse: build.mutation<
        MediaControllerCreateBaseCourseApiResponse,
        MediaControllerCreateBaseCourseApiArg & {
          abortController: AbortController;
          onUploadProgress: AxiosRequestConfig['onUploadProgress'];
        }
      >({
        query: ({ body, abortController, onUploadProgress }) => ({
          url: `/api/v1/media`,
          method: 'POST',
          body,
          abortController,
          onUploadProgress,
        }),
      }),
      customCoursesControllerGenerateCertificate: build.mutation<
        CoursesControllerGenerateCertificateApiResponse,
        CoursesControllerGenerateCertificateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/course/generate-certificate`,
          method: 'POST',
          body: queryArg.idDto,
          responseType: 'blob',
        }),
      }),
    }),
  })
  .enhanceEndpoints({
    addTagTypes: [
      'User',
      'Certificate',
      'RateAndReview',
      'BaseCourse',
      'CompanyCourse',
      'CourseStore',
      'MyCourse',
      'Group',
      'Notification',
      'Company',
      'Report',
      'Ticket',
      'EmailTemplate',
    ],
  });
*/

export const {
  useCustomMediaControllerCreateBaseCourseMutation,
  useCustomCoursesControllerGenerateCertificateMutation,
} = api;
