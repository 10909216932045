import { FC } from 'react';
import { AddRounded } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

type CreateBaseCourseCardPropsType = {};

export const CreateBaseCourseCard: FC<CreateBaseCourseCardPropsType> = () => {
  return (
    <Link to="/admin/base-courses/create" style={{ textDecoration: 'none' }}>
      <Button
        fullWidth
        sx={{
          border: '2px dashed #92CBB4',
          borderRadius: 2,
          p: 0,
          height: 365,
        }}
      >
        <Stack alignItems="center" px={3} spacing={2}>
          <Box border={2} lineHeight={0} py={0.7} px={1.5} borderTop={0}>
            <AddRounded fontSize="large" />
          </Box>
          <Typography
            variant="subtitle1"
            color="primary.main"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            Create New Course
          </Typography>
        </Stack>
      </Button>
    </Link>
  );
};
