import { FC, ReactNode, useState } from 'react';

import { Stack, Box, Tab, Tabs } from '@mui/material';
import { ViewUserProfile } from './viewUser/ViewUserProfile';
import { ViewUserCourses } from './viewUser/ViewUserCourses';
import { ViewUserHistory } from './viewUser/ViewUserHistory';

type TabPanelProps = { children?: ReactNode; index: number; value: number };

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} {...other} height="100%">
      {value === index && (
        <Stack py={3} px={1} spacing={3} height="100%">
          {children}
        </Stack>
      )}
    </Box>
  );
};

export const SpecifiedUser: FC = () => {
  const [value, setValue] = useState(0);
  const handleChangeTab = (e: any, newValue: number) => setValue(newValue);

  return (
    <Stack spacing={1.5} height="100%">
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        alignItems="center"
      >
        <Tabs
          value={value}
          onChange={handleChangeTab}
          sx={{
            borderBottom: 1,
            borderColor: 'secondary.dark',
            '& .Mui-selected': { borderBottom: 3, fontWeight: 'bold' },
            '& .MuiButtonBase-root': {
              fontSize: { xs: 10, md: 16 },
              minWidth: 0,
            },
          }}
        >
          <Tab label="Profile" />
          <Tab label="Courses" />
          <Tab label="History" />
        </Tabs>
      </Stack>
      <TabPanel value={value} index={0}>
        <ViewUserProfile />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ViewUserCourses />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ViewUserHistory />
      </TabPanel>
    </Stack>
  );
};
