import { FC, MouseEvent, useState } from 'react';
import {
  Checkbox,
  Chip,
  IconButton,
  Stack,
  TableRow,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  BusinessCenter,
  ChangeCircleOutlined,
  PersonOutline,
} from '@mui/icons-material';
import { StyledTableCell } from '../../molecules/StyledTableCell';
import { Trash2 } from '../../atoms/svg/TrashSvg';
import { DeleteDialog } from 'src/components/atoms/DeleteDialog';
import { useUsersControllerDeleteMutation } from 'src/app/services/generatedApi';
import { toast } from 'react-toastify';
import { UserDataType } from 'src/pages/admin/users/AllUsers';
import { SuspendUserDialog } from './userTable/SuspendUserDialog';
import { useAppSelector } from 'src/app/reduxCustomHooks';

export const UserTableRow: FC<{
  row: UserDataType;
  isItemSelected: boolean;
  index: number;
  handleClick: (event: MouseEvent<unknown>, name: string) => void;
  isCompany: boolean;
}> = ({ row, isItemSelected, index, handleClick, isCompany }) => {
  const navigate = useNavigate();
  const { id, username, name, email, companyName, isActive, isManager } = row;
  const [openDelete, setOpenDelete] = useState(false);
  const [openSuspendDialog, setOpenSuspendDialog] = useState(false);

  const [deleteUser, { isLoading: loadingDelete }] =
    useUsersControllerDeleteMutation();

  const submitDeleteUser = () => {
    deleteUser({ id })
      .unwrap()
      .then(() => toast.success('User deleted successfully'))
      .catch(({ data }) => toast.error(data));
    setOpenDelete(false);
  };

  const user = useAppSelector((state) => state.auth.user);

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: index % 2 === 0 ? 'white' : '#ecf9f4',
          // rgba(0, 0, 0, 0.04)
          cursor: 'pointer',
        }}
        hover
        onClick={() => navigate(id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={username}
        selected={isItemSelected}
      >
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClick(e, username);
            }}
          />
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {isManager && (
              <BusinessCenter fontSize="small" color="primary" sx={{ mb: 0 }} />
            )}
            <Typography>{username}</Typography>
          </Stack>
        </StyledTableCell>
        <StyledTableCell>{name}</StyledTableCell>
        <StyledTableCell>{email}</StyledTableCell>
        {!isCompany && <StyledTableCell>{companyName}</StyledTableCell>}
        <StyledTableCell>
          <Chip
            label={isActive ? 'Active' : 'Passive'}
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setOpenSuspendDialog(true);
            }}
            icon={<ChangeCircleOutlined fontSize="small" />}
            size="small"
            disabled={id === user?.id}
            color={isActive ? 'primary' : 'error'}
          />
        </StyledTableCell>
        <StyledTableCell align="center">
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={1}
          >
            <IconButton
              size="small"
              sx={{ p: 1 }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                navigate(id);
              }}
            >
              <PersonOutline fontSize="small" />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setOpenDelete(true);
              }}
              size="small"
              color="error"
              sx={{ p: 1 }}
            >
              <Trash2 color="error" fontSize="small" />
            </IconButton>
          </Stack>
        </StyledTableCell>
      </TableRow>
      <DeleteDialog
        openDialog={openDelete}
        handleClose={() => setOpenDelete(false)}
        submitDelete={submitDeleteUser}
        type="User"
        name={name}
        loading={loadingDelete}
      />
      <SuspendUserDialog
        openDialog={openSuspendDialog}
        handleClose={() => setOpenSuspendDialog(false)}
        isActive={isActive}
        user={{ id, name }}
      />
    </>
  );
};
