import { FC, Fragment, MouseEvent, useState } from 'react';
import {
  Badge,
  Button,
  Divider,
  IconButton,
  Menu,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useNotificationControllerGetNotificationQuery } from 'src/app/services/generatedApi';
import { Notification } from './svg/NotificationSvg';
import { Picture } from './svg/PictureSvg';
import EmptyMessages from 'src/assets/images/emptyChat.png';
import { getCustomDate } from 'src/utils/date';

export const NotificationMenu: FC = () => {
  const { data: notifications, isLoading } =
    useNotificationControllerGetNotificationQuery();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Stack>
      <IconButton onClick={handleClick}>
        <Badge
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          badgeContent={notifications?.length}
          color="primary"
          sx={{
            '& .MuiBadge-badge': {
              ml: 0.2,
              mt: 0.5,
              fontSize: 12,
              minWidth: 10,
            },
          }}
        >
          <Notification sx={{ width: 28, height: 28 }} />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
            width: 350,
            borderRadius: '12px 0px 12px 12px',
            backgroundColor: 'secondary.main',
            color: 'white',
            '& .MuiMenu-list': { p: 0 },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Stack>
          <Typography sx={{ px: 2, py: 1 }}>Notifications</Typography>
          <Divider sx={{ bgcolor: 'secondary.light' }} />
          {isLoading ? (
            [...Array(3)].map((_, index) => (
              <Stack
                key={index}
                p={1.5}
                spacing={1.5}
                sx={{
                  '&:hover': { backgroundColor: 'customGray.text1' },
                  minWidth: 340,
                }}
              >
                <Stack direction="row" spacing={3}>
                  <Skeleton
                    variant="circular"
                    width={35}
                    height={35}
                    sx={{ backgroundColor: 'secondary.light' }}
                  />
                  <Stack spacing={0.5}>
                    <Skeleton
                      variant="text"
                      sx={{
                        width: 100,
                        fontSize: 18,
                        backgroundColor: 'secondary.light',
                      }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{
                        width: 250,
                        fontSize: 12,
                        backgroundColor: 'secondary.light',
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack alignItems="end">
                  <Skeleton
                    variant="text"
                    sx={{
                      width: 50,
                      fontSize: 10,
                      backgroundColor: 'secondary.light',
                    }}
                  />
                </Stack>
              </Stack>
            ))
          ) : notifications?.length === 0 ? (
            <Stack width="100%" py={2} spacing={2} alignItems="center">
              <img
                src={EmptyMessages}
                style={{ width: 200, height: 140 }}
                alt="EmptyCourses"
              />
              <Typography variant="subtitle2" color="secondary.light">
                Your messages inbox is empty
              </Typography>
            </Stack>
          ) : (
            notifications
              ?.slice(0, 3)
              .reverse()
              .map(({ title, text, date, seen }, index) => (
                <Fragment key={index}>
                  <Stack
                    key={index}
                    p={1.5}
                    spacing={1.5}
                    sx={{
                      '&:hover': { backgroundColor: 'customGray.text1' },
                    }}
                  >
                    <Stack direction="row" spacing={3}>
                      <Picture seen={seen} />
                      <Stack spacing={0.5}>
                        <Typography variant="text1">{title}</Typography>
                        <Typography
                          variant="text2"
                          maxWidth={260}
                          sx={{
                            height: 40,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: '2',
                            overflow: 'hidden',
                          }}
                        >
                          {text}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack alignItems="end">
                      <Typography variant="text3">
                        {getCustomDate(date)} ago
                      </Typography>
                    </Stack>
                  </Stack>
                  {index !== notifications.length - 1 && (
                    <Divider sx={{ bgcolor: 'secondary.light' }} />
                  )}
                </Fragment>
              ))
          )}
          <Button
            variant="contained"
            sx={{ borderRadius: '0 0 12px 12px', py: 1 }}
            component={RouterLink}
            to="/setting/notifications"
          >
            See more ...
          </Button>
        </Stack>
      </Menu>
    </Stack>
  );
};
