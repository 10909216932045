import { Pagination, Stack } from '@mui/material';
import { FC } from 'react';

type LmsTablePaginationPropsType = {
  rows: any[];
  rowsPerPage: number;
  page: number;
  handleChangePage: (e: any, value: number) => void;
};
export const LmsTablePagination: FC<LmsTablePaginationPropsType> = ({
  rows,
  rowsPerPage,
  page,
  handleChangePage,
}) => {
  return (
    <Stack
      display={rows.length <= rowsPerPage ? 'none' : 'flex'}
      alignItems="center"
      py={1}
    >
      <Pagination
        count={Math.ceil(rows.length / rowsPerPage)}
        siblingCount={0}
        page={page}
        onChange={handleChangePage}
        boundaryCount={2}
        showFirstButton
        showLastButton
        sx={{
          '& .MuiPaginationItem-root': {
            backgroundColor: '#fff',
            borderRadius: '10px',
            width: 30,
            height: 30,
          },
          '& .MuiPaginationItem-root.Mui-selected': {
            border: 2,
            borderColor: 'secondary.light',
          },
        }}
      />
    </Stack>
  );
};
