import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type PictureSvgPropsType = { seen: boolean };

export const Picture: FC<SvgIconProps & PictureSvgPropsType> = ({
  seen,
  ...props
}) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx }}>
      <path
        d="M20.4 0H3.6C1.61484 0 0 1.61484 0 3.6V20.4C0 22.3852 1.61484 24 3.6 24H20.4C22.3852 24 24 22.3852 24 20.4V3.6C24 1.61484 22.3852 0 20.4 0ZM3.6 2.4H20.4C21.0615 2.4 21.6 2.93789 21.6 3.6V12.4582L18.283 14.1164C17.0139 14.7516 15.092 14.3625 14.1686 13.2867L11.6537 10.3512C10.7988 9.35508 9.64336 8.80547 8.4 8.80547C7.15664 8.80547 6.00117 9.35508 5.14629 10.3523L2.4 13.5563V3.6C2.4 2.93789 2.93848 2.4 3.6 2.4ZM16.8 10.8C18.7852 10.8 20.4 9.18516 20.4 7.2C20.4 5.21484 18.7852 3.6 16.8 3.6C14.8148 3.6 13.2 5.21484 13.2 7.2C13.2 9.18516 14.8148 10.8 16.8 10.8Z"
        fill={seen ? '#C7C7C7' : '#27B67C'}
      />
    </SvgIcon>
  );
};
