import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useWorkspacesControllerAdminCreateCompanyWorkspaceMutation,
  useWorkspacesControllerCreateCompanyWorkspaceMutation,
  useWorkspacesControllerUpdateWorkspaceMutation,
} from 'src/app/services/generatedApi';
import { SelectUserMenu } from '../course/enrollUser/SelectUserMenu';
import { useAppSelector } from 'src/app/reduxCustomHooks';

type CreateGroupDialogPropsType = {
  open: boolean;
  onClose: () => void;
  groupId?: string;
  initialValues?: { name: string; description: string };
};

export const CreateGroupDialog: FC<CreateGroupDialogPropsType> = ({
  open,
  onClose,
  groupId,
  initialValues,
}) => {
  const [name, setName] = useState(initialValues?.name || '');
  const [description, setDescription] = useState(
    initialValues?.description || ''
  );

  const [selectedUsersId, setSelectedUsersId] = useState<string[]>([]);

  const [createGroup, { isLoading: loadingCreate }] =
    useWorkspacesControllerCreateCompanyWorkspaceMutation();

  const [adminCreateGroup, { isLoading: loadingAdminCreate }] =
    useWorkspacesControllerAdminCreateCompanyWorkspaceMutation();

  const [updateGroup, { isLoading: loadingUpdate }] =
    useWorkspacesControllerUpdateWorkspaceMutation();

  const { isAdmin, selectedCompanyId } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    selectedCompanyId: state.auth.adminSelectedCompanyId,
  }));

  const handleClose = () => {
    setName('');
    setDescription('');
    setSelectedUsersId([]);
    onClose();
  };

  const submitHandler = () => {
    if (initialValues && groupId) {
      updateGroup({ id: groupId, updateWorkspaceDto: { name, description } })
        .unwrap()
        .then(() => {
          toast.success('Group updated successfully');
          onClose();
        })
        .catch(({ data }) => toast.error(data));
    } else {
      const createWorkspaceDto = {
        name,
        description,
        workspaceUsers: selectedUsersId,
      };
      (isAdmin && selectedCompanyId
        ? adminCreateGroup({
            createWorkspaceDto,
            id: selectedCompanyId,
          })
        : createGroup({
            createWorkspaceDto,
          })
      )
        .unwrap()
        .then(() => {
          toast.success('Group created successfully');
          handleClose();
        })
        .catch(({ data }) => toast.error(data));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      PaperProps={{ sx: { borderRadius: 2, width: '100%' } }}
    >
      <DialogTitle align="center">Create New Group</DialogTitle>
      <DialogContent>
        <DialogContentText>Group name</DialogContentText>
        <TextField
          autoFocus
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>

      <DialogContent>
        <DialogContentText>Group description</DialogContentText>
        <TextField
          fullWidth
          multiline
          rows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>

      {!initialValues && (
        <DialogContent>
          <Stack>
            <DialogContentText>Group users</DialogContentText>
            <SelectUserMenu
              enrolledUsers={[]}
              selectedUsersId={selectedUsersId}
              setSelectedUsersId={setSelectedUsersId}
              selectElement
            />
          </Stack>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={loadingCreate || loadingUpdate || loadingAdminCreate}
          variant="contained"
          color="primary"
          component="button"
          type="submit"
          disabled={
            !name ||
            !description ||
            (!initialValues && selectedUsersId.length === 0)
          }
          onClick={submitHandler}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
