import { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Rating,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useRateAndReviewControllerCreateMutation } from 'src/app/services/generatedApi';
import { toast } from 'react-toastify';

type RateAndReviewDialogPropsType = {
  openDialog: boolean;
  handleClose: () => void;
  courseId: string;
};

export const RateAndReviewDialog: FC<RateAndReviewDialogPropsType> = ({
  openDialog,
  handleClose,
  courseId,
}) => {
  const [rate, setRate] = useState<number | null>(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const onClose = () => {
    setRate(null);
    setTitle('');
    setDescription('');
    handleClose();
  };

  const [rateAndReview, { isLoading }] =
    useRateAndReviewControllerCreateMutation();

  const submitHandler = () => {
    if (!rate) return;
    rateAndReview({
      createRateAndReviewDto: { courseId: courseId, rate, title, description },
    })
      .unwrap()
      .then(() => {
        toast.success('Successfully Rate and Review Course.');
        onClose();
      })
      .catch(({ data }) => toast.error(data));
  };

  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      maxWidth="xs"
      PaperProps={{ sx: { borderRadius: 2, width: '100%' } }}
    >
      <DialogTitle align="center">Rate and Review Course</DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <DialogContentText variant="body2">Tap to Rate</DialogContentText>
          <Rating
            value={rate}
            onChange={(e, newVal) => setRate(newVal)}
            precision={0.5}
          />
        </Stack>
        <DialogContentText>Title</DialogContentText>
        <TextField
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ mb: 1 }}
        />
        <DialogContentText>Review</DialogContentText>
        <TextField
          multiline
          fullWidth
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={submitHandler}
          disabled={!rate}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
