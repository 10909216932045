import { FC } from 'react';
import { Rating, Skeleton, Stack, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { RateAndReviewRo } from 'src/app/services/generatedApi';

type CommentsPropsType = {
  rateAndReview?: RateAndReviewRo[];
  isLoading: boolean;
};

export const Comments: FC<CommentsPropsType> = ({
  rateAndReview,
  isLoading,
}) => {
  return (
    <Swiper
      style={{ maxWidth: 280 }}
      effect={'cards'}
      grabCursor={true}
      modules={[EffectCards]}
    >
      {isLoading
        ? [...Array(4)].map((_, index) => (
            <SwiperSlide key={index} style={{ borderRadius: 18 }}>
              <Skeleton
                variant="rectangular"
                height={162}
                width={280}
                sx={{ bgcolor: 'secondary.light', borderRadius: 2 }}
              />
            </SwiperSlide>
          ))
        : rateAndReview?.map(({ creator, rate, title, description }, index) => (
            <SwiperSlide key={index} style={{ borderRadius: 18 }}>
              <Stack
                bgcolor={index % 2 === 0 ? '#e6e5e5' : '#dad8d8'}
                spacing={1}
                borderRadius={3}
                sx={{ py: 2, px: 1, minHeight: 130 }}
              >
                <Typography variant="text1" fontWeight="bold">
                  {title}
                </Typography>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography color="secondary.main" variant="text2">
                    {creator.firstName} {creator.lastName}
                  </Typography>
                  <Rating precision={0.5} readOnly value={rate} size="small" />
                </Stack>
                <Stack
                  maxWidth={280}
                  maxHeight={80}
                  sx={{
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': { width: 5, zIndex: 2 },
                    '::-webkit-scrollbar-thumb': {
                      borderRadius: 6,
                      backgroundColor: 'secondary.light',
                      zIndex: 3,
                    },
                  }}
                >
                  <Typography variant="text2">{description}</Typography>
                </Stack>
              </Stack>
            </SwiperSlide>
          ))}
    </Swiper>
  );
};
