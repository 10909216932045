import { FC, MouseEventHandler } from 'react';
import { Download } from '@mui/icons-material';
import { useCustomCoursesControllerGenerateCertificateMutation } from 'src/app/services/api';
import { LoadingButton } from '@mui/lab';
import { saveAs } from 'file-saver';

type DownloadLicenseButtonPropsType = { enrollUserId: string };

export const DownloadLicenseButton: FC<DownloadLicenseButtonPropsType> = ({
  enrollUserId,
}) => {
  const [generateCertificate, { isLoading }] =
    useCustomCoursesControllerGenerateCertificateMutation();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    generateCertificate({ idDto: { id: enrollUserId } }).then((res: any) => {
      if (res.data) {
        const blob = new Blob([res.data], { type: 'images/png' });
        saveAs(blob, 'certificate.png');
      }
    });
  };

  return (
    <LoadingButton
      loading={isLoading}
      variant="contained"
      fullWidth
      startIcon={<Download />}
      onClick={handleClick}
    >
      Download Certificate
    </LoadingButton>
  );
};
