import { FC, useState } from 'react';
import { Chip, Stack, Typography, TextField, Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  useTicketControllerAddTicketMessageMutation,
  useTicketControllerChangeTicketStatusMutation,
  useTicketControllerGetTicketByIdQuery,
} from 'src/app/services/generatedApi';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { TicketMessage } from 'src/components/organisms/support/TicketMessage';

const Ticket: FC = () => {
  const [text, setText] = useState('');

  const { ticketId } = useParams();

  const { data: ticket, isLoading } = useTicketControllerGetTicketByIdQuery(
    { id: ticketId! },
    { skip: !ticketId }
  );

  const { isAdmin, user } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    user: state.auth.user,
  }));

  const [sendMessage, { isLoading: loadingSendMessage }] =
    useTicketControllerAddTicketMessageMutation();

  const [changeTicketStatus, { isLoading: loadingChangeTicketStatus }] =
    useTicketControllerChangeTicketStatusMutation();

  const closeTicket = () => {
    if (!ticketId) return;
    changeTicketStatus({ id: ticketId, updateTicketDto: { status: 'CLOSED' } });
  };

  const submitSendMessage = () => {
    if (!ticketId || !text) return;
    sendMessage({ id: ticketId, ticketMessageDto: { text } })
      .unwrap()
      .then(() => {
        setText('');
      })
      .catch(({ data }) => toast.error(data));
  };

  return (
    <Stack height="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={2}>
          {isLoading ? (
            <Skeleton variant="rounded" height={30} width={170} />
          ) : (
            <Typography
              variant="title3"
              color="secondary.dark"
              fontWeight="bold"
            >
              {ticket?.name}
            </Typography>
          )}
          {isAdmin && ticket?.status !== 'CLOSED' && (
            <LoadingButton
              variant="contained"
              loading={loadingChangeTicketStatus}
              onClick={closeTicket}
            >
              Submit as resolved
            </LoadingButton>
          )}
        </Stack>
        {isLoading ? (
          <Skeleton
            variant="rounded"
            width={80}
            height={30}
            sx={{ borderRadius: 5 }}
          />
        ) : (
          <Chip
            color={ticket?.status === 'CLOSED' ? 'error' : 'primary'}
            variant="outlined"
            sx={{ px: 1 }}
            label={ticket?.status}
          />
        )}
      </Stack>
      {/* ========================================== Chat Box ======================================== */}
      <Stack
        spacing={3}
        sx={{
          flexGrow: 1,
          maxHeight: '68vh',
          overflowX: 'hidden',
          overflowY: 'auto',
          py: 3,
          px: 0.5,
        }}
      >
        {isLoading
          ? [
              [200, 50, 1],
              [300, 100, 1],
              [400, 120, 2],
              [200, 50, 1],
              [300, 50, 2],
            ].map((item, index) => (
              <Stack alignItems={item[2] === 2 ? 'start' : 'end'} key={index}>
                <Skeleton variant="rounded" width={item[0]} height={item[1]} />
              </Stack>
            ))
          : ticket?.messages.map(({ sender, createdAt, text }, index) => (
              <TicketMessage
                key={index}
                sender={sender}
                text={text}
                createdAt={createdAt}
                user={user}
              />
            ))}
      </Stack>
      <Stack direction="row" spacing={2} py={1}>
        <TextField
          fullWidth
          autoComplete="off"
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && submitSendMessage()}
          disabled={ticket?.status === 'CLOSED'}
          label={
            ticket?.status === 'CLOSED' ? 'this ticket has been closed' : ''
          }
        />
        <LoadingButton
          variant="contained"
          sx={{ px: 5 }}
          onClick={submitSendMessage}
          loading={loadingSendMessage}
          disabled={ticket?.status === 'CLOSED'}
        >
          Send
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default Ticket;
