import { Grid, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useCoursesControllerFindMyCoursesQuery } from 'src/app/services/generatedApi';
import { MyCourseCard } from '../courseCard/MyCourseCard';
import emptySvg from 'src/assets/svg/Empty.svg';
import { CourseCardLoading } from '../courseCard/CourseCardLoading';

type MyCoursesListPropsType = {
  limit?: number;
};
export const MyCoursesList: FC<MyCoursesListPropsType> = ({ limit }) => {
  const { data: myCourses = [], isLoading } =
    useCoursesControllerFindMyCoursesQuery();

  const courses = [...myCourses].reverse();

  return (
    <Grid container spacing={2} height="100%">
      {isLoading ? (
        [...Array(3)].map((_, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <CourseCardLoading isMyCourse />
          </Grid>
        ))
      ) : myCourses.length === 0 ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={3}
          sx={{ width: '100%', pt: 4 }}
        >
          <img src={emptySvg} alt="empty courses" />
          <Typography variant="title3" color="primary">
            There is no course in your list.
          </Typography>
        </Stack>
      ) : (
        (limit ? courses.slice(0, limit) : courses).map((myCourse) => (
          <Grid item xs={12} sm={6} lg={4} key={myCourse.id}>
            <MyCourseCard myCourse={myCourse} />
          </Grid>
        ))
      )}
    </Grid>
  );
};
