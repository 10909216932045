import { FC, Fragment, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { Trash } from 'src/components/atoms/svg/TrashSvg';
import { useFormik } from 'formik';
import {
  useEmailTemplatesControllerCreateMutation,
  useEmailTemplatesControllerFindOneQuery,
  useEmailTemplatesControllerUpdateMutation,
} from 'src/app/services/generatedApi';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

const formInitialValues: {
  mode: 'REPORT' | 'WELCOME' | 'ENROLL';
  description: string;
  subject: string;
  message: string;
} = {
  mode: 'REPORT',
  description: '',
  subject: '',
  message: '',
};

const validationSchema = yup.object().shape({
  mode: yup
    .string()
    .oneOf(['REPORT', 'WELCOME', 'ENROLL'])
    .required('This field is required'),
  description: yup.string().required('This field is required'),
  subject: yup.string().required('This field is required'),
  message: yup.string().required('This field is required'),
});

const EmailTemplate: FC = () => {
  const { emailTemplateId } = useParams();
  const navigate = useNavigate();

  const subjectRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLInputElement>(null);

  const { data: template } = useEmailTemplatesControllerFindOneQuery(
    { id: emailTemplateId! },
    { skip: !emailTemplateId }
  );

  const initialValues = template
    ? {
        mode: template.title,
        description: template.description,
        subject: template.subject,
        message: template.message,
      }
    : formInitialValues;

  useEffect(() => {
    if (!template) return;

    const { title, description, subject, message } = template;
    formik.setValues({ mode: title, description, subject, message }); // do not depend on formik
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  const fields = [
    {
      title: 'Subject',
      options: ['First Name', 'Last Name', 'Portal Name'],
      isMessage: false,
    },
    {
      title: 'Message',
      options: [
        'First Name',
        'Last Name',
        'Email',
        'Portal Name',
        'Confirmation URL',
        'Banner Image',
      ],
      isMessage: true,
    },
  ];

  const handleClick = (word: string, isMessage: boolean) => {
    const field = `{{${word.toLowerCase().replace(' ', '_')}}}`;
    const inputRef = isMessage ? messageRef : subjectRef;
    const value = isMessage ? formik.values.message : formik.values.subject;
    if (!inputRef.current) return;
    const selectionStartRef = inputRef.current.selectionStart;
    const selectionEndRef = inputRef.current.selectionEnd;
    const selectionStart = selectionStartRef || selectionEndRef || value.length;
    const selectionEnd = selectionEndRef || selectionStart || value.length;
    const newValue =
      value.substring(0, selectionStart) +
      field +
      value.substring(selectionEnd);
    const newPosition = selectionStart + field.length;
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.selectionStart = newPosition;
        inputRef.current.selectionEnd = newPosition;
        inputRef.current.focus();
      }
    }, 10);
    formik.setFieldValue(isMessage ? 'message' : 'subject', newValue);
  };

  const [createEmailTemplate, { isLoading: loadingCreate }] =
    useEmailTemplatesControllerCreateMutation();

  const [editEmailTemplate, { isLoading: loadingEdit }] =
    useEmailTemplatesControllerUpdateMutation();

  const onSubmit = (values: typeof initialValues) => {
    const { mode, description, message, subject } = values;
    if (emailTemplateId && template) {
      editEmailTemplate({
        id: emailTemplateId,
        updateEmailTemplateDto: {
          title: mode,
          message,
          subject,
          setting: '',
        },
      })
        .unwrap()
        .then(() => {
          toast.success('Email Template updated successfully');
          navigate(-1);
        });
    } else {
      createEmailTemplate({
        createEmailTemplateDto: {
          title: mode,
          description,
          subject,
          message,
          setting: '',
        },
      })
        .unwrap()
        .then(() => {
          toast.success('Email Template created successfully');
          navigate(-1);
        })
        .catch(({ data }) => toast.error(data));
    }
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack height="100%" justifyContent="space-between" py={4}>
        <Stack direction="row" spacing={3} width="100%">
          <Stack width="100%">
            <Typography variant="subtitle1" fontWeight={700}>
              Title
            </Typography>
            <TextField
              select
              id="mode"
              name="mode"
              type="mode"
              onChange={formik.handleChange}
              value={formik.values.mode}
              error={Boolean(formik.errors.mode && formik.touched.mode)}
              helperText={formik.touched.mode && formik.errors.mode}
            >
              {['REPORT', 'WELCOME', 'ENROLL'].map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  sx={{ '&: focus': { color: 'primary.main' } }}
                >
                  {item.charAt(0) + item.slice(1, item.length).toLowerCase()}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack width="100%">
            <Typography variant="subtitle1" fontWeight={700}>
              Description
            </Typography>
            <TextField
              id="description"
              name="description"
              type="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              error={Boolean(
                formik.errors.description && formik.touched.description
              )}
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
          </Stack>
        </Stack>
        {fields.map(({ title, options, isMessage }, index) => (
          <Stack spacing={1} width="100%" key={index}>
            <Typography variant="subtitle1" fontWeight={700}>
              {title}
            </Typography>
            <Stack spacing={2} p={2} bgcolor="customGray.ultralight">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                component={Paper}
                elevation={0}
                px={1}
                py={0.5}
              >
                <Stack direction="row" spacing={1}>
                  {options.map((item, idx) => (
                    <Fragment key={idx}>
                      <Box
                        onClick={() => handleClick(item, isMessage)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': { color: 'customGray.text2' },
                        }}
                      >
                        <Typography>{item}</Typography>
                      </Box>
                      {idx !== options.length - 1 && (
                        <Divider orientation="vertical" flexItem />
                      )}
                    </Fragment>
                  ))}
                </Stack>
                <IconButton
                  size="small"
                  onClick={() =>
                    isMessage
                      ? formik.setFieldValue('message', '')
                      : formik.setFieldValue('subject', '')
                  }
                >
                  <Trash fontSize="small" />
                </IconButton>
              </Stack>
              <Stack component={Paper} elevation={0}>
                {isMessage ? (
                  <TextField
                    id="message"
                    name="message"
                    type="message"
                    value={formik.values.message}
                    inputRef={messageRef}
                    onChange={formik.handleChange}
                    multiline
                    rows={4}
                    error={Boolean(
                      formik.errors.message && formik.touched.message
                    )}
                    helperText={formik.touched.message && formik.errors.message}
                  />
                ) : (
                  <TextField
                    id="subject"
                    name="subject"
                    type="subject"
                    onChange={formik.handleChange}
                    value={formik.values.subject}
                    error={Boolean(
                      formik.errors.subject && formik.touched.subject
                    )}
                    helperText={formik.touched.subject && formik.errors.subject}
                    inputRef={subjectRef}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        ))}
        <Stack direction="row" justifyContent="end" spacing={1}>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            autoFocus
            type="submit"
            component="button"
            loading={loadingCreate || loadingEdit}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};

export default EmailTemplate;
