import { useMemo } from 'react';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import {
  BaseCourseStoreViewRo,
  PopulatedCourseRo,
  ResourceRo,
  useRateAndReviewControllerGetAverageRateQuery,
} from 'src/app/services/generatedApi';
import {
  BaseCourseRo,
  MyCourseRo,
  useBaseCoursesControllerGetByIdQuery,
  useBaseCoursesControllerGetStoreByIdQuery,
  useCoursesControllerGetCourseByIdQuery,
  useCoursesControllerGetMyCourseByIdQuery,
} from 'src/app/services/generatedApi';
import { convertDate } from 'src/utils/date';

type useGetCoursePropsType = {
  baseCourseId?: string;
  courseId?: string;
  myCourseId?: string;
};

export const useGetCourse: (props: useGetCoursePropsType) => {
  myCourse?: MyCourseRo;
  companyCourse?: PopulatedCourseRo;
  baseCourse?: BaseCourseRo;
  storedCourse?: BaseCourseStoreViewRo;
  isLoading: boolean;
  resources: ResourceRo[];
  myProgress?: number;
  courseDetails: { title: string; value: any }[];
  enrolledUsers?: string[];
  certificateId?: string;
} = ({ baseCourseId, courseId, myCourseId }) => {
  const isAdmin = useAppSelector((state) => state.auth.isAdmin);

  const { data: baseCourse, isLoading: loadingBaseCourse } =
    useBaseCoursesControllerGetByIdQuery(
      { id: baseCourseId! },
      { skip: !baseCourseId || !isAdmin }
    );
  const { data: storedCourse, isLoading: loadingStoredCourse } =
    useBaseCoursesControllerGetStoreByIdQuery(
      { id: baseCourseId! },
      { skip: !baseCourseId || isAdmin }
    );

  const { data: companyCourse, isLoading: loadingCompanyCourse } =
    useCoursesControllerGetCourseByIdQuery(
      { id: courseId! },
      { skip: !courseId }
    );

  const { data: myCourse, isLoading: loadingMyCourse } =
    useCoursesControllerGetMyCourseByIdQuery(
      { id: myCourseId! },
      { skip: !myCourseId }
    );

  const { data: rateAverage } = useRateAndReviewControllerGetAverageRateQuery(
    { id: (baseCourseId || courseId || myCourseId)! },
    { skip: !(baseCourseId || courseId || myCourseId) }
  );

  const isLoading =
    loadingBaseCourse ||
    loadingCompanyCourse ||
    loadingMyCourse ||
    loadingStoredCourse;

  const resources = useMemo(() => {
    if (baseCourse) return baseCourse.resource;
    if (storedCourse) return storedCourse.resource;
    if (companyCourse) return companyCourse.baseCourse.resource;
    if (myCourse) return myCourse.baseCourse.resource;
    return [];
  }, [baseCourse, companyCourse, myCourse, storedCourse]);

  const certificateId = useMemo(() => {
    if (companyCourse) return companyCourse.baseCourse.certificate;
    if (baseCourse) return baseCourse.certificate;
    if (storedCourse) return storedCourse.certificate?.id;
  }, [baseCourse, companyCourse, storedCourse]);

  const myProgress = useMemo(() => {
    if (!myCourse) return;
    if (myCourse.enrolledData.state === 'IN_PROGRESS') return 50;
    if (myCourse.enrolledData.state === 'COMPLETED') return 100;
    else return 0;
  }, [myCourse]);

  const courseDetails = useMemo(() => {
    if (baseCourse) {
      const list = [
        { title: 'Rate', value: rateAverage || 0 },
        {
          title: 'Date Created',
          value: convertDate(baseCourse.createdAt, 'DD MMM YYYY'),
        },
      ];
      const certificate = { title: 'Certificate', value: certificateId };
      const inStore = { title: 'In Store', value: baseCourse.inStore };

      if (isAdmin) return [...list, certificate, inStore];
      return list;
    }
    if (storedCourse) {
      const list = [
        { title: 'Rate', value: rateAverage || 0 },
        {
          title: 'Date Created',
          value: convertDate(storedCourse.createdAt, 'DD MMM YYYY'),
        },
        { title: 'Certificate', value: certificateId },
      ];
      return list;
    }
    if (companyCourse) {
      const list = [
        { title: 'Enrolled Users', value: companyCourse.enrolledUsers?.length },
        { title: 'Rate', value: rateAverage || 0 },
        {
          title: 'Date Created',
          value: convertDate(companyCourse.createdAt, 'DD MMM YYYY'),
        },
      ];
      if (isAdmin) {
        return [...list, { title: 'Certificate', value: certificateId }];
      } else {
        if (certificateId) {
          return [...list, { title: 'Certificate', value: certificateId }];
        } else return list;
      }
    }
    if (myCourse) {
      return [
        {
          title: 'Status',
          value: myCourse.enrolledData.state
            .replace('_', ' ')
            .toLowerCase()
            .replace(/\b\w/g, (match) => match.toUpperCase()),
        },
        {
          title: 'Date invited',
          value: convertDate(
            myCourse.enrolledData.invitedAt || '-',
            'DD MMM YYYY'
          ),
        },
        {
          title: 'Last opened',
          value: convertDate(
            myCourse.enrolledData.lastOpened || '-',
            'DD MMM YYYY'
          ),
        },
        { title: 'Rate', value: rateAverage || 0 },
      ];
    }
    return [];
  }, [
    baseCourse,
    certificateId,
    companyCourse,
    isAdmin,
    myCourse,
    rateAverage,
    storedCourse,
  ]);

  const enrolledUsers = useMemo(() => {
    if (!companyCourse) return;
    return companyCourse.enrolledUsers?.map((user) => user.user.id) || [];
  }, [companyCourse]);

  return {
    myCourse,
    companyCourse,
    baseCourse,
    storedCourse,
    isLoading,
    resources,
    myProgress,
    courseDetails,
    enrolledUsers,
    certificateId,
  };
};
