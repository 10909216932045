import { FC, useEffect, useState } from 'react';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Home } from 'src/components/atoms/svg/HomeSvg';
import { SidebarButton } from 'src/components/atoms/SidebarButton';
import { Setting } from 'src/components/atoms/svg/SettingSvg';
import { History } from 'src/components/atoms/svg/HistorySvg';
import { Help } from 'src/components/atoms/svg/HelpSvg';
import { Logout } from 'src/components/atoms/svg/LogoutSvg';
import { useLocation } from 'react-router-dom';
import { logoutAction } from 'src/app/slices/authSlice';
import { useAppDispatch } from 'src/app/reduxCustomHooks';
import { Logo } from 'src/components/atoms/svg/LogoSvg';
import { MyCoursesSvg } from 'src/components/atoms/svg/MyCoursesSvg';

const sidebarItems = [
  { title: 'home', path: '/', icon: Home },
  { title: 'courses', path: '/my-courses', icon: MyCoursesSvg },
  { title: 'history', path: '/history', icon: History },
  { title: 'setting', path: '/setting/profile', icon: Setting },
  { title: 'support', path: '/support', icon: Help },
];

export const UserSidebar: FC = () => {
  const { pathname } = useLocation();
  const [selectedButton, setSelectedButton] = useState('home');
  useEffect(() => {
    if (pathname === '/' || pathname.includes('course'))
      setSelectedButton('home');
    if (pathname.includes('my-courses')) setSelectedButton('courses');
    if (pathname.includes('history')) setSelectedButton('history');
    if (pathname.includes('setting')) setSelectedButton('setting');
    if (pathname.includes('support')) setSelectedButton('support');
  }, [pathname]);

  const dispatch = useAppDispatch();

  return (
    <Stack
      display={{ xs: 'none', md: 'flex' }}
      bgcolor="secondary.main"
      py={4}
      justifyContent="space-between"
    >
      <Stack spacing={3} alignItems="center">
        <Stack alignItems="center" spacing={1}>
          <Logo fontSize="large" />
          <Typography variant="title1" color="white" fontWeight={700}>
            LMS
          </Typography>
        </Stack>
        <Stack spacing={2}>
          {sidebarItems
            .filter((item) => item.title !== 'support')
            .map(({ title, path, icon }) => (
              <SidebarButton
                key={title}
                title={title}
                path={path}
                icon={icon}
                isSelected={selectedButton === title}
              />
            ))}
        </Stack>
      </Stack>
      <Stack spacing={1.5}>
        {sidebarItems
          .filter((item) => item.title === 'support')
          .map(({ title, path, icon }) => (
            <SidebarButton
              title={title}
              path={path}
              icon={icon}
              key={title}
              isSelected={selectedButton === title}
            />
          ))}
        <ListItemButton
          sx={{
            pl: 3,
            width: 150,
          }}
          onClick={() => dispatch(logoutAction())}
        >
          <ListItemIcon
            sx={{
              minWidth: 38,
            }}
          >
            <Logout />
          </ListItemIcon>
          <ListItemText
            sx={{ color: ({ palette }) => palette.error.light }}
            // color="error.light"
            primary="Logout"
          />
        </ListItemButton>
      </Stack>
    </Stack>
  );
};
