import { FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { WorkspaceRo } from 'src/app/services/generatedApi';
import { Search } from 'src/components/atoms/svg/SearchSvg';
import { useCompanyUsers } from 'src/constant/useCompanyUsers';
import { useMyAccessGroup } from './useMyAccessGroups';
import { LmsSwitch } from 'src/components/molecules/LmsSwitch';

type SelectUserMenuPropsType = {
  enrolledUsers: string[];
  selectedUsersId: string[];
  setSelectedUsersId: (selectedUsersId: string[]) => void;
  selectElement?: boolean;
};

export const SelectUserMenu: FC<SelectUserMenuPropsType> = ({
  enrolledUsers,
  selectedUsersId,
  setSelectedUsersId,
  selectElement = false,
}) => {
  const [filter, setFilter] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const { myAccessGroups, isLoading: loadingGroups } = useMyAccessGroup();

  const { users, isLoading: loadingUsers } = useCompanyUsers({ filter });

  const isLoading = loadingGroups || loadingUsers;

  const usersWithGroup = useMemo(
    () =>
      users
        .filter((user) => !enrolledUsers.includes(user.id))
        .map(({ id, username }) => ({
          id,
          username,
          group:
            myAccessGroups.find(({ workspaceUsers }) =>
              workspaceUsers.includes(id)
            )?.name || '',
        })),
    [enrolledUsers, myAccessGroups, users]
  );

  const getGroupMode = (group: WorkspaceRo) => {
    const isOneSelected = group.workspaceUsers.some((workspaceUsers) =>
      selectedUsersId.includes(workspaceUsers)
    );
    const isAllSelected = group.workspaceUsers.every((workspaceUsers) =>
      selectedUsersId.includes(workspaceUsers)
    );
    return isAllSelected ? 1 : isOneSelected ? 0 : -1;
  };

  const handleChangeGroup = (group: WorkspaceRo) => {
    const mode = getGroupMode(group);

    if (mode === 1) {
      setSelectedUsersId(
        selectedUsersId.filter(
          (selectedUserId) => !group.workspaceUsers.includes(selectedUserId)
        )
      );
    } else {
      setSelectedUsersId(
        Array.from(new Set([...selectedUsersId, ...group.workspaceUsers]))
      );
    }
  };

  const toggleSelectUser = (id: string) => {
    if (selectedUsersId.includes(id)) {
      setSelectedUsersId(selectedUsersId.filter((u) => u !== id));
    } else {
      setSelectedUsersId([...selectedUsersId, id]);
    }
  };

  useEffect(() => {
    if (selectAll) {
      setSelectedUsersId(usersWithGroup.map((user) => user.id));
    } else {
      setSelectedUsersId([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  const menu = (
    <Stack minWidth={{ xs: 300, md: 400 }}>
      <Stack pb={1} px={1}>
        <TextField
          onChange={(e) => setFilter(e.target.value)}
          InputProps={{
            type: 'search',
            sx: { borderRadius: 2, px: 1 },
            startAdornment: <Search sx={{ transform: 'rotate(90deg)' }} />,
          }}
        />
      </Stack>
      {!isLoading && usersWithGroup.length === 0 ? (
        <Typography textAlign="center" variant="title3">
          There is no user to invite.
        </Typography>
      ) : (
        <Stack
          direction="row"
          sx={{
            '& .MuiMenuItem-root': {
              borderRadius: 1,
              backgroundColor: '#F3F4F6',
              m: 0.5,
              py: 0.5,
              px: 0,
              color: 'secondary',
              '&:hover': { bgcolor: '#d9f2e8' },
            },
          }}
        >
          <Stack
            width="100%"
            sx={{
              maxHeight: 350,
              overflowY: 'auto',
              '&::-webkit-scrollbar': { width: 5, zIndex: 2 },
              '::-webkit-scrollbar-thumb': {
                borderRadius: 6,
                backgroundColor: 'secondary.light',
              },
            }}
          >
            {isLoading ? (
              <Stack spacing={1} py={0.5}>
                {[...Array(3)].map((_, index) => (
                  <Skeleton
                    key={index}
                    animation="wave"
                    variant="rounded"
                    height={50}
                  />
                ))}
              </Stack>
            ) : (
              myAccessGroups.map((group) => {
                const mode = getGroupMode(group);
                return (
                  <MenuItem
                    key={group.id}
                    onClick={() => handleChangeGroup(group)}
                    value={group.name}
                  >
                    <Checkbox checked={mode === 1} indeterminate={mode === 0} />
                    <ListItemText primary={group.name} />
                  </MenuItem>
                );
              })
            )}
          </Stack>
          <Box minHeight="100%" border="1px dashed #B8B8B8" mx={0.5}></Box>
          <Stack
            width="100%"
            sx={{
              maxHeight: 350,
              minHeight: 150,
              overflowY: 'auto',
              '&::-webkit-scrollbar': { width: 5, zIndex: 2 },
              '::-webkit-scrollbar-thumb': {
                borderRadius: 6,
                backgroundColor: 'secondary.light',
              },
            }}
          >
            {isLoading ? (
              <Stack spacing={1} py={0.5}>
                {[...Array(5)].map((_, index) => (
                  <Skeleton
                    key={index}
                    animation="wave"
                    variant="rounded"
                    height={50}
                  />
                ))}
              </Stack>
            ) : (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    px: 1,
                    py: 1.5,
                    mx: 0.5,
                    bgcolor: '#F3F4F6',
                    borderRadius: 2,
                    cursor: 'pointer',
                    '&:hover': { bgcolor: 'primary.light' },
                  }}
                  onClick={() => setSelectAll((prev) => !prev)}
                >
                  <Typography>Select All</Typography>
                  <LmsSwitch checked={selectAll} />
                </Stack>
                {usersWithGroup.map(({ id, username }) => (
                  <MenuItem
                    key={id}
                    onClick={() => toggleSelectUser(id)}
                    value={id}
                  >
                    <Checkbox checked={selectedUsersId.includes(id)} />
                    <ListItemText primary={username} />
                  </MenuItem>
                ))}
              </>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );

  return selectElement ? (
    <Select
      multiple
      value={users
        .filter((user) => selectedUsersId.includes(user.id))
        .map((user) => user.username)}
      renderValue={(usernames) => usernames.join(', ')}
    >
      {menu}
    </Select>
  ) : (
    menu
  );
};
