import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Person: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx }}>
      <path
        d="M11.829 11.5609C13.4172 11.5609 14.7925 10.9912 15.9163 9.86733C17.04 8.74362 17.6096 7.36868 17.6096 5.78025C17.6096 4.19237 17.04 2.81725 15.9161 1.69317C14.7922 0.569639 13.4171 0 11.829 0C10.2405 0 8.8656 0.569639 7.74188 1.69335C6.61816 2.81707 6.04834 4.19218 6.04834 5.78025C6.04834 7.36868 6.61816 8.7438 7.74207 9.86751C8.86597 10.991 10.2411 11.5609 11.829 11.5609V11.5609Z"
        fill="#B8B8B8"
      />
      <path
        d="M21.9435 18.4548C21.9111 17.9872 21.8456 17.477 21.7491 16.9383C21.6517 16.3956 21.5262 15.8825 21.3761 15.4136C21.221 14.9289 21.0101 14.4503 20.7493 13.9916C20.4787 13.5156 20.1608 13.101 19.8041 12.7599C19.4312 12.403 18.9745 12.1161 18.4464 11.9068C17.9202 11.6986 17.337 11.5931 16.7131 11.5931C16.4681 11.5931 16.2312 11.6937 15.7736 11.9916C15.492 12.1752 15.1626 12.3876 14.7949 12.6226C14.4805 12.8229 14.0546 13.0106 13.5286 13.1805C13.0153 13.3465 12.4942 13.4308 11.9799 13.4308C11.4655 13.4308 10.9446 13.3465 10.4308 13.1805C9.90527 13.0107 9.47937 12.8231 9.16534 12.6227C8.80115 12.39 8.47156 12.1776 8.18573 11.9914C7.7287 11.6935 7.49158 11.593 7.24658 11.593C6.62256 11.593 6.03955 11.6986 5.51349 11.907C4.98578 12.1159 4.52893 12.4028 4.15558 12.7601C3.79907 13.1014 3.48102 13.5157 3.21075 13.9916C2.9502 14.4503 2.73926 14.9288 2.58398 15.4138C2.43402 15.8827 2.30859 16.3956 2.21118 16.9383C2.11469 17.4763 2.04913 17.9866 2.01672 18.4553C1.98486 18.9146 1.96875 19.3912 1.96875 19.8726C1.96875 21.1254 2.367 22.1396 3.15234 22.8876C3.92798 23.6257 4.95428 24.0001 6.20233 24.0001H17.7585C19.0065 24.0001 20.0325 23.6259 20.8083 22.8876C21.5938 22.1401 21.9921 21.1257 21.9921 19.8724C21.9919 19.3888 21.9756 18.9118 21.9435 18.4548V18.4548Z"
        fill="#B8B8B8"
      />
    </SvgIcon>
  );
};
