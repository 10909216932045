import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PDF: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx }}>
      <path
        d="M8.26702 15.1801C8.08302 15.1801 7.95902 15.1981 7.89502 15.2161V16.3941C7.97102 16.4121 8.06602 16.4171 8.19702 16.4171C8.67602 16.4171 8.97102 16.1751 8.97102 15.7661C8.97102 15.4001 8.71702 15.1801 8.26702 15.1801V15.1801ZM11.754 15.1921C11.554 15.1921 11.424 15.2101 11.347 15.2281V17.8381C11.424 17.8561 11.548 17.8561 11.66 17.8561C12.477 17.8621 13.009 17.4121 13.009 16.4601C13.015 15.6301 12.53 15.1921 11.754 15.1921V15.1921Z"
        fill="#CD2020"
      />
      <path
        d="M14 2.5H6C5.46957 2.5 4.96086 2.71071 4.58579 3.08579C4.21071 3.46086 4 3.96957 4 4.5V20.5C4 21.0304 4.21071 21.5391 4.58579 21.9142C4.96086 22.2893 5.46957 22.5 6 22.5H18C18.5304 22.5 19.0391 22.2893 19.4142 21.9142C19.7893 21.5391 20 21.0304 20 20.5V8.5L14 2.5ZM9.498 16.69C9.189 16.98 8.733 17.11 8.202 17.11C8.09904 17.1111 7.99613 17.1051 7.894 17.092V18.518H7V14.582C7.40346 14.5218 7.81112 14.4944 8.219 14.5C8.776 14.5 9.172 14.606 9.439 14.819C9.693 15.021 9.865 15.352 9.865 15.742C9.864 16.134 9.734 16.465 9.498 16.69V16.69ZM13.305 18.045C12.885 18.394 12.246 18.56 11.465 18.56C10.997 18.56 10.666 18.53 10.441 18.5V14.583C10.8446 14.5241 11.2521 14.4963 11.66 14.5C12.417 14.5 12.909 14.636 13.293 14.926C13.708 15.234 13.968 15.725 13.968 16.43C13.968 17.193 13.689 17.72 13.305 18.045V18.045ZM17 15.27H15.468V16.181H16.9V16.915H15.468V18.519H14.562V14.53H17V15.27ZM14 9.5H13V4.5L18 9.5H14Z"
        fill="#CD2020"
      />
    </SvgIcon>
  );
};
