import { ManageAccountsRounded } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { FC } from 'react';

type ManageAccountSvgPropsType = { selected: boolean };

export const ManageAccountSvg: FC<SvgIconProps & ManageAccountSvgPropsType> = ({
  selected,
  ...props
}) => {
  return (
    <ManageAccountsRounded
      {...props}
      sx={{
        ...props.sx,
        color: selected ? 'primary.main' : 'rgba(255, 255, 255, 0.75)',
        fontSize: 32,
      }}
    />
  );
};
