import { FC, useState, ChangeEvent } from 'react';
import { Groups } from '@mui/icons-material';
import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { LmsSwitch } from 'src/components/molecules/LmsSwitch';
import { useMediaControllerBatchCreateUserMutation } from 'src/app/services/generatedApi';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { CustomUploadFile } from 'src/components/atoms/CustomUploadFile';
import { saveAs } from 'file-saver';
import { useAppSelector } from 'src/app/reduxCustomHooks';

const MAXIMUM_FILE_SIZE = 20;

const BatchUserUpload: FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [inviteToCourse, setInviteToCourse] = useState(false);

  const navigate = useNavigate();

  const isAdmin = useAppSelector((state) => state.auth.isAdmin);

  const handleDownload = () => {
    const fileUrl = `/csvs/${isAdmin ? 'admin' : 'manager'}-batch-users.csv`;
    const fileName = 'csv-users.csv';
    fetch(fileUrl)
      .then((response) => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.blob();
      })
      .then((blob) => saveAs(blob, fileName))
      .catch((error) => {
        console.error('There was a problem with the download:', error);
      });
  };

  const [batchCreateUser, { isLoading }] =
    useMediaControllerBatchCreateUserMutation();

  const handleSelectFile = async (event: any) => {
    const file = event.target.files[0];
    if (file.size > 1024 * 1024 * MAXIMUM_FILE_SIZE) {
      toast.error(`Please select file under ${MAXIMUM_FILE_SIZE} MB`);
      return;
    }
    setFile(file);
  };

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInviteToCourse(event.target.checked);
  };

  const submitHandler = () => {
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file);
    formData.append(
      'settingDto',
      JSON.stringify({ sendInvite: inviteToCourse })
    );
    batchCreateUser({ body: formData as any })
      .unwrap()
      .then(() => {
        toast.success('Users created successfully');
        navigate(-1);
      })
      .catch(({ data }) => toast.error(data));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Stack
            alignItems="center"
            spacing={1}
            component={Paper}
            elevation={2}
            sx={{ borderRadius: 2, px: 2, py: 1 }}
          >
            <Groups fontSize="large" color="primary" />
            <Typography variant="title3" fontWeight={700}>
              Batch User Upload
            </Typography>
            <Typography variant="text1" textAlign="center">
              You can easily batch upload a set of users to automatically
              create. Invite and/or update the users in your portal. You can
              also assign users to groups and/or courses during your upload,
              making it even easier to manage your community of users.
            </Typography>
            <Typography variant="text1" textAlign="center">
              You can also download a user file that will be ready made for
              uploading. Simply download your user list using the button below,
              make your edit and upload the file again with updates enabled to
              perform batch updates on your users.
            </Typography>
            <Button variant="contained" onClick={handleDownload}>
              Download CSV
            </Button>
          </Stack>
        </Grid>
        <Grid item xs>
          <Stack spacing={2}>
            <Typography>Select your user data file</Typography>
            <CustomUploadFile
              file={file}
              MAXIMUM_FILE_SIZE={MAXIMUM_FILE_SIZE}
              handleSelectFile={handleSelectFile}
              fileType="csv"
              accept="text/csv"
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
            pt={2}
          >
            <Stack direction="row" spacing={2}>
              <LmsSwitch
                checked={inviteToCourse}
                onChange={handleSwitchChange}
                color="primary"
              />
              <Typography>Send invite to users</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Button variant="outlined" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                onClick={submitHandler}
                loading={isLoading}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default BatchUserUpload;
