import { FC, MouseEvent, useEffect, useState } from 'react';
import {
  Divider,
  Grid,
  LinearProgress,
  Menu,
  Paper,
  Rating,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { Comments } from './Comments';
import {
  useRateAndReviewControllerGetAverageRateQuery,
  useRateAndReviewControllerGetOneQuery,
} from 'src/app/services/generatedApi';

type CourseRatingPropsType = { courseId: string };

export const CourseRating: FC<CourseRatingPropsType> = ({ courseId }) => {
  const { data: rateAndReview, isLoading } =
    useRateAndReviewControllerGetOneQuery({
      id: courseId,
    });

  const { data: rateAverage } = useRateAndReviewControllerGetAverageRateQuery(
    { id: courseId! },
    { skip: !courseId }
  );

  const [usersRates, setUsersRates] = useState<number[]>([]);

  useEffect(() => {
    const usersRatesArray = [];
    for (let i = 1; i <= 5; i++) {
      const numberOfUsers =
        rateAndReview?.filter((user) => Math.floor(user.rate) === i).length ||
        0;
      usersRatesArray.push(numberOfUsers);
    }
    setUsersRates(usersRatesArray);
  }, [rateAndReview]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Stack onClick={handleClick} width="100%" alignItems="center">
        <Rating readOnly precision={0.5} value={rateAverage} />
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))',
            mt: 1.5,
            borderRadius: 3,
            backgroundColor: 'customGray.background',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              backgroundColor: 'customGray.background',
              top: 0,
              left: '50%',
              width: 10,
              height: 10,
              transform: 'translate(-50%, -50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Stack py={2} px={5} spacing={2}>
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="text0" fontWeight="bold" color="white">
                Ratings and Reviews
              </Typography>
            </Stack>
            <Stack direction="row" spacing={5}>
              <Stack direction="column" alignItems="center">
                <Typography
                  fontSize={{ xs: 30, md: 35 }}
                  color="secondary.light"
                >
                  {rateAverage || 0}
                </Typography>
                <Typography color="customGray.ultralight" variant="text2">
                  Out of 5
                </Typography>
              </Stack>
              <Stack>
                {[...usersRates].reverse().map((numberOfUsers, index) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    key={index}
                  >
                    <Stack direction="row" justifyContent="end" width="100%">
                      <Rating
                        sx={{ color: 'secondary.light', fontSize: 13 }}
                        precision={0.5}
                        readOnly
                        size="small"
                        max={5 - index}
                        value={5 - index}
                      />
                    </Stack>
                    <Stack width="100%">
                      <LinearProgress
                        variant="determinate"
                        color="primary"
                        value={numberOfUsers ? numberOfUsers * 20 : 0}
                        sx={{
                          width: 100,
                          height: 3,
                          borderRadius: 2,
                          '&.MuiLinearProgress-root': {
                            backgroundColor: 'secondary.light',
                          },
                        }}
                      />
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Stack>
            <Divider sx={{ borderColor: 'secondary.light', my: 0.5, mx: 3 }} />
          </Stack>
          {isLoading || !rateAndReview || rateAndReview.length < 1 ? (
            <Stack alignItems="center" spacing={1}>
              {[...Array(2)].map((_, index) => (
                <Grid
                  key={index}
                  component={Paper}
                  elevation={2}
                  container
                  sx={{
                    backgroundColor: 'secondary.light',
                    my: 2,
                    p: 1,
                    borderRadius: 4,
                  }}
                  style={{
                    marginBottom: index === 0 ? '-20px' : 0,
                    marginLeft: index === 1 ? '20px' : '-20px',
                  }}
                >
                  <Grid item xs={2.5}>
                    <Skeleton
                      animation={false}
                      variant="circular"
                      width={40}
                      height={40}
                    />
                  </Grid>
                  <Grid item xs>
                    <Skeleton
                      animation={false}
                      variant="text"
                      sx={{ fontSize: '1rem' }}
                    />
                    <Skeleton
                      animation={false}
                      variant="text"
                      width="60%"
                      sx={{ fontSize: '1rem' }}
                    />
                  </Grid>
                </Grid>
              ))}
              <Typography color="white" variant="text0">
                No Comment Yet !
              </Typography>
            </Stack>
          ) : (
            <Comments rateAndReview={rateAndReview} isLoading={isLoading} />
          )}
        </Stack>
      </Menu>
    </>
  );
};
