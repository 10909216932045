import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type HistorySvgPropsType = { selected: boolean };

export const History: FC<SvgIconProps & HistorySvgPropsType> = ({
  selected,
  ...props
}) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx }}>
      <path
        d="M11.9786 19.7492C13.5071 19.7492 15.0012 19.296 16.272 18.4468C17.5429 17.5976 18.5334 16.3907 19.1183 14.9786C19.7032 13.5665 19.8562 12.0127 19.558 10.5136C19.2599 9.01455 18.5239 7.63756 17.4431 6.55679C16.3623 5.47602 14.9853 4.74001 13.4863 4.44183C11.9872 4.14364 10.4334 4.29668 9.02129 4.88159C7.60921 5.4665 6.40227 6.45701 5.55312 7.72786C4.70397 8.99871 4.25073 10.4928 4.25073 12.0213C4.25382 14.0699 5.069 16.0337 6.51759 17.4823C7.96619 18.9309 9.93002 19.7461 11.9786 19.7492V19.7492ZM11.12 9.44529C11.12 9.21756 11.2104 8.99915 11.3715 8.83812C11.5325 8.67709 11.7509 8.58663 11.9786 8.58663C12.2064 8.58663 12.4248 8.67709 12.5858 8.83812C12.7468 8.99915 12.8373 9.21756 12.8373 9.44529V11.6606L14.4086 13.232C14.5692 13.3939 14.6591 13.6128 14.6587 13.8409C14.6582 14.0689 14.5674 14.2875 14.4061 14.4488C14.2449 14.61 14.0263 14.7008 13.7983 14.7013C13.5702 14.7018 13.3513 14.6119 13.1893 14.4513L11.369 12.6309C11.2115 12.4668 11.1224 12.2488 11.12 12.0213V9.44529Z"
        fill={selected ? '#27B67C' : 'rgba(255, 255, 255, 0.75)'}
      />
      <path
        d="M11.9788 1.87376e-05C11.7511 1.87376e-05 11.5327 0.0904842 11.3716 0.251514C11.2106 0.412543 11.1201 0.630946 11.1201 0.858676C11.1201 1.08641 11.2106 1.30481 11.3716 1.46584C11.5327 1.62687 11.7511 1.71733 11.9788 1.71733C14.3826 1.7074 16.7148 2.53471 18.5749 4.05718C20.4351 5.57966 21.7071 7.7023 22.1726 10.0606C22.638 12.4189 22.2678 14.8656 21.1256 16.9807C19.9834 19.0957 18.1404 20.7471 15.9131 21.6512C13.6858 22.5553 11.2133 22.6557 8.92003 21.9351C6.6268 21.2145 4.65599 19.718 3.34607 17.7025C2.03615 15.687 1.46884 13.2783 1.74159 10.8901C2.01433 8.50182 3.11009 6.28305 4.84071 4.61479L4.78249 5.05889C4.75289 5.28473 4.81421 5.51308 4.95297 5.6937C5.09173 5.87433 5.29656 5.99244 5.5224 6.02204C5.74824 6.05165 5.97659 5.99033 6.15721 5.85157C6.33784 5.71281 6.45595 5.50798 6.48555 5.28214L6.81974 2.72797C6.84018 2.50377 6.7753 2.28011 6.63804 2.10166C6.50078 1.92321 6.30126 1.8031 6.07932 1.76533L3.52516 1.4314C3.41181 1.41337 3.29599 1.41826 3.18456 1.44576C3.07313 1.47326 2.96834 1.52282 2.87639 1.59151C2.78444 1.66021 2.7072 1.74664 2.64922 1.8457C2.59125 1.94476 2.55373 2.05444 2.53887 2.16825C2.52401 2.28206 2.53212 2.39769 2.56272 2.50832C2.59332 2.61894 2.64579 2.7223 2.71702 2.8123C2.78825 2.9023 2.8768 2.9771 2.97744 3.03229C3.07808 3.08748 3.18876 3.12193 3.30294 3.1336L3.83763 3.20367C1.78778 5.10579 0.466596 7.6641 0.10229 10.4367C-0.262016 13.2093 0.35343 16.022 1.84232 18.3891C3.33121 20.7562 5.60006 22.529 8.25695 23.4013C10.9139 24.2736 13.792 24.1905 16.3942 23.1665C18.9963 22.1425 21.1592 20.2419 22.5091 17.7929C23.8591 15.3439 24.3113 12.5003 23.7878 9.75334C23.2642 7.00636 21.7978 4.52849 19.6417 2.74771C17.4856 0.966931 14.7752 -0.00492091 11.9788 1.87376e-05V1.87376e-05Z"
        fill={selected ? '#27B67C' : 'rgba(255, 255, 255, 0.75)'}
      />
    </SvgIcon>
  );
};
