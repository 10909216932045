import { FC, Fragment, useState } from 'react';
import {
  Edit,
  PlayArrowRounded,
  RateReviewRounded,
  RocketLaunch,
} from '@mui/icons-material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Resources } from 'src/components/organisms/course/courseView/Resources';
import { EnrolledUsersTable } from 'src/components/organisms/course/report/EnrolledUsersTable';
import { SelectCompanyDialog } from 'src/components/organisms/company/SelectCompanyDialog';
import { CertificateDialog } from 'src/components/organisms/certificate/CertificateDialog';
import { SelectCertificateDialog } from 'src/components/organisms/certificate/SelectCertificateDialog';
import { RateAndReviewDialog } from 'src/components/organisms/course/dialog/RateAndReviewDialog';
import { toast } from 'react-toastify';
import {
  useBaseCoursesControllerSetInStoreMutation,
  useCertificatesControllerGetByIdQuery,
  useCoursesControllerDeleteMutation,
  useCoursesControllerRemoveMutation,
} from 'src/app/services/generatedApi';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { useGetCourse } from './useGetCourse';
import { LmsSwitch } from 'src/components/molecules/LmsSwitch';
import { DeleteDialog } from 'src/components/atoms/DeleteDialog';
import { InviteUserDialog } from 'src/components/organisms/course/dialog/InviteUserDialog';
import { Trash2 } from 'src/components/atoms/svg/TrashSvg';

const CourseView: FC = () => {
  const [openInviteUser, setOpenInviteUser] = useState(false);
  const [openSelectCompanyDialog, setOpenSelectCompanyDialog] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openCreateCertDialog, setOpenCreateCertDialog] = useState(false);
  const [initialCertId, setInitialCertId] = useState('');
  const [openSetCertDialog, setOpenSetCertDialog] = useState(false);
  const [openRateDialog, setOpenRateDialog] = useState(false);

  const navigate = useNavigate();

  const { courseId, myCourseId, baseCourseId } = useParams() as {
    courseId?: string;
    myCourseId?: string;
    baseCourseId?: string;
  };

  const {
    baseCourse,
    companyCourse,
    myCourse,
    storedCourse,
    isLoading,
    resources,
    myProgress,
    courseDetails,
    enrolledUsers,
    certificateId,
  } = useGetCourse({ courseId, baseCourseId, myCourseId });

  const { data: certificate } = useCertificatesControllerGetByIdQuery(
    { id: certificateId! },
    { skip: !certificateId }
  );

  const course = baseCourse || companyCourse || myCourse || storedCourse;

  const { isAdmin, selectedCompanyId, isManager } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    selectedCompanyId: state.auth.adminSelectedCompanyId,
    isManager: state.auth.isManager,
  }));

  const handleOpenCertDialog = (certId?: string) => {
    if (certId) setInitialCertId(certId);
    setOpenCreateCertDialog(true);
  };

  const [setInStore] = useBaseCoursesControllerSetInStoreMutation();

  const handleInStore = () => {
    if (!baseCourseId || !baseCourse) return;
    setInStore({
      setBaseCourseStoreDto: { baseCourseId, inStore: !baseCourse.inStore },
    });
  };

  // Delete Course by Admins
  const [deleteCourse, { isLoading: loadingDeleteAdmin }] =
    useCoursesControllerDeleteMutation();

  // Delete Course by Managers
  const [removeCourse, { isLoading: loadingDeleteManager }] =
    useCoursesControllerRemoveMutation();

  const submitDeleteCourse = () => {
    if (!courseId || (!isAdmin && !isManager)) return;

    (isAdmin ? deleteCourse : removeCourse)({ id: courseId })
      .unwrap()
      .then(() => {
        toast.success('Course deleted successfully');
        setOpenDelete(false);
        navigate('/company/courses');
      })
      .catch(({ data }) => toast.error(data));
  };

  const loadingDelete = isAdmin ? loadingDeleteAdmin : loadingDeleteManager;

  const LaunchCourseButton = () => {
    if (isAdmin && !selectedCompanyId) {
      return (
        <Button
          variant="contained"
          startIcon={<RocketLaunch />}
          onClick={() => setOpenSelectCompanyDialog(true)}
        >
          Launch This Course
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          startIcon={<RocketLaunch />}
          component={RouterLink}
          to={`/course-store/${course?.id}/launch-course`}
        >
          Launch This Course
        </Button>
      );
    }
  };

  return (
    <>
      <Grid container gap={5} pt={3}>
        <Grid item xs>
          <Stack spacing={4} justifyContent="space-between" pb={1}>
            <Grid container spacing={2} pb={3}>
              <Grid item xs={4.8}>
                <Stack sx={{ border: '10px solid #E4E7EC', borderRadius: 1.5 }}>
                  {isLoading ? (
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      sx={{ borderRadius: 1, height: 150 }}
                    />
                  ) : (
                    <img
                      src={course?.thumbnailUrl}
                      alt="course"
                      width="100%"
                      height={150}
                      style={{ borderRadius: 5 }}
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item xs>
                <Stack height="100%" justifyContent="space-between">
                  <Stack spacing={1}>
                    {isLoading ? (
                      <Skeleton
                        variant="rounded"
                        animation="wave"
                        width={230}
                        height={25}
                      />
                    ) : (
                      <Typography variant="title3" fontWeight="bold">
                        {course?.name}
                      </Typography>
                    )}
                    {isLoading ? (
                      <Stack pt={1} spacing={1}>
                        <Skeleton animation="wave" height={14} width="100%" />
                        <Skeleton animation="wave" height={14} width="100%" />
                        <Skeleton animation="wave" height={14} width="80%" />
                      </Stack>
                    ) : (
                      <Typography variant="text0">
                        {course?.description}
                      </Typography>
                    )}
                  </Stack>
                  <Stack alignItems="end" pt={1}>
                    {isLoading ? (
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        width="60%"
                        height={35}
                      />
                    ) : (
                      <Button
                        variant="contained"
                        sx={{ px: 4 }}
                        startIcon={<PlayArrowRounded />}
                        component={RouterLink}
                        to={myCourse ? 'session' : 'preview'}
                      >
                        {myCourse ? 'Play Course' : 'Preview this course'}
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Resources resources={resources} loading={isLoading} />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack spacing={3}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              {isLoading ? (
                <>
                  <Skeleton variant="circular" width={30} height={30} />
                  <Skeleton variant="rounded" width={120} height={40} />
                </>
              ) : (
                <>
                  <Stack direction="row" spacing={1}>
                    {myCourse &&
                      myCourse.enrolledData.state === 'COMPLETED' && (
                        <Button
                          variant="contained"
                          component={RouterLink}
                          to="result"
                        >
                          Get Certificate
                        </Button>
                      )}
                    {companyCourse && (
                      <IconButton
                        sx={{ p: 0.5 }}
                        size="small"
                        component={RouterLink}
                        to="edit"
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    )}
                    {companyCourse && (
                      <IconButton
                        onClick={() => setOpenDelete(true)}
                        sx={{ p: 0.5 }}
                        size="small"
                      >
                        <Trash2 fontSize="small" />
                      </IconButton>
                    )}
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    {companyCourse && (
                      <Button
                        variant="outlined"
                        onClick={() => setOpenInviteUser(true)}
                      >
                        Invite Users
                      </Button>
                    )}
                    {(companyCourse ||
                      (myCourse &&
                        myCourse.enrolledData.state === 'COMPLETED')) && (
                      <Button
                        variant="contained"
                        component={RouterLink}
                        to={'report'}
                      >
                        Reports
                      </Button>
                    )}
                    {baseCourse && isAdmin && (
                      <Button
                        variant="contained"
                        onClick={() => navigate('edit')}
                      >
                        Edit Base Course
                      </Button>
                    )}
                  </Stack>
                </>
              )}
            </Stack>
            {myProgress && (
              <Stack spacing={1}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant="text0"
                    color="secondary.dark"
                    whiteSpace="nowrap"
                  >
                    My progress
                  </Typography>
                  <Typography variant="text0" color="secondary.dark">
                    {myProgress}%
                  </Typography>
                </Stack>
                <LinearProgress
                  variant="determinate"
                  value={myProgress}
                  sx={{ minWidth: 320, height: 8, borderRadius: 1 }}
                />
              </Stack>
            )}
            <Stack>
              {isLoading ? (
                <Stack spacing={2.5} pt={2}>
                  {[
                    [25, 30],
                    [35, 20],
                    [25, 30],
                    [20, 35],
                  ].map((item, index) => (
                    <Fragment key={index}>
                      <Stack direction="row" justifyContent="space-between">
                        <Skeleton
                          animation="wave"
                          height={14}
                          width={`${item[0]}%`}
                        />
                        <Skeleton
                          animation="wave"
                          height={14}
                          width={`${item[1]}%`}
                        />
                      </Stack>
                      <Divider />
                    </Fragment>
                  ))}
                </Stack>
              ) : (
                courseDetails.map(({ title, value }, index) => (
                  <Fragment key={index}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      py={2}
                    >
                      <Typography variant="text0" color="secondary.main">
                        {title}
                      </Typography>
                      {title === 'In Store' ? (
                        <LmsSwitch
                          checked={value as boolean}
                          onChange={handleInStore}
                          id="isManager"
                          name="isManager"
                        />
                      ) : title === 'Certificate' ? (
                        <Tooltip title={certificate?.name} arrow>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              if (value) {
                                handleOpenCertDialog(String(value));
                              } else setOpenSetCertDialog(true);
                            }}
                          >
                            {value ? 'View' : 'Set Certificate'}
                          </Button>
                        </Tooltip>
                      ) : (
                        <Typography variant="text0">{value}</Typography>
                      )}
                    </Stack>
                    <Divider />
                  </Fragment>
                ))
              )}
              {myCourse && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  py={1.5}
                >
                  <Typography variant="text0" color="secondary.main">
                    Rate and review
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={() => setOpenRateDialog(true)}
                  >
                    <RateReviewRounded />
                  </IconButton>
                </Stack>
              )}
            </Stack>
            {baseCourseId && isLoading ? (
              <Skeleton
                variant="rounded"
                animation="wave"
                width="100%"
                height={35}
              />
            ) : (
              (baseCourse || storedCourse) && <LaunchCourseButton />
            )}
          </Stack>
        </Grid>
      </Grid>
      {courseId && <EnrolledUsersTable isReportPage={false} />}
      {courseId && course && enrolledUsers && (
        <>
          <DeleteDialog
            openDialog={openDelete}
            handleClose={() => setOpenDelete(false)}
            submitDelete={submitDeleteCourse}
            type="Course"
            name={course.name}
            loading={loadingDelete}
          />

          <InviteUserDialog
            courseId={courseId}
            initializedSelectedUsersId={enrolledUsers}
            openDialog={openInviteUser}
            handleClose={() => setOpenInviteUser(false)}
          />
        </>
      )}

      {isAdmin && course && (
        <SelectCompanyDialog
          open={openSelectCompanyDialog}
          handleClose={() => setOpenSelectCompanyDialog(false)}
          courseId={course.id}
        />
      )}
      {initialCertId && (
        <CertificateDialog
          open={openCreateCertDialog}
          handleClose={() => setOpenCreateCertDialog(false)}
          certId={initialCertId}
          mode="UPDATE_CERTIFICATE"
        />
      )}

      {baseCourseId && (
        <SelectCertificateDialog
          open={openSetCertDialog}
          handleClose={() => setOpenSetCertDialog(false)}
          baseCourseId={baseCourseId}
        />
      )}

      {myCourseId && (
        <RateAndReviewDialog
          openDialog={openRateDialog}
          handleClose={() => setOpenRateDialog(false)}
          courseId={myCourseId}
        />
      )}
    </>
  );
};

export default CourseView;
