import {
  Box,
  Checkbox,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { ChangeEvent, FC, MouseEvent, useMemo } from 'react';
import { StyledTableCell } from 'src/components/molecules/StyledTableCell';
import { Order } from 'src/pages/admin/users/CompanyUsers';
import { visuallyHidden } from '@mui/utils';
import { UserDataType } from 'src/pages/admin/users/AllUsers';

type HeadCell = { id: keyof UserDataType; label: string };

const headCells: readonly HeadCell[] = [
  { id: 'username', label: 'Username' },
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'companyName', label: 'Company' },
  { id: 'isActive', label: 'Status' },
];

type UsersTableProps = {
  numSelected: number;
  onRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof UserDataType
  ) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  isCompany: boolean;
};

export const UsersTableHead: FC<UsersTableProps> = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  isCompany,
}) => {
  const createSortHandler =
    (property: keyof UserDataType) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const cells = useMemo(
    () =>
      isCompany
        ? headCells.filter((cell) => cell.id !== 'companyName')
        : headCells,
    [isCompany]
  );

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </StyledTableCell>
        {cells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell></StyledTableCell>
      </TableRow>
    </TableHead>
  );
};
