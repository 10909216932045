import { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

type DeleteDialogPropsType = {
  openDialog: boolean;
  handleClose: () => void;
  submitDelete: () => void;
  type: string;
  name: string;
  loading: boolean;
};

export const DeleteDialog: FC<DeleteDialogPropsType> = ({
  openDialog,
  handleClose,
  submitDelete,
  type,
  name,
  loading,
}) => {
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperProps={{ sx: { p: 0.5, borderRadius: 2 } }}
    >
      <DialogTitle color="error" align="center">
        Delete {type}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure want to Delete {type}{' '}
          <span style={{ color: '#F83232' }}>{name}</span> ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="outlined"
          color="error"
          autoFocus
          component="button"
          sx={{ color: 'error.main' }}
          onClick={submitDelete}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
