import { FC, useState } from 'react';
import { Stack } from '@mui/material';
import CustomDatePicker from 'src/components/atoms/CustomDatePicker';
import { CustomTimeline } from 'src/components/organisms/history/CustomTimeline';
import { useUserActivityControllerGetMyActivitiesQuery } from 'src/app/services/generatedApi';

const History: FC = () => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [currentDate, setCurrentDate] = useState<string | null>(null);

  const { data: activity = [], isLoading } =
    useUserActivityControllerGetMyActivitiesQuery();

  return (
    <Stack direction={{ xs: 'column', lg: 'row' }} py={{ xs: 3, md: 10 }}>
      <Stack>
        <CustomDatePicker
          currentDate={currentDate}
          setSelectedDate={setSelectedDate}
        />
      </Stack>
      <Stack width="100%">
        <CustomTimeline
          selectedDate={selectedDate}
          setCurrentDate={setCurrentDate}
          activity={activity}
          isLoading={isLoading}
        />
      </Stack>
    </Stack>
  );
};

export default History;
