import { FC } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { getCustomDate } from 'src/utils/date';
import {
  useNotificationControllerArchiveNotificationMutation,
  useNotificationControllerSeenNotificationMutation,
} from 'src/app/services/generatedApi';

type NotifCardPropsType = { notification: any };

export const NotificationCard: FC<NotifCardPropsType> = ({ notification }) => {
  const { id, title, text, date, seen, archive } = notification;

  const [seenNotification, { isLoading: loadingSeen }] =
    useNotificationControllerSeenNotificationMutation();

  const [archiveNotification, { isLoading: loadingArchive }] =
    useNotificationControllerArchiveNotificationMutation();

  const handleSeenNotification = () => {
    seenNotification({ idsDto: { ids: [id] } })
      .unwrap()
      .then()
      .catch(({ data }) => toast.error(data));
  };

  const handleArchiveNotification = () => {
    archiveNotification({ idsDto: { ids: [id] } });
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      bgcolor="customGray.lightBackground"
      borderRadius={3}
      p={{ xs: 1.5, md: 2 }}
    >
      <Box
        position="relative"
        minWidth={{ xs: 30, md: 40 }}
        height={{ xs: 30, md: 40 }}
        sx={{
          background: `radial-gradient(50% 50% at 50% 50%, ${
            seen ? 'rgba(149, 149, 150, 0.16)' : 'rgba(18, 239, 149, 0.19)'
          } 0%, ${
            seen ? 'rgba(149, 149, 150, 0.16)' : 'rgba(18, 239, 149, 0.19)'
          } 100%)`,
          filter: 'blur(3px)',
          borderRadius: '50%',
        }}
      >
        <Box
          position="absolute"
          top="50%"
          left="50%"
          bgcolor={seen ? 'secondary.main' : 'primary.main'}
          width="50%"
          height="50%"
          borderRadius="50%"
          sx={{ transform: 'translate(-50%, -50%)' }}
        />
      </Box>
      <Stack spacing={1} width="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            color="secondary.dark"
          >
            {title}
          </Typography>
          <Typography variant="text2" color="customGray.gray5">
            {getCustomDate(date)} ago
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="text0" color="secondary.dark">
            {text}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="end"
          alignItems="center"
          spacing={2}
          py={1}
        >
          <LoadingButton
            size="small"
            variant="outlined"
            sx={{ px: 3 }}
            loading={loadingArchive}
            onClick={handleArchiveNotification}
          >
            {archive ? 'Unarchive' : 'Archive'}
          </LoadingButton>
          {!seen && (
            <LoadingButton
              size="small"
              variant="contained"
              sx={{ px: 2 }}
              loading={loadingSeen}
              onClick={handleSeenNotification}
            >
              Mark as read
            </LoadingButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
