import {
  Alert,
  Box,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCoursesControllerGetCourseByIdQuery } from 'src/app/services/generatedApi';
import { LmsTablePagination } from 'src/components/atoms/LmsTablePagination';
import { TableSearchInput } from 'src/components/atoms/TableSearchInput';
import { StyledTableCell } from 'src/components/molecules/StyledTableCell';
import { visuallyHidden } from '@mui/utils';
import { EnrolledUserTableRow } from './EnrolledUserTableRow';

export type UserDataType = {
  id: string;
  name: string;
  invitedAt: string;
  lastOpened: string;
  state: string;
  userId: string;
};

const headCells = [
  { id: 'name', label: 'Name' },
  { id: 'invitedAt', label: 'Invited At' },
  { id: 'lastOpened', label: 'Last Opened' },
  { id: 'state', label: 'State' },
  { id: 'actions', label: '' },
];

const createData = (
  id: string,
  name: string,
  invitedAt: string,
  lastOpened: string,
  state: string,
  userId: string
): UserDataType => {
  return { id, name, invitedAt, lastOpened, state, userId };
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof UserDataType>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const EnrolledUsersTable: FC<{ isReportPage: boolean }> = ({
  isReportPage,
}) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof UserDataType>('name');
  const rowsPerPage = 5;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const createSortHandler =
    (property: keyof UserDataType) => (event: MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

  const { courseId } = useParams();

  const { data: companyCourse, isLoading } =
    useCoursesControllerGetCourseByIdQuery(
      { id: courseId! },
      { skip: !courseId }
    );

  const rows =
    companyCourse?.enrolledUsers
      ?.filter((user) =>
        `${user.user.firstName} ${user.user.lastName}`
          .toLowerCase()
          .includes(search.toLowerCase())
      )
      .map((user) => {
        return createData(
          user.id,
          `${user.user.firstName} ${user.user.lastName}`,
          user.invitedAt,
          user.lastOpened,
          user.state,
          user.user.id
        );
      }) || [];

  const handleChangePage = (e: any, value: number) => setPage(value);
  return (
    <Box>
      <Toolbar sx={{ px: 0 + '!important' }}>
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" fontWeight={700}>
          Enrolled Users
        </Typography>
        <TableSearchInput search={search} setSearch={setSearch} />
      </Toolbar>
      <Paper sx={{ width: '100%', mb: 1 }}>
        <TableContainer>
          {isLoading ? (
            <Stack spacing={0.5} p={1} width="100%">
              {[...Array(6)].map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={45}
                  width="100%"
                  sx={{ bgcolor: 'secondary.light', borderRadius: 1 }}
                />
              ))}
            </Stack>
          ) : (
            <Table sx={{ minWidth: 750 }} size="medium">
              <TableHead>
                <TableRow>
                  {headCells.map(({ id, label }) => (
                    <StyledTableCell
                      key={id}
                      sortDirection={orderBy === id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === id}
                        direction={orderBy === id ? order : 'asc'}
                        onClick={createSortHandler('id')}
                      >
                        {label}
                        {orderBy === id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort<UserDataType>(rows, getComparator(order, orderBy))
                  .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                  .map((row, index) => (
                    <EnrolledUserTableRow
                      key={index}
                      row={row}
                      index={index}
                      isReportPage={isReportPage}
                    />
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <LmsTablePagination
          rows={rows}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Paper>
      <Alert severity="warning">
        To receive a user's report, the user must have completed the course !
      </Alert>
    </Box>
  );
};
