import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import {
  CompanyUsageRo,
  useCompaniesControllerGetAllQuery,
} from 'src/app/services/generatedApi';
import { LmsTablePagination } from 'src/components/atoms/LmsTablePagination';
import { TableSearchInput } from 'src/components/atoms/TableSearchInput';
import { StyledTableCell } from 'src/components/molecules/StyledTableCell';
import { visuallyHidden } from '@mui/utils';
import { CompanyTableRow } from 'src/components/organisms/company/CompanyTableRow';
import { CreateCompanyDialog } from './CreateCompanyDialog';

type CompanyDataType = {
  id: string;
  name: string;
  owner: string;
  users: number;
  limits: CompanyUsageRo;
};

const createData = (
  id: string,
  name: string,
  owner: string,
  users: number,
  limits: CompanyUsageRo
): CompanyDataType => {
  return { id, name, owner, users, limits };
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

export type Order = 'asc' | 'desc';

function getComparator<Key extends keyof CompanyDataType>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', label: 'Name' },
  { id: 'owner', label: 'Owner' },
  { id: 'users', label: 'Users' },
  { id: 'actions', label: '' },
];

const Companies: FC = () => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof CompanyDataType>('name');
  const rowsPerPage = 5;
  const [page, setPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [search, setSearch] = useState('');

  const createSortHandler =
    (property: keyof CompanyDataType) => (event: MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

  const { data: companies, isLoading } = useCompaniesControllerGetAllQuery();

  const rows =
    companies
      ?.filter((company) =>
        company.name.toLowerCase().includes(search.toLowerCase())
      )
      .map((company) => {
        return createData(
          company.id,
          company.name,
          company.owner?.username,
          company.usersCount,
          company.limit
        );
      }) || [];

  const handleChangePage = (e: any, value: number) => setPage(value);

  return (
    <>
      <Box px={8} sx={{ width: '100%' }}>
        <Toolbar sx={{ px: 0 + '!important' }}>
          <Typography sx={{ flex: '1 1 100%' }} variant="h6">
            Companies
          </Typography>
          <Stack direction="row" spacing={2}>
            <TableSearchInput search={search} setSearch={setSearch} />
            <Button
              variant="contained"
              startIcon={<Add fontSize="large" />}
              onClick={() => setOpenDialog(true)}
            >
              Create new Company
            </Button>
          </Stack>
        </Toolbar>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            {isLoading ? (
              <Stack spacing={0.5} p={1} width="100%">
                {[...Array(6)].map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    height={45}
                    width="100%"
                    sx={{ bgcolor: 'secondary.light', borderRadius: 1 }}
                  />
                ))}
              </Stack>
            ) : (
              <Table sx={{ minWidth: 750 }} size="medium">
                <TableHead>
                  <TableRow>
                    {headCells.map(({ id, label }) => (
                      <StyledTableCell
                        key={id}
                        sortDirection={orderBy === id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === id}
                          direction={orderBy === id ? order : 'asc'}
                          onClick={createSortHandler('id')}
                        >
                          {label}
                          {orderBy === id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc'
                                ? 'sorted descending'
                                : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort<CompanyDataType>(
                    rows,
                    getComparator(order, orderBy)
                  )
                    .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                    .map((row, index) => (
                      <CompanyTableRow row={row} index={index} key={index} />
                    ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          <LmsTablePagination
            rows={rows}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
          />
        </Paper>
      </Box>
      <CreateCompanyDialog
        openDialog={openDialog}
        handleClose={() => setOpenDialog(false)}
      />
    </>
  );
};

export default Companies;
