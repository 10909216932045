import { FC } from 'react';
import { PlayArrowRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useCoursesControllerSetLastOpenedMutation } from 'src/app/services/generatedApi';

type StartButtonPropsType = { id: string };

export const StartButton: FC<StartButtonPropsType> = ({ id }) => {
  const [setLastOpened] = useCoursesControllerSetLastOpenedMutation();

  return (
    <Button
      variant="contained"
      fullWidth
      startIcon={<PlayArrowRounded />}
      component={RouterLink}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setLastOpened({ id });
      }}
      to={`/my-courses/${id}/session`}
    >
      Start
    </Button>
  );
};
