import { Groups, Task, Timer, Update } from '@mui/icons-material';
import { Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { CompanyReportRo } from 'src/app/services/generatedApi';

type InsightsPropsType = {
  values?: CompanyReportRo;
  loading: boolean;
};

export const Insights: FC<InsightsPropsType> = ({ values, loading }) => {
  const convertToReadableTime = (time: number) => {
    if (time <= 60) return `${Math.round(time)} seconds`;
    if (time <= 3600) return `${Math.round(time / 60)} minutes`;
    if (time <= 86400) return `${Math.round(time / 3600)} hours`;
    else return `${Math.round(time / 86400)} days`;
  };

  const totalReports = [
    {
      icon: Groups,
      color: '#00C49F',
      title: 'Total Enrolled',
      value: values?.totalEnrolled,
    },
    {
      icon: Update,
      color: '#448AFF',
      title: 'In Progress',
      value: values?.inProgress,
    },
    {
      icon: Task,
      color: '#7C4DFF',
      title: 'Completed Training',
      value: values?.completed,
    },
    {
      icon: Timer,
      color: '#448AFF',
      title: 'Average Time to Complete',
      value: convertToReadableTime(values?.averageTimeToComplete || 0) || '-',
    },
  ];

  return (
    <Grid item xs component={Paper} p={2}>
      <Stack spacing={1}>
        {totalReports.map(({ icon: ItemIcon, title, color, value }, index) => (
          <Stack
            key={index}
            direction="row"
            alignItems="center"
            spacing={3}
            sx={{ bgcolor: color, p: 2, borderRadius: 2 }}
          >
            <ItemIcon sx={{ color: 'white' }} fontSize="large" />
            <Stack spacing={loading ? 1 : 0}>
              <Typography variant="text0" color="white" fontWeight={700} noWrap>
                {title}
              </Typography>
              {loading ? (
                <Skeleton variant="rounded" width={20} height={18} />
              ) : (
                <Typography variant="text0" color="white" fontWeight={700}>
                  {value}
                </Typography>
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Grid>
  );
};
