import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/app/reduxCustomHooks';

export const PrivateRoute: FC = () => {
  const token = useAppSelector((state) => state.auth.token);

  if (!token) return <Navigate to="/auth/login" />;

  return <Outlet />;
};
