import { Box, Container, Stack, Typography } from '@mui/material';
import loginSvg from 'src/assets/svg/Login.svg';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { Logo } from 'src/components/atoms/svg/LogoSvg';

export const LoginTemplate = () => {
  const token = useAppSelector((state) => state.auth.token);

  if (token) return <Navigate to="/" />;

  return (
    <Stack sx={{ height: '100vh' }}>
      <Box sx={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)', py: 2 }}>
        <Container maxWidth="lg">
          <Stack direction="row" columnGap={2} alignItems="center">
            <Logo fontSize="large" />
            <Typography color="secondary.main" fontSize={32} fontWeight={900}>
              LMS
            </Typography>
          </Stack>
        </Container>
      </Box>
      <Container maxWidth="lg" sx={{ height: '100%' }}>
        <Grid2
          container
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
          height="100%"
        >
          <Grid2
            xs={0}
            lg={6}
            sx={{ display: { xs: 'none', lg: 'flex' } }}
            overflow="hidden"
          >
            <img src={loginSvg} alt="login-svg" />
          </Grid2>
          <Grid2 xs={12} lg={6}>
            <Box
              bgcolor="customGray.light"
              px={{ xs: 2, sm: 6 }}
              py={8}
              borderRadius={4}
              sx={{ maxWidth: { xs: '100%', sm: 450 } }}
              mx="auto"
            >
              <Outlet />
            </Box>
          </Grid2>
        </Grid2>
      </Container>
    </Stack>
  );
};
