import * as yup from 'yup';

export const usernameValidator = yup
  .string()
  .min(3, 'minimum 3 characters')
  .max(50, 'maximum 50 characters');

export const emailValidator = yup.string().email('Email is not valid!');

export const passwordValidator = yup.string();

export const phoneNumberValidator = yup
  .string()
  .min(11, 'phone number is not valid!')
  .max(11, 'phone number is not valid!');

export const passwordValidatorRegex =
  /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
