import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Menu: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx }}>
      <path d="M3 7H21" stroke="#fff" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M8 12L21 12"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 17L21 17"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
