import { FC, useEffect, useRef, useState } from 'react';
import {
  Button,
  CircularProgress,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCoursesControllerGetMyCourseByIdQuery,
  useCoursesControllerChangeEnrolledUserStateMutation,
  useBaseCoursesControllerGetByIdQuery,
  useCoursesControllerGetCourseByIdQuery,
  useCoursesControllerSetFinishMutation,
} from 'src/app/services/generatedApi';
import { MaxSize } from 'src/components/atoms/svg/MaxSizeSvg';
import { BeatLoader } from 'react-spinners';
import { baseUrl } from 'src/app/services/baseQuery';

const Session: FC = () => {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const { myCourseId, courseId, baseCourseId } = useParams() as {
    myCourseId?: string;
    courseId?: string;
    baseCourseId?: string;
  };

  const { data: myCourse, isLoading: myCourseIsLoading } =
    useCoursesControllerGetMyCourseByIdQuery(
      { id: myCourseId! },
      { skip: !myCourseId }
    );

  const { data: companyCourse, isLoading: companyCourseIsLoading } =
    useCoursesControllerGetCourseByIdQuery(
      { id: courseId! },
      { skip: !courseId }
    );

  const { data: baseCourse, isLoading: courseIsLoading } =
    useBaseCoursesControllerGetByIdQuery(
      { id: baseCourseId! },
      { skip: !baseCourseId }
    );

  const isLoading =
    myCourseIsLoading || companyCourseIsLoading || courseIsLoading;
  const url =
    baseUrl +
    (myCourse
      ? `/api/v1/course/get-scorm-file/${myCourse?.enrolledData.id}/index_lms.html`
      : `/api/v1/course/get-scorm-preview/${
          baseCourse?.id || companyCourse?.baseCourse.id
        }/index_lms.html`);

  const [changeEnrolledUserState] =
    useCoursesControllerChangeEnrolledUserStateMutation();

  const [setFinish] = useCoursesControllerSetFinishMutation();

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const handleFullScreen = () => {
    iframeRef.current?.requestFullscreen();
  };

  useEffect(() => {
    if (!iframeLoaded || baseCourseId || courseId) return;

    const iframeDataHandler = (e: MessageEvent) => {
      if (e?.data?.type === 'update-scorm-data' && myCourse?.enrolledData.id) {
        changeEnrolledUserState({
          changeEnrolledUserStateDto: {
            suspendData: e?.data?.suspendData,
            answers: e?.data?.answers,
            enrolledUserId: myCourse.enrolledData.id,
          },
        }).unwrap();
      } else if (e.data.type === 'finish' && myCourse?.enrolledData.id) {
        if (e?.data?.suspendData) {
          changeEnrolledUserState({
            changeEnrolledUserStateDto: {
              suspendData: e?.data?.suspendData,
              answers: e?.data?.answers,
              enrolledUserId: myCourse.enrolledData.id,
            },
          }).unwrap();
        }

        setFinish({ id: myCourse.enrolledData.id }).unwrap();
      }
    };

    window.addEventListener('message', iframeDataHandler);

    return () => {
      window.removeEventListener('message', iframeDataHandler);
    };
  }, [
    baseCourseId,
    changeEnrolledUserState,
    courseId,
    iframeLoaded,
    myCourse?.enrolledData.id,
    setFinish,
  ]);

  return (
    <Stack spacing={3} height="100%">
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        spacing={2}
      >
        {isLoading ? (
          <Skeleton
            variant="text"
            sx={{
              width: { xs: 300, md: 350 },
              fontSize: { xs: 26, md: 32 },
              backgroundColor: 'secondary.light',
            }}
          />
        ) : (
          <Typography
            variant="title1"
            fontWeight="bold"
            color="customGray.dark"
          >
            {baseCourse?.name}
          </Typography>
        )}
        <Stack direction="row" spacing={2}>
          <IconButton
            onClick={handleFullScreen}
            disabled={isLoading}
            sx={{ minWidth: 50 }}
          >
            {isLoading ? <CircularProgress size={20} /> : <MaxSize />}
          </IconButton>
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              width={175}
              height={48}
              sx={{ backgroundColor: 'secondary.light', borderRadius: 1 }}
            />
          ) : (
            myCourseId && (
              <Button
                variant="contained"
                sx={{ px: 6 }}
                fullWidth
                onClick={() => navigate(-1)}
              >
                Save & Exit
              </Button>
            )
          )}
        </Stack>
      </Stack>
      <Stack
        height="100%"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {isLoading ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ minWidth: '100%', minHeight: '100%' }}
            bgcolor="customGray.gray1"
            borderRadius={3}
          >
            <BeatLoader color={theme.palette.primary.main} loading size={50} />
          </Stack>
        ) : (
          <iframe
            onLoad={() => setIframeLoaded(true)}
            title={baseCourse?.name}
            src={url}
            width="100%"
            height="100%"
            ref={iframeRef}
            allowFullScreen
            style={{ border: 'none' }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default Session;
