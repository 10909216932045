import { FC } from 'react';
import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { colorPickerHelper } from 'src/utils/colorPicker';
import { useWorkspacesControllerGetWorkspaceByIdQuery } from 'src/app/services/generatedApi';
import { takeFirstWordOfString } from 'src/utils/avatarUtils';
import { useCompanyUsers } from 'src/constant/useCompanyUsers';
import { RemoveUserButton } from './RemoveUserButton';
import { AddUserButton } from './AddUserButton';

type ManageUsersDialogPropsType = {
  open: boolean;
  handleClose: () => void;
  groupId: string;
};

export const ManageUsersDialog: FC<ManageUsersDialogPropsType> = ({
  open,
  handleClose,
  groupId,
}) => {
  const { users } = useCompanyUsers({ filter: '' });

  const { data: group } = useWorkspacesControllerGetWorkspaceByIdQuery({
    id: groupId,
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>Group User Management</DialogTitle>
      <DialogContent dividers>
        <Typography align="center">{`Total Members: ${users.length}`}</Typography>
        <Typography align="center">{`Group's Members: ${group?.workspaceUsers.length}`}</Typography>
        <Stack spacing={2} pt={3}>
          {users.map((user, index) => (
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              rowGap={1}
              key={index}
            >
              <Grid item xs>
                <Stack
                  direction="row"
                  alignItems="center"
                  columnGap={2}
                  overflow="hidden"
                >
                  <Avatar
                    sx={{
                      width: 36,
                      height: 36,
                      color: 'white',
                      fontWeight: 700,
                      display: { xs: 'none', sm: 'flex' },
                      bgcolor: colorPickerHelper(index + 1),
                    }}
                    children={takeFirstWordOfString(user.username)}
                  />
                  <Typography maxWidth={{ xs: 95, sm: 130 }}>
                    {user.username}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs textAlign="end">
                {group?.workspaceUsers.includes(user.id) ? (
                  <RemoveUserButton groupId={groupId} userId={user.id} />
                ) : (
                  <AddUserButton groupId={groupId} userId={user.id} />
                )}
              </Grid>
            </Grid>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
