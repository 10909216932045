import { FC, useState } from 'react';
import { Button, Skeleton, Stack, Toolbar, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { SendNotificationDialog } from 'src/components/organisms/user/SendNotificationDialog';
import { TableSearchInput } from 'src/components/atoms/TableSearchInput';
import { SelectUserCreationDialog } from '../SelectUserCreationDialog';

type UsersTableToolbarProps = {
  selected: string[];
  loading: boolean;
  removeSelection: () => void;
  search: string;
  setSearch: (search: string) => void;
};

export const UsersTableToolbar: FC<UsersTableToolbarProps> = ({
  selected,
  loading,
  removeSelection,
  search,
  setSearch,
}) => {
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const [openCreateUserDialog, setOpenCreateUserDialog] = useState(false);
  const numSelected = selected.length;

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Users
          </Typography>
        )}
        {loading ? (
          <Stack direction="row" spacing={1}>
            <Skeleton
              variant="rectangular"
              height={30}
              width={30}
              sx={{ bgcolor: 'secondary.light', borderRadius: 10 }}
            />
            <Skeleton
              variant="rectangular"
              height={30}
              width={200}
              sx={{ bgcolor: 'secondary.light', borderRadius: 1 }}
            />
          </Stack>
        ) : numSelected > 0 ? (
          <Button
            variant="contained"
            onClick={() => setOpenNotificationDialog(true)}
          >
            Send notifications
          </Button>
        ) : (
          <Stack direction="row" spacing={2}>
            <TableSearchInput search={search} setSearch={setSearch} />
            <Button
              variant="contained"
              startIcon={<Add fontSize="large" />}
              onClick={() => setOpenCreateUserDialog(true)}
            >
              Create User
            </Button>
          </Stack>
        )}
        <SendNotificationDialog
          mode="User"
          selectedUsers={selected}
          openDialog={openNotificationDialog}
          onClose={() => setOpenNotificationDialog(false)}
          removeSelection={removeSelection}
        />
      </Toolbar>
      <SelectUserCreationDialog
        openDialog={openCreateUserDialog}
        onClose={() => setOpenCreateUserDialog(false)}
      />
    </>
  );
};
