import { FC } from 'react';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { CompanyCoursesList } from 'src/components/organisms/course/coursesList/CompanyCoursesList';

const CompanyCourses: FC = () => {
  const { isAdmin, adminSelectedCompanyId } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    adminSelectedCompanyId: state.auth.adminSelectedCompanyId,
  }));

  return (
    <CompanyCoursesList
      companyId={adminSelectedCompanyId || ''}
      isAdmin={isAdmin}
    />
  );
};

export default CompanyCourses;
