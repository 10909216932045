import { FC } from 'react';
import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useUserActivityControllerFindLatestQuery } from 'src/app/services/generatedApi';
import { getHistoryLogData } from '../history/constants';
import dayjs from 'dayjs';

type DashboardActivitiesPropsType = {};

export const DashboardActivities: FC<DashboardActivitiesPropsType> = () => {
  const { data: historyShortList, isLoading } =
    useUserActivityControllerFindLatestQuery();

  return (
    <>
      <Stack direction="row" alignItems="end" spacing={2} py={2}>
        <Typography variant="title1" fontWeight={700} color="secondary.dark">
          History
        </Typography>
        <Button
          component={RouterLink}
          to={'/history'}
          color="secondary"
          sx={{ p: 0, color: 'customGray.text2' }}
        >
          See more
        </Button>
      </Stack>
      <Stack spacing={3} px={2}>
        {isLoading ? (
          <Stack spacing={3}>
            {[...Array(3)].map((_, index) => (
              <Stack direction="row" justifyContent="space-between" key={index}>
                <Skeleton variant="rounded" width="60%" />
                <Skeleton variant="rounded" width="20%" />
              </Stack>
            ))}
          </Stack>
        ) : (
          historyShortList?.map(({ method, createdAt }, index) => {
            const formattedDate =
              dayjs(createdAt).format('YYYY') === dayjs().format('YYYY')
                ? dayjs(createdAt).format('MMM DD')
                : dayjs(createdAt).format('MMM DD YYYY');

            return (
              <Stack direction="row" justifyContent="space-between" key={index}>
                <Typography
                  variant="text2"
                  color="secondary.main"
                  maxWidth={350}
                >
                  {getHistoryLogData(method, createdAt)?.description}
                </Typography>
                <Typography variant="text2" color="secondary.light">
                  {formattedDate}
                </Typography>
              </Stack>
            );
          })
        )}
      </Stack>
    </>
  );
};
