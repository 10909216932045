import { FC, Fragment, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import {
  useReportControllerGetEnrolledUserReportQuery,
  useReportControllerGetMyReportQuery,
} from 'src/app/services/generatedApi';
import { useParams } from 'react-router-dom';
import { SelfAdjustSvg } from 'src/components/organisms/course/report/SelfAdjustSvg';
import { useGetCourse } from './courseView/useGetCourse';
import { generatePDF } from 'src/components/organisms/course/report/generatePdf';

type Field = { name: string; value: number };

type ResultListItem = { title: string; fields: Field[] };

const CourseReport: FC = () => {
  const reportRef = useRef<HTMLDivElement | null>(null);
  const firstGridRef = useRef<HTMLDivElement | null>(null);
  const secondGridRef = useRef<HTMLDivElement | null>(null);

  const { courseId, myCourseId, baseCourseId, userId } = useParams() as {
    courseId?: string;
    myCourseId?: string;
    baseCourseId?: string;
    userId?: string;
  };

  const { data: courseReport, isLoading } = useReportControllerGetMyReportQuery(
    { id: myCourseId! },
    { skip: !myCourseId }
  );

  const { data: userCourseReport } =
    useReportControllerGetEnrolledUserReportQuery(
      { courseId: courseId!, userId: userId! },
      { skip: !courseId || !userId }
    );

  const { myCourse, companyCourse } = useGetCourse({
    courseId,
    myCourseId,
    baseCourseId,
  });

  const answers = useMemo(() => {
    if (courseReport) return courseReport.answers;
    if (userCourseReport) return userCourseReport.answers;
    return [];
  }, [courseReport, userCourseReport]);

  const mergedList = useMemo(() => {
    if (!myCourse && !companyCourse) return [];
    return answers.map((item1) => {
      const item2 = (
        myCourse || companyCourse
      )?.baseCourse.courseArguments.find((item2) => item2.name === item1.name);
      return { ...item1, ...item2 };
    });
  }, [answers, companyCourse, myCourse]);

  // Create a map to store the fields grouped by category
  const fieldsByCategory = new Map();

  // Iterate through the original list and group fields by category
  mergedList.forEach((item) => {
    const { name, category } = item;
    if (!fieldsByCategory.has(category)) {
      fieldsByCategory.set(category, []);
    }
    fieldsByCategory.get(category).push({ name, value: item.value });
  });

  // Create a list of objects with title and fields properties
  const resultList: ResultListItem[] = Array.from(
    fieldsByCategory.entries()
  ).map(([title, fields]) => {
    return { title, fields };
  });

  const filteredList = resultList.map((item) => ({
    ...item,
    fields: item.fields.filter((field) => field.value),
  }));

  const isSelfAdjust = (myCourse || companyCourse)?.name
    ?.toLowerCase()
    .includes('self adjust');

  const submitGenerateReport = () => {
    const course = myCourse || companyCourse;
    if (!course) return;
    generatePDF(
      course.name || 'Course',
      secondGridRef,
      filteredList,
      course.thumbnailUrl
    );
  };

  return (
    <Container maxWidth="md" sx={{ height: '100%' }}>
      <Stack
        spacing={3}
        height="100%"
        py={3}
        justifyContent="center"
        alignItems="center"
      >
        <Stack direction="row" justifyContent="end" width="100%">
          <Button variant="contained" onClick={submitGenerateReport}>
            Download report
          </Button>
        </Stack>

        <Grid container gap={3} ref={reportRef}>
          <Grid item xs p={2} component={Paper}>
            {isLoading ? (
              [...Array(2)].map((_, index) => (
                <Fragment key={index}>
                  <Skeleton variant="rounded" width={200} />
                  <Stack spacing={2.5} my={2} px={4}>
                    {[...Array(4)].map((_, idx) => (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        key={idx}
                      >
                        <Skeleton variant="rounded" width={100} height={10} />
                        <Skeleton variant="rounded" width={40} height={10} />
                      </Stack>
                    ))}
                  </Stack>
                </Fragment>
              ))
            ) : (
              <Stack spacing={3} ref={firstGridRef}>
                {resultList.map(({ title, fields }, index) => (
                  <Box key={index}>
                    <Typography variant="subtitle1">{title}</Typography>
                    {fields.map(({ name, value }, idx) => (
                      <Box key={idx} px={4}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          py={1}
                        >
                          <Typography variant="text0" color="secondary.main">
                            {name.replace('_', ' ')}
                          </Typography>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={3}
                          >
                            <Typography variant="text0">
                              {value || '-'}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Divider />
                      </Box>
                    ))}
                  </Box>
                ))}
              </Stack>
            )}
          </Grid>
          {isLoading ? (
            <Grid item xs>
              <Skeleton variant="rounded" height="100%" />
            </Grid>
          ) : (
            isSelfAdjust && (
              <Grid item xs component={Paper}>
                <Box ref={secondGridRef}>
                  <SelfAdjustSvg
                    size={{ width: 360, height: 500 }}
                    values={answers}
                  />
                </Box>
              </Grid>
            )
          )}
        </Grid>
      </Stack>
    </Container>
  );
};

export default CourseReport;
