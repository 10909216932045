import { FC } from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import { MinimalUserRo, UserLoginRo } from 'src/app/services/generatedApi';
import { convertDate } from 'src/utils/date';

type TicketMessagePropsType = {
  sender: MinimalUserRo;
  text: string;
  createdAt: string;
  user: UserLoginRo | null;
};

export const TicketMessage: FC<TicketMessagePropsType> = ({
  sender,
  text,
  createdAt,
  user,
}) => {
  return (
    <Stack alignItems={sender.id === user?.id ? 'end' : 'start'}>
      <Stack
        component={Paper}
        bgcolor={sender.id === user?.id ? 'customGray.gray3' : 'white'}
        px={3}
        py={2}
        borderRadius={3}
        maxWidth={{ xs: '80%', md: '70%' }}
        elevation={2}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'start', md: 'center' }}
            spacing={{ xs: 0, md: 1.5 }}
          >
            <Typography variant="subtitle1" fontWeight="bold">
              {sender.roleNames.includes('admin') && sender.id !== user?.id
                ? 'LMS Support'
                : `${sender.firstName} ${sender.lastName}`}
            </Typography>
            {sender.roleNames.includes('admin') && (
              <Typography variant="text0" color="secondary.light">
                Admin
              </Typography>
            )}
          </Stack>
          <Typography variant="text1" color="customGray.gray4">
            {convertDate(createdAt, 'DD/MM/YYYY')}
          </Typography>
        </Stack>
        <Stack py={2}>
          <Typography variant="text0" color="secondary.main">
            {text}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
