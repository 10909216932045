import { FC, useEffect, useRef, useState } from 'react';
import {
  ImageFieldRo,
  TextFieldDto,
  TextFieldRo,
} from 'src/app/services/generatedApi';
import { Stage, Layer, Image } from 'react-konva';
import { Box, Paper, Stack } from '@mui/material';
import { TextItem } from './item/TextItem';
import { ImageItem } from './item/ImageItem';
import { useDropArgument } from './arguments/useDropArgument';
import useImage from 'use-image';

type CertificateStagePropsType = {
  aspectRatio: number;
  background?: { file?: File; base64: string };
  imageFields: Omit<ImageFieldRo, 'filePath'>[];
  textFields: TextFieldRo[];
  stageWidth: number;
  setStageWidth: (stageWidth: number) => void;
  onChangeTextField: (index: number, data: TextFieldDto) => void;
  onChangeImageField: (
    index: number,
    data: Omit<ImageFieldRo, 'filePath'>
  ) => void;
  addTextField: (data: TextFieldDto) => void;
  addImageField: (
    data: Omit<ImageFieldRo, 'filePath'> & { file: File }
  ) => void;
  removeTextField: (index: number) => void;
  removeImageField: (index: number) => void;
  preview: boolean;
};

export const CertificateStage: FC<CertificateStagePropsType> = ({
  aspectRatio,
  imageFields,
  textFields,
  background,
  stageWidth,
  setStageWidth,
  onChangeImageField,
  onChangeTextField,
  addImageField,
  addTextField,
  removeImageField,
  removeTextField,
  preview,
}) => {
  const [selectedId, selectId] = useState<number>();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const getStageWidth = () => {
    if (!containerRef.current) return 0;
    const rect = containerRef.current.getBoundingClientRect();

    if (aspectRatio > rect.width / rect.height) {
      return rect.width;
    } else {
      return rect.height * aspectRatio;
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setStageWidth(getStageWidth());
    });

    setTimeout(() => {
      if (!containerRef.current) return;

      resizeObserver.observe(containerRef.current);
    }, 100);
    return () => resizeObserver?.disconnect(); // clean up
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aspectRatio]);

  const { canDrop, isOver, drop } = useDropArgument(
    addTextField,
    addImageField,
    selectId,
    wrapperRef
  );

  const checkDeselect = (e: any) => {
    const clickedOnEmpty =
      e.target === e.target.getStage() || e.target.id === 'backgroundImage';
    if (clickedOnEmpty) selectId(undefined);
  };

  const [backgroundImage] = useImage(background?.base64 || '');

  return (
    <Stack
      component={Paper}
      sx={{ width: '100%', height: '100%', borderRadius: 2 }}
      alignItems="center"
      justifyContent="center"
      ref={(el: HTMLDivElement) => {
        containerRef.current = el;
        drop(el);
      }}
    >
      <Box
        sx={{
          position: 'relative',
          backgroundColor:
            canDrop && isOver ? '#27B67C' : canDrop ? '#8cd9ba' : '#c6ecdc',
          border: canDrop && isOver ? '1px solid' : '1px dashed',
          width: stageWidth,
          height: stageWidth / aspectRatio,
          borderRadius: 2,
        }}
        ref={wrapperRef}
      >
        <Stage
          width={stageWidth}
          height={stageWidth / aspectRatio}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
        >
          <Layer>
            {backgroundImage && (
              <Image
                id="backgroundImage"
                image={backgroundImage}
                width={stageWidth}
                height={stageWidth / aspectRatio}
                listening={false}
              />
            )}
            {textFields.map((textField, index) => (
              <TextItem
                key={index + textField.inputType}
                shapeProps={{
                  x: textField.location.x,
                  y: textField.location.y,
                  text: textField.text || textField.inputType,
                  fontSize: textField.fontSize,
                  rotation: textField.rotation,
                  bold: textField.bold,
                  color: textField.color,
                }}
                isSelected={index === selectedId}
                stageSize={{
                  width: stageWidth,
                  height: stageWidth / aspectRatio,
                }}
                onSelect={() => !preview && selectId(index)}
                onChange={(newAttrs) => {
                  onChangeTextField(index, {
                    ...textField,
                    location: { x: newAttrs.x, y: newAttrs.y },
                    fontSize: newAttrs.fontSize,
                    bold: newAttrs.bold,
                    rotation: newAttrs.rotation || textField.rotation,
                    color: newAttrs.color,
                  });
                }}
                remove={() => {
                  selectId(undefined);
                  removeTextField(index);
                }}
                preview={preview}
              />
            ))}
            {imageFields.map((imageField, index) => (
              <ImageItem
                key={index + imageField.url}
                url={imageField.url || ''}
                shapeProps={{
                  x: imageField.location.x,
                  y: imageField.location.y,
                  width: imageField.size.width,
                  height: imageField.size.height,
                  rotation: imageField.rotation,
                }}
                isSelected={
                  selectedId !== undefined &&
                  index === selectedId - textFields.length
                }
                stageSize={{
                  width: stageWidth,
                  height: stageWidth / aspectRatio,
                }}
                onSelect={() => !preview && selectId(index + textFields.length)}
                onChange={(newAttrs) => {
                  onChangeImageField(index, {
                    ...imageField,
                    location: { x: newAttrs.x, y: newAttrs.y },
                    size: { width: newAttrs.width, height: newAttrs.height },
                    rotation: newAttrs.rotation || imageField.rotation,
                  });
                }}
                remove={() => {
                  selectId(undefined);
                  removeImageField(index);
                }}
                preview={preview}
              />
            ))}
          </Layer>
        </Stage>
      </Box>
    </Stack>
  );
};
