import { ContactMail } from '@mui/icons-material';
import { Button, IconButton, Paper, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEmailTemplatesControllerRemoveMutation } from 'src/app/services/generatedApi';
import { DeleteDialog } from 'src/components/atoms/DeleteDialog';
import { Trash } from 'src/components/atoms/svg/TrashSvg';

type EmailTemplateCardPropsType = {
  id: string;
  title: string;
  description: string;
};

export const EmailTemplateCard: FC<EmailTemplateCardPropsType> = ({
  id,
  title,
  description,
}) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [removeEmailTemplate, { isLoading }] =
    useEmailTemplatesControllerRemoveMutation();

  const submitRemoveTemplate = () => {
    if (!id) return;
    removeEmailTemplate({ id })
      .unwrap()
      .then(() => {
        toast.success('Email Template removed successfully');
        setOpenDeleteDialog(false);
      })
      .catch(({ data }) => toast.error(data));
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        component={Paper}
        elevation={2}
        sx={{ borderRadius: 2, p: 2 }}
      >
        <Stack direction="row" alignItems="center" spacing={3}>
          <ContactMail color="primary" fontSize="large" sx={{ m: 1 }} />
          <Stack>
            <Typography variant="subtitle2" fontWeight={700}>
              {title.charAt(0) + title.slice(1, title.length).toLowerCase()}
            </Typography>
            <Typography variant="text0">{description}</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          <IconButton size="small" onClick={() => setOpenDeleteDialog(true)}>
            <Trash fontSize="small" />
          </IconButton>
          <Button variant="contained" component={Link} to={id}>
            Edit Template
          </Button>
        </Stack>
      </Stack>
      <DeleteDialog
        openDialog={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        name={title.charAt(0) + title.slice(1, title.length).toLowerCase()}
        type="Email Template"
        submitDelete={submitRemoveTemplate}
        loading={isLoading}
      />
    </>
  );
};
