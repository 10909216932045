import type { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Trash: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} sx={{ fill: 'none', ...props.sx }}>
      <path
        d="M21 5.98C17.67 5.65 14.32 5.48 10.98 5.48C9 5.48 7.02 5.58 5.04 5.78L3 5.98"
        stroke="#F00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
        stroke="#F00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.85 9.14L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79C6 22 5.91 20.78 5.8 19.21L5.15 9.14"
        stroke="#F00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.33 16.5H13.66"
        stroke="#F00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 12.5H14.5"
        stroke="#F00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const Trash2: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} sx={{ fill: 'none', ...props.sx }}>
      <path
        d="M12 3C13.8639 3 15.4301 4.27489 15.874 6.00024L20 6C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H18.864L18.0622 20.0665C18.0272 20.5918 17.5909 21 17.0644 21H6.93555C6.40909 21 5.97279 20.5918 5.93777 20.0665L5.133 8H4C3.44772 8 3 7.55228 3 7C3 6.44772 3.44772 6 4 6L8.12595 6.00024C8.56991 4.27489 10.1361 3 12 3ZM16.861 8H7.136L7.871 19H16.128L16.861 8ZM12 5C11.2971 5 10.6789 5.36261 10.3222 5.91098L10.268 6H13.731L13.6778 5.91098C13.3466 5.40178 12.7899 5.05275 12.1493 5.00549L12 5Z"
        fill="#F83232"
      />
    </SvgIcon>
  );
};
