import moment from 'moment';

export const convertDate = (date: string, format: string) => {
  if (date === '-') return '-';
  const orgDate = moment(date, 'YYYY-MM-DD THH:mm:ss.sssZ');
  const newDate = String(orgDate.format(format));

  return newDate;
};

export const getCustomDate = (date: string) => {
  const dateString = date;
  const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
  const newDate = moment(dateString, dateFormat);
  const now = moment();
  const difference = now.diff(newDate);
  const customDate = moment.duration(difference).humanize();
  return customDate;
};
