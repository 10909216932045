import { FC, useState } from 'react';
import {
  Button,
  MenuItem,
  Stack,
  Typography,
  TextField,
  Container,
  Autocomplete,
} from '@mui/material';
import { CreateBaseCourseFieldType } from 'src/pages/admin/course/CreateBaseCourse';
import {
  BaseCourseRo,
  CourseArgumentsDto,
} from 'src/app/services/generatedApi';
import { LoadingButton } from '@mui/lab';
import Dropzone from 'src/components/atoms/Dropzone';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

type FileAndArgumentsStepPropsType = {
  mode: 'CREATE' | 'EDIT' | 'DUPLICATE';
  goBack: () => void;
  file: File | null;
  args: CourseArgumentsDto[];
  fillFormData: (fields: CreateBaseCourseFieldType) => void;
  selectedCourse?: BaseCourseRo;
  isLoading: boolean;
  submit: () => void;
};
const initialArgs: CourseArgumentsDto = {
  name: '',
  fieldType: 'STRING',
  category: '',
};
export const FileAndArgumentsStep: FC<FileAndArgumentsStepPropsType> = ({
  mode,
  goBack,
  file,
  args,
  fillFormData,
  selectedCourse,
  isLoading,
  submit,
}) => {
  const [newObject, setNewObject] = useState(initialArgs);

  const setArgs = (items: CourseArgumentsDto[]) => {
    fillFormData([{ key: 'courseArguments', value: items }]);
  };

  const addArgument = () => {
    if (!newObject.name || !newObject.category) return;
    setArgs([...args, newObject]);
    setNewObject(initialArgs);
  };

  const handleUploadCourse = (selectedFile: File) => {
    fillFormData([{ key: 'file', value: selectedFile }]);
  };

  const submitHandler = () => {
    if (mode === 'CREATE' && !file) return;
    submit();
  };

  const categories = args.map((arg) => arg.category);

  return (
    <Stack justifyContent="space-between" height="100%">
      <Stack spacing={2}>
        <Typography variant="title2" fontWeight="bold">
          Session
        </Typography>
        <Container maxWidth="md" sx={{ margin: 'auto !important' }}>
          {!selectedCourse && (
            <Dropzone onChange={handleUploadCourse} mode={mode} />
          )}
          <Typography variant="subtitle1" fontWeight="bold" mb={1.5} mt={6}>
            Arguments
          </Typography>
          <Stack rowGap={{ xs: 5, md: 2 }}>
            <Grid2
              container
              rowGap={1}
              direction={{ md: 'row' }}
              justifyContent="space-between"
            >
              <Grid2 xs={12} md={3.9}>
                <TextField
                  fullWidth
                  placeholder="Name"
                  value={newObject.name}
                  onChange={(e) => {
                    setNewObject({ ...newObject, name: e.target.value });
                  }}
                />
              </Grid2>
              <Grid2 xs={12} md={3.9}>
                <Autocomplete
                  disablePortal
                  freeSolo
                  options={Array.from(new Set(categories))}
                  value={newObject.category}
                  onChange={(e, value) => {
                    value && setNewObject({ ...newObject, category: value });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder="Category"
                      onChange={(e) => {
                        setNewObject({
                          ...newObject,
                          category: e.target.value,
                        });
                      }}
                    />
                  )}
                />
              </Grid2>
              <Grid2 xs={12} md={1.9}>
                <TextField
                  fullWidth
                  select
                  placeholder="Type"
                  value={newObject.fieldType}
                  onChange={(e) => {
                    setNewObject({
                      ...newObject,
                      fieldType: e.target.value as 'STRING' | 'NUMBER',
                    });
                  }}
                >
                  {['STRING', 'NUMBER'].map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid2>
              <Grid2 xs={12} md={1.9}>
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  sx={{ px: 2.8, height: 40 }}
                  onClick={addArgument}
                >
                  Add
                </Button>
              </Grid2>
            </Grid2>
            {args.map(({ name, category, fieldType }, index) => (
              <Grid2
                container
                rowGap={1}
                key={index}
                direction={{ md: 'row' }}
                justifyContent="space-between"
              >
                <Grid2 xs={12} md={3.9}>
                  <TextField
                    fullWidth
                    placeholder="Argument"
                    value={name}
                    onChange={(e) => {
                      setArgs(
                        args.map((arg, idx) =>
                          index === idx ? { ...arg, name: e.target.value } : arg
                        )
                      );
                    }}
                  />
                </Grid2>
                <Grid2 xs={12} md={3.9}>
                  <TextField
                    fullWidth
                    placeholder="Value"
                    value={category}
                    onChange={(e) => {
                      setArgs(
                        args.map((arg, idx) =>
                          index === idx
                            ? { ...arg, category: e.target.value }
                            : arg
                        )
                      );
                    }}
                  />
                </Grid2>
                <Grid2 xs={12} md={1.9}>
                  <TextField
                    fullWidth
                    select
                    value={fieldType}
                    onChange={(e) => {
                      setArgs(
                        args.map((arg, idx) =>
                          index === idx
                            ? {
                                ...arg,
                                fieldType: e.target.value as
                                  | 'STRING'
                                  | 'NUMBER',
                              }
                            : arg
                        )
                      );
                    }}
                  >
                    {['STRING', 'NUMBER'].map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>
                <Grid2 xs={12} md={1.9}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="error"
                    size="small"
                    sx={{ px: 1, height: 40 }}
                    onClick={() =>
                      setArgs(args.filter((arg, idx) => index !== idx))
                    }
                  >
                    Remove
                  </Button>
                </Grid2>
              </Grid2>
            ))}
          </Stack>
        </Container>
      </Stack>
      <Stack direction="row" justifyContent="end" spacing={2} py={1}>
        <Button
          variant="outlined"
          sx={{ color: 'secondary.main' }}
          onClick={goBack}
        >
          Back
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={submitHandler}
        >
          Next
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
