import { FC } from 'react';
import { GroupsTable } from 'src/components/organisms/groups/GroupsTable';
import { useMyAccessGroup } from 'src/components/organisms/course/enrollUser/useMyAccessGroups';

const CompanyGroups: FC = () => {
  const { myAccessGroups, isLoading } = useMyAccessGroup();

  return <GroupsTable groups={myAccessGroups || []} isLoading={isLoading} />;
};

export default CompanyGroups;
