import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { ChangeEvent, FC, MouseEvent, useState } from 'react';
import { Filter } from 'src/components/atoms/svg/FilterSvg';

const subjectEnum = ['GENERAL', 'ACCOUNTING', 'TECHNICAL', 'MARKETING'];

type FilterListPropsType = {
  filters: { [key: string]: boolean };
  setFilters: (list: { [key: string]: boolean }) => void;
};

export const FilterList: FC<FilterListPropsType> = ({
  filters,
  setFilters,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleJobChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton onClick={handleClick}>
        <Filter />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {subjectEnum.map((item, index) => (
          <MenuItem key={index} sx={{ py: 0, pr: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters[item]}
                  onChange={handleJobChange}
                  name={item}
                />
              }
              label={item}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
