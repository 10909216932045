import { styled, TableCell, tableCellClasses } from '@mui/material';

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EFEFEF',
    border: 0,
    fontSize: 14,
    fontWeight: 700,
    padding: '10px 16px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    width: '20%',
    maxWidth: 200,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '12px 16px',
    textOverflow: 'ellipsis',
  },
}));
