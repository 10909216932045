import { FC, useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import {
  useBaseCoursesControllerSetCertificateMutation,
  useCertificatesControllerGetAllQuery,
} from 'src/app/services/generatedApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { SelectCertificateTable } from '../../certificate/SelectCertificateTable';

type CertificateStepPropsType = {
  initialValue?: string;
  baseCourseId: string;
};

export const CertificateStep: FC<CertificateStepPropsType> = ({
  initialValue,
  baseCourseId,
}) => {
  const { data: certs, isLoading: loadingCerts } =
    useCertificatesControllerGetAllQuery();

  const modifiedList = useMemo(() => {
    if (!certs) return;
    const list = certs.map((cert) => {
      if (cert.id === initialValue) {
        return { id: cert.id, name: cert.name, selected: true };
      } else {
        return { id: cert.id, name: cert.name, selected: false };
      }
    });
    return list;
  }, [certs, initialValue]);

  const [certsList, setCertsList] = useState(modifiedList || []);
  useEffect(() => modifiedList && setCertsList(modifiedList), [modifiedList]);

  const navigate = useNavigate();

  const isNotSelected = useMemo(() => {
    if (!certsList) return;
    return certsList.every((cert) => !cert.selected);
  }, [certsList]);

  const handleSelect = (index: number) => {
    const newList = certsList?.map((cert, i) => {
      return { ...cert, selected: !certsList[index].selected && i === index };
    });
    setCertsList(newList);
  };

  const [setCertificate, { isLoading: loadingSetCert }] =
    useBaseCoursesControllerSetCertificateMutation();

  const submitHandler = () => {
    const certificate = certsList.find((cert) => cert.selected);
    if (!certificate || !baseCourseId) return;
    setCertificate({
      setCertificateDto: { baseCourseId, certificateId: certificate.id },
    })
      .unwrap()
      .then(() => {
        toast.success('Course created successfully');
        navigate('/course-store');
      })
      .catch(({ data }) => toast.error(data));
  };

  return (
    <Stack spacing={2} justifyContent="space-between" height="100%">
      <Stack spacing={2}>
        <Typography variant="title2" fontWeight="bold">
          Certificate
        </Typography>
        <SelectCertificateTable
          certsList={certsList}
          loadingCerts={loadingCerts}
          handleSelectCert={handleSelect}
        />
      </Stack>
      <Stack direction="row" justifyContent="end" spacing={2} py={1}>
        <Button
          variant="outlined"
          onClick={() => navigate('/admin/base-courses/')}
        >
          Skip
        </Button>
        <LoadingButton
          variant="contained"
          loading={loadingSetCert}
          disabled={isNotSelected}
          onClick={submitHandler}
        >
          Submit
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
