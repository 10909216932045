import { FC, useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { toast } from 'react-toastify';
import {
  useBaseCoursesControllerSetCertificateMutation,
  useCertificatesControllerGetAllQuery,
} from 'src/app/services/generatedApi';
import { SelectCertificateTable } from './SelectCertificateTable';

type SetCertificateDialogPropsType = {
  open: boolean;
  handleClose: () => void;
  baseCourseId: string;
};

export const SelectCertificateDialog: FC<SetCertificateDialogPropsType> = ({
  open,
  handleClose,
  baseCourseId,
}) => {
  const { data: certs, isLoading: loadingCerts } =
    useCertificatesControllerGetAllQuery(undefined as any, { skip: !open });

  const modifiedList = useMemo(() => {
    if (!certs) return;
    const list = certs.map((cert) => {
      return { id: cert.id, name: cert.name, selected: false };
    });
    return list;
  }, [certs]);

  const [certsList, setCertsList] = useState(modifiedList || []);
  useEffect(() => modifiedList && setCertsList(modifiedList), [modifiedList]);

  const isNotSelected = useMemo(() => {
    if (!certsList) return;
    return certsList.every((cert) => !cert.selected);
  }, [certsList]);

  const handleSelect = (index: number) => {
    const newList = certsList?.map((cert, i) => {
      return { ...cert, selected: !certsList[index].selected && i === index };
    });
    setCertsList(newList);
  };

  const [setCertificate, { isLoading: loadingSetCert }] =
    useBaseCoursesControllerSetCertificateMutation();

  const submitHandler = () => {
    const certificate = certsList.find((cert) => cert.selected);
    if (!certificate || !baseCourseId) return;
    setCertificate({
      setCertificateDto: { baseCourseId, certificateId: certificate.id },
    })
      .unwrap()
      .then(() => {
        toast.success('Course created successfully');
        handleClose();
      })
      .catch(({ data }) => toast.error(data));
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper">
      <DialogTitle>Set Certificate or Generate a new</DialogTitle>
      <DialogContent dividers>
        <SelectCertificateTable
          certsList={certsList}
          handleSelectCert={handleSelect}
          loadingCerts={loadingCerts}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={loadingSetCert}
          disabled={isNotSelected}
          onClick={submitHandler}
        >
          Set Certificate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
