import { FC, useState } from 'react';
import { Assessment, PersonRemove } from '@mui/icons-material';
import { IconButton, TableRow, Tooltip } from '@mui/material';
import { StyledTableCell } from 'src/components/molecules/StyledTableCell';
import { UserDataType } from './EnrolledUsersTable';
import { useNavigate } from 'react-router-dom';
import { convertDate } from 'src/utils/date';
import { UnenrollUserDialog } from './UnenrollUserDialog';

type EnrolledUserTableRowPropsType = {
  row: UserDataType;
  index: number;
  isReportPage: boolean;
};

export const EnrolledUserTableRow: FC<EnrolledUserTableRowPropsType> = ({
  row,
  index,
  isReportPage,
}) => {
  const [openUnenroll, setOpenUnenroll] = useState(false);
  const navigate = useNavigate();
  const isCompleted = row.state === 'COMPLETED';

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: index % 2 === 0 ? 'white' : '#ecf9f4',
          // rgba(0, 0, 0, 0.04)
        }}
        hover={isCompleted}
        role="checkbox"
        tabIndex={-1}
      >
        <StyledTableCell component="th" scope="row">
          {row.name}
        </StyledTableCell>
        <StyledTableCell>
          {convertDate(row.invitedAt, 'DD/MM/YYYY')}
        </StyledTableCell>
        <StyledTableCell>
          {convertDate(row.invitedAt || row.lastOpened, 'DD/MM/YYYY')}
        </StyledTableCell>
        <StyledTableCell>{row.state}</StyledTableCell>
        <StyledTableCell align="center">
          <IconButton
            size="small"
            onClick={() =>
              navigate(isReportPage ? row.userId : `report/${row.userId}`)
            }
          >
            <Tooltip title="Get User Report" color="primary">
              <Assessment />
            </Tooltip>
          </IconButton>
          <IconButton
            size="small"
            sx={{ ml: 2 }}
            onClick={() => setOpenUnenroll(true)}
          >
            <Tooltip title="Unenroll User">
              <PersonRemove color="error" />
            </Tooltip>
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <UnenrollUserDialog
        open={openUnenroll}
        handleClose={() => setOpenUnenroll(false)}
        id={row.id}
        name={row.name}
      />
    </>
  );
};
