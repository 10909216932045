import { FC, useEffect, useMemo } from 'react';
import {
  Skeleton,
  Stack,
  Typography,
  TextField,
  Container,
} from '@mui/material';
import { Avatar } from 'src/components/atoms/svg/AvatarSvg';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import {
  useUsersControllerFindQuery,
  useUsersControllerUpdateMutation,
} from 'src/app/services/generatedApi';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LmsSwitch } from 'src/components/molecules/LmsSwitch';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  isManager: false,
};

const formValidation = yup.object().shape({
  firstName: yup.string().required('Name is required!'),
  lastName: yup.string().required('Last name is required!'),
  email: yup.string().required('Email is required!'),
  isManager: yup.boolean(),
});

export const ViewUserProfile: FC = () => {
  const { userId } = useParams();

  const { data: users, isLoading } = useUsersControllerFindQuery({
    id: userId,
  });

  const user = useMemo(() => users && users[0], [users]);

  const [editUser, { isLoading: loadingEditUser }] =
    useUsersControllerUpdateMutation();

  const formInitialValues = user
    ? {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        isManager: user.isManager || false,
      }
    : initialValues;

  const submitHandler = (values: typeof initialValues) => {
    if (!user) return;
    const { firstName, lastName, email, isManager } = values;
    editUser({
      id: user.id,
      userUpdateDto: { firstName, lastName, email, isManager },
    })
      .unwrap()
      .then(() => toast.success('User Information updated successfully'))
      .catch(({ data }) => toast.error(data));
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formValidation,
    onSubmit: (
      { firstName, lastName, email, isManager },
      { setSubmitting }
    ) => {
      submitHandler({ firstName, lastName, email, isManager });
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!user) return;

    const { firstName, lastName, email, isManager } = user;
    formik.setValues({
      firstName,
      lastName,
      email,
      isManager: isManager || false,
    }); // do not depend on formik
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Container maxWidth="md">
      <form onSubmit={formik.handleSubmit}>
        <Stack width="100%" spacing={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center" spacing={2} pb={2}>
              <Avatar sx={{ width: 72, height: 72 }} />
              <Stack spacing={1}>
                {isLoading ? (
                  <>
                    <Skeleton variant="rounded" height={30} width={140} />
                    <Skeleton variant="rounded" height={20} width={180} />
                  </>
                ) : (
                  <>
                    <Typography
                      variant="title3"
                      color="secondary.dark"
                      fontWeight="bold"
                    >
                      {`${user?.firstName} ${user?.lastName}`}
                    </Typography>
                    <Typography variant="text0" color="secondary.dark">
                      {user?.email}
                    </Typography>
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack px={5} spacing={1}>
            <Stack>
              <Typography variant="subtitle1">Name</Typography>
              {isLoading ? (
                <Skeleton variant="rounded" height={40} />
              ) : (
                <TextField
                  error={Boolean(
                    formik.errors.firstName && formik.touched.firstName
                  )}
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  {...formik.getFieldProps('firstName')}
                />
              )}
            </Stack>
            <Stack>
              <Typography variant="subtitle1">Last name</Typography>
              {isLoading ? (
                <Skeleton variant="rounded" height={40} />
              ) : (
                <TextField
                  error={Boolean(
                    formik.errors.lastName && formik.touched.lastName
                  )}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  {...formik.getFieldProps('lastName')}
                />
              )}
            </Stack>
            <Stack>
              <Typography variant="subtitle1">Email</Typography>
              {isLoading ? (
                <Skeleton variant="rounded" height={40} />
              ) : (
                <TextField
                  error={Boolean(formik.errors.email && formik.touched.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  {...formik.getFieldProps('email')}
                />
              )}
            </Stack>
            <Stack spacing={1} pt={2}>
              <Typography variant="subtitle1">Is Manager</Typography>
              <LmsSwitch
                checked={formik.values.isManager}
                onChange={() =>
                  formik.setFieldValue('isManager', !formik.values.isManager)
                }
              />
            </Stack>
            <Stack direction="row" justifyContent="end" py={2}>
              <LoadingButton
                component="button"
                type="submit"
                size="large"
                variant="contained"
                loading={loadingEditUser}
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Container>
  );
};
