import { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  useUsersControllerActivateMutation,
  useUsersControllerSuspendMutation,
} from 'src/app/services/generatedApi';
import { toast } from 'react-toastify';

type SuspendUserDialogPropsType = {
  openDialog: boolean;
  handleClose: () => void;
  user: { id: string; name: string };
  isActive: boolean;
};

export const SuspendUserDialog: FC<SuspendUserDialogPropsType> = ({
  openDialog,
  handleClose,
  user,
  isActive,
}) => {
  const [suspendUser, { isLoading: loadingSuspend }] =
    useUsersControllerSuspendMutation();
  const [activeUser, { isLoading: loadingActive }] =
    useUsersControllerActivateMutation();

  const loading = loadingSuspend || loadingActive;

  const submitChangeUserStatus = () => {
    if (!isActive) {
      activeUser({ id: user.id })
        .unwrap()
        .then(() => {
          toast.success('User successfully activated');
          handleClose();
        })
        .catch(({ data }) => toast.error(data));
    } else {
      suspendUser({ id: user.id })
        .unwrap()
        .then(() => {
          toast.success('User successfully suspended');
          handleClose();
        })
        .catch(({ data }) => toast.error(data));
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperProps={{ sx: { p: 0.5, borderRadius: 2 } }}
    >
      <DialogTitle color={isActive ? 'error' : 'primary'} align="center">
        {isActive ? 'Suspend' : 'Active'} User
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure want to {isActive ? 'Suspend' : 'Active'} User{' '}
          <span style={{ color: isActive ? '#F83232' : '#27B67C' }}>
            {user.name}
          </span>{' '}
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant="outlined"
          color={isActive ? 'error' : 'primary'}
          autoFocus
          component="button"
          onClick={submitChangeUserStatus}
        >
          {isActive ? 'Suspend' : 'Active'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
