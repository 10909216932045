import { FC, useState } from 'react';
import { Dialog, DialogContent, Paper, Stack, Typography } from '@mui/material';
import { Groups, PersonAdd } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

type SelectUserCreationDialogPropsType = {
  openDialog: boolean;
  onClose: () => void;
};

export const SelectUserCreationDialog: FC<
  SelectUserCreationDialogPropsType
> = ({ openDialog, onClose }) => {
  const [firstElevation, setFirstElevation] = useState(2);
  const [secondElevation, setSecondElevation] = useState(2);

  const navigate = useNavigate();

  return (
    <Dialog open={openDialog} onClose={onClose} maxWidth="xs">
      <DialogContent>
        <Stack
          alignItems="center"
          component={Paper}
          elevation={firstElevation}
          onMouseOver={() => setFirstElevation(5)}
          onMouseLeave={() => setFirstElevation(2)}
          onClick={() => {
            navigate('new-user');
            onClose();
          }}
          sx={{ borderRadius: 2, cursor: 'pointer', p: 1 }}
        >
          <PersonAdd fontSize="large" color="primary" />
          <Typography variant="title3" fontWeight={700} color="primary">
            Create New User
          </Typography>
          <Typography variant="text0" color="customGray.text2">
            - Create User for specified Company
          </Typography>
          <Typography variant="text0" color="customGray.text2">
            - Create User without Company
          </Typography>
          <Typography variant="text0" color="customGray.text2">
            - Create User for new Company
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          spacing={1}
          component={Paper}
          elevation={secondElevation}
          onMouseOver={() => setSecondElevation(5)}
          onMouseLeave={() => setSecondElevation(2)}
          onClick={() => {
            navigate('batch-user');
            onClose();
          }}
          sx={{ mt: 2, borderRadius: 2, cursor: 'pointer', p: 1 }}
        >
          <Groups fontSize="large" color="primary" />
          <Typography variant="title3" fontWeight={700} color="primary">
            Batch User Upload
          </Typography>
          <Typography
            variant="text0"
            textAlign="center"
            color="customGray.text2"
          >
            Batch upload and manage users in your portal, including automatic
            creation, updates, invitations, and group/course assignment.
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
