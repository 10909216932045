import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  DialogContent,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useCoursesControllerFindMyCoursesQuery,
  useTicketControllerCreateTicketMutation,
} from 'src/app/services/generatedApi';
import { toast } from 'react-toastify';
import { SubjectType } from 'src/pages/support/Support';

const initialValues = {
  title: '',
  subject: '',
  courseId: '',
  message: '',
  // file: null,
};

const subjectEnum = ['GENERAL', 'ACCOUNTING', 'TECHNICAL', 'MARKETING'];

const validationSchema = yup.object().shape({
  title: yup.string().required('This field is required'),
  subject: yup.string().oneOf(subjectEnum).required('This field is required'),
  courseId: yup.string(),
  message: yup.string().required('This field is required'),
});

type NewTicketDialogPropsType = {
  openDialog: boolean;
  handleClose: () => void;
};

export const NewTicketDialog: FC<NewTicketDialogPropsType> = ({
  openDialog,
  handleClose,
}) => {
  const { data: myCourses } = useCoursesControllerFindMyCoursesQuery();

  const [createTicket, { isLoading }] =
    useTicketControllerCreateTicketMutation();

  const onClose = () => {
    formik.resetForm();
    handleClose();
  };

  const onSubmit = (values: typeof initialValues) => {
    createTicket({
      createTicketDto: {
        title: values.title,
        subject: values.subject as SubjectType,
        courseId: values.courseId,
        message: values.message,
      },
    })
      .unwrap()
      .then(() => {
        toast.success('Ticket sent successfully !');
        onClose();
      })
      .catch(({ data }) => toast.error(data));
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      PaperProps={{ sx: { width: '100%' } }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <DialogTitle align="center">Add New Ticket</DialogTitle>
          <DialogContent>
            <Stack spacing={2} py={1}>
              <TextField
                label="Title *"
                id="title"
                name="title"
                type="title"
                onChange={formik.handleChange}
                value={formik.values.title}
                error={Boolean(formik.errors.title && formik.touched.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
              <TextField
                select
                label="Subject"
                id="subject"
                name="subject"
                type="subject"
                onChange={formik.handleChange}
                value={formik.values.subject}
                error={Boolean(formik.errors.subject && formik.touched.subject)}
                helperText={formik.touched.subject && formik.errors.subject}
              >
                {subjectEnum.map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                    sx={{ '&: focus': { color: 'primary.main' } }}
                  >
                    {item.charAt(0) + item.slice(1).toLowerCase()}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label="Course"
                id="courseId"
                name="courseId"
                type="courseId"
                onChange={formik.handleChange}
                value={formik.values.courseId}
                error={Boolean(
                  formik.errors.courseId && formik.touched.courseId
                )}
                helperText={formik.touched.courseId && formik.errors.courseId}
              >
                {myCourses?.map(({ id, name }) => (
                  <MenuItem
                    key={id}
                    value={id}
                    sx={{
                      borderRadius: 1,
                      color: 'secondary',
                      '&: focus': { color: 'primary.main' },
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Message"
                multiline
                rows={3}
                id="message"
                name="message"
                type="message"
                onChange={formik.handleChange}
                value={formik.values.message}
                error={Boolean(formik.errors.message && formik.touched.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              autoFocus
              type="submit"
              component="button"
              loading={isLoading}
            >
              Add Ticket
            </LoadingButton>
          </DialogActions>
        </Stack>
      </form>
    </Dialog>
  );
};
