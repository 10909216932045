import { PaletteColorOptions } from '@mui/material';
import { PaletteColor } from '@mui/material';
import { createTheme } from '@mui/material';
import { breakpointGenerator } from 'src/utils/breakpointGenerator';

const customBreakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    title0: React.CSSProperties;
    title1: React.CSSProperties;
    title2: React.CSSProperties;
    title3: React.CSSProperties;
    subtitle0: React.CSSProperties;
    subtitle1: React.CSSProperties;
    subtitle2: React.CSSProperties;
    text0: React.CSSProperties;
    text1: React.CSSProperties;
    text2: React.CSSProperties;
    text3: React.CSSProperties;
    button1: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    title0?: React.CSSProperties;
    title1?: React.CSSProperties;
    title2?: React.CSSProperties;
    title3?: React.CSSProperties;
    subtitle0?: React.CSSProperties;
    subtitle1?: React.CSSProperties;
    subtitle2?: React.CSSProperties;
    text0?: React.CSSProperties;
    text1?: React.CSSProperties;
    text2?: React.CSSProperties;
    text3?: React.CSSProperties;
    button1?: React.CSSProperties;
  }

  interface Palette {
    customGray: PaletteColor & {
      ultralight: string;
      text1: string;
      text2: string;
      gray1: string;
      gray2: string;
      gray3: string;
      gray4: string;
      gray5: string;
      gray6: string;
      background: string;
      lightBackground: string;
    };
  }

  interface PaletteOptions {
    customGray: PaletteColorOptions & {
      ultralight: string;
      text1: string;
      text2: string;
      gray1: string;
      gray2: string;
      gray3: string;
      gray4: string;
      gray5: string;
      gray6: string;
      background: string;
      lightBackground: string;
    };
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title0: true;
    title1: true;
    title2: true;
    title3: true;
    subtitle0: true;
    subtitle1: true;
    subtitle2: true;
    text0: true;
    text1: true;
    text2: true;
    text3: true;
    button1: true;
  }
}

const theme = createTheme({
  breakpoints: { values: customBreakpoints },

  palette: {
    primary: { main: '#27B67C', light: '#A9E2CB' },
    secondary: { main: '#464646', light: '#B4B3B3', dark: '#4A4A4A' },
    error: { main: '#F83232', light: '#FF7373', dark: 'rgba(181, 91, 82, 1)' },
    warning: { main: '#FFC700' },
    info: { main: '#00C2FF' },
    customGray: {
      main: '#f00',
      light: '#EBEBEB',
      ultralight: '#F3F3F3',
      dark: '#2F3032',
      text1: '#5C5C5C',
      text2: '#6F6F6F',
      gray1: '#D3D3D3',
      gray2: '#667085',
      gray3: '#E4E7EC',
      gray4: '#9A9A9A',
      gray5: '#B8B8B8',
      gray6: '#293845',
      background: '#777',
      lightBackground: '#f7f7f7',
    },
  },

  typography: {
    button: { textTransform: 'capitalize' },
    title0: { ...breakpointGenerator([35, 40]) },
    title1: { ...breakpointGenerator([26, 32]) },
    title2: { ...breakpointGenerator([24, 28]) },
    title3: { ...breakpointGenerator([20, 24]) },
    subtitle0: { ...breakpointGenerator([18, 22]) },
    subtitle1: { ...breakpointGenerator([17, 20]) },
    subtitle2: { ...breakpointGenerator([16, 18]) },
    text0: { ...breakpointGenerator([14, 16]) },
    text1: { ...breakpointGenerator([12, 14]) },
    text2: { ...breakpointGenerator([10, 12]) },
    text3: { ...breakpointGenerator([8, 10]) },
    button1: { ...breakpointGenerator([14, 18]) },
  },

  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        contained: {
          fontWeight: 'bold',
          color: 'white',
          whiteSpace: 'nowrap',
        },
        outlined: {
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
        },
      },
    },
  },
});

export default theme;
