import { FC, Fragment, ReactNode, SyntheticEvent, useState } from 'react';
import { Stack, Box, Tab, Tabs, Skeleton } from '@mui/material';
import { NotificationCard } from 'src/components/atoms/NotificationCard';
import { useNotificationControllerGetNotificationQuery } from 'src/app/services/generatedApi';

type TabPanelProps = { children?: ReactNode; index: number; value: number };

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <Stack>
      {value === index && (
        <Stack spacing={2} py={3} px={{ xs: 1, md: 3 }}>
          {children}
        </Stack>
      )}
    </Stack>
  );
};

const Notifications: FC = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data: notifications, isLoading } =
    useNotificationControllerGetNotificationQuery();

  return (
    <Stack py={5} maxHeight="100%">
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            maxWidth: 'fit-content',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              '& .MuiButtonBase-root': { fontSize: { xs: 13, md: 16 } },
              '& .MuiTab-root.Mui-selected': {
                fontWeight: 'bold',
                borderBottom: 4,
              },
            }}
          >
            <Tab label="Inbox" />
            <Tab label="Archive" />
          </Tabs>
        </Box>
        {isLoading ? (
          <Stack spacing={2} p={3}>
            {[...Array(5)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={120}
                sx={{ bgcolor: 'secondary.light', borderRadius: 2 }}
              />
            ))}
          </Stack>
        ) : (
          <Fragment>
            <TabPanel value={value} index={0}>
              {notifications
                ?.filter((notification) => !notification.archive)
                .reverse()
                .map((notification, index) => (
                  <NotificationCard key={index} notification={notification} />
                ))}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {notifications
                ?.filter((notification) => notification.archive)
                .reverse()
                .map((notification, index) => (
                  <NotificationCard key={index} notification={notification} />
                ))}
            </TabPanel>
          </Fragment>
        )}
      </Box>
    </Stack>
  );
};

export default Notifications;
