import { FC, useEffect, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { CustomStepper } from 'src/components/organisms/course/createCourseSteps/CustomStepper';
import { MediaControllerCreateBaseCourseApiArg } from 'src/app/services/generatedApi';

import { useCustomMediaControllerCreateBaseCourseMutation } from 'src/app/services/api';
import { DescriptionStep } from 'src/components/organisms/course/createCourseSteps/DescriptionStep';
import { FileAndArgumentsStep } from 'src/components/organisms/course/createCourseSteps/FileAndArgsStep';
import { CertificateStep } from 'src/components/organisms/course/createCourseSteps/SetCertStep';
import { toast } from 'react-toastify';
import { UploadProcessDialog } from 'src/components/organisms/uploadProcess/UploadProcessDialog';

const createBaseCourseSteps = ['Details *', 'Session *', 'Certificate'];

const initialValues = {
  file: null,
  thumbnail: null,
  courseArguments: [],
  name: '',
  description: '',
  inStore: 'false',
  resource: [],
};

export type CreateBaseCourseFieldType = {
  key: keyof MediaControllerCreateBaseCourseApiArg['body'];
  value: any;
}[];

const CreateBaseCourse: FC = () => {
  const [initialData, setInitialData] = useState(initialValues);
  const [activeStep, setActiveStep] = useState(0);

  const [uploadProcessEvent, setUploadProcessEvent] = useState<{
    loaded: number;
    total: number;
  }>();

  const abortController = useRef<AbortController | undefined>();

  const [
    createBaseCourse,
    { isLoading: loadingCreateCourse, data: baseCourseRes },
  ] = useCustomMediaControllerCreateBaseCourseMutation();
  const [baseCourseId, setBaseCourseId] = useState<string>('');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (fields: CreateBaseCourseFieldType) => {
    fields.forEach((item) => {
      setInitialData((prevState) => ({ ...prevState, [item.key]: item.value }));
    });
  };

  const submitCreateCourse = () => {
    const {
      file,
      thumbnail,
      courseArguments,
      name,
      description,
      inStore,
      resource,
    } = initialData;

    if (!file || !thumbnail || !courseArguments) return;
    let formData = new FormData();
    formData.append('file', file);
    formData.append('thumbnail', thumbnail);
    formData.append('courseArguments', JSON.stringify(courseArguments));
    formData.append('name', name);
    formData.append('description', description);
    formData.append('inStore', inStore);
    formData.append('resource', JSON.stringify(resource));

    abortController.current = new AbortController();

    createBaseCourse({
      body: formData as any,
      abortController: abortController.current,
      onUploadProgress: setUploadProcessEvent as any,
    })
      .unwrap()
      .then(() => {
        toast.success('Course created successfully');
        setUploadProcessEvent(undefined);
      })
      .catch(({ data }) => {
        toast.error(data);
        setUploadProcessEvent(undefined);
      });
  };

  useEffect(() => {
    if (!baseCourseRes) return;
    setBaseCourseId(baseCourseRes as string);
    baseCourseId && handleNext();
  }, [baseCourseId, baseCourseRes]);

  return (
    <Stack pt={1} height="100%">
      <CustomStepper steps={createBaseCourseSteps} activeStep={activeStep} />
      <Stack height="100%" py={2}>
        {activeStep === 0 ? (
          <DescriptionStep
            mode="CREATE"
            goNext={handleNext}
            fillFormData={handleChange}
            image={initialData.thumbnail}
            resource={initialData.resource}
          />
        ) : activeStep === 1 ? (
          <FileAndArgumentsStep
            goBack={handleBack}
            mode="CREATE"
            file={initialData.file}
            args={initialData.courseArguments}
            fillFormData={handleChange}
            isLoading={loadingCreateCourse}
            submit={submitCreateCourse}
          />
        ) : (
          <CertificateStep baseCourseId={baseCourseId} />
        )}
      </Stack>
      <UploadProcessDialog
        handleClose={() => setUploadProcessEvent(undefined)}
        uploadProcessEvent={uploadProcessEvent as any}
        cancel={() => {
          abortController.current?.abort();
          setUploadProcessEvent(undefined);
        }}
      />
    </Stack>
  );
};

export default CreateBaseCourse;
