import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Filter: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33292 5.73508C2.46341 4.56597 3.39838 3 4.96591 3H19.0343C20.572 3 21.5124 4.51323 20.703 5.68521L15.4721 13.2594C15.4241 13.329 15.3987 13.4091 15.3987 13.4909V18.2614C15.3987 18.9567 14.9419 19.5867 14.2332 19.869L11.7799 20.8464C10.482 21.3634 9.02017 20.5119 9.02017 19.2388V13.5151C9.02017 13.4282 8.9915 13.3434 8.93777 13.2711L3.33292 5.73508ZM4.96592 4.75923L10.5708 12.2953C10.8394 12.6565 10.9828 13.0809 10.9828 13.5151V19.2388L13.4361 18.2614V13.4909C13.4361 13.082 13.5633 12.6812 13.8034 12.3334L19.0343 4.75923L4.96592 4.75923Z"
        fill="#4A4A4A"
      />
    </SvgIcon>
  );
};
