import { FC } from 'react';
import { BaseCoursesList } from 'src/components/organisms/course/coursesList/BaseCoursesList';

type AllBaseCoursesPropsType = {};

const AllBaseCourses: FC<AllBaseCoursesPropsType> = () => {
  return <BaseCoursesList />;
};

export default AllBaseCourses;
