import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useCoursesControllerUnEnrollUsersMutation } from 'src/app/services/generatedApi';
import { toast } from 'react-toastify';

type UnenrollUserDialogPropsType = {
  open: boolean;
  handleClose: () => void;
  name: string;
  id: string;
};

export const UnenrollUserDialog: FC<UnenrollUserDialogPropsType> = ({
  open,
  handleClose,
  name,
  id,
}) => {
  const [unenrollUser, { isLoading }] =
    useCoursesControllerUnEnrollUsersMutation();

  const handleSubmit = () => {
    unenrollUser({ idDto: { id } })
      .unwrap()
      .then(() => {
        toast.success('User successfully unenrolled');
        handleClose();
      })
      .catch(({ data }) => toast.error(data));
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { p: 0.5, borderRadius: 2 } }}
    >
      <DialogTitle color="error" align="center">
        Unenroll user
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure want to Unenroll user{' '}
          <span style={{ color: '#F83232' }}>{name}</span> ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="outlined"
          color="error"
          autoFocus
          component="button"
          sx={{ color: 'error.main' }}
          onClick={handleSubmit}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
