import { FC } from 'react';
import { Grid, Stack } from '@mui/material';
import { useBaseCoursesControllerGetStoreQuery } from 'src/app/services/generatedApi';
import { BaseCourseCard } from 'src/components/organisms/course/courseCard/BaseCourseCard';
import PageLoading from 'src/components/atoms/PageLoading';
import { BaseCourseCardLoading } from 'src/components/organisms/course/courseCard/CourseCardLoading';

const CourseStore: FC = () => {
  const { data: baseCourses = [], isLoading } =
    useBaseCoursesControllerGetStoreQuery();

  if (isLoading) return <PageLoading />;

  return (
    <Stack spacing={3} pb={2}>
      <Grid container spacing={2} justifyContent="start">
        {isLoading
          ? [...Array(3)].map((_, index) => (
              <Grid xs={12} sm={6} lg={4} item key={index}>
                <BaseCourseCardLoading />
              </Grid>
            ))
          : baseCourses?.map((baseCourse, index) => (
              <Grid xs={12} sm={6} lg={4} item key={index}>
                <BaseCourseCard baseCourse={baseCourse} />
              </Grid>
            ))}
      </Grid>
    </Stack>
  );
};

export default CourseStore;
