import { FC } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import Login from '../pages/auth/Login';
import Dashboard from 'src/pages/Dashboard';
import { SpecifiedUser } from 'src/components/organisms/user/SpecifiedUser';
import { ManagerRoute } from './ManagerRoute';
import ResetPassword from 'src/pages/auth/ResetPassword';
import ForgetPassword from 'src/pages/auth/ForgetPassword';
import Courses from 'src/pages/admin/course/Courses';
import CourseStore from 'src/pages/admin/course/courseStore/CourseStore';
import LaunchCourse from 'src/pages/admin/course/LaunchCourse';
import CreateBaseCourse from 'src/pages/admin/course/CreateBaseCourse';
import Users from 'src/pages/admin/users/CompanyUsers';
import NewUser from 'src/pages/admin/users/CreateUser';
import EditBaseCourse from 'src/pages/admin/course/EditBaseCourse';
import CourseReport from 'src/pages/course/CourseReport';
import Session from 'src/pages/course/Session';
import CourseResult from 'src/pages/course/CourseResult';
import History from 'src/pages/report/History';
import Profile from 'src/pages/setting/Profile';
import Notifications from 'src/pages/setting/Notifications';
import Support from 'src/pages/support/Support';
import Ticket from 'src/pages/support/Ticket';
import Notfound from 'src/pages/Notfound';
import {
  DashboardTemplate,
  DashboardTemplatePropsType,
} from 'src/components/organisms/templates/DashboardTemplate';
import { LoginTemplate } from 'src/components/organisms/templates/LoginTemplate';
import AllBaseCourses from 'src/pages/admin/AllBaseCourses';
import { AdminPrivateRoute } from './AdminPrivateRoute';
import CourseView from 'src/pages/course/courseView/CourseView';
import CompanyCourses from 'src/pages/admin/course/CompanyCourses';
import CompanyGroups from 'src/pages/admin/groups/CompanyGroups';
import Companies from 'src/pages/admin/company/Companies';
import CompanyCourseReport from 'src/pages/admin/course/CompanyCourseReport';
import EmailTemplates from 'src/pages/emailTemplate/EmailTemplates';
import EmailTemplate from 'src/pages/emailTemplate/EmailTemplate';
import AllUsers from 'src/pages/admin/users/AllUsers';
import BatchUserUpload from 'src/pages/admin/users/BatchUserUpload';

const getPage = <T = any,>(
  PageComponent: FC<any>,
  templateProps: DashboardTemplatePropsType,
  pageProps?: T
) => (
  <DashboardTemplate {...templateProps}>
    <PageComponent {...pageProps} />
  </DashboardTemplate>
);

const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth" element={<LoginTemplate />}>
          <Route path="login" element={<Login />} />
          <Route path="forget-password" element={<ForgetPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route
            index
            element={getPage(Dashboard, {
              pageTitle: 'Dashboard',
            })}
          />

          <Route path="my-courses">
            <Route index element={getPage(Courses, { pageTitle: 'Courses' })} />
            <Route path=":myCourseId">
              <Route
                index
                element={getPage(CourseView, { pageTitle: 'Course' })}
              />
              <Route
                path="session"
                element={getPage(Session, {
                  pageTitle: 'Course',
                  routes: [
                    { title: 'Courses', url: '/' },
                    { title: 'Name', url: '/my-courses/:myCourseId' },
                    { title: 'Session' },
                  ],
                })}
              />
              <Route
                path="result"
                element={getPage(CourseResult, {
                  pageTitle: 'Course',
                  routes: [
                    { title: 'Courses' },
                    { title: 'Name', url: '/my-courses/:myCourseId' },
                    { title: 'Result' },
                  ],
                })}
              />
              <Route
                path="report"
                element={getPage(CourseReport, {
                  pageTitle: 'Reports',
                  routes: [
                    { title: 'Courses', url: '/' },
                    { title: 'Name', url: '/my-courses/:myCourseId' },
                    { title: 'Reports' },
                  ],
                })}
              />
            </Route>
          </Route>

          <Route path="course-store" element={<ManagerRoute />}>
            <Route
              index
              element={getPage(CourseStore, {
                pageTitle: 'Course Store',
              })}
            ></Route>
            <Route path=":baseCourseId">
              <Route
                index
                element={getPage(CourseView, {
                  pageTitle: 'Course Detail',
                  routes: [
                    { title: 'Dashboard', url: '/' },
                    { title: 'Course Store', url: '/course-store' },
                    { title: 'Detail' },
                  ],
                })}
              />
              <Route
                path="preview"
                element={getPage(Session, {
                  pageTitle: 'Preview Course',
                  routes: [
                    { title: 'Dashboard', url: '/' },
                    { title: 'Courses', url: '/course-store' },
                    {
                      title: 'Name',
                      url: '/course-store/:baseCourseId',
                    },
                    { title: 'Preview' },
                  ],
                })}
              />
              <Route
                path="launch-course"
                element={getPage(LaunchCourse, {
                  pageTitle: 'Launch Course',
                  routes: [
                    { title: 'Company' },
                    { title: 'Course Store', url: '/course-store' },
                    {
                      title: 'Name',
                      url: '/course-store/:baseCourseId',
                    },
                    { title: 'Launch Course' },
                  ],
                })}
              />
            </Route>
          </Route>

          <Route path="company" element={<ManagerRoute />}>
            <Route path="courses">
              <Route
                index
                element={getPage(CompanyCourses, {
                  pageTitle: 'Company Courses',
                })}
              />
              <Route path=":courseId">
                <Route
                  index
                  element={getPage(CourseView, { pageTitle: 'Course' })}
                />
                <Route
                  path="preview"
                  element={getPage(Session, {
                    pageTitle: 'Course',
                    routes: [
                      { title: 'Courses', url: '/' },
                      { title: 'Name', url: '/company/courses/:courseId' },
                      { title: 'Preview' },
                    ],
                  })}
                />
                <Route path="report">
                  <Route
                    index
                    element={getPage(CompanyCourseReport, {
                      pageTitle: 'Course Report',
                      routes: [
                        { title: 'Courses', url: '/' },
                        { title: 'Name', url: '/company/courses/:courseId' },
                        { title: 'Reports' },
                      ],
                    })}
                  />
                  <Route
                    path=":userId"
                    element={getPage(CourseReport, {
                      pageTitle: 'User Course Report',
                      routes: [
                        { title: 'Courses', url: '/' },
                        { title: 'Name', url: '/company/courses/:courseId' },
                        {
                          title: 'Reports',
                          url: '/company/courses/:courseId/report',
                        },
                        { title: 'User Course Reports' },
                      ],
                    })}
                  />
                </Route>
                <Route
                  path="edit"
                  element={getPage(LaunchCourse, {
                    pageTitle: 'Edit Course',
                    routes: [
                      { title: 'Courses', url: '/' },
                      { title: 'Name', url: '/company/courses/:courseId' },
                      { title: 'Edit Course' },
                    ],
                  })}
                />
              </Route>
            </Route>
            <Route path="users">
              <Route index element={getPage(Users, { pageTitle: 'Users' })} />
              <Route
                path=":userId"
                element={getPage(SpecifiedUser, {
                  pageTitle: 'User',
                  routes: [
                    { title: 'Company' },
                    { title: 'Users', url: '/company/users' },
                    { title: 'Name' },
                  ],
                })}
              />
              <Route
                path="new-user"
                element={getPage(NewUser, {
                  pageTitle: 'Create User',
                  routes: [
                    { title: 'Company' },
                    { title: 'Users', url: '/company/users' },
                    { title: 'Create User' },
                  ],
                })}
              />
              <Route
                path="batch-user"
                element={getPage(BatchUserUpload, {
                  pageTitle: 'Batch User Upload',
                  routes: [
                    { title: 'Company' },
                    { title: 'Company Users', url: '/company/users' },
                    { title: 'Batch User Upload' },
                  ],
                })}
              />
            </Route>
            <Route
              path="groups"
              element={getPage(CompanyGroups, { pageTitle: 'Groups' })}
            />
            <Route path="email-templates">
              <Route
                index
                element={getPage(EmailTemplates, {
                  pageTitle: 'Email Templates',
                })}
              />
              <Route
                path=":emailTemplateId"
                element={getPage(EmailTemplate, {
                  pageTitle: 'Enrollment Template',
                })}
              />
            </Route>
          </Route>

          <Route path="admin" element={<AdminPrivateRoute />}>
            <Route
              path="companies"
              element={getPage(Companies, { pageTitle: 'Companies' })}
            />
            <Route path="users">
              <Route
                index
                element={getPage(AllUsers, { pageTitle: 'All Users' })}
              />
              <Route
                path="new-user"
                element={getPage(NewUser, {
                  pageTitle: 'User',
                  routes: [
                    { title: 'Admin Area' },
                    { title: 'All Users', url: '/admin/users' },
                    { title: 'Create New User' },
                  ],
                })}
              />
              <Route
                path="batch-user"
                element={getPage(BatchUserUpload, {
                  pageTitle: 'Batch User Upload',
                  routes: [
                    { title: 'Admin Area' },
                    { title: 'All Users', url: '/admin/users' },
                    { title: 'Batch User Upload' },
                  ],
                })}
              />
              <Route
                path=":userId"
                element={getPage(SpecifiedUser, {
                  pageTitle: 'User',
                  routes: [
                    { title: 'Admin Area' },
                    { title: 'All Users', url: '/admin/users' },
                    { title: 'Name' },
                  ],
                })}
              />
            </Route>
            <Route path="base-courses">
              <Route
                index
                element={getPage(AllBaseCourses, {
                  pageTitle: 'Base Courses',
                })}
              />
              <Route
                path="create"
                element={getPage(CreateBaseCourse, {
                  pageTitle: 'Create Course',
                  routes: [
                    { title: 'Dashboard', url: '/' },
                    { title: 'Base Courses', url: '/admin/base-courses' },
                    { title: 'Create' },
                  ],
                })}
              />
              <Route path=":baseCourseId">
                <Route
                  index
                  element={getPage(CourseView, {
                    pageTitle: 'Course Detail',
                    routes: [
                      { title: 'Dashboard', url: '/' },
                      { title: 'Base Courses', url: '/admin/base-courses' },
                      { title: 'Detail' },
                    ],
                  })}
                />
                <Route
                  path="preview"
                  element={getPage(Session, {
                    pageTitle: 'Preview Course',
                    routes: [
                      { title: 'Dashboard', url: '/' },
                      { title: 'Base Courses', url: '/admin/base-courses' },
                      {
                        title: 'Name',
                        url: '/admin/base-courses/:baseCourseId',
                      },
                      { title: 'Preview' },
                    ],
                  })}
                />
                <Route
                  path="edit"
                  element={getPage(EditBaseCourse, {
                    pageTitle: 'Edit Course',
                    routes: [
                      { title: 'Dashboard', url: '/' },
                      { title: 'Base Courses', url: '/admin/base-courses' },
                      {
                        title: 'Name',
                        url: '/admin/base-courses/:baseCourseId',
                      },
                      { title: 'Edit' },
                    ],
                  })}
                />
              </Route>
            </Route>
            <Route path="email-templates">
              <Route
                index
                element={getPage(EmailTemplates, {
                  pageTitle: 'Email Templates',
                })}
              />
              <Route
                path=":emailTemplateId"
                element={getPage(EmailTemplate, {
                  pageTitle: 'Enrollment Template',
                })}
              />
            </Route>
          </Route>

          <Route
            path="/history"
            element={getPage(History, {
              pageTitle: 'History',
            })}
          />

          <Route path="/setting">
            <Route
              path="profile"
              element={getPage(Profile, {
                pageTitle: 'Profile',
              })}
            />
            <Route
              path="notifications"
              element={getPage(Notifications, {
                pageTitle: 'Notifications',
              })}
            />
          </Route>

          <Route path="/support">
            <Route
              index
              element={getPage(Support, {
                pageTitle: 'Support',
              })}
            />
            <Route
              path="/support/ticket/:ticketId"
              element={getPage(Ticket, {
                pageTitle: 'Ticket',
                routes: [
                  { title: 'Support', url: '/support' },
                  { title: 'Ticket' },
                ],
              })}
            />
          </Route>
        </Route>

        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
