import { FC } from 'react';
import { useDrag } from 'react-dnd';
import { makeStyles } from '@material-ui/core/styles';
import { ImageArgumentType } from '../CertificateMenu';

const useStyles = makeStyles({
  image: {
    opacity: 1,
    border: '1px dashed',
    borderRadius: '3%',
    transition: 'opacity 0.5s',
    '&:hover': { opacity: 0.8, border: '1px solid' },
    width: '100%',
    height: '100%',
    cursor: 'move',
  },
});

export const ImageArgument: FC<{ data: ImageArgumentType }> = ({ data }) => {
  const [_, drag] = useDrag(() => ({
    type: 'ARGUMENT',
    item: data,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const classes = useStyles();

  return <img ref={drag} src={data.url} alt="" className={classes.image} />;
};
