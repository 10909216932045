import { FC, useMemo } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { DashboardReport } from 'src/components/organisms/dashboard/DashboardReport';
import { DashboardTotalCourses } from 'src/components/organisms/dashboard/DashboardTotalCourses';
import { DashboardActivities } from 'src/components/organisms/dashboard/DashboardActivities';
import { CourseMode } from 'src/types/course-mode.enum';
import { BaseCoursesList } from 'src/components/organisms/course/coursesList/BaseCoursesList';
import { MyCoursesList } from 'src/components/organisms/course/coursesList/MyCoursesList';
import { CompanyCoursesList } from 'src/components/organisms/course/coursesList/CompanyCoursesList';
import { Link as RouterLink } from 'react-router-dom';

const Dashboard: FC = () => {
  const { isManager, isAdmin, adminSelectedCompanyId } = useAppSelector(
    (state) => ({
      isManager: state.auth.isManager,
      isAdmin: state.auth.isAdmin,
      adminSelectedCompanyId: state.auth.adminSelectedCompanyId,
    })
  );

  const courseMode = useMemo(() => {
    if (isAdmin && !adminSelectedCompanyId) {
      return CourseMode.BASE_COURSE;
    }
    if (isManager || isAdmin) {
      return CourseMode.COMPANY_COURSE;
    }
    return CourseMode.MY_COURSE;
  }, [adminSelectedCompanyId, isAdmin, isManager]);

  return (
    <Stack justifyContent="space-between">
      <Stack spacing={1}>
        <Stack direction="row" alignItems="end" spacing={2}>
          <Typography variant="title1" fontWeight={700} color="secondary.dark">
            {courseMode === CourseMode.BASE_COURSE
              ? 'Base Courses'
              : courseMode === CourseMode.MY_COURSE
              ? 'My Courses'
              : 'Company Courses'}
          </Typography>
          <Button
            component={RouterLink}
            to={
              courseMode === CourseMode.BASE_COURSE
                ? '/admin/base-courses'
                : courseMode === CourseMode.MY_COURSE
                ? '/my-courses'
                : '/company/courses'
            }
            color="secondary"
            sx={{ p: 0, color: 'customGray.text2' }}
          >
            See more
          </Button>
        </Stack>
        {courseMode === CourseMode.BASE_COURSE ? (
          <BaseCoursesList limit={3} />
        ) : courseMode === CourseMode.MY_COURSE ? (
          <MyCoursesList limit={3} />
        ) : (
          <CompanyCoursesList
            limit={3}
            isAdmin={isAdmin}
            companyId={adminSelectedCompanyId}
          />
        )}
      </Stack>
      <Grid2 container gap={4}>
        <Grid2 xs={12} md={6.1} overflow="hidden">
          {isManager ? <DashboardReport /> : <DashboardTotalCourses />}
        </Grid2>
        <Grid2 xs={12} md>
          <DashboardActivities />
        </Grid2>
      </Grid2>
    </Stack>
  );
};

export default Dashboard;
