import { FC } from 'react';
import { Box, Container, Stack } from '@mui/material';
import { UserSidebar } from '../layout/UserSidebar';
import { Header } from '../layout/Header';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { BreadCrumbs } from '../layout/BreadCrumbs';
import { AdminSidebar } from '../layout/AdminSidebar';
import LmsFooter from 'src/assets/images/lms-footer.png';

export type dashboardTemplateRouteType = {
  title: string;
  url?: string;
  autoUrl?: boolean;
};

export type DashboardTemplatePropsType = {
  pageTitle?: string;
  children?: JSX.Element;
  routes?: dashboardTemplateRouteType[];
};

export const DashboardTemplate: FC<DashboardTemplatePropsType> = ({
  pageTitle = '',
  children,
  routes,
}) => {
  const { isManager } = useAppSelector((state) => ({
    isManager: state.auth.isManager,
  }));

  return (
    <Stack
      direction="row"
      width="100%"
      height="100vh"
      maxHeight="100vh"
      overflow="hidden"
    >
      {isManager ? <AdminSidebar /> : <UserSidebar />}
      <Stack width="100%" sx={{ height: '100vh' }}>
        <Header title={pageTitle} />
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Container
            sx={{ display: 'flex', flexDirection: 'column', minHeight: '82vh' }}
          >
            {routes && <BreadCrumbs routes={routes} />}
            <Box sx={{ flexGrow: 1, display: 'grid', py: 1 }}>{children}</Box>
          </Container>
          <Stack
            justifyContent="center"
            sx={{
              width: '100%',
              height: 65,
              mt: 1,
              px: 2,
            }}
          >
            <img
              src={LmsFooter}
              alt="footer"
              style={{ width: 200, height: 50 }}
            />
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};
