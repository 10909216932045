import { FC } from 'react';
import { Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { Insights } from 'src/components/organisms/course/report/Insights';
import { EnrolledUsersTable } from 'src/components/organisms/course/report/EnrolledUsersTable';
import {
  useCoursesControllerGetCourseByIdQuery,
  useReportControllerGetCompanyCourseReportQuery,
  useReportControllerGetSelfAdjustCourseBodyAverageForCompanyQuery,
} from 'src/app/services/generatedApi';
import { useParams } from 'react-router-dom';
import { SelfAdjustSvg } from 'src/components/organisms/course/report/SelfAdjustSvg';
import { CustomDoughnut } from 'src/components/atoms/Doughnut';

const CompanyCourseReport: FC = () => {
  const { courseId } = useParams();

  const { data: companyCourse } = useCoursesControllerGetCourseByIdQuery(
    { id: courseId! },
    { skip: !courseId }
  );

  const { data: courseReport, isLoading } =
    useReportControllerGetCompanyCourseReportQuery(
      { id: courseId! },
      { skip: !courseId }
    );

  const { data: selfAdjustAverage } =
    useReportControllerGetSelfAdjustCourseBodyAverageForCompanyQuery(
      { id: courseId! },
      { skip: !courseId }
    );

  return (
    <Stack spacing={4}>
      <Grid container gap={3}>
        <Grid item xs component={Paper}>
          <Stack height="100%">
            <Typography variant="subtitle1" fontWeight={700} sx={{ px: 1 }}>
              Result summary
            </Typography>
            <Divider />
            {courseReport && <CustomDoughnut values={courseReport} />}
          </Stack>
        </Grid>
        {companyCourse?.name
          .toLowerCase()
          .replace('_', ' ')
          .includes('self adjust') &&
          selfAdjustAverage && (
            <Grid item xs component={Paper}>
              <SelfAdjustSvg
                size={{ width: 260, height: 380 }}
                values={selfAdjustAverage}
              />
            </Grid>
          )}
        <Insights values={courseReport} loading={isLoading} />
      </Grid>
      <EnrolledUsersTable isReportPage={true} />
    </Stack>
  );
};

export default CompanyCourseReport;
