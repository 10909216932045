import { FC, useEffect } from 'react';
import {
  Stack,
  Typography,
  TextField,
  Skeleton,
  Container,
} from '@mui/material';
import { Avatar } from 'src/components/atoms/svg/AvatarSvg';
import {
  useUsersControllerGetUserProfileQuery,
  useUsersControllerUpdateMyProfileMutation,
} from 'src/app/services/generatedApi';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { ChangePassword } from 'src/components/organisms/setting/ChangePassword';

const initialValues = { firstName: '', lastName: '', email: '' };

const formValidation = yup.object().shape({
  firstName: yup.string().required('Name is required!'),
  lastName: yup.string().required('Last name is required!'),
  email: yup.string().required('Email is required!'),
});

const Profile: FC = () => {
  const { data: user, isLoading } = useUsersControllerGetUserProfileQuery();

  const formInitialValues = user
    ? {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      }
    : initialValues;

  const [editUser, { isLoading: loadingEdit }] =
    useUsersControllerUpdateMyProfileMutation();

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formValidation,
    onSubmit: ({ firstName, lastName, email }, { setSubmitting }) => {
      editUser({ userUpdateDto: { firstName, lastName, email } })
        .unwrap()
        .then(() => toast.success('Information updated successfully'))
        .catch(({ data }) => toast.error(data));
      setSubmitting(false);
    },
  });

  const setInitData = () => {
    if (!user) return;

    const { firstName, lastName, email } = user;
    formik.setValues({ firstName, lastName, email }); // do not depend on formik
  };

  useEffect(() => {
    setInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Container maxWidth="md">
      <Stack width="100%" spacing={4}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar sx={{ width: 72, height: 72 }} />
          <Stack spacing={1}>
            {isLoading ? (
              <>
                <Skeleton variant="rounded" height={30} width={140} />
                <Skeleton variant="rounded" height={20} width={180} />
              </>
            ) : (
              <>
                <Typography
                  variant="title3"
                  color="secondary.dark"
                  fontWeight="bold"
                >
                  {`${user?.firstName} ${user?.lastName}`}
                </Typography>
                <Typography variant="text0" color="secondary.dark">
                  {user?.email}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
        <Stack px={5} spacing={2}>
          <form onSubmit={formik.handleSubmit}>
            <Stack>
              <Typography variant="subtitle2">Name</Typography>
              <TextField
                error={Boolean(
                  formik.errors.firstName && formik.touched.firstName
                )}
                helperText={formik.touched.firstName && formik.errors.firstName}
                {...formik.getFieldProps('firstName')}
              />
            </Stack>
            <Stack>
              <Typography variant="subtitle2">Last name</Typography>
              <TextField
                error={Boolean(
                  formik.errors.lastName && formik.touched.lastName
                )}
                helperText={formik.touched.lastName && formik.errors.lastName}
                {...formik.getFieldProps('lastName')}
              />
            </Stack>
            <Stack>
              <Typography variant="subtitle2">Email</Typography>
              <TextField
                error={Boolean(formik.errors.email && formik.touched.email)}
                helperText={formik.touched.email && formik.errors.email}
                {...formik.getFieldProps('email')}
              />
            </Stack>
            <Stack direction="row" justifyContent="end" spacing={2} py={2}>
              <LoadingButton
                component="button"
                type="submit"
                variant="contained"
                loading={loadingEdit}
              >
                Save
              </LoadingButton>
            </Stack>
          </form>
        </Stack>
        <ChangePassword />
      </Stack>
    </Container>
  );
};

export default Profile;
