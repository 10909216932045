import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Logout: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx }}>
      <path
        d="M14.9998 12.9998C14.4469 12.9998 13.9999 13.4479 13.9999 13.9997V17.9998C13.9999 18.5508 13.5519 18.9998 12.9998 18.9998H9.99984V3.9999C9.99984 3.1459 9.45584 2.38291 8.63791 2.09892L8.34183 1.99986H12.9998C13.5519 1.99986 13.9999 2.44883 13.9999 2.99997V5.99994C13.9999 6.55182 14.4469 6.99987 14.9998 6.99987C15.5528 6.99987 15.9998 6.55182 15.9998 5.99994V2.99997C15.9998 1.346 14.6538 0 12.9998 0H2.24998C2.21189 0 2.18003 0.0170287 2.14304 0.0219724C2.09489 0.0179442 2.04893 0 2.00004 0C0.897025 0 0 0.896842 0 1.99986V19.9997C0 20.8537 0.544001 21.6167 1.36192 21.9007L7.37999 23.9067C7.58397 23.9697 7.78685 23.9998 7.99998 23.9998C9.103 23.9998 9.99984 23.1027 9.99984 21.9997V20.9998H12.9998C14.6538 20.9998 15.9998 19.6538 15.9998 17.9998V13.9997C15.9998 13.4479 15.5528 12.9998 14.9998 12.9998V12.9998Z"
        fill="#B55B52"
      />
      <path
        d="M23.7067 9.29292L19.7067 5.29302C19.4208 5.00702 18.9907 4.92096 18.6168 5.07586C18.2438 5.23095 17.9998 5.59588 17.9998 5.99999V8.99996H13.9999C13.4478 8.99996 12.9998 9.44783 12.9998 9.99989C12.9998 10.5519 13.4478 10.9998 13.9999 10.9998H17.9998V13.9998C17.9998 14.4039 18.2438 14.7688 18.6168 14.9239C18.9907 15.0788 19.4208 14.9928 19.7067 14.7069L23.7067 10.7069C24.0977 10.3159 24.0977 9.68385 23.7067 9.29292V9.29292Z"
        fill="#B55B52"
      />
    </SvgIcon>
  );
};
