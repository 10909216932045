import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  ResponseType,
} from 'axios';
import { RootStateType } from '../store';
import { toast } from 'react-toastify';
import { logoutAction } from '../slices/authSlice';

const getBaseCourse = () => {
  let url =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEVELOPMENT_BASE_URL
      : process.env.REACT_APP_PRODUCTION_BASE_URL;
  if (!url || url === 'auto') {
    url = window.location.origin;
    if (
      url === 'https://lms-eosin.vercel.app' ||
      url === 'https://lms-pza9wq5hu-info-aminafzarco.vercel.app'
    ) {
      url = 'https://lms-backend.amaj.dev';
    }
  }
  return url;
};

export const baseUrl = getBaseCourse();

export const baseQuery: BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig['method'];
    body?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
    abortController?: AbortController;
    responseType?: ResponseType;
    onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
  },
  unknown,
  unknown
> = async (
  {
    url,
    method = 'GET',
    body,
    params,
    headers,
    abortController,
    responseType,
    onUploadProgress,
  },
  { getState, dispatch }
) => {
  const errorMessage = 'Something went wrong, please try again later.';

  try {
    const { auth } = getState() as RootStateType;

    const axiosHeader = () => {
      let result = { ...headers } as AxiosRequestHeaders;
      if (auth.token) {
        result.authorization = `Bearer ${auth.token}`;
      }
      return result;
    };

    const result = await axios({
      url: baseUrl + url,
      method,
      data: body,
      params,
      headers: axiosHeader(),
      ...(abortController && { signal: abortController.signal }),
      responseType,
      onUploadProgress,
    });
    return { data: result.data };
  } catch (axiosError) {
    const e = axiosError as AxiosError;
    const error = {
      status: e.response?.status,
      data: (e.response?.data || e.message) as any,
    };

    console.log('rtk query error handler => ', error);
    if (error?.data === 'canceled' || !error.status) {
      return { error };
    }
    switch (error.status) {
      case 404:
        return { error };
      case 401:
        dispatch(logoutAction());
        break;
      default:
        toast.error(error.data?.message || errorMessage);
        console.log(
          error.data?.message
            ? error.status
              ? error.data?.message + ` (status code: ${error.status})`
              : error.data?.message
            : errorMessage
        );
        break;
    }
    return { error };
  }
};
