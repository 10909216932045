import type { FC } from 'react';
import { Stack } from '@mui/material';
import { useDrag } from 'react-dnd';
import { textArgumentsType } from '../CertificateMenu';

export const TextArgument: FC<{
  argument: textArgumentsType;
}> = ({ argument }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'ARGUMENT',
    item: argument,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <Stack
      ref={drag}
      alignItems="center"
      sx={{
        cursor: 'move',
        opacity: isDragging ? 0.4 : 1,
        py: 0.5,
        border: '1px dashed',
        borderRadius: 2,
        '&: hover': { border: '1px solid' },
      }}
    >
      {argument.text || argument.type}
    </Stack>
  );
};
