import { FC, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { EnrolledUserAnswerRo } from 'src/app/services/generatedApi';

type SelfAdjustSvgPropsType = {
  size: { width: number; height: number };
  values: EnrolledUserAnswerRo[];
};

export const SelfAdjustSvg: FC<SelfAdjustSvgPropsType> = ({ size, values }) => {
  const items = [
    {
      name: 'right_foot',
      d: 'M157.92 377.16C158.253 380.277 158.815 383.365 159.6 386.4C161.42 387.047 164.958 387.503 165.76 389.2C164.766 390.27 164.396 391.938 163.52 393.12C160.504 397.188 151.872 402.581 144.76 400.96C144.29 400.249 143.365 399.552 143.08 398.72C142.986 396.292 142.893 393.866 142.8 391.44C142.451 389.802 141.523 386.565 141.96 384.72C142.475 382.542 144.091 381.181 144.48 378.84C149.374 378.902 153.569 377.734 157.92 377.16Z',
    },
    {
      name: 'left_foot',
      d: 'M119 377.16C123.48 377.812 127.96 378.466 132.44 379.12C136.082 383.541 134.93 393.837 133.28 400.12C126.018 404.586 112.325 394.937 111.44 388.64C113.42 387.704 115.482 386.955 117.6 386.4C117.972 383.432 118.951 380.867 119 377.16Z',
    },
    {
      name: 'right_lowleg',
      d: 'M144.76 378C144.667 372.4 144.573 366.8 144.48 361.2C143.609 357.188 144.026 352.848 143.08 348.6C140.521 337.114 140.745 321.633 143.08 310.52H151.48L168.56 308.84C169.092 312.892 170.754 318.542 169.68 323.4C169.213 327.04 168.747 330.68 168.28 334.32C166.068 342.586 163.814 350.806 161.28 358.68C160.44 362.788 159.6 366.894 158.76 371C158.338 372.834 158.738 374.752 158.2 376.32C153.728 376.463 149.627 377.894 144.76 378Z',
    },
    {
      name: 'left_lowleg',
      d: 'M134.12 310.52C134.68 313.788 135.24 317.054 135.8 320.32V325.64C135.8 330.901 135.916 336.143 134.96 340.48L134.4 347.76C132.349 356.838 132.216 367.612 132.44 378C129.436 378.053 120.722 377.3 119 376.04C118.04 374.489 118.65 371.918 118.16 369.88C117.414 366.332 116.667 362.786 115.92 359.24C113.635 351.725 111.338 343.983 109.2 336.28C108.827 333.572 108.454 330.866 108.08 328.16C106.852 322.801 107.049 313.589 108.64 308.84C116.601 310.313 125.047 310.472 134.12 310.52Z',
    },
    {
      name: 'right_knee',
      d: 'M168.28 307.72C160.279 307.997 152.299 309.333 143.36 309.4V308.84C144.304 307.398 143.92 304.055 143.92 301.84C143.92 295.523 143.49 289.769 141.68 285.04H142.8C144.54 283.998 148.103 285.253 150.36 284.76C156.642 283.388 163.545 283.962 169.12 281.96V285.04C168.025 286.804 169.052 290.016 168.56 292.32C167.552 297.044 168.248 302.691 168.28 307.72Z',
    },
    {
      name: 'left_knee',
      d: 'M135.24 285.04C134.777 289.044 133.912 293.98 133 298.2C132.165 302.064 133.492 306.04 133.56 309.4C124.609 309.476 116.682 307.994 108.64 307.72V307.16C109.308 306.068 108.92 303.173 108.92 301.56C108.92 296.71 108.472 292.564 108.36 288.12C108.318 286.437 107.411 282.685 108.36 281.96C109.893 282.96 112.202 282.582 114.24 283.08C120.41 284.592 128.067 284.508 135.24 285.04Z',
    },
    {
      name: 'right_thigh',
      d: 'M141.4 283.64C141.587 271.695 141.773 259.748 141.96 247.8C141.662 246.414 142.055 243.934 141.68 242.2C141.307 239.213 140.933 236.227 140.56 233.24C153.893 233.431 166.889 231.211 177.8 228.76V230.72C176.568 232.8 176.867 240.115 176.12 243.04C173.939 251.58 172.961 260.821 170.8 269.36C169.841 273.149 170.351 277.315 169.12 280.84C165.662 280.952 161.94 281.792 158.76 282.52C153.662 283.688 147.068 283.576 141.4 283.64Z',
    },
    {
      name: 'left_thigh',
      d: 'M135.8 283.64C130.604 283.702 125.099 283.517 120.68 282.52C119.187 282.427 117.693 282.333 116.2 282.24C113.629 281.635 110.716 280.952 107.8 280.84C107.387 272.204 105.305 263.328 103.32 255.64C101.062 246.896 100.981 237.985 99.1201 228.76H99.6801C102.068 230.399 106.018 229.939 109.2 230.72C117.199 232.682 127.321 233.159 136.64 233.24V233.52C135.088 235.969 136.248 241.316 135.52 244.72C134.785 248.159 135.24 253.108 135.24 257.04C135.427 265.906 135.613 274.772 135.8 283.64Z',
    },
    {
      name: 'buttocks',
      d: 'M99.6798 194.04C101.867 194.31 103.901 195.183 105.84 195.72C109.452 196.72 113.225 196.872 116.76 197.68L128.24 198.8H134.96C138.659 199.584 146.45 199.307 150.08 198.52L156.8 198.24C163.798 196.686 171.464 196.748 177.52 194.32C178.19 200.046 178.92 205.942 178.92 212.8V218.4C178.325 221.176 178.108 224.634 178.08 227.64H177.52C175.463 229.068 171.854 228.654 169.12 229.32C164.197 230.518 158.127 230.429 152.88 231.56H148.68C146.817 231.961 143.197 232.769 140.84 232.12C139.79 231.831 138.656 230.956 137.76 230.44C137.605 231.527 137.41 231.496 136.92 232.12C135.318 231.924 131.987 232.652 129.64 232.12C122.655 230.538 115.17 230.765 108.64 229.04C105.565 228.228 101.348 228.544 99.1198 226.8C97.8344 224.756 99.1224 220.756 98.5598 218.12C96.8933 210.308 98.7903 201.27 99.6798 194.04Z',
    },
    {
      name: 'low_back',
      d: 'M172.48 172.76C172.613 179.191 175.336 186.841 176.68 192.92H176.12C172.764 195.264 163.721 195.18 158.76 196.28C156.044 196.882 152.368 196.786 149.52 197.4H143.08C139.347 198.187 131.598 197.912 127.96 197.12C123.917 196.24 120.298 197.161 116.48 196.28C110.81 194.971 104.947 193.87 99.6802 192.64V191.52L103.32 177.24C103.728 175.707 103.38 173.89 104.16 172.76C105.329 173.502 107 172.96 108.64 173.32C111.2 173.883 114.677 173.872 117.32 174.44H121.52C125.36 175.26 130.012 175 134.4 175C140.818 175 148.075 175.656 153.72 174.44H157.64L172.48 172.76Z',
    },
    {
      name: 'mid_back',
      d: 'M174.16 147C174.006 150.673 173.537 154.327 172.76 157.92C172.667 162.307 172.573 166.693 172.48 171.08C162.221 172.289 151.699 173.6 139.72 173.6H127.12C123.243 172.767 118.864 173.642 114.8 172.76L104.44 171.36C104.504 162.143 103.127 154.76 103.04 146.44H103.6C106.279 148.502 112.315 148.552 116.2 149.52C123.004 151.215 130.975 151.48 139.44 151.48C142.244 151.48 145.782 151.707 148.12 151.2L157.36 150.64L168.28 148.68C170.138 148.162 172.125 147.289 174.16 147Z',
    },
    {
      name: 'right_hand',
      d: 'M252.56 205.52C249.183 204.975 246.497 198.275 245 195.44C244.55 194.587 242.859 192.239 243.32 191.52C246.527 188.29 251.514 186.208 253.68 182H254.24C257.463 187.413 262.949 191.754 265.44 197.68C267.256 202.002 265.877 208.79 263.48 211.4C262.989 211.223 263.208 211.346 262.92 211.12C260.52 208.504 259.868 204.023 257.88 201.04C255.91 198.086 253.522 197.014 249.76 195.72V196.84C251.048 198.502 252.527 202.683 252.56 205.52Z',
    },
    {
      name: 'right_wrist',
      d: 'M246.12 170.24C248.556 171.222 249.794 175.898 251.16 178.08C251.699 178.94 252.882 179.656 252.56 180.6C251.613 183.341 245.067 188.923 242.2 189.84V189.56C240.243 186.639 240.389 182.452 238 180.04C240.233 176.67 244.89 174.491 246.12 170.24Z',
    },
    {
      name: 'left_hand',
      d: 'M23.5199 182.28C25.0425 182.832 25.8103 184.592 26.8799 185.64C29.1179 187.833 31.8429 190.333 34.7199 191.8C33.9863 194.904 28.3605 205.366 25.4799 205.8C25.279 205.167 25.1844 204.504 25.1999 203.84C26.7119 201.834 27.2887 198.686 28.2799 196.28H26.5999C25.8119 196.866 24.6399 196.853 23.7999 197.4C22.1252 198.489 20.3735 200.741 19.3199 202.44C17.9977 204.572 16.4011 211.161 13.9999 211.68C5.94425 199.211 19.5677 189.939 23.5199 182.28Z',
    },
    {
      name: 'left_wrist',
      d: 'M35.8399 190.12C31.4422 189.333 27.7443 183.588 24.9199 180.88L25.1999 180.04C27.3324 178.332 29.7858 172.984 31.3599 170.52H31.6399C32.5446 173.253 35.3401 175.898 37.2399 177.8C38.0729 178.634 39.7501 179.538 39.4799 180.6C38.5232 183.746 36.212 186.251 35.8399 190.12Z',
    },
    {
      name: 'right_lowarm',
      d: 'M226.24 144.2C228.338 145.079 229.957 148.313 231.28 150.08C234.251 154.045 237.922 157.747 240.8 161.84C242.334 164.022 243.396 166.729 245.28 168.56C243.865 173.05 239.277 175.363 236.88 178.92L236.6 178.64C235.228 177.723 234.537 175.982 233.24 175C229.33 172.039 225.642 168.678 221.76 165.76C219.707 164.217 216.178 162.181 215.6 159.32C218.676 157.252 225.148 148.244 226.24 144.2Z',
    },
    {
      name: 'left_lowarm',
      d: 'M51.2398 144.48C52.481 145.59 52.8601 147.791 53.7598 149.24C55.7097 152.381 59.4544 157.905 62.4398 159.88L62.1598 160.16C58.9678 165.485 52.5149 169.092 47.5998 172.76C45.2828 174.489 43.4891 177.949 40.8798 179.2C38.6487 175.85 33.2408 173.376 32.7598 168.56C35.8762 165.448 37.9806 160.931 40.5998 157.36C43.8508 152.927 48.2093 149.076 51.2398 144.48Z',
    },
    {
      name: 'left_elbow',
      d: 'M61.3202 131.6C62.4178 132.785 62.6838 134.668 63.5602 136.08C64.6802 137.885 66.1785 139.673 67.4802 141.4C68.8522 143.22 72.1425 144.348 72.8002 146.72C70.0677 148.551 65.4642 155.492 63.5602 158.48C60.4371 156.863 52.6147 146.279 52.6402 142.8L61.3202 131.6Z',
    },
    {
      name: 'right_elbow',
      d: 'M216.16 131.32C219.196 133.32 221.319 137.595 223.44 140.56C224.065 141.434 225.466 142.333 225.12 143.36C224.237 147 219.917 152.589 217.56 155.12C216.992 155.73 214.857 158.561 214.2 157.92C211.85 156.066 210.386 152.516 208.6 150.08C207.505 148.587 205.639 147.497 204.96 145.6C208.364 144.393 215.535 135.387 216.16 131.32Z',
    },
    {
      name: 'left_uparm',
      d: 'M83.1599 100.8C84.2334 102.587 83.9764 106.878 84.8399 108.92C87.0239 114.084 91.703 119.333 96.5999 121.8V122.36L89.3199 130.76C84.3975 135.69 78.6799 139.962 73.9199 145.04C73.4288 144.863 73.648 144.986 73.3599 144.76C70.05 143.606 66.524 137.67 64.6799 134.68C64.0222 133.613 62.2266 131.448 62.7199 130.2C63.6215 127.484 66.4686 125.156 68.0399 122.92C71.0258 118.671 73.4574 114.296 76.4399 110.04C78.562 107.012 81.4105 104.132 83.1599 100.8Z',
    },
    {
      name: 'right_uparm',
      d: 'M194.32 100.24C196.513 102.376 197.851 105.246 199.64 107.8C202.726 112.204 205.734 116.748 208.88 121.24C210.541 123.611 214.732 127.566 215.32 130.48L211.96 136.92C209.88 139.897 206.873 142.159 204.4 144.76C201.273 143.647 199.33 139.923 196.84 138.04C193.881 135.803 191.59 133.07 189 130.48L185.64 127.4C184.22 125.518 182.849 123.419 181.16 121.8L181.44 121.52C182.638 120.266 184.233 119.496 185.64 118.44C190.211 115.008 194.309 108.294 194.32 100.24Z',
    },
    {
      name: 'upper_back',
      d: 'M136.64 88.2H141.12C147.015 101.248 152.856 107.635 164.64 114.8C167.556 116.573 171.291 118.744 175.28 119.28C175.094 127.96 174.907 136.64 174.72 145.32H174.16C171.213 147.593 164.192 147.657 159.88 148.68C147.926 151.516 127.868 151.015 116.48 148.12C112.266 147.049 105.935 146.763 103.04 144.48C102.099 143.014 102.723 138.455 102.76 136.36C102.147 135.38 102.48 132.801 102.48 131.32C102.387 127.587 102.294 123.853 102.2 120.12C106.064 119.616 109.704 117.103 112.56 115.36C120.372 110.592 126.308 105.814 131.32 98.28C133.193 95.4641 136.056 92.1004 136.64 88.2Z',
    },
    {
      name: 'right_shoulder',
      d: 'M167.16 80.92C168.99 81.1266 170.268 82.0184 171.64 82.6C175.621 84.2873 179.238 85.5985 182.56 87.92C183.87 88.835 184.951 90.3333 186.2 91.28C188.53 93.0454 191.288 95.3445 192.36 98.28C193.454 101.274 192.073 104.978 191.24 106.96C189.042 112.189 186.581 116.809 182 119.56C179.477 121.075 172.587 116.939 169.96 115.92C156.791 110.81 148.184 101.369 142.52 88.76C149.471 88.4665 163.653 84.42 167.16 80.92Z',
    },
    {
      name: 'left_shoulder',
      d: 'M110.04 81.2C112.353 81.7813 114.37 83.6405 116.48 84.56C119.859 86.0328 123.785 86.9098 127.4 87.92C129.253 88.4377 134.448 88.3479 135.24 89.6C133.557 91.392 132.921 94.4975 131.6 96.6C128.358 101.761 123.62 107.084 118.72 110.6C115.668 112.819 112.382 114.697 108.92 116.2C105.304 117.717 101.167 118.196 98.5601 120.68C91.2681 119.123 81.0349 104.084 87.0801 95.76C90.67 90.8172 97.8523 86.5715 103.6 83.72C105.59 82.7327 108.324 82.5267 110.04 81.2Z',
    },
    {
      name: 'neck',
      d: 'M134.4 51.52C140.352 51.359 150.056 52.3779 151.76 55.72V63L153.44 70.28C155.512 75.1881 159.174 79.0977 164.92 80.36L164.64 80.64C156.923 88.1325 132.653 88.4688 120.68 84.56C117.614 83.559 113.525 82.8931 111.72 80.64C118.736 78.9141 123.504 72.989 125.16 65.8C125.916 62.5139 124.749 58.996 124.6 56.28C125.955 55.412 126.987 53.9 128.52 53.2C130.225 52.4216 132.819 52.5745 134.4 51.52Z',
    },
    {
      name: 'head',
      d: 'M134.96 16.8C144.754 16.6628 151.981 18.9311 154.28 26.04C154.56 29.12 154.84 32.2 155.12 35.28C155.904 37.4265 158.133 38.8746 158.76 41.16C160.26 46.6278 155.28 53.9064 152.6 55.16C149.592 50.5638 138.205 48.6256 131.04 50.96C128.159 51.8986 126.068 54.1024 123.48 54.88C122.268 51.4903 118.875 49.9747 117.88 45.92C116.58 40.6188 120.312 38.4387 121.52 35.56C123.079 31.8438 122.07 26.404 124.04 23.24C125.493 20.9073 128.67 19.0221 131.32 17.92C132.561 17.4037 133.947 17.5045 134.96 16.8Z',
    },
  ];

  useEffect(() => {
    const svg = document.getElementById('my-svg');
    if (svg) {
      const shapes = svg.querySelectorAll('path');

      shapes.forEach((shape) => {
        const value =
          values.find(({ name }) => name === shape.dataset.name)?.value || 0;

        const bbox = shape.getBBox();
        const centerX = bbox.x + bbox.width / 2;
        const centerY = bbox.y + bbox.height / 2;

        const text = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'text'
        );
        text.setAttribute('x', centerX.toString());
        text.setAttribute('y', centerY.toString());
        text.setAttribute('text-anchor', 'middle');
        text.setAttribute('alignment-baseline', 'middle');
        text.setAttribute('fill', 'black');
        text.setAttribute('font-size', '12');
        text.textContent = String(value);

        svg.appendChild(text);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const mergedList = items.map((item1) => {
    const item2 = values.find((item2) => item2.name === item1.name);
    return { ...item1, ...item2 };
  });

  const getColor = (value: number) => {
    switch (Math.floor(value)) {
      case 0:
        return '#008000';
      case 1:
        return '#2b8c00';
      case 2:
        return '#5aa700';
      case 3:
        return '#8dc200';
      case 4:
        return '#bfe000';
      case 5:
        return '#e6e600';
      case 6:
        return '#ffb300';
      case 7:
        return '#ff7f00';
      case 8:
        return '#ff3300';
      case 9:
        return '#ff0000';

      default:
        return '#AAA';
    }
  };

  const eyes = values.find(({ name }) => name === 'eyes');

  const eyeStyle = {
    width: 30,
    height: 30,
    backgroundColor: eyes ? getColor(+eyes.value) : '#AAA',
    borderRadius: '100% 0px',
    transform: 'rotate(45deg)',
  };

  return (
    <Stack justifyContent="center" alignItems="center" position="relative">
      <Stack
        alignItems="center"
        sx={{ position: 'absolute', right: 50, top: 20 }}
      >
        <Stack direction="row" spacing={1.5}>
          <Box sx={eyeStyle} />
          <Box sx={eyeStyle} />
        </Stack>
        <Typography variant="text1" sx={{ mt: -1 }}>
          {eyes ? +eyes.value : 0}
        </Typography>
      </Stack>
      <svg
        id="my-svg"
        width={size.width}
        height={size.height}
        viewBox="0 0 280 420"
      >
        {mergedList.map((item) => (
          <path
            key={item.name}
            data-name={item.name}
            d={item.d}
            fill={getColor(item.value ? +item.value - 1 : 10)}
          />
        ))}
      </svg>
    </Stack>
  );
};
