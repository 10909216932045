import { Middleware } from '@reduxjs/toolkit';
import { logoutAction } from '../slices/authSlice';
import { api } from './api';

export const authMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === logoutAction.type) {
      dispatch(api.util.resetApiState());
    }
    return next(action);
  };
