import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { defaultArguments } from './constant';
import { getImageData } from 'src/utils/getImageData';
import { ImageArgument } from './arguments/ImageArgument';
import { Stack } from '@mui/system';
import { TextArgument } from './arguments/TextArgument';
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Upload } from '@mui/icons-material';
import {
  FilePathDto,
  ImageFieldRo,
  TextFieldDto,
} from 'src/app/services/generatedApi';

type CertificateMenuPropsType = {
  aspectRatio: number;
  setAspectRatio: (aspectRatio: number) => void;
  setBackground: (background: { file: File; base64: string }) => void;
  background?: { file?: File; base64: string };
  setName: (name: string) => void;
  name: string;
  baseCourseArguments: string[];
  imageFields: ImageFieldRo[];
};

export type ImageArgumentType = {
  file?: File;
  url: string;
  filePath?: FilePathDto;
  base64?: string;
  ratio: number;
};

export type textArgumentsType = {
  type: TextFieldDto['inputType'];
  text?: string;
};

export const CertificateMenu: FC<CertificateMenuPropsType> = ({
  aspectRatio,
  setAspectRatio,
  baseCourseArguments,
  name,
  setName,
  setBackground,
  background,
  imageFields,
}) => {
  const textArguments: textArgumentsType[] = useMemo(
    () => [
      ...defaultArguments.map((arg) => ({ type: arg })),
      ...baseCourseArguments.map((arg) => ({
        type: 'ARGUMENT' as TextFieldDto['inputType'],
        text: arg,
      })),
    ],
    [baseCourseArguments]
  );

  const [imageArguments, setImageArguments] = useState<ImageArgumentType[]>([]);

  useEffect(() => {
    if (imageFields.length > 0) {
      setImageArguments((prev) => {
        const urls: string[] = [];
        const newImageArguments = [
          ...prev,
          ...imageFields.map((imageField) => ({
            url: imageField.url,
            filePath: imageField.filePath,
            ratio: imageField.size.width / imageField.size.height,
          })),
        ];

        return newImageArguments.filter((imageArgument) => {
          if (urls.includes(imageArgument.url)) {
            return false;
          }
          urls.push(imageArgument.url);
          return true;
        });
      });
    }
  }, [imageFields]);

  const handleFileInputChange = async (
    event: ChangeEvent<HTMLInputElement>,
    isBackground = false
  ) => {
    const files = event.target.files;

    if (!files?.length) return;
    if (isBackground) {
      const file = files[0];
      const { base64 } = await getImageData(file);
      setBackground({ file, base64 });
      return;
    }
    const newImages: ImageArgumentType[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { base64, ratio } = await getImageData(file);
      newImages.push({ file, url: base64, ratio });
    }
    setImageArguments((prev) => [...prev, ...newImages]);
  };

  const [tabValue, setTabValue] = useState(0);

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        py: 2,
        px: 1,
        background: '#efefef',
      }}
    >
      <Stack
        alignItems="flex-start"
        justifyContent="center"
        spacing={2}
        sx={{ maxHeight: '100%' }}
      >
        <Stack spacing={1} width="100%">
          <Typography variant="h6">Certificate Name</Typography>
          <TextField
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Stack>
        <TextField
          label="Template Size"
          select
          fullWidth
          value={aspectRatio}
          onChange={(e) => setAspectRatio(+e.target.value)}
        >
          <MenuItem value={16 / 9}>16 * 9</MenuItem>
          <MenuItem value={1}>1 * 1</MenuItem>
          <MenuItem value={20 / 3}>20 * 3</MenuItem>
        </TextField>

        <Divider sx={{ width: '100%' }} />

        <Stack
          spacing={1}
          width="100%"
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="h6">Background Image</Typography>
          <Button
            component="label"
            variant={background ? 'outlined' : 'contained'}
            color="primary"
            startIcon={<Upload />}
          >
            {background ? 'Change' : 'Upload'}
            <input
              accept="image/*"
              id="upload-background"
              type="file"
              onChange={(e) => handleFileInputChange(e, true)}
              hidden
            />
          </Button>
        </Stack>

        <Tabs
          value={tabValue}
          sx={{ borderBottom: '1px solid #ccc', width: '100%' }}
          onChange={(e, value) => setTabValue(value)}
        >
          <Tab label={'Arguments'} />
          <Tab label={'Images'} />
        </Tabs>
        <Stack
          spacing={1}
          sx={{ overflowY: 'scroll', flexGrow: 1, width: '100%' }}
        >
          {tabValue === 0 ? (
            textArguments.map((argument, index) => (
              <TextArgument key={index} argument={argument} />
            ))
          ) : (
            <>
              <Button
                component="label"
                variant="outlined"
                startIcon={<Upload />}
              >
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleFileInputChange}
                  hidden
                />
                Upload Image
              </Button>
              {imageArguments.map((argument, i) => (
                <Box sx={{ width: '100%' }} key={i}>
                  <ImageArgument data={argument} />
                </Box>
              ))}
            </>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};
