import { FC } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';

const stepLevelStyle = {
  '& .Mui-active .MuiStepIcon-text': { fill: 'white' },
  '& .MuiStepIcon-root': {
    color: 'white',
    border: '1px solid #999',
    borderRadius: '50%',
    '&.Mui-completed': { color: 'secondary.light', border: 0 },
  },
  '& .Mui-active .MuiStepIcon-root': { color: 'primary.main', border: 0 },
  '& .MuiStepIcon-text': { color: 'secondary.light', fontSize: 13 },
  '& .MuiStepLabel-label': {
    mt: 1,
    '&.Mui-active': { color: 'primary.main' },
    '&.Mui-completed': { color: 'secondary.main' },
  },
};

type CustomStepperPropsType = {
  activeStep: number;
  steps: string[];
};

export const CustomStepper: FC<CustomStepperPropsType> = ({
  activeStep,
  steps,
}) => {
  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel sx={stepLevelStyle}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
