import { Close, SaveOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { FC } from 'react';

type CertificateDialogAppBarPropsType = {
  disableSubmit: boolean;
  onClose: () => void;
  loadingSubmit: boolean;
  submitHandler: () => void;
  preview: boolean;
};

export const CertificateDialogAppBar: FC<CertificateDialogAppBarPropsType> = ({
  disableSubmit,
  onClose,
  loadingSubmit,
  submitHandler,
  preview,
}) => {
  return (
    <AppBar sx={{ position: 'relative' }}>
      <Container maxWidth="xl">
        <Toolbar>
          <IconButton edge="start" sx={{ color: 'white' }} onClick={onClose}>
            <Close />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="subtitle0"
            fontWeight={700}
            color="white"
          >
            Certificate
          </Typography>
          {!preview && (
            <LoadingButton
              loading={loadingSubmit}
              variant="outlined"
              disabled={disableSubmit}
              sx={{
                color: 'white',
                borderColor: 'white',
                '&:hover': { borderColor: 'white' },
              }}
              onClick={submitHandler}
              startIcon={<SaveOutlined />}
            >
              Save
            </LoadingButton>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
