import { useAppSelector } from 'src/app/reduxCustomHooks';
import { useMemo } from 'react';
import {
  UserRo,
  useUsersControllerFindQuery,
  useUsersControllerGetCompanyUsersQuery,
} from 'src/app/services/generatedApi';

type useCompanyUsersPropsType = { filter: string };
export const useCompanyUsers: (props: useCompanyUsersPropsType) => {
  users: UserRo[];
  isLoading: boolean;
} = ({ filter }) => {
  const { isAdmin, selectedCompanyId, isManager } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    selectedCompanyId: state.auth.adminSelectedCompanyId,
    isManager: state.auth.isManager,
  }));

  const { data: allUsers, isLoading: loadingAllUsers } =
    useUsersControllerFindQuery({}, { skip: !isAdmin });

  const { data: usersByAdmin, isLoading: loadingUsersByAdmin } =
    useUsersControllerFindQuery(
      { companyId: selectedCompanyId! },
      { skip: !isAdmin || !selectedCompanyId }
    );

  const { data: usersByManager, isLoading: loadingUsersByManager } =
    useUsersControllerGetCompanyUsersQuery(undefined as any, {
      skip: isAdmin || !isManager,
    });

  const lowerCaseFilter = useMemo(() => filter.toLowerCase(), [filter]);

  const users = useMemo(() => {
    if (isAdmin && !selectedCompanyId) return allUsers || [];
    else {
      return (usersByAdmin || usersByManager || []).filter((user) =>
        user.username.toLowerCase().includes(lowerCaseFilter)
      );
    }
  }, [
    allUsers,
    isAdmin,
    lowerCaseFilter,
    selectedCompanyId,
    usersByAdmin,
    usersByManager,
  ]);

  return {
    users,
    isLoading: loadingUsersByAdmin || loadingUsersByManager || loadingAllUsers,
  };
};
