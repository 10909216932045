import { CloudUploadOutlined as CloudUploadOutlinedIcon } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Dialog,
  Typography,
  Stack,
  DialogTitle,
  Button,
  DialogActions,
} from '@mui/material';
import { FC } from 'react';
import { humanFileSize } from 'src/utils/humanReadableFileSizeUtils';

type UploadProcessDialogPropsType = {
  handleClose: () => void;
  uploadProcessEvent: { loaded: number; total: number } | undefined;
  cancel: () => void;
};

export const UploadProcessDialog: FC<UploadProcessDialogPropsType> = ({
  handleClose,
  uploadProcessEvent,
  cancel,
}) => {
  const value = uploadProcessEvent
    ? Math.round((uploadProcessEvent.loaded * 100) / uploadProcessEvent.total)
    : 0;

  return (
    <Dialog open={!!uploadProcessEvent} onClose={handleClose} maxWidth="xs">
      <DialogTitle sx={{ backgroundColor: 'primary.main', color: 'white' }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          direction="row"
          spacing={1}
        >
          <CloudUploadOutlinedIcon />
          <Typography variant="h5">Uploading File...</Typography>
        </Stack>
      </DialogTitle>
      <Stack alignItems="center" pt={4} spacing={1}>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          {value === 100 ? (
            <CircularProgress size={150} />
          ) : (
            <CircularProgress variant="determinate" value={value} size={150} />
          )}

          <Stack
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {value === 100 ? (
              <>
                <Typography variant="subtitle2" color="text.secondary">
                  Please Wait ...
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Checking files.
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h4" color="text.secondary">
                  {`${value}%`}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {`${humanFileSize(
                    uploadProcessEvent?.loaded
                  )}/${humanFileSize(uploadProcessEvent?.total)}`}
                </Typography>
              </>
            )}
          </Stack>
        </Box>
      </Stack>

      <DialogActions>
        <Button color="error" onClick={cancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
