import { FC, useState } from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { CourseRo } from 'src/app/services/generatedApi';
import defaultImage from 'src/assets/images/defaultCourseImage.png';
import { PreviewButton } from 'src/components/atoms/course/PreviewButton';
import { CourseRating } from '../../user/CourseRating';

type CompanyCourseCardPropsType = {
  course: CourseRo;
};

export const CompanyCourseCard: FC<CompanyCourseCardPropsType> = ({
  course,
}) => {
  const [elevation, setElevation] = useState(2);

  return (
    <RouterLink
      to={`/company/courses/${course.id}`}
      style={{ textDecoration: 'none' }}
    >
      <Stack
        component={Paper}
        elevation={elevation}
        alignItems="center"
        spacing={2.5}
        sx={{ p: 2, overflow: 'hidden', cursor: 'pointer' }}
        onMouseOver={() => setElevation(5)}
        onMouseLeave={() => setElevation(2)}
      >
        <Typography
          variant="title3"
          fontWeight="bold"
          maxWidth={{ xs: 300, sm: 200, lg: 280 }}
          noWrap
          textOverflow="ellipsis"
        >
          {course.name}
        </Typography>
        <img
          src={course.thumbnailUrl || defaultImage}
          alt="course1"
          width={300}
          height={180}
        />
        <Stack
          px={1}
          alignItems="center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <CourseRating courseId={course.id} />
        </Stack>
        <Typography
          variant="text1"
          textAlign="justify"
          height={43}
          sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '2',
            overflow: 'hidden',
          }}
        >
          {course.description}
        </Typography>
        {course.baseCourse?.id && <PreviewButton id={course.baseCourse.id} />}
      </Stack>
    </RouterLink>
  );
};
