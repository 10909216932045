import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Search: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx }}>
      <path
        d="M11.1 3C15.5735 3 19.2 6.64568 19.2 11.1429C19.2 13.3032 18.3631 15.267 16.9975 16.7247C17.0169 16.735 17.0344 16.7477 17.0516 16.7612L17.1364 16.8364L20.7364 20.4555C21.0879 20.8088 21.0879 21.3817 20.7364 21.735C20.412 22.0612 19.9015 22.0862 19.5484 21.8103L19.4636 21.735L15.8636 18.116C15.7865 18.0384 15.7263 17.9504 15.683 17.8564C14.3811 18.7581 12.8018 19.2857 11.1 19.2857C6.62649 19.2857 3 15.64 3 11.1429C3 6.64568 6.62649 3 11.1 3ZM11.1 4.80952C7.62061 4.80952 4.8 7.64505 4.8 11.1429C4.8 14.6407 7.62061 17.4762 11.1 17.4762C14.5794 17.4762 17.4 14.6407 17.4 11.1429C17.4 7.64505 14.5794 4.80952 11.1 4.80952Z"
        fill="#4A4A4A"
      />
    </SvgIcon>
  );
};

export const SearchAdmin: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx, fill: 'none' }}>
      <path
        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
        stroke="#6D7280"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9999 21L16.6499 16.65"
        stroke="#6D7280"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
