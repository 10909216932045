import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'src/app/reduxCustomHooks';
import { useCompaniesControllerGetAllQuery } from 'src/app/services/generatedApi';
import { setAdminSelectedCompanyIdAction } from 'src/app/slices/authSlice';

type SelectCompanyDialogPropsType = {
  open: boolean;
  handleClose: () => void;
  courseId: string;
};

export const SelectCompanyDialog: FC<SelectCompanyDialogPropsType> = ({
  open,
  handleClose,
  courseId,
}) => {
  const { data: companies, isLoading } = useCompaniesControllerGetAllQuery();

  const modifiedList = useMemo(() => {
    if (!companies) return;
    const list = companies.map((company) => {
      return { id: company.id, name: company.name, selected: false };
    });
    return list;
  }, [companies]);

  const [companiesList, setCompaniesList] = useState(modifiedList || []);
  useEffect(
    () => modifiedList && setCompaniesList(modifiedList),
    [modifiedList]
  );

  const onClose = () => {
    setCompaniesList(modifiedList || []);
    handleClose();
  };

  const navigate = useNavigate();

  const isNotSelected = useMemo(() => {
    if (!companiesList) return;
    return companiesList.every((company) => !company.selected);
  }, [companiesList]);

  const handleSelect = (index: number) => {
    const newList = companiesList?.map((company, i) => {
      return {
        ...company,
        selected: !companiesList[index].selected && i === index,
      };
    });
    setCompaniesList(newList);
  };

  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    const company = companiesList?.find((company) => company.selected);
    if (!company) return;
    dispatch(setAdminSelectedCompanyIdAction(company.id));
    toast.info(`Portal Changed to ${company.name} company.`);
    navigate(`/course-store/${courseId}/launch-course`);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="paper">
      <DialogTitle>Select a Company to Launch Course</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={1}>
          {isLoading
            ? [...Array(4)].map((_, index) => (
                <Skeleton
                  key={index}
                  animation="wave"
                  variant="rounded"
                  height={40}
                  sx={{ borderRadius: 2 }}
                />
              ))
            : companiesList.map(({ id, name, selected }, index) => (
                <Stack
                  key={id}
                  alignItems="center"
                  sx={{
                    border: 1,
                    borderColor: 'primary.main',
                    borderRadius: 2,
                    py: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: selected ? 'primary.light' : 'customGray.light',
                    },
                  }}
                  bgcolor={selected ? 'primary.light' : 'inherit'}
                  onClick={() => handleSelect(index)}
                >
                  <Typography>{name}</Typography>
                </Stack>
              ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isNotSelected}
          onClick={handleSubmit}
        >
          Launch Course
        </Button>
      </DialogActions>
    </Dialog>
  );
};
