import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Edit: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx }}>
      <path
        d="M5.3122 21.0095C4.22688 21.2148 3.18061 20.5014 2.97528 19.4161C2.9288 19.1704 2.9288 18.9182 2.97528 18.6725L3.63524 15.1842C3.70996 14.7892 3.90195 14.4259 4.18617 14.1417L15.9035 2.42442C16.5122 1.8157 17.4421 1.66479 18.2121 2.04978L18.9817 2.43455C20.0932 2.99031 20.9944 3.89158 21.5502 5.00309L21.935 5.77263C22.32 6.54261 22.169 7.47255 21.5603 8.08128L9.84302 19.7986C9.5588 20.0828 9.19554 20.2748 8.80059 20.3495L5.3122 21.0095ZM15.3531 5.8046L18.1816 8.63303L20.1461 6.66706L19.7613 5.89751C19.3991 5.17306 18.8117 4.58563 18.0872 4.22341L17.3177 3.83863L15.3531 5.8046ZM5.55405 15.8045L8.18166 18.4321L8.42881 18.3844L16.7673 10.0472L13.9389 7.21882L5.60038 15.5559L5.55405 15.8045Z"
        fill="#4A4A4A"
      />
    </SvgIcon>
  );
};
