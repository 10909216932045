import { FC, useEffect, useRef } from 'react';
import { IconButton, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import Konva from 'konva';
import { Group, Image, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';
import useImage from 'use-image';
import { ImageSizeDto } from 'src/app/services/generatedApi';

type ImageItemPropsType = {
  url: string;
  shapeProps: any;
  isSelected: boolean;
  stageSize: ImageSizeDto;
  onSelect: () => void;
  onChange: (newAttrs: any) => void;
  remove: () => void;
  preview: boolean;
};

export const ImageItem: FC<ImageItemPropsType> = ({
  url,
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  remove,
  stageSize,
  preview,
}) => {
  const groupRef = useRef<Konva.Group | null>(null);
  const trRef = useRef<Konva.Transformer | null>(null);

  useEffect(() => {
    if (isSelected && groupRef.current) {
      trRef.current?.nodes([groupRef.current]);
      trRef.current?.getLayer()?.batchDraw();
    }
  }, [isSelected]);

  const [image] = useImage(url);

  const { x, y, width, height, rotation = 0, ...imageProps } = shapeProps;

  return (
    <>
      <Group
        ref={groupRef}
        draggable={!preview}
        onDragMove={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x() / stageSize.width,
            y: e.target.y() / stageSize.height,
          });
        }}
        x={x * stageSize.width}
        y={y * stageSize.height}
        width={shapeProps.width * stageSize.width}
        height={shapeProps.height * stageSize.height}
        rotation={rotation}
      >
        <Image
          onClick={onSelect}
          onTap={onSelect}
          {...imageProps}
          width={shapeProps.width * stageSize.width}
          height={shapeProps.height * stageSize.height}
          scaleX={1}
          scaleY={1}
          image={image}
        />
        {isSelected && (
          <Html>
            <Stack
              alignItems="center"
              justifyContent="center"
              direction="row"
              sx={{ width: 0, height: 0 }}
            >
              <IconButton
                sx={{ transform: 'translate(-10px, -14px)' }}
                size="small"
                onClick={() => remove()}
              >
                <Close />
              </IconButton>
            </Stack>
          </Html>
        )}
      </Group>

      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) =>
            newBox.width < 5 || newBox.height < 5 ? oldBox : newBox
          }
          rotation={rotation}
          onTransform={() => {
            if (!groupRef.current) return;
            const group = groupRef.current;
            const scaleX = group.scaleX() ?? 1;
            const scaleY = group.scaleY() ?? 1;

            group.scaleX(1);
            group.scaleY(1);

            const width = Math.max(5, (group.width() ?? 0) * scaleX);
            const height = Math.max(5, (group.height() ?? 0) * scaleY);

            onChange({
              ...shapeProps,
              x: (group.x() ?? 0) / stageSize.width,
              y: (group.y() ?? 0) / stageSize.height,
              width: width / stageSize.width,
              height: height / stageSize.height,
              rotation: trRef.current?.rotation() ?? 0,
            });
          }}
        />
      )}
    </>
  );
};
