import { FC, useMemo, useState } from 'react';
import {
  Button,
  Stack,
  Typography,
  TextField,
  Grid,
  Skeleton,
  Box,
} from '@mui/material';
import { Edit, NewspaperRounded, PhotoOutlined } from '@mui/icons-material';
import { CertificateDialog } from 'src/components/organisms/certificate/CertificateDialog';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { getImageData } from 'src/utils/getImageData';
import {
  FilePathDto,
  useBaseCoursesControllerGetByIdQuery,
  useCertificatesControllerGetByIdQuery,
  useCoursesControllerAdminLaunchCourseMutation,
  useCoursesControllerCreateMutation,
  useCoursesControllerGetCourseByIdQuery,
  useCoursesControllerUpdateMutation,
  useMediaControllerUploadImageMutation,
  useMediaControllerUploadThumbnailMutation,
} from 'src/app/services/generatedApi';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { formikOnSubmitType } from 'src/types/form.type';
import { toast } from 'react-toastify';
import { SelectUserMenu } from 'src/components/organisms/course/enrollUser/SelectUserMenu';
import { useAppSelector } from 'src/app/reduxCustomHooks';

const formInitialValues = { name: '', description: '' };

const formValidation = yup.object().shape({
  name: yup.string().required("Course's Name is required!"),
  description: yup.string().required('Description is required'),
});

const LaunchCourse: FC = () => {
  const { baseCourseId, courseId } = useParams();

  const { data: baseCourse, isLoading: loadingBaseCourse } =
    useBaseCoursesControllerGetByIdQuery(
      { id: baseCourseId! },
      { skip: !baseCourseId }
    );

  const { data: companyCourse, isLoading: loadingCompanyCourse } =
    useCoursesControllerGetCourseByIdQuery(
      { id: courseId! },
      { skip: !courseId }
    );

  const course = useMemo(() => {
    if (baseCourse) return baseCourse;
    if (companyCourse) return companyCourse;
  }, [baseCourse, companyCourse]);

  const courseCertId = course?.certificate;

  const { data: courseCert } = useCertificatesControllerGetByIdQuery(
    { id: courseCertId! },
    { skip: !courseCertId }
  );

  const [file, setFile] = useState<File | null>(null);
  const [selectImageError, setSelectImageError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();

  const isLoading = loadingBaseCourse || loadingCompanyCourse;

  const [picture, setPicture] = useState<string | undefined>(
    companyCourse?.thumbnailUrl || baseCourse?.thumbnailUrl
  );

  const [launchCourse, { isLoading: loadingLaunchCourse }] =
    useCoursesControllerCreateMutation();

  const [adminLaunchCourse, { isLoading: loadingAdminLaunchCourse }] =
    useCoursesControllerAdminLaunchCourseMutation();

  const [editLaunchedCourse, { isLoading: loadingEdit }] =
    useCoursesControllerUpdateMutation();

  const [uploadImage, { isLoading: uploadImageLoading }] =
    useMediaControllerUploadImageMutation();

  const [editThumbnail, { isLoading: editThumbnailLoading }] =
    useMediaControllerUploadThumbnailMutation();

  const handleSelectImage = async (event: any) => {
    const file = event.target.files[0];
    const isValidImage = file && file.type.startsWith('image/');
    if (!isValidImage) return;
    const imageUrl = (await getImageData(file)).base64;
    setFile(file);
    setPicture(imageUrl);
    setSelectImageError(false);
  };

  const initFormValue = companyCourse
    ? { name: companyCourse.name, description: companyCourse.description }
    : baseCourse
    ? { name: baseCourse.name, description: baseCourse.description }
    : formInitialValues;

  const [selectedUsersId, setSelectedUsersId] = useState<string[]>([]);

  const { isAdmin, selectedCompanyId } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    selectedCompanyId: state.auth.adminSelectedCompanyId,
  }));

  const submitHandler: formikOnSubmitType<typeof formInitialValues> = async (
    props,
    { setSubmitting }
  ) => {
    if (baseCourseId && baseCourse) {
      let thumbnail: FilePathDto | undefined;
      if (file) {
        const formData = new FormData();
        formData.append('image', file);

        try {
          const thumbnailUrl = await uploadImage({
            body: formData as any,
          }).unwrap();
          thumbnail = {
            mode: 'S3',
            path: thumbnailUrl,
          };
        } catch (err) {
          console.error('Error uploading image:', err);
        }
      }

      const createCourseDto = {
        baseCourseId,
        name: props.name,
        description: props.description,
        userIds: selectedUsersId,
        thumbnail,
      };

      (isAdmin && selectedCompanyId
        ? adminLaunchCourse({
            createCourseDto,
            id: selectedCompanyId,
          })
        : launchCourse({ createCourseDto })
      )
        .unwrap()
        .then(() => {
          toast.success('Course launched successfully.');
          navigate('/company/courses');
        })
        .catch(({ data }) => toast.error(data));

      return;
    }
    if (courseId && companyCourse) {
      if (file) {
        const formData = new FormData();
        const options = { id: courseId, mode: 'COURSE_THUMBNAIL' };
        formData.append('image', file);
        formData.append('options', JSON.stringify(options));
        editThumbnail({ body: formData as any });
      }
      editLaunchedCourse({
        id: courseId,
        updateCourseDto: { name: props.name, description: props.description },
      })
        .unwrap()
        .then(() => {
          toast.success('Course updated successfully.');
          navigate(`/company/courses/${courseId}`);
        })
        .catch(({ data }) => toast.error(data));
      return;
    }
    toast.error('No courses found');
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        initialValues={initFormValue}
        validationSchema={formValidation}
        onSubmit={submitHandler}
      >
        {({ errors, touched, getFieldProps }) => (
          <Form autoComplete="on" style={{ height: '100%' }}>
            <Stack justifyContent="space-between" height="100%" spacing={2}>
              <Stack spacing={6}>
                <Box>
                  <Typography variant="title2" fontWeight="bold" sx={{ py: 1 }}>
                    Details
                  </Typography>
                  <Grid container spacing={{ xs: 2, sm: 4 }} px={4}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ mt: 1 }}>Name</Typography>
                      {isLoading ? (
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width="100%"
                          sx={{ bgcolor: 'secondary.light', borderRadius: 2 }}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          error={Boolean(errors.name && touched.name)}
                          helperText={
                            Boolean(errors.name && touched.name) && errors.name
                          }
                          {...getFieldProps('name')}
                        />
                      )}
                      {/*  */}
                      <Typography sx={{ mt: 2 }}>Description</Typography>
                      {isLoading ? (
                        <Skeleton
                          variant="rectangular"
                          height={90}
                          width="100%"
                          sx={{ bgcolor: 'secondary.light', borderRadius: 2 }}
                        />
                      ) : (
                        <TextField
                          multiline
                          fullWidth
                          rows={3}
                          error={Boolean(
                            errors.description && touched.description
                          )}
                          helperText={
                            Boolean(
                              errors.description && touched.description
                            ) && errors.description
                          }
                          {...getFieldProps('description')}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ mt: 1 }}>Course Image</Typography>
                      <Stack
                        alignItems="center"
                        spacing={2}
                        border="2px dashed"
                        borderColor={
                          selectImageError ? 'error.light' : 'secondary.light'
                        }
                        py={2}
                        justifyContent="center"
                        borderRadius={1}
                      >
                        {picture ? (
                          <img
                            src={picture}
                            alt="coursePicture"
                            style={{
                              width: '80px',
                              height: '80px',
                              padding: 5,
                            }}
                          />
                        ) : (
                          <PhotoOutlined color="primary" fontSize="large" />
                        )}

                        {file ? (
                          <Stack alignItems="center">
                            <Typography variant="subtitle2">
                              {file.name}
                            </Typography>
                            <Typography variant="text0">
                              {(file.size / 1048576).toFixed(2)} MB
                            </Typography>
                          </Stack>
                        ) : picture ? (
                          <Stack alignItems="center">
                            <Typography variant="text1">
                              You can choose another image
                            </Typography>
                            <Typography variant="text2">
                              File format: SVG, PNG, JPG or Jpeg (12MB)
                            </Typography>
                          </Stack>
                        ) : (
                          <Stack alignItems="start">
                            <Typography variant="text1" noWrap>
                              Upload a picture for the course.
                            </Typography>
                            <Typography variant="text2">
                              SVG, PNG, JPG or Jpeg (12MB)
                            </Typography>
                          </Stack>
                        )}
                        <Button
                          size="small"
                          component="label"
                          variant="outlined"
                          sx={{ borderRadius: 5 }}
                        >
                          Browse
                          <input
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={handleSelectImage}
                            hidden
                          />
                        </Button>
                      </Stack>
                      {selectImageError && (
                        <Typography variant="text2" color="error.main">
                          select the picture
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Typography variant="title2" fontWeight="bold">
                    Invite People
                  </Typography>
                  <Stack
                    spacing={0.5}
                    mx={4}
                    mt={2}
                    width={{ md: '60%', lg: '50%' }}
                  >
                    <Typography variant="text0">Select User</Typography>

                    <SelectUserMenu
                      enrolledUsers={
                        companyCourse?.enrolledUsers?.map(
                          (enrolledUser) => enrolledUser.user.id
                        ) || []
                      }
                      selectedUsersId={selectedUsersId}
                      setSelectedUsersId={setSelectedUsersId}
                      selectElement
                    />
                  </Stack>
                </Box>

                {courseCert && (
                  <Box>
                    <Typography variant="title2" fontWeight="bold">
                      Certificate
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        bgcolor: 'primary.light',
                        p: 1.5,
                        mx: 4,
                        mt: 2,
                        borderRadius: 2,
                        width: { md: '60%', lg: '50%' },
                      }}
                    >
                      <Stack direction="row" spacing={2}>
                        <NewspaperRounded color="secondary" />
                        <Typography noWrap>{courseCert.name}</Typography>
                      </Stack>
                      <Button
                        variant="contained"
                        startIcon={<Edit />}
                        onClick={() => setOpenDialog(true)}
                      >
                        Edit Certificate
                      </Button>
                    </Stack>
                  </Box>
                )}
              </Stack>

              <Stack direction="row" justifyContent="end" spacing={2}>
                <Button variant="outlined" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
                <LoadingButton
                  component="button"
                  type="submit"
                  loading={
                    loadingLaunchCourse ||
                    loadingAdminLaunchCourse ||
                    loadingEdit ||
                    uploadImageLoading ||
                    editThumbnailLoading
                  }
                  variant="contained"
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
      {courseCert && (
        <CertificateDialog
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          certId={courseCert.id}
          mode="UPDATE_CERTIFICATE"
        />
      )}
    </>
  );
};

export default LaunchCourse;
