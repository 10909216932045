import { FC, useState } from 'react';
import {
  Card,
  CardActionArea,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import {
  MyCourseRo,
  UserRo,
  useRateAndReviewControllerGetAverageRateQuery,
} from 'src/app/services/generatedApi';
import { Link as RouterLink } from 'react-router-dom';

import { DownloadLicenseButton } from './DownloadLicenseButton';
import { enrollUserStatesMap } from 'src/utils/getCourseState';
import defaultImage from 'src/assets/images/defaultCourseImage.png';
import { CourseRating } from '../../user/CourseRating';
import { StartButton } from 'src/components/atoms/course/StartButton';
import { ResumeButton } from 'src/components/atoms/course/ResumeButton';
import { convertDate } from 'src/utils/date';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { AskSetCompanyDialog } from '../../user/viewUser/AskSetCompanyDialog';

type CourseCardPropsType = {
  myCourse: MyCourseRo;
  isAdminView?: boolean;
  user?: UserRo;
};

export const MyCourseCard: FC<CourseCardPropsType> = ({
  myCourse,
  isAdminView,
  user,
}) => {
  const [elevation, setElevation] = useState(2);
  const [openAskSetCompanyDialog, setOpenAskSetCompanyDialog] = useState(false);

  const { isAdmin, selectedCompanyId } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    selectedCompanyId: state.auth.adminSelectedCompanyId,
  }));
  const courseId = myCourse.id;

  const { data: rateAverage } = useRateAndReviewControllerGetAverageRateQuery(
    { id: courseId! },
    { skip: !courseId }
  );

  return (
    <>
      <Card
        elevation={elevation}
        onMouseOver={() => setElevation(5)}
        onMouseLeave={() => setElevation(2)}
      >
        <CardActionArea
          component={RouterLink}
          onClick={() =>
            isAdminView &&
            isAdmin &&
            !selectedCompanyId &&
            setOpenAskSetCompanyDialog(true)
          }
          to={
            isAdminView
              ? isAdmin && !selectedCompanyId
                ? ''
                : `/company/courses/${myCourse.id}/report/`
              : `/my-courses/${myCourse.id}`
          }
          style={{ textDecoration: 'none' }}
        >
          <Stack alignItems="center" spacing={2.5} p={2}>
            <Typography
              variant="title3"
              fontWeight="bold"
              maxWidth={{ xs: 300, sm: 200, lg: 280 }}
              noWrap
              textOverflow="ellipsis"
            >
              {myCourse.name}
            </Typography>
            <img
              src={myCourse.thumbnailUrl || defaultImage}
              alt="course1"
              width={300}
              height={180}
            />
            <Stack
              px={1}
              alignItems="center"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <CourseRating courseId={myCourse.id} />
            </Stack>

            <Stack direction="row" spacing={3}>
              <Typography variant="text2" textAlign="center">
                {enrollUserStatesMap[myCourse.enrolledData.state].text}
              </Typography>
              <Divider sx={{ maxHeight: 22 }} orientation="vertical" flexItem />
              <Typography variant="text2" textAlign="center">
                {rateAverage || '-'}
              </Typography>
              <Divider sx={{ maxHeight: 22 }} orientation="vertical" flexItem />
              <Typography variant="text2" textAlign="center">
                {convertDate(myCourse.createdAt, 'D MMM YYYY')}
              </Typography>
            </Stack>
            <Typography
              variant="text1"
              textAlign="justify"
              height={43}
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '2',
                overflow: 'hidden',
              }}
            >
              {myCourse.description}
            </Typography>
            {isAdminView ? null : myCourse.enrolledData.state === 'ENROLLED' ? (
              <StartButton id={myCourse.id} />
            ) : myCourse.enrolledData.state === 'IN_PROGRESS' ? (
              <ResumeButton id={myCourse.id} />
            ) : (
              <DownloadLicenseButton enrollUserId={myCourse.enrolledData.id} />
            )}
          </Stack>
        </CardActionArea>
      </Card>
      {user && user.company && (
        <AskSetCompanyDialog
          open={openAskSetCompanyDialog}
          handleClose={() => setOpenAskSetCompanyDialog(false)}
          company={{ id: user.company.id, name: user.company.name }}
          courseId={myCourse.id}
          userId={user.id}
        />
      )}
    </>
  );
};
