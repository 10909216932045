import { FC, useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import {
  useUsersControllerCheckResetPasswordCredQuery,
  useUsersControllerResetPasswordMutation,
} from 'src/app/services/generatedApi';
import { formikOnSubmitType } from 'src/types/form.type';
import { passwordValidatorRegex } from 'src/utils/formValidator';
import * as yup from 'yup';

const initialValues = {
  newPassword: '',
  confirmPassword: '',
};

const passValidationHandler = (value: string) =>
  !value ? false : passwordValidatorRegex.test(value);

const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .test('Password validation', 'Password is not valid', (value) =>
      passValidationHandler(value as string)
    ),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('newPassword'), undefined],
      'Repeating the new password is incorrect'
    )
    .required('Confirm Password is required and must be match'),
});

const ResetPassword: FC = () => {
  const hasToken = useAppSelector((state) => state.auth.token);
  const [searchParams] = useSearchParams();

  const resetCode = searchParams.get('resetCode');
  const email = searchParams.get('email');

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { data: checkResetPassword, isLoading: checkResetPasswordLoading } =
    useUsersControllerCheckResetPasswordCredQuery(
      {
        resetCode: resetCode!,
        email: email!,
      },
      { skip: !resetCode || !email }
    );

  useEffect(() => {
    if (checkResetPassword || checkResetPasswordLoading) return;
    navigate(-1);
  }, [checkResetPassword, checkResetPasswordLoading, navigate]);

  const [resetPassword, { isLoading: resetLoading }] =
    useUsersControllerResetPasswordMutation();

  const goBack = () => navigate(-1);

  const showPasswordHandler = () => setShowPassword((prevState) => !prevState);
  const showPasswordConfirmHandler = () =>
    setShowConfirmPassword((prevState) => !prevState);

  const onSubmit: formikOnSubmitType<typeof initialValues> = (
    { newPassword: password },
    { setSubmitting }
  ) => {
    resetPassword({
      resetCode: resetCode!,
      email: email!,
      changePasswordDto: { password },
    })
      .unwrap()
      .then(() => {
        toast.success('Your password has been successfully reset');
        navigate('/');
      })
      .catch(({ status, data }) => {
        if (status === 401 || status === 404)
          toast.error('Password is not correct!');
        else toast.error(data);
      });
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  if (hasToken || !resetCode || !email || !checkResetPassword) {
    return <Navigate to="/" />;
  }

  return (
    <Stack component="form" onSubmit={formik.handleSubmit} rowGap={3}>
      <Typography
        align="center"
        fontWeight={700}
        variant="title1"
        whiteSpace="nowrap"
        color="secondary.main"
      >
        Reset password
      </Typography>
      <Typography align="center">Please enter your new password</Typography>
      <TextField
        fullWidth
        label="New password"
        id="newPassword"
        name="newPassword"
        onChange={formik.handleChange}
        value={formik.values.newPassword}
        error={Boolean(formik.errors.newPassword && formik.touched.newPassword)}
        helperText={formik.touched.newPassword && formik.errors.newPassword}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={showPasswordHandler}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth
        label="Confirm password"
        id="confirmPassword"
        name="confirmPassword"
        onChange={formik.handleChange}
        value={formik.values.confirmPassword}
        error={Boolean(
          formik.errors.confirmPassword && formik.touched.confirmPassword
        )}
        helperText={
          formik.touched.confirmPassword && formik.errors.confirmPassword
        }
        type={showConfirmPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={showPasswordConfirmHandler}
                edge="end"
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Stack sx={{ minWidth: '100%', mt: 3 }} direction="row" columnGap={2}>
        <Button size="large" onClick={goBack} fullWidth variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          loading={resetLoading}
          size="large"
          fullWidth
          variant="contained"
          type="submit"
        >
          Submit
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default ResetPassword;
