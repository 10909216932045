import { PictureAsPdfRounded } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { ResourceDto } from 'src/app/services/generatedApi';
import { Trash } from 'src/components/atoms/svg/TrashSvg';
import { AddResourceDialog } from './AddResourceDialog';

type ResourcesCRDPropsType = {
  resource: ResourceDto[];
  setResource: (resources: ResourceDto[]) => void;
};

export const ResourcesCRD: FC<ResourcesCRDPropsType> = ({
  resource,
  setResource,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const addNewResource = (rc: ResourceDto) => {
    setResource([...resource, rc]);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt={5}
        mb={2}
      >
        <Typography variant="title2" fontWeight="bold">
          Resources
        </Typography>
        <Button variant="contained" onClick={() => setOpenDialog(true)}>
          Add new Resource
        </Button>
      </Stack>
      <Grid container spacing={2} px={3}>
        {resource.map(({ name }, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                p: 1,
                borderRadius: 2,
                bgcolor: 'primary.light',
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar sx={{ bgcolor: 'white', p: 2 }} variant="rounded">
                  <PictureAsPdfRounded fontSize="large" color="primary" />
                </Avatar>
                <Typography>{name}</Typography>
              </Stack>
              <IconButton
                sx={{ p: 1 }}
                onClick={() => {
                  setResource(resource.filter((arg, idx) => index !== idx));
                }}
              >
                <Trash fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        ))}
      </Grid>
      <AddResourceDialog
        openDialog={openDialog}
        handleClose={() => setOpenDialog(false)}
        addResource={addNewResource}
      />
    </>
  );
};
