import { colorsArray } from 'src/constant/colorsArray';

export const colorArrayIndexEnhancer = (idx: number) => {
  let returnedIndex = idx;
  let colorArrayLength = colorsArray.length;
  while (returnedIndex >= colorArrayLength) {
    returnedIndex = returnedIndex - colorArrayLength;
  }
  return returnedIndex;
};

export const colorPickerHelper = (index: number) =>
  colorsArray[colorArrayIndexEnhancer(index)];
