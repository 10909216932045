import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Logo: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3333 10.1875C19.9107 10.1875 22 8.13081 22 5.59375C22 3.05669 19.9107 1 17.3333 1C14.756 1 12.6667 3.05669 12.6667 5.59375C12.6667 8.13081 14.756 10.1875 17.3333 10.1875ZM10 10.1875C10 12.3621 8.20914 14.125 6 14.125C3.79086 14.125 2 12.3621 2 10.1875C2 8.01288 3.79086 6.25 6 6.25C8.20914 6.25 10 8.01288 10 10.1875ZM19.3333 18.7188C19.3333 20.5309 17.8409 22 16 22C14.1591 22 12.6667 20.5309 12.6667 18.7188C12.6667 16.9066 14.1591 15.4375 16 15.4375C17.8409 15.4375 19.3333 16.9066 19.3333 18.7188Z"
        fill="white"
      />
    </SvgIcon>
  );
};
