import { Download, PlayArrowRounded } from '@mui/icons-material';

export const getHistoryLogData = (title: string, date: string) => {
  const convertedDate = new Date(date);
  const formattedDate = convertedDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
  const formattedTime = convertedDate.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  const formattedDateTime = `${formattedDate} at ${formattedTime}`;

  switch (title) {
    case 'LOGIN':
      return { description: `This account logged in on ${formattedDateTime}.` };

    case 'LAUNCH_COURSE':
      return {
        description: `You launched course at on ${formattedDateTime}. Get ready to start your learning course!`,
        button: { title: 'Preview Course', icon: <PlayArrowRounded /> },
      };

    case 'ENROLLED_COURSE':
      return {
        description: `Your course enrollment is confirmed for on ${formattedDateTime}. Get ready to start your course!`,
        button: { title: 'Play Course', icon: <PlayArrowRounded /> },
      };

    case 'CHANGE_ENROLLED_USER_STATE':
      return {
        description: `You Enrolled user to course at on ${formattedDateTime}`,
      };

    case 'STARTED_COURSE':
      return {
        description: `Start the course at on ${formattedDateTime}`,
        button: { title: 'Resume Course', icon: <PlayArrowRounded /> },
      };

    case 'FINISHED_COURSE':
      return {
        description: `Finish the course at ${formattedDate}${formattedTime}`,
        button: { title: 'Download Certificate', icon: <Download /> },
      };

    case 'CREATED_REVIEW':
      return {
        description: `Review the course at ${formattedDate}${formattedTime}`,
      };

    case 'GENERATE_ENROLLED_USER_CERTIFICATE':
      return {
        description: `You generated certificate at ${formattedDate}${formattedTime}`,
      };

    default:
      return null;
  }
};

export const StackStyle = {
  position: 'relative',
  pt: 5,
  pb: 30,
  px: { xs: 0, md: 5 },
  overflowY: 'overlay',
  overflowX: 'hidden',
  maxHeight: 500,
  '&::-webkit-scrollbar': { display: 'none' },
  '&::-webkit-scrollbar:hover': { display: 'none' },
  '&::-webkit-scrollbar-track': { display: 'none' },
  '&::-webkit-scrollbar-thumb': { display: 'none' },
  '&::-webkit-scrollbar-thumb:hover': { display: 'none' },
};
