import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { useUsersControllerChangePasswordMutation } from 'src/app/services/generatedApi';
import { formikOnSubmitType } from 'src/types/form.type';
import { passwordValidatorRegex } from 'src/utils/formValidator';
import * as yup from 'yup';

const formInitialValues = { newPassword: '', confirmPassword: '' };
const passValidationHandler = (value: string) =>
  !value ? false : passwordValidatorRegex.test(value);

export const ChangePassword: FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formValidation = yup.object().shape({
    newPassword: yup
      .string()
      .test('Password validation', 'Password is not valid', (value) =>
        passValidationHandler(value as string)
      ),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), undefined],
        'Repeating the new password is incorrect'
      )
      .required('Confirm Password is required and must be match'),
  });

  const [changePassword, { isLoading }] =
    useUsersControllerChangePasswordMutation();

  const submitHandler: formikOnSubmitType<typeof formInitialValues> = (
    { newPassword: password },
    { setSubmitting }
  ) => {
    changePassword({ changePasswordDto: { password } })
      .unwrap()
      .then(() => toast.success('Password changed successfully'))
      .catch(({ status, data }) => {
        if (status === 401 || status === 404)
          toast.error('Password is not correct!');
        else toast.error(data);
      });
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formValidation,
    onSubmit: submitHandler,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack px={5} spacing={2}>
        <Typography variant="title3" color="secondary.dark" fontWeight="bold">
          Change Password
        </Typography>
        <Stack>
          <Typography variant="subtitle1">New Password</Typography>
          <TextField
            placeholder="New Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(
              formik.errors.newPassword && formik.touched.newPassword
            )}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            {...formik.getFieldProps('newPassword')}
          />
        </Stack>
        <Stack>
          <Typography variant="subtitle1">Confirm Password</Typography>
          <TextField
            placeholder="Confirm New Password"
            type={showConfirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(
              formik.errors.confirmPassword && formik.touched.confirmPassword
            )}
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            {...formik.getFieldProps('confirmPassword')}
          />
        </Stack>
        <Stack direction="row" justifyContent="end" spacing={2} py={2}>
          <LoadingButton
            component="button"
            type="submit"
            loading={isLoading}
            variant="contained"
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};
