import { FC, useState } from 'react';
import {
  Button,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { NewTicketDialog } from 'src/components/organisms/support/NewTicketDialog';
import {
  useTicketControllerGetMyTicketsQuery,
  useTicketControllerGetTicketsQuery,
} from 'src/app/services/generatedApi';
import { StyledTableCell } from 'src/components/molecules/StyledTableCell';
import { useAppSelector } from 'src/app/reduxCustomHooks';
import { Container } from '@mui/system';
import { TicketTableRow } from 'src/components/organisms/support/TicketTableRow';
import { TableSearchInput } from 'src/components/atoms/TableSearchInput';
import { FilterList } from 'src/components/atoms/FilterList';

const data = (
  id: string,
  title: string,
  subject: string,
  dateCreated: string,
  status: 'OPEN' | 'IN_PROGRESS' | 'CLOSED'
) => {
  return { id, title, subject, dateCreated, status };
};

export type SubjectType = 'GENERAL' | 'ACCOUNTING' | 'TECHNICAL' | 'MARKETING';

const headCells = ['Title', 'Subject', 'Date Created', 'Status', ''];

const Support: FC = () => {
  const [search, setSearch] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const [subjects, setSubjects] = useState<{
    [key: string]: boolean;
  }>({ GENERAL: true, ACCOUNTING: true, MARKETING: true, TECHNICAL: true });

  const isAdmin = useAppSelector((state) => state.auth.isAdmin);

  const { data: allTickets, isLoading: loadingAllTickets } =
    useTicketControllerGetTicketsQuery(undefined as any, { skip: !isAdmin });

  const { data: myTickets, isLoading: loadingMyTickets } =
    useTicketControllerGetMyTicketsQuery(undefined as any, { skip: isAdmin });

  const filteredList = (isAdmin ? allTickets : myTickets)
    ?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
    .filter((item) => {
      if (subjects.GENERAL && item.subject === 'GENERAL') return true;
      if (subjects.ACCOUNTING && item.subject === 'ACCOUNTING') return true;
      if (subjects.MARKETING && item.subject === 'MARKETING') return true;
      if (subjects.TECHNICAL && item.subject === 'TECHNICAL') return true;
      return false;
    });

  const isLoading = loadingAllTickets || loadingMyTickets;

  const rows = filteredList?.map((ticket) => {
    const { id, name, subject, createdAt, status } = ticket;
    return data(id, name, subject, createdAt, status);
  });

  return (
    <>
      <Container maxWidth="md">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Button variant="contained" onClick={() => setOpenDialog(true)}>
            New Ticket
          </Button>
          <Stack direction="row" alignItems="center" spacing={1}>
            <FilterList filters={subjects} setFilters={setSubjects} />
            <TableSearchInput search={search} setSearch={setSearch} />
          </Stack>
        </Stack>
        <Stack>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((cell, index) => (
                    <StyledTableCell key={index}>{cell}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? [...Array(5)].map((_, index) => (
                      <TableRow key={index}>
                        {[...Array(5)].map((_, idx) => (
                          <StyledTableCell align="center" key={idx}>
                            <Skeleton
                              width={
                                idx === 4 ? 30 : index % 2 === 0 ? 50 : 100
                              }
                              height={idx === 4 ? 30 : 15}
                              variant="rounded"
                            />
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    ))
                  : rows?.map((row, index) => (
                      <TicketTableRow key={index} index={index} row={row} />
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Container>
      <NewTicketDialog
        openDialog={openDialog}
        handleClose={() => setOpenDialog(false)}
      />
    </>
  );
};

export default Support;
