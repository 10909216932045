import { FC, useEffect, useRef, useState } from 'react';
import { IconButton, Menu, Stack } from '@mui/material';
import {
  Edit,
  FormatBold,
  TextDecrease,
  TextIncrease,
} from '@mui/icons-material';
import Konva from 'konva';
import { Group, Text, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';
import { Trash } from 'src/components/atoms/svg/TrashSvg';
import { ChangeTextColor } from 'src/components/molecules/ChangeTextColor';
import { ImageSizeDto } from 'src/app/services/generatedApi';

type TextItemPropsType = {
  shapeProps: any;
  isSelected: boolean;
  stageSize: ImageSizeDto;
  onSelect: () => void;
  onChange: (newAttrs: any) => void;
  remove: () => void;
  preview: boolean;
};

export const TextItem: FC<TextItemPropsType> = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  remove,
  stageSize,
  preview,
}) => {
  const groupRef = useRef<Konva.Group | null>(null);
  const trRef = useRef<Konva.Transformer | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    if (isSelected && groupRef.current) {
      trRef.current?.nodes([groupRef.current]);
      trRef.current?.getLayer()?.batchDraw();
    }
  }, [isSelected]);

  useEffect(() => {
    trRef.current?.getLayer()?.batchDraw();
  }, [shapeProps]);

  const { x, y, rotation = 0, ...textProps } = shapeProps;

  return (
    <>
      <Group
        ref={groupRef}
        draggable={!preview}
        onDragMove={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x() / stageSize.width,
            y: e.target.y() / stageSize.height,
          });
        }}
        x={x * stageSize.width}
        y={y * stageSize.height}
        rotation={rotation}
      >
        <Text
          onClick={onSelect}
          onTap={onSelect}
          {...textProps}
          fontStyle={shapeProps.bold ? 'bold' : 'normal'}
          fill={shapeProps.color}
        />
        {isSelected && (
          <Html>
            <Stack
              alignItems="center"
              justifyContent="center"
              direction="row"
              sx={{ width: 0, height: 0 }}
            >
              <IconButton
                sx={{
                  transform: 'translate(-7px, -14px)',
                  p: 0.5,
                  color: '#777',
                }}
                onClick={handleClick}
              >
                <Edit />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                <Stack direction="row" spacing={1} px={1}>
                  <IconButton
                    onClick={() => {
                      remove();
                      handleClose();
                    }}
                    sx={{ p: 0.6 }}
                  >
                    <Trash fontSize="small" color="error" />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      onChange({
                        ...shapeProps,
                        fontSize: shapeProps.fontSize + 1,
                      })
                    }
                    sx={{ p: 0.6 }}
                  >
                    <TextIncrease fontSize="small" color="inherit" />
                  </IconButton>
                  <IconButton
                    sx={{ p: 0.6 }}
                    onClick={() =>
                      onChange({
                        ...shapeProps,
                        fontSize: shapeProps.fontSize - 1,
                      })
                    }
                  >
                    <TextDecrease fontSize="small" color="inherit" />
                  </IconButton>
                  <IconButton
                    sx={{
                      p: 0.6,
                      backgroundColor: 'customGray.gray1',
                      '&:hover': { backgroundColor: 'customGray.gray1' },
                    }}
                    onClick={() => {
                      onChange({ ...shapeProps, bold: !shapeProps.bold });
                    }}
                  >
                    <FormatBold fontSize="small" color="primary" />
                  </IconButton>
                  <ChangeTextColor
                    changeColor={(color: string) =>
                      onChange({ ...shapeProps, color })
                    }
                  />
                </Stack>
              </Menu>
            </Stack>
          </Html>
        )}
      </Group>

      {isSelected && (
        <Transformer
          ref={trRef}
          resizeEnabled={false}
          boundBoxFunc={(oldBox, newBox) =>
            newBox.width < 5 || newBox.height < 5 ? oldBox : newBox
          }
          onTransform={() => {
            const node = groupRef.current;

            onChange({
              ...shapeProps,
              x: (node?.x() ?? 0) / stageSize.width,
              y: (node?.y() ?? 0) / stageSize.height,
              rotation: trRef.current?.rotation() ?? 0,
            });
          }}
        />
      )}
    </>
  );
};
