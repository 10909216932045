import { FC } from 'react';
import { IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import { ResourceRo } from 'src/app/services/generatedApi';
import { PDF as PDFIcon } from 'src/components/atoms/svg/PdfSvg';
import emptySvg from 'src/assets/svg/Empty.svg';

type ResourcesPropsType = { resources: ResourceRo[]; loading: boolean };

export const Resources: FC<ResourcesPropsType> = ({ resources, loading }) => {
  return (
    <Stack spacing={3}>
      <Typography variant="title3" color="secondary.dark" fontWeight="bold">
        Resources
      </Typography>
      <Stack px={{ xs: 0, sm: 2 }} spacing={2}>
        {loading ? (
          [...Array(3)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rounded"
              height={55}
              animation="wave"
            />
          ))
        ) : resources.length === 0 ? (
          <Stack alignItems="center" spacing={2}>
            <img
              src={emptySvg}
              style={{ width: 200, height: 140 }}
              alt="EmptyCourses"
            />
            <Typography variant="subtitle2" color="secondary.light">
              This course doesn't has resource.
            </Typography>
          </Stack>
        ) : (
          resources.map(({ name, path, size }, index) => (
            <Stack
              key={index}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ py: 1.5, px: 2, borderRadius: 3 }}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <PDFIcon sx={{ width: 50, height: 50 }} />
                <Typography
                  variant="text0"
                  color="secondary.dark"
                  fontWeight="bold"
                >
                  {name}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="text2">
                  File Size: {(size / 1048576).toFixed(2)} MB
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => (window.location.href = path)}
                >
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
          ))
        )}
      </Stack>
    </Stack>
  );
};
