import { FC, useState } from 'react';
import {
  TextField,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { formikOnSubmitType } from 'src/types/form.type';
import { useAuthControllerLoginMutation } from 'src/app/services/generatedApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

const formInitialValues = { email: '', password: '' };

const formValidation = yup.object().shape({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
});

const Login: FC = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [loginUser, { isLoading }] = useAuthControllerLoginMutation();

  const submitHandler: formikOnSubmitType<typeof formInitialValues> = (
    { email: username, password },
    { setSubmitting }
  ) => {
    loginUser({ loginDto: { username, password } })
      .unwrap()
      .then((res) => {
        toast.success(`Welcome ${res?.user?.username}`);
        navigate('/');
      })
      .catch(({ status }: { status: number }) => {
        status === 401 && toast.error('Username or Password is incorrect');
      });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidation}
      onSubmit={submitHandler}
    >
      {({ errors, touched, getFieldProps }) => (
        <Form autoComplete="on">
          <Typography
            component="div"
            align="center"
            fontWeight={700}
            variant="title1"
            color="secondary.main"
            mb={{ xs: 5, sm: 4, md: 5 }}
          >
            Log in to your account
          </Typography>
          <Stack spacing={{ xs: 3, sm: 4 }}>
            <TextField
              fullWidth
              error={Boolean(errors.email && touched.email)}
              {...getFieldProps('email')}
              helperText={touched.email && errors.email}
              placeholder="Username"
            />
            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              error={Boolean(errors.password && touched.password)}
              {...getFieldProps('password')}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="Password"
            />
            <Stack alignItems="center" spacing={0.5}>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Sign in
              </LoadingButton>
              <Link
                to="/auth/forget-password"
                style={{ textDecoration: 'none' }}
              >
                <Typography color="secondary.main" variant="text1">
                  Forget Password ?
                </Typography>
              </Link>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
