import { api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authControllerLogin: build.mutation<
      AuthControllerLoginApiResponse,
      AuthControllerLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth`,
        method: 'POST',
        body: queryArg.loginDto,
      }),
    }),
    authControllerSignup: build.mutation<
      AuthControllerSignupApiResponse,
      AuthControllerSignupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/signup`,
        method: 'POST',
        body: queryArg.userCreateDto,
      }),
    }),
    usersControllerCreate: build.mutation<
      UsersControllerCreateApiResponse,
      UsersControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: 'POST',
        body: queryArg.userCreateDto,
      }),
    }),
    usersControllerFind: build.query<
      UsersControllerFindApiResponse,
      UsersControllerFindApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        params: {
          id: queryArg.id,
          username: queryArg.username,
          firstName: queryArg.firstName,
          lastName: queryArg.lastName,
          email: queryArg.email,
          documentCount: queryArg.documentCount,
          companyId: queryArg.companyId,
          isManager: queryArg.isManager,
        },
      }),
    }),
    usersControllerUpdate: build.mutation<
      UsersControllerUpdateApiResponse,
      UsersControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/other-profile/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.userUpdateDto,
      }),
    }),
    usersControllerGetUserProfile: build.query<
      UsersControllerGetUserProfileApiResponse,
      UsersControllerGetUserProfileApiArg
    >({
      query: () => ({ url: `/api/v1/users/profile` }),
    }),
    usersControllerUpdateMyProfile: build.mutation<
      UsersControllerUpdateMyProfileApiResponse,
      UsersControllerUpdateMyProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/profile`,
        method: 'PATCH',
        body: queryArg.userUpdateDto,
      }),
    }),
    usersControllerGetUserProfileById: build.query<
      UsersControllerGetUserProfileByIdApiResponse,
      UsersControllerGetUserProfileByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/profile-by-id/${queryArg.id}`,
      }),
    }),
    usersControllerDelete: build.mutation<
      UsersControllerDeleteApiResponse,
      UsersControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/delete/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    usersControllerSuspend: build.mutation<
      UsersControllerSuspendApiResponse,
      UsersControllerSuspendApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/suspend/${queryArg.id}`,
        method: 'PATCH',
      }),
    }),
    usersControllerActivate: build.mutation<
      UsersControllerActivateApiResponse,
      UsersControllerActivateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/activate/${queryArg.id}`,
        method: 'POST',
      }),
    }),
    usersControllerAssignRole: build.mutation<
      UsersControllerAssignRoleApiResponse,
      UsersControllerAssignRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/role/${queryArg.id}`,
        method: 'POST',
        body: queryArg.userRoleDto,
      }),
    }),
    usersControllerMailResetPassword: build.mutation<
      UsersControllerMailResetPasswordApiResponse,
      UsersControllerMailResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/mailResetPassword`,
        method: 'POST',
        body: queryArg.mailResetPasswordDto,
      }),
    }),
    usersControllerCheckResetPasswordCred: build.query<
      UsersControllerCheckResetPasswordCredApiResponse,
      UsersControllerCheckResetPasswordCredApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/checkResetPasswordCred`,
        params: { resetCode: queryArg.resetCode, email: queryArg.email },
      }),
    }),
    usersControllerResetPassword: build.mutation<
      UsersControllerResetPasswordApiResponse,
      UsersControllerResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/resetPassword`,
        method: 'POST',
        body: queryArg.changePasswordDto,
        params: { resetCode: queryArg.resetCode, email: queryArg.email },
      }),
    }),
    usersControllerChangePassword: build.mutation<
      UsersControllerChangePasswordApiResponse,
      UsersControllerChangePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/changePassword`,
        method: 'POST',
        body: queryArg.changePasswordDto,
      }),
    }),
    usersControllerGetCompanyUsers: build.query<
      UsersControllerGetCompanyUsersApiResponse,
      UsersControllerGetCompanyUsersApiArg
    >({
      query: () => ({ url: `/api/v1/users/my-company-users` }),
    }),
    companiesControllerGetAll: build.query<
      CompaniesControllerGetAllApiResponse,
      CompaniesControllerGetAllApiArg
    >({
      query: () => ({ url: `/api/v1/companies` }),
    }),
    companiesControllerCreate: build.mutation<
      CompaniesControllerCreateApiResponse,
      CompaniesControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/companies`,
        method: 'POST',
        body: queryArg.companyCreateDto,
      }),
    }),
    companiesControllerDelete: build.mutation<
      CompaniesControllerDeleteApiResponse,
      CompaniesControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/companies`,
        method: 'DELETE',
        body: queryArg.idDto,
      }),
    }),
    companiesControllerGetById: build.query<
      CompaniesControllerGetByIdApiResponse,
      CompaniesControllerGetByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/companies/by-id/${queryArg.id}` }),
    }),
    companiesControllerUpdateCompanyLicense: build.mutation<
      CompaniesControllerUpdateCompanyLicenseApiResponse,
      CompaniesControllerUpdateCompanyLicenseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/companies/update-company-limit`,
        method: 'PATCH',
        body: queryArg.updateCompanyLimitDto,
      }),
    }),
    userActivityControllerFindAll: build.query<
      UserActivityControllerFindAllApiResponse,
      UserActivityControllerFindAllApiArg
    >({
      query: () => ({ url: `/api/v1/user-activity` }),
    }),
    userActivityControllerFindLatest: build.query<
      UserActivityControllerFindLatestApiResponse,
      UserActivityControllerFindLatestApiArg
    >({
      query: () => ({ url: `/api/v1/user-activity/latest` }),
    }),
    userActivityControllerGetUserActivities: build.query<
      UserActivityControllerGetUserActivitiesApiResponse,
      UserActivityControllerGetUserActivitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/user-activity/user/${queryArg.id}`,
      }),
    }),
    userActivityControllerGetMyActivities: build.query<
      UserActivityControllerGetMyActivitiesApiResponse,
      UserActivityControllerGetMyActivitiesApiArg
    >({
      query: () => ({ url: `/api/v1/user-activity/my` }),
    }),
    coursesControllerCreate: build.mutation<
      CoursesControllerCreateApiResponse,
      CoursesControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course`,
        method: 'POST',
        body: queryArg.createCourseDto,
      }),
    }),
    coursesControllerAdminLaunchCourse: build.mutation<
      CoursesControllerAdminLaunchCourseApiResponse,
      CoursesControllerAdminLaunchCourseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/admin/${queryArg.id}`,
        method: 'POST',
        body: queryArg.createCourseDto,
      }),
    }),
    coursesControllerGetMyCompanyAllCourses: build.query<
      CoursesControllerGetMyCompanyAllCoursesApiResponse,
      CoursesControllerGetMyCompanyAllCoursesApiArg
    >({
      query: () => ({ url: `/api/v1/course/my-company-all-courses` }),
    }),
    coursesControllerGetCompanyAllCourses: build.query<
      CoursesControllerGetCompanyAllCoursesApiResponse,
      CoursesControllerGetCompanyAllCoursesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/selected-company-all-courses/${queryArg.id}`,
      }),
    }),
    coursesControllerGetUserCourses: build.query<
      CoursesControllerGetUserCoursesApiResponse,
      CoursesControllerGetUserCoursesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/user-courses/${queryArg.id}`,
      }),
    }),
    coursesControllerFindMyCourses: build.query<
      CoursesControllerFindMyCoursesApiResponse,
      CoursesControllerFindMyCoursesApiArg
    >({
      query: () => ({ url: `/api/v1/course/get-my-courses` }),
    }),
    coursesControllerGetMyCourseById: build.query<
      CoursesControllerGetMyCourseByIdApiResponse,
      CoursesControllerGetMyCourseByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/get-my-course-by-id/${queryArg.id}`,
      }),
    }),
    coursesControllerEnrollUsers: build.mutation<
      CoursesControllerEnrollUsersApiResponse,
      CoursesControllerEnrollUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/enroll`,
        method: 'POST',
        body: queryArg.enrollUserDto,
      }),
    }),
    coursesControllerUnEnrollUsers: build.mutation<
      CoursesControllerUnEnrollUsersApiResponse,
      CoursesControllerUnEnrollUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/unenroll`,
        method: 'POST',
        body: queryArg.idDto,
      }),
    }),
    coursesControllerGetCourseById: build.query<
      CoursesControllerGetCourseByIdApiResponse,
      CoursesControllerGetCourseByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/course/course/${queryArg.id}` }),
    }),
    coursesControllerGetScormPreviewFile: build.query<
      CoursesControllerGetScormPreviewFileApiResponse,
      CoursesControllerGetScormPreviewFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/get-scorm-preview/${queryArg.id}/*`,
      }),
    }),
    coursesControllerGetScormFile: build.query<
      CoursesControllerGetScormFileApiResponse,
      CoursesControllerGetScormFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/get-scorm-file/${queryArg.id}/*`,
      }),
    }),
    coursesControllerUpdate: build.mutation<
      CoursesControllerUpdateApiResponse,
      CoursesControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/update/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateCourseDto,
      }),
    }),
    coursesControllerSetCertificate: build.mutation<
      CoursesControllerSetCertificateApiResponse,
      CoursesControllerSetCertificateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/set-certificate`,
        method: 'PUT',
        body: queryArg.setCourseCertificateDto,
      }),
    }),
    coursesControllerChangeEnrolledUserState: build.mutation<
      CoursesControllerChangeEnrolledUserStateApiResponse,
      CoursesControllerChangeEnrolledUserStateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/change-enrolled-user-state`,
        method: 'PATCH',
        body: queryArg.changeEnrolledUserStateDto,
      }),
    }),
    coursesControllerRemove: build.mutation<
      CoursesControllerRemoveApiResponse,
      CoursesControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/remove/${queryArg.id}`,
        method: 'PATCH',
      }),
    }),
    coursesControllerDelete: build.mutation<
      CoursesControllerDeleteApiResponse,
      CoursesControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/delete/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    coursesControllerGenerateCertificate: build.mutation<
      CoursesControllerGenerateCertificateApiResponse,
      CoursesControllerGenerateCertificateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/generate-certificate`,
        method: 'POST',
        body: queryArg.idDto,
      }),
    }),
    coursesControllerSetFinish: build.mutation<
      CoursesControllerSetFinishApiResponse,
      CoursesControllerSetFinishApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/finish/${queryArg.id}`,
        method: 'POST',
      }),
    }),
    coursesControllerSetLastOpened: build.mutation<
      CoursesControllerSetLastOpenedApiResponse,
      CoursesControllerSetLastOpenedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/course/play/${queryArg.id}`,
        method: 'POST',
      }),
    }),
    baseCoursesControllerGetAll: build.query<
      BaseCoursesControllerGetAllApiResponse,
      BaseCoursesControllerGetAllApiArg
    >({
      query: () => ({ url: `/api/v1/base-course/all` }),
    }),
    baseCoursesControllerGetById: build.query<
      BaseCoursesControllerGetByIdApiResponse,
      BaseCoursesControllerGetByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/base-course/by-id/${queryArg.id}`,
      }),
    }),
    baseCoursesControllerGetStore: build.query<
      BaseCoursesControllerGetStoreApiResponse,
      BaseCoursesControllerGetStoreApiArg
    >({
      query: () => ({ url: `/api/v1/base-course/store` }),
    }),
    baseCoursesControllerGetStoreById: build.query<
      BaseCoursesControllerGetStoreByIdApiResponse,
      BaseCoursesControllerGetStoreByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/base-course/store/${queryArg.id}`,
      }),
    }),
    baseCoursesControllerSetInStore: build.mutation<
      BaseCoursesControllerSetInStoreApiResponse,
      BaseCoursesControllerSetInStoreApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/base-course/set-store`,
        method: 'PATCH',
        body: queryArg.setBaseCourseStoreDto,
      }),
    }),
    baseCoursesControllerSetCertificate: build.mutation<
      BaseCoursesControllerSetCertificateApiResponse,
      BaseCoursesControllerSetCertificateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/base-course/set-certificate`,
        method: 'PATCH',
        body: queryArg.setCertificateDto,
      }),
    }),
    baseCoursesControllerUpdate: build.mutation<
      BaseCoursesControllerUpdateApiResponse,
      BaseCoursesControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/base-course`,
        method: 'PUT',
        body: queryArg.updateBaseCourseDto,
      }),
    }),
    baseCoursesControllerDelete: build.mutation<
      BaseCoursesControllerDeleteApiResponse,
      BaseCoursesControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/base-course`,
        method: 'PATCH',
        body: queryArg.idDto,
      }),
    }),
    certificatesControllerGetAll: build.query<
      CertificatesControllerGetAllApiResponse,
      CertificatesControllerGetAllApiArg
    >({
      query: () => ({ url: `/api/v1/certificate/all` }),
    }),
    certificatesControllerGetById: build.query<
      CertificatesControllerGetByIdApiResponse,
      CertificatesControllerGetByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/certificate/${queryArg.id}` }),
    }),
    certificatesControllerUpdate: build.mutation<
      CertificatesControllerUpdateApiResponse,
      CertificatesControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/certificate/update/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateCertificateDto,
      }),
    }),
    certificatesControllerRemove: build.mutation<
      CertificatesControllerRemoveApiResponse,
      CertificatesControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/certificate/remove/${queryArg.id}`,
        method: 'PATCH',
      }),
    }),
    emailTemplatesControllerCreate: build.mutation<
      EmailTemplatesControllerCreateApiResponse,
      EmailTemplatesControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/email-templates`,
        method: 'POST',
        body: queryArg.createEmailTemplateDto,
      }),
    }),
    emailTemplatesControllerGetAll: build.query<
      EmailTemplatesControllerGetAllApiResponse,
      EmailTemplatesControllerGetAllApiArg
    >({
      query: () => ({ url: `/api/v1/email-templates` }),
    }),
    emailTemplatesControllerGetAllCompany: build.query<
      EmailTemplatesControllerGetAllCompanyApiResponse,
      EmailTemplatesControllerGetAllCompanyApiArg
    >({
      query: () => ({ url: `/api/v1/email-templates/me/company` }),
    }),
    emailTemplatesControllerFindOne: build.query<
      EmailTemplatesControllerFindOneApiResponse,
      EmailTemplatesControllerFindOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/email-templates/get/${queryArg.id}`,
      }),
    }),
    emailTemplatesControllerUpdate: build.mutation<
      EmailTemplatesControllerUpdateApiResponse,
      EmailTemplatesControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/email-templates/update/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateEmailTemplateDto,
      }),
    }),
    emailTemplatesControllerRemove: build.mutation<
      EmailTemplatesControllerRemoveApiResponse,
      EmailTemplatesControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/email-templates/remove/${queryArg.id}`,
        method: 'PATCH',
      }),
    }),
    emailTemplatesControllerDelete: build.mutation<
      EmailTemplatesControllerDeleteApiResponse,
      EmailTemplatesControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/email-templates/delete/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    rateAndReviewControllerCreate: build.mutation<
      RateAndReviewControllerCreateApiResponse,
      RateAndReviewControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/rate-and-review`,
        method: 'POST',
        body: queryArg.createRateAndReviewDto,
      }),
    }),
    rateAndReviewControllerFindAll: build.query<
      RateAndReviewControllerFindAllApiResponse,
      RateAndReviewControllerFindAllApiArg
    >({
      query: () => ({ url: `/api/v1/rate-and-review` }),
    }),
    rateAndReviewControllerUpdate: build.mutation<
      RateAndReviewControllerUpdateApiResponse,
      RateAndReviewControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/rate-and-review`,
        method: 'PATCH',
        body: queryArg.updateRateAndReviewDto,
        params: { id: queryArg.id },
      }),
    }),
    rateAndReviewControllerGetAverageRate: build.query<
      RateAndReviewControllerGetAverageRateApiResponse,
      RateAndReviewControllerGetAverageRateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/rate-and-review/average/${queryArg.id}`,
      }),
    }),
    rateAndReviewControllerGetOne: build.query<
      RateAndReviewControllerGetOneApiResponse,
      RateAndReviewControllerGetOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/rate-and-review/rate`,
        params: { id: queryArg.id },
      }),
    }),
    rateAndReviewControllerDelete: build.mutation<
      RateAndReviewControllerDeleteApiResponse,
      RateAndReviewControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/rate-and-review/delete`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    mediaControllerCreateBaseCourse: build.mutation<
      MediaControllerCreateBaseCourseApiResponse,
      MediaControllerCreateBaseCourseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    mediaControllerCreateCertificate: build.mutation<
      MediaControllerCreateCertificateApiResponse,
      MediaControllerCreateCertificateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/certificate`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    mediaControllerUploadThumbnail: build.mutation<
      MediaControllerUploadThumbnailApiResponse,
      MediaControllerUploadThumbnailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/thumbnail`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    mediaControllerUploadImage: build.mutation<
      MediaControllerUploadImageApiResponse,
      MediaControllerUploadImageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/image`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    mediaControllerUploadResource: build.mutation<
      MediaControllerUploadResourceApiResponse,
      MediaControllerUploadResourceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/resource`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    mediaControllerBatchCreateUser: build.mutation<
      MediaControllerBatchCreateUserApiResponse,
      MediaControllerBatchCreateUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media/batch`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    workspacesControllerGetWorkspaceById: build.query<
      WorkspacesControllerGetWorkspaceByIdApiResponse,
      WorkspacesControllerGetWorkspaceByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/workspaces/by-id/${queryArg.id}` }),
    }),
    workspacesControllerGetMyCompanyWorkspaces: build.query<
      WorkspacesControllerGetMyCompanyWorkspacesApiResponse,
      WorkspacesControllerGetMyCompanyWorkspacesApiArg
    >({
      query: () => ({ url: `/api/v1/workspaces/company-workspaces` }),
    }),
    workspacesControllerGetCompanyWorkspaces: build.query<
      WorkspacesControllerGetCompanyWorkspacesApiResponse,
      WorkspacesControllerGetCompanyWorkspacesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/company-workspaces-by-id/${queryArg.id}`,
      }),
    }),
    workspacesControllerAdminCreateCompanyWorkspace: build.mutation<
      WorkspacesControllerAdminCreateCompanyWorkspaceApiResponse,
      WorkspacesControllerAdminCreateCompanyWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/admin-workspace/${queryArg.id}`,
        method: 'POST',
        body: queryArg.createWorkspaceDto,
      }),
    }),
    workspacesControllerCreateCompanyWorkspace: build.mutation<
      WorkspacesControllerCreateCompanyWorkspaceApiResponse,
      WorkspacesControllerCreateCompanyWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/company-workspace`,
        method: 'POST',
        body: queryArg.createWorkspaceDto,
      }),
    }),
    workspacesControllerUpdateWorkspace: build.mutation<
      WorkspacesControllerUpdateWorkspaceApiResponse,
      WorkspacesControllerUpdateWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/workspace/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateWorkspaceDto,
      }),
    }),
    workspacesControllerDeleteWorkspace: build.mutation<
      WorkspacesControllerDeleteWorkspaceApiResponse,
      WorkspacesControllerDeleteWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/workspace/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    workspacesControllerInviteUserToWorkspace: build.mutation<
      WorkspacesControllerInviteUserToWorkspaceApiResponse,
      WorkspacesControllerInviteUserToWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/invite-user`,
        method: 'POST',
        body: queryArg.inviteUsersDto,
      }),
    }),
    workspacesControllerDeleteWorkspaceUser: build.mutation<
      WorkspacesControllerDeleteWorkspaceUserApiResponse,
      WorkspacesControllerDeleteWorkspaceUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/delete-workspace-user`,
        method: 'DELETE',
        body: queryArg.deleteWorkspaceUserDto,
      }),
    }),
    rolesControllerCreateRole: build.mutation<
      RolesControllerCreateRoleApiResponse,
      RolesControllerCreateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: 'POST',
        body: queryArg.roleDto,
      }),
    }),
    rolesControllerUpdateRole: build.mutation<
      RolesControllerUpdateRoleApiResponse,
      RolesControllerUpdateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: 'PUT',
        body: queryArg.updateRoleDto,
      }),
    }),
    rolesControllerReadRole: build.query<
      RolesControllerReadRoleApiResponse,
      RolesControllerReadRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        params: { roleId: queryArg.roleId },
      }),
    }),
    rolesControllerDeleteRole: build.mutation<
      RolesControllerDeleteRoleApiResponse,
      RolesControllerDeleteRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: 'DELETE',
        body: queryArg.idDto,
      }),
    }),
    rolesControllerFindRole: build.query<
      RolesControllerFindRoleApiResponse,
      RolesControllerFindRoleApiArg
    >({
      query: () => ({ url: `/api/v1/roles/roles` }),
    }),
    notificationControllerCreateMyCompanyNotification: build.mutation<
      NotificationControllerCreateMyCompanyNotificationApiResponse,
      NotificationControllerCreateMyCompanyNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notification/create/my-company`,
        method: 'POST',
        body: queryArg.createCompanyNotificationDto,
      }),
    }),
    notificationControllerCreateNotification: build.mutation<
      NotificationControllerCreateNotificationApiResponse,
      NotificationControllerCreateNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notification/create/users`,
        method: 'POST',
        body: queryArg.createNotificationDto,
      }),
    }),
    notificationControllerCreateCompanyNotification: build.mutation<
      NotificationControllerCreateCompanyNotificationApiResponse,
      NotificationControllerCreateCompanyNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notification/create/company`,
        method: 'POST',
        body: queryArg.createCompanyNotificationDto,
        params: { companyId: queryArg.companyId },
      }),
    }),
    notificationControllerGetUnseenNotification: build.query<
      NotificationControllerGetUnseenNotificationApiResponse,
      NotificationControllerGetUnseenNotificationApiArg
    >({
      query: () => ({ url: `/api/v1/notification/user-unseen` }),
    }),
    notificationControllerGetPreviewUnseenNotification: build.query<
      NotificationControllerGetPreviewUnseenNotificationApiResponse,
      NotificationControllerGetPreviewUnseenNotificationApiArg
    >({
      query: () => ({ url: `/api/v1/notification/user-unseen-preview` }),
    }),
    notificationControllerGetUnseenNotificationCount: build.query<
      NotificationControllerGetUnseenNotificationCountApiResponse,
      NotificationControllerGetUnseenNotificationCountApiArg
    >({
      query: () => ({ url: `/api/v1/notification/user-unseen-count` }),
    }),
    notificationControllerGetNotification: build.query<
      NotificationControllerGetNotificationApiResponse,
      NotificationControllerGetNotificationApiArg
    >({
      query: () => ({ url: `/api/v1/notification/user` }),
    }),
    notificationControllerSeenNotification: build.mutation<
      NotificationControllerSeenNotificationApiResponse,
      NotificationControllerSeenNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notification/seen`,
        method: 'POST',
        body: queryArg.idsDto,
      }),
    }),
    notificationControllerArchiveNotification: build.mutation<
      NotificationControllerArchiveNotificationApiResponse,
      NotificationControllerArchiveNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notification/archive`,
        method: 'POST',
        body: queryArg.idsDto,
      }),
    }),
    reportControllerGetMyReport: build.query<
      ReportControllerGetMyReportApiResponse,
      ReportControllerGetMyReportApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/report/course/${queryArg.id}` }),
    }),
    reportControllerGetEnrolledUserReport: build.query<
      ReportControllerGetEnrolledUserReportApiResponse,
      ReportControllerGetEnrolledUserReportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/report/course/enrolled-user/${queryArg.courseId}/${queryArg.userId}`,
      }),
    }),
    reportControllerGetCompanyCourseReport: build.query<
      ReportControllerGetCompanyCourseReportApiResponse,
      ReportControllerGetCompanyCourseReportApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/report/company-course/${queryArg.id}`,
      }),
    }),
    reportControllerGetSelfAdjustCourseBodyAverageForCompany: build.query<
      ReportControllerGetSelfAdjustCourseBodyAverageForCompanyApiResponse,
      ReportControllerGetSelfAdjustCourseBodyAverageForCompanyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/report/self-adjust/${queryArg.id}`,
      }),
    }),
    reportControllerGetUserStatistics: build.query<
      ReportControllerGetUserStatisticsApiResponse,
      ReportControllerGetUserStatisticsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/report/user/statistics/${queryArg.id}`,
      }),
    }),
    reportControllerGetAllEnrollments: build.query<
      ReportControllerGetAllEnrollmentsApiResponse,
      ReportControllerGetAllEnrollmentsApiArg
    >({
      query: () => ({ url: `/api/v1/report/enrollments` }),
    }),
    ticketControllerCreateTicket: build.mutation<
      TicketControllerCreateTicketApiResponse,
      TicketControllerCreateTicketApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tickets`,
        method: 'POST',
        body: queryArg.createTicketDto,
      }),
    }),
    ticketControllerGetTickets: build.query<
      TicketControllerGetTicketsApiResponse,
      TicketControllerGetTicketsApiArg
    >({
      query: () => ({ url: `/api/v1/tickets` }),
    }),
    ticketControllerGetMyTickets: build.query<
      TicketControllerGetMyTicketsApiResponse,
      TicketControllerGetMyTicketsApiArg
    >({
      query: () => ({ url: `/api/v1/tickets/user` }),
    }),
    ticketControllerGetTicketById: build.query<
      TicketControllerGetTicketByIdApiResponse,
      TicketControllerGetTicketByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/tickets/${queryArg.id}` }),
    }),
    ticketControllerAddTicketMessage: build.mutation<
      TicketControllerAddTicketMessageApiResponse,
      TicketControllerAddTicketMessageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tickets/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.ticketMessageDto,
      }),
    }),
    ticketControllerDeleteTicket: build.mutation<
      TicketControllerDeleteTicketApiResponse,
      TicketControllerDeleteTicketApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tickets/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    ticketControllerChangeTicketStatus: build.mutation<
      TicketControllerChangeTicketStatusApiResponse,
      TicketControllerChangeTicketStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tickets/status/${queryArg.id}`,
        method: 'PATCH',
        body: queryArg.updateTicketDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AuthControllerLoginApiResponse = /** status 200  */ LoginRo;
export type AuthControllerLoginApiArg = {
  loginDto: LoginDto;
};
export type AuthControllerSignupApiResponse = /** status 201  */ LoginRo;
export type AuthControllerSignupApiArg = {
  userCreateDto: UserCreateDto;
};
export type UsersControllerCreateApiResponse = /** status 201  */ UserRo;
export type UsersControllerCreateApiArg = {
  userCreateDto: UserCreateDto;
};
export type UsersControllerFindApiResponse = /** status 200  */ UserRo[];
export type UsersControllerFindApiArg = {
  id?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  documentCount?: number;
  companyId?: string;
  isManager?: boolean;
};
export type UsersControllerUpdateApiResponse = unknown;
export type UsersControllerUpdateApiArg = {
  id: string;
  userUpdateDto: UserUpdateDto;
};
export type UsersControllerGetUserProfileApiResponse =
  /** status 200  */ UserRo;
export type UsersControllerGetUserProfileApiArg = void;
export type UsersControllerUpdateMyProfileApiResponse = unknown;
export type UsersControllerUpdateMyProfileApiArg = {
  userUpdateDto: UserUpdateDto;
};
export type UsersControllerGetUserProfileByIdApiResponse =
  /** status 200  */ UserRo;
export type UsersControllerGetUserProfileByIdApiArg = {
  id: string;
};
export type UsersControllerDeleteApiResponse = unknown;
export type UsersControllerDeleteApiArg = {
  id: string;
};
export type UsersControllerSuspendApiResponse = unknown;
export type UsersControllerSuspendApiArg = {
  id: string;
};
export type UsersControllerActivateApiResponse = unknown;
export type UsersControllerActivateApiArg = {
  id: string;
};
export type UsersControllerAssignRoleApiResponse = unknown;
export type UsersControllerAssignRoleApiArg = {
  id: string;
  userRoleDto: UserRoleDto;
};
export type UsersControllerMailResetPasswordApiResponse = unknown;
export type UsersControllerMailResetPasswordApiArg = {
  mailResetPasswordDto: MailResetPasswordDto;
};
export type UsersControllerCheckResetPasswordCredApiResponse = unknown;
export type UsersControllerCheckResetPasswordCredApiArg = {
  resetCode: string;
  email: string;
};
export type UsersControllerResetPasswordApiResponse = unknown;
export type UsersControllerResetPasswordApiArg = {
  resetCode: string;
  email: string;
  changePasswordDto: ChangePasswordDto;
};
export type UsersControllerChangePasswordApiResponse = unknown;
export type UsersControllerChangePasswordApiArg = {
  changePasswordDto: ChangePasswordDto;
};
export type UsersControllerGetCompanyUsersApiResponse =
  /** status 200  */ UserRo[];
export type UsersControllerGetCompanyUsersApiArg = void;
export type CompaniesControllerGetAllApiResponse =
  /** status 200  */ PopulatedCompanyRo[];
export type CompaniesControllerGetAllApiArg = void;
export type CompaniesControllerCreateApiResponse = /** status 201  */ string;
export type CompaniesControllerCreateApiArg = {
  companyCreateDto: CompanyCreateDto;
};
export type CompaniesControllerDeleteApiResponse = unknown;
export type CompaniesControllerDeleteApiArg = {
  idDto: IdDto;
};
export type CompaniesControllerGetByIdApiResponse =
  /** status 200  */ CompanyRo;
export type CompaniesControllerGetByIdApiArg = {
  id: string;
};
export type CompaniesControllerUpdateCompanyLicenseApiResponse = unknown;
export type CompaniesControllerUpdateCompanyLicenseApiArg = {
  updateCompanyLimitDto: UpdateCompanyLimitDto;
};
export type UserActivityControllerFindAllApiResponse =
  /** status 200  */ UserActivityRo[];
export type UserActivityControllerFindAllApiArg = void;
export type UserActivityControllerFindLatestApiResponse =
  /** status 200  */ UserActivityRo[];
export type UserActivityControllerFindLatestApiArg = void;
export type UserActivityControllerGetUserActivitiesApiResponse =
  /** status 200  */ UserActivityRo[];
export type UserActivityControllerGetUserActivitiesApiArg = {
  id: string;
};
export type UserActivityControllerGetMyActivitiesApiResponse =
  /** status 200  */ UserActivityRo[];
export type UserActivityControllerGetMyActivitiesApiArg = void;
export type CoursesControllerCreateApiResponse = /** status 201  */ string;
export type CoursesControllerCreateApiArg = {
  createCourseDto: CreateCourseDto;
};
export type CoursesControllerAdminLaunchCourseApiResponse =
  /** status 201  */ string;
export type CoursesControllerAdminLaunchCourseApiArg = {
  id: string;
  createCourseDto: CreateCourseDto;
};
export type CoursesControllerGetMyCompanyAllCoursesApiResponse =
  /** status 201  */ CourseRo[];
export type CoursesControllerGetMyCompanyAllCoursesApiArg = void;
export type CoursesControllerGetCompanyAllCoursesApiResponse =
  /** status 201  */ CourseRo[];
export type CoursesControllerGetCompanyAllCoursesApiArg = {
  id: string;
};
export type CoursesControllerGetUserCoursesApiResponse =
  /** status 201  */ MyCourseRo[];
export type CoursesControllerGetUserCoursesApiArg = {
  id: string;
};
export type CoursesControllerFindMyCoursesApiResponse =
  /** status 201  */ MyCourseRo[];
export type CoursesControllerFindMyCoursesApiArg = void;
export type CoursesControllerGetMyCourseByIdApiResponse =
  /** status 201  */ MyCourseRo;
export type CoursesControllerGetMyCourseByIdApiArg = {
  id: string;
};
export type CoursesControllerEnrollUsersApiResponse =
  /** status 201  */ CourseRo;
export type CoursesControllerEnrollUsersApiArg = {
  enrollUserDto: EnrollUserDto;
};
export type CoursesControllerUnEnrollUsersApiResponse = unknown;
export type CoursesControllerUnEnrollUsersApiArg = {
  idDto: IdDto;
};
export type CoursesControllerGetCourseByIdApiResponse =
  /** status 201  */ PopulatedCourseRo;
export type CoursesControllerGetCourseByIdApiArg = {
  id: string;
};
export type CoursesControllerGetScormPreviewFileApiResponse = unknown;
export type CoursesControllerGetScormPreviewFileApiArg = {
  id: any;
};
export type CoursesControllerGetScormFileApiResponse = unknown;
export type CoursesControllerGetScormFileApiArg = {
  id: any;
};
export type CoursesControllerUpdateApiResponse = unknown;
export type CoursesControllerUpdateApiArg = {
  id: string;
  updateCourseDto: UpdateCourseDto;
};
export type CoursesControllerSetCertificateApiResponse = unknown;
export type CoursesControllerSetCertificateApiArg = {
  setCourseCertificateDto: SetCourseCertificateDto;
};
export type CoursesControllerChangeEnrolledUserStateApiResponse = unknown;
export type CoursesControllerChangeEnrolledUserStateApiArg = {
  changeEnrolledUserStateDto: ChangeEnrolledUserStateDto;
};
export type CoursesControllerRemoveApiResponse = unknown;
export type CoursesControllerRemoveApiArg = {
  id: string;
};
export type CoursesControllerDeleteApiResponse = unknown;
export type CoursesControllerDeleteApiArg = {
  id: string;
};
export type CoursesControllerGenerateCertificateApiResponse =
  /** status 200  */ StreamableFile;
export type CoursesControllerGenerateCertificateApiArg = {
  idDto: IdDto;
};
export type CoursesControllerSetFinishApiResponse = /** status 200  */ CourseRo;
export type CoursesControllerSetFinishApiArg = {
  id: string;
};
export type CoursesControllerSetLastOpenedApiResponse = unknown;
export type CoursesControllerSetLastOpenedApiArg = {
  id: string;
};
export type BaseCoursesControllerGetAllApiResponse =
  /** status 201  */ BaseCourseRo[];
export type BaseCoursesControllerGetAllApiArg = void;
export type BaseCoursesControllerGetByIdApiResponse =
  /** status 201  */ BaseCourseRo;
export type BaseCoursesControllerGetByIdApiArg = {
  id: string;
};
export type BaseCoursesControllerGetStoreApiResponse =
  /** status 201  */ BaseCourseRo[];
export type BaseCoursesControllerGetStoreApiArg = void;
export type BaseCoursesControllerGetStoreByIdApiResponse =
  /** status 201  */ BaseCourseStoreViewRo;
export type BaseCoursesControllerGetStoreByIdApiArg = {
  id: string;
};
export type BaseCoursesControllerSetInStoreApiResponse =
  /** status 201  */ string;
export type BaseCoursesControllerSetInStoreApiArg = {
  setBaseCourseStoreDto: SetBaseCourseStoreDto;
};
export type BaseCoursesControllerSetCertificateApiResponse =
  /** status 201  */ string;
export type BaseCoursesControllerSetCertificateApiArg = {
  setCertificateDto: SetCertificateDto;
};
export type BaseCoursesControllerUpdateApiResponse = /** status 201  */ string;
export type BaseCoursesControllerUpdateApiArg = {
  updateBaseCourseDto: UpdateBaseCourseDto;
};
export type BaseCoursesControllerDeleteApiResponse = /** status 201  */ string;
export type BaseCoursesControllerDeleteApiArg = {
  idDto: IdDto;
};
export type CertificatesControllerGetAllApiResponse =
  /** status 200  */ CertificateMinimalRo[];
export type CertificatesControllerGetAllApiArg = void;
export type CertificatesControllerGetByIdApiResponse =
  /** status 200  */ CertificateRo;
export type CertificatesControllerGetByIdApiArg = {
  id: string;
};
export type CertificatesControllerUpdateApiResponse = unknown;
export type CertificatesControllerUpdateApiArg = {
  id: string;
  updateCertificateDto: UpdateCertificateDto;
};
export type CertificatesControllerRemoveApiResponse = unknown;
export type CertificatesControllerRemoveApiArg = {
  id: string;
};
export type EmailTemplatesControllerCreateApiResponse = unknown;
export type EmailTemplatesControllerCreateApiArg = {
  createEmailTemplateDto: CreateEmailTemplateDto;
};
export type EmailTemplatesControllerGetAllApiResponse =
  /** status 200  */ EmailTemplateRo[];
export type EmailTemplatesControllerGetAllApiArg = void;
export type EmailTemplatesControllerGetAllCompanyApiResponse =
  /** status 200  */ EmailTemplateRo[];
export type EmailTemplatesControllerGetAllCompanyApiArg = void;
export type EmailTemplatesControllerFindOneApiResponse =
  /** status 200  */ EmailTemplateRo;
export type EmailTemplatesControllerFindOneApiArg = {
  id: string;
};
export type EmailTemplatesControllerUpdateApiResponse = unknown;
export type EmailTemplatesControllerUpdateApiArg = {
  id: string;
  updateEmailTemplateDto: UpdateEmailTemplateDto;
};
export type EmailTemplatesControllerRemoveApiResponse = unknown;
export type EmailTemplatesControllerRemoveApiArg = {
  id: string;
};
export type EmailTemplatesControllerDeleteApiResponse = unknown;
export type EmailTemplatesControllerDeleteApiArg = {
  id: string;
};
export type RateAndReviewControllerCreateApiResponse =
  /** status 201  */ RateAndReviewRo;
export type RateAndReviewControllerCreateApiArg = {
  createRateAndReviewDto: CreateRateAndReviewDto;
};
export type RateAndReviewControllerFindAllApiResponse =
  /** status 201  */ RateAndReviewRo[];
export type RateAndReviewControllerFindAllApiArg = void;
export type RateAndReviewControllerUpdateApiResponse = unknown;
export type RateAndReviewControllerUpdateApiArg = {
  id: string;
  updateRateAndReviewDto: UpdateRateAndReviewDto;
};
export type RateAndReviewControllerGetAverageRateApiResponse =
  /** status 201  */ number;
export type RateAndReviewControllerGetAverageRateApiArg = {
  id: string;
};
export type RateAndReviewControllerGetOneApiResponse =
  /** status 201  */ RateAndReviewRo[];
export type RateAndReviewControllerGetOneApiArg = {
  id: string;
};
export type RateAndReviewControllerDeleteApiResponse = unknown;
export type RateAndReviewControllerDeleteApiArg = {
  id: string;
};
export type MediaControllerCreateBaseCourseApiResponse =
  /** status 201  */ string;
export type MediaControllerCreateBaseCourseApiArg = {
  body: {
    file: Blob;
    thumbnail?: Blob;
    courseArguments: CourseArgumentsDto[];
    name: string;
    description: string;
    inStore?: string;
    resource: ResourceDto[];
  };
};
export type MediaControllerCreateCertificateApiResponse =
  /** status 201  */ string;
export type MediaControllerCreateCertificateApiArg = {
  body: {
    background: Blob;
    certificate: CertificateDetailDto;
    textFields: TextFieldDto[];
    imageFields: ImageFieldDto[];
    baseCourseId?: string;
  };
};
export type MediaControllerUploadThumbnailApiResponse =
  /** status 200  */ string;
export type MediaControllerUploadThumbnailApiArg = {
  body: {
    image: Blob;
    options: UploadImageDto;
  };
};
export type MediaControllerUploadImageApiResponse = /** status 200  */ string;
export type MediaControllerUploadImageApiArg = {
  body: {
    image: Blob;
  };
};
export type MediaControllerUploadResourceApiResponse =
  /** status 200  */ ResourceRo;
export type MediaControllerUploadResourceApiArg = {
  body: {
    file: Blob;
  };
};
export type MediaControllerBatchCreateUserApiResponse = unknown;
export type MediaControllerBatchCreateUserApiArg = {
  body: {
    file: Blob;
    settingDto: BatchUserSettingDto;
  };
};
export type WorkspacesControllerGetWorkspaceByIdApiResponse =
  /** status 200  */ WorkspaceRo;
export type WorkspacesControllerGetWorkspaceByIdApiArg = {
  id: string;
};
export type WorkspacesControllerGetMyCompanyWorkspacesApiResponse =
  /** status 200  */ WorkspaceRo[];
export type WorkspacesControllerGetMyCompanyWorkspacesApiArg = void;
export type WorkspacesControllerGetCompanyWorkspacesApiResponse =
  /** status 200  */ PopulatedWorkspaceRo[];
export type WorkspacesControllerGetCompanyWorkspacesApiArg = {
  id: string;
};
export type WorkspacesControllerAdminCreateCompanyWorkspaceApiResponse =
  unknown;
export type WorkspacesControllerAdminCreateCompanyWorkspaceApiArg = {
  id: string;
  createWorkspaceDto: CreateWorkspaceDto;
};
export type WorkspacesControllerCreateCompanyWorkspaceApiResponse = unknown;
export type WorkspacesControllerCreateCompanyWorkspaceApiArg = {
  createWorkspaceDto: CreateWorkspaceDto;
};
export type WorkspacesControllerUpdateWorkspaceApiResponse =
  /** status 200  */ WorkspaceRo;
export type WorkspacesControllerUpdateWorkspaceApiArg = {
  id: string;
  updateWorkspaceDto: UpdateWorkspaceDto;
};
export type WorkspacesControllerDeleteWorkspaceApiResponse = unknown;
export type WorkspacesControllerDeleteWorkspaceApiArg = {
  id: string;
};
export type WorkspacesControllerInviteUserToWorkspaceApiResponse =
  /** status 200  */ InviteUserRo[];
export type WorkspacesControllerInviteUserToWorkspaceApiArg = {
  inviteUsersDto: InviteUsersDto;
};
export type WorkspacesControllerDeleteWorkspaceUserApiResponse = unknown;
export type WorkspacesControllerDeleteWorkspaceUserApiArg = {
  deleteWorkspaceUserDto: DeleteWorkspaceUserDto;
};
export type RolesControllerCreateRoleApiResponse = /** status 201  */ RoleRo;
export type RolesControllerCreateRoleApiArg = {
  roleDto: RoleDto;
};
export type RolesControllerUpdateRoleApiResponse = unknown;
export type RolesControllerUpdateRoleApiArg = {
  updateRoleDto: UpdateRoleDto;
};
export type RolesControllerReadRoleApiResponse = /** status 200  */ RoleRo;
export type RolesControllerReadRoleApiArg = {
  roleId: string;
};
export type RolesControllerDeleteRoleApiResponse = unknown;
export type RolesControllerDeleteRoleApiArg = {
  idDto: IdDto;
};
export type RolesControllerFindRoleApiResponse = /** status 200  */ RoleRo[];
export type RolesControllerFindRoleApiArg = void;
export type NotificationControllerCreateMyCompanyNotificationApiResponse =
  /** status 201  */ NotificationRo[];
export type NotificationControllerCreateMyCompanyNotificationApiArg = {
  createCompanyNotificationDto: CreateCompanyNotificationDto;
};
export type NotificationControllerCreateNotificationApiResponse =
  /** status 201  */ NotificationRo[];
export type NotificationControllerCreateNotificationApiArg = {
  createNotificationDto: CreateNotificationDto;
};
export type NotificationControllerCreateCompanyNotificationApiResponse =
  /** status 201  */ NotificationRo[];
export type NotificationControllerCreateCompanyNotificationApiArg = {
  companyId: string;
  createCompanyNotificationDto: CreateCompanyNotificationDto;
};
export type NotificationControllerGetUnseenNotificationApiResponse =
  /** status 201  */ NotificationRo[];
export type NotificationControllerGetUnseenNotificationApiArg = void;
export type NotificationControllerGetPreviewUnseenNotificationApiResponse =
  /** status 201  */ NotificationPreviewRo[];
export type NotificationControllerGetPreviewUnseenNotificationApiArg = void;
export type NotificationControllerGetUnseenNotificationCountApiResponse =
  /** status 201  */ NotificationCountRo;
export type NotificationControllerGetUnseenNotificationCountApiArg = void;
export type NotificationControllerGetNotificationApiResponse =
  /** status 201  */ NotificationRo[];
export type NotificationControllerGetNotificationApiArg = void;
export type NotificationControllerSeenNotificationApiResponse = unknown;
export type NotificationControllerSeenNotificationApiArg = {
  idsDto: IdsDto;
};
export type NotificationControllerArchiveNotificationApiResponse = unknown;
export type NotificationControllerArchiveNotificationApiArg = {
  idsDto: IdsDto;
};
export type ReportControllerGetMyReportApiResponse =
  /** status 200  */ ReportRo;
export type ReportControllerGetMyReportApiArg = {
  id: string;
};
export type ReportControllerGetEnrolledUserReportApiResponse =
  /** status 200  */ ReportRo;
export type ReportControllerGetEnrolledUserReportApiArg = {
  courseId: string;
  userId: string;
};
export type ReportControllerGetCompanyCourseReportApiResponse =
  /** status 200  */ CompanyReportRo;
export type ReportControllerGetCompanyCourseReportApiArg = {
  id: string;
};
export type ReportControllerGetSelfAdjustCourseBodyAverageForCompanyApiResponse =
  /** status 200  */ EnrolledUserAnswerRo[];
export type ReportControllerGetSelfAdjustCourseBodyAverageForCompanyApiArg = {
  id: string;
};
export type ReportControllerGetUserStatisticsApiResponse =
  /** status 200  */ UserStatisticsRo;
export type ReportControllerGetUserStatisticsApiArg = {
  id: string;
};
export type ReportControllerGetAllEnrollmentsApiResponse =
  /** status 200  */ AllEnrollmentsRo[];
export type ReportControllerGetAllEnrollmentsApiArg = void;
export type TicketControllerCreateTicketApiResponse =
  /** status 201  */ TicketRo;
export type TicketControllerCreateTicketApiArg = {
  createTicketDto: CreateTicketDto;
};
export type TicketControllerGetTicketsApiResponse =
  /** status 200  */ TicketRo[];
export type TicketControllerGetTicketsApiArg = void;
export type TicketControllerGetMyTicketsApiResponse =
  /** status 200  */ TicketRo[];
export type TicketControllerGetMyTicketsApiArg = void;
export type TicketControllerGetTicketByIdApiResponse =
  /** status 200  */ TicketRo;
export type TicketControllerGetTicketByIdApiArg = {
  id: string;
};
export type TicketControllerAddTicketMessageApiResponse = unknown;
export type TicketControllerAddTicketMessageApiArg = {
  id: string;
  ticketMessageDto: TicketMessageDto;
};
export type TicketControllerDeleteTicketApiResponse = unknown;
export type TicketControllerDeleteTicketApiArg = {
  id: string;
};
export type TicketControllerChangeTicketStatusApiResponse = unknown;
export type TicketControllerChangeTicketStatusApiArg = {
  id: string;
  updateTicketDto: UpdateTicketDto;
};
export type UserLoginRo = {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  roleNames: string[];
  isActive: boolean;
  documentCount: number;
  companyId?: string;
  isManager?: boolean;
};
export type LoginRo = {
  token: string;
  user: UserLoginRo;
};
export type LoginDto = {
  username: string;
  password: string;
};
export type UserCreateDto = {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  companyName?: string;
  isManager: boolean;
  groupId?: string;
};
export type MinimalCompanyRo = {
  id: string;
  name: string;
  ownerId: string;
};
export type UserRo = {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  roleNames: string[];
  isActive: boolean;
  documentCount: number;
  company?: MinimalCompanyRo;
  isManager?: boolean;
  lastLogin: string;
};
export type UserUpdateDto = {
  firstName?: string;
  lastName?: string;
  email?: string;
  isManager?: boolean;
  companyId?: string;
  groupId?: string;
};
export type UserRoleDto = {
  roleIds: string[];
};
export type MailResetPasswordDto = {
  email: string;
  callbackUrl: string;
};
export type ChangePasswordDto = {
  password: string;
};
export type CompanyUsageRo = {
  maxUsers: number;
  courseCount: number;
  enrollmentCount: number;
  expirationTime: string;
};
export type PopulatedCompanyRo = {
  id: string;
  name: string;
  owner: UserRo;
  limit: CompanyUsageRo;
  usersCount: number;
};
export type CompanyCreateDto = {
  name: string;
  owner: string;
};
export type IdDto = {
  id: string;
};
export type CompanyRo = {
  id: string;
  name: string;
  owner: UserRo;
  limit: CompanyUsageRo;
};
export type UpdateCompanyLimitDto = {
  id: string;
  maxUsers: number;
  maxPages: number;
  expirationTime: string;
};
export type UserActivityRo = {
  method:
    | 'LOGIN'
    | 'LAUNCH_COURSE'
    | 'ENROLLED_COURSE'
    | 'CHANGE_ENROLLED_USER_STATE'
    | 'STARTED_COURSE'
    | 'PAUSED_COURSE'
    | 'RESUMED_COURSE'
    | 'FINISHED_COURSE'
    | 'PASSED_QUIZ'
    | 'FAILED_QUIZ'
    | 'CREATED_REVIEW'
    | 'GENERATE_ENROLLED_USER_CERTIFICATE';
  request: object;
  response: object;
  caller: string;
  callee: string;
  calleeKey: string;
  calleeType:
    | 'ROLE'
    | 'USER'
    | 'ENROLLED_USER'
    | 'COURSE'
    | 'BASE_COURSE'
    | 'REVIEW'
    | 'REPORT';
  createdAt: string;
};
export type FilePathDto = {
  path: string;
  mode: 'LOCAL' | 'URL' | 'S3';
};
export type CreateCourseDto = {
  baseCourseId: string;
  name: string;
  description: string;
  userIds: string[];
  thumbnail?: FilePathDto;
};
export type EnrolledUserAnswerRo = {
  name: string;
  value: object;
};
export type EnrolledUserRo = {
  id: string;
  user: string;
  invitedAt: string;
  lastOpened: string;
  startedAt: string;
  finishedAt: string;
  state: 'ENROLLED' | 'REGISTERED' | 'IN_PROGRESS' | 'COMPLETED' | 'FAILED';
  suspendData: string;
  answers: EnrolledUserAnswerRo[];
};
export type CourseArgumentsRo = {
  name: string;
  fieldType: 'NUMBER' | 'STRING';
  category: string;
};
export type BaseCourseRateRo = {
  average: number;
  count: number;
};
export type ResourceRo = {
  name: string;
  path: string;
  size: number;
  fileKey: string;
};
export type BaseCourseRo = {
  id: string;
  creator: string;
  name: string;
  description: string;
  inStore: boolean;
  url: string;
  courseArguments: CourseArgumentsRo[];
  certificate?: string;
  rate: BaseCourseRateRo;
  thumbnailUrl?: string;
  createdAt: string;
  resource: ResourceRo[];
};
export type CourseRo = {
  id: string;
  name: string;
  description: string;
  certificate: string;
  emailTemplates: string[];
  enrolledUsers?: EnrolledUserRo[];
  createdAt: string;
  rate: number;
  thumbnailUrl: string;
  isDeleted: boolean;
  baseCourse: BaseCourseRo;
  answers: string[];
};
export type MyCourseRo = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  rate: number;
  thumbnailUrl: string;
  enrolledData: EnrolledUserRo;
  baseCourse: BaseCourseRo;
};
export type EnrollUserDto = {
  courseId: string;
  userIds: string[];
};
export type PopulatedEnrolledUserRo = {
  id: string;
  user: UserRo;
  invitedAt: string;
  lastOpened: string;
  startedAt: string;
  finishedAt: string;
  state: 'ENROLLED' | 'REGISTERED' | 'IN_PROGRESS' | 'COMPLETED' | 'FAILED';
  suspendData: string;
  answers: string[];
};
export type PopulatedBaseCourseRo = {
  id: string;
  creator: string;
  name: string;
  description: string;
  inStore: boolean;
  filePath: FilePathDto;
  courseArguments: CourseArgumentsRo[];
  certificate?: string;
  rate: BaseCourseRateRo;
  thumbnail?: FilePathDto;
  createdAt: string;
  resource: ResourceRo[];
};
export type PopulatedCourseRo = {
  id: string;
  name: string;
  description: string;
  certificate: string;
  emailTemplates: string[];
  enrolledUsers?: PopulatedEnrolledUserRo[];
  createdAt: string;
  rate: number;
  thumbnailUrl: string;
  isDeleted: boolean;
  baseCourse: PopulatedBaseCourseRo;
  answers: string[];
};
export type UpdateCourseDto = {
  name?: string;
  description?: string;
  visibility?: 'PUBLIC' | 'PRIVATE';
};
export type SetCourseCertificateDto = {
  courseId: string;
  certificateId: string;
};
export type EnrolledUserAnswerDto = {
  name: string;
  value: object;
};
export type ChangeEnrolledUserStateDto = {
  enrolledUserId: string;
  suspendData: string;
  answers: EnrolledUserAnswerDto[];
};
export type StreamableFile = {};
export type ImageSizeDto = {
  width: number;
  height: number;
};
export type LocationDto = {
  x: number;
  y: number;
};
export type TextFieldRo = {
  inputType:
    | 'FIRST_NAME'
    | 'LAST_NAME'
    | 'COMPANY_NAME'
    | 'SCORE'
    | 'FINISH_DATE'
    | 'ARGUMENT'
    | 'CUSTOM';
  text?: string;
  fontSize: number;
  color: string;
  bold: boolean;
  location: LocationDto;
  rotation: number;
};
export type ImageFieldRo = {
  url: string;
  filePath: FilePathDto;
  rotation: number;
  location: LocationDto;
  size: ImageSizeDto;
};
export type CertificateRo = {
  id: string;
  name: string;
  creator: string;
  backgroundUrl: string;
  backgroundPath: FilePathDto;
  size: ImageSizeDto;
  textFields: TextFieldRo[];
  imageFields: ImageFieldRo[];
};
export type BaseCourseStoreViewRo = {
  id: string;
  name: string;
  description: string;
  url: string;
  certificate: CertificateRo;
  rate: BaseCourseRateRo;
  thumbnailUrl?: string;
  createdAt: string;
  resource: ResourceRo[];
};
export type SetBaseCourseStoreDto = {
  baseCourseId: string;
  inStore: boolean;
};
export type SetCertificateDto = {
  baseCourseId: string;
  certificateId: string;
};
export type CourseArgumentsDto = {
  name: string;
  fieldType: 'NUMBER' | 'STRING';
  category: string;
};
export type ResourceDto = {
  name: string;
  path: FilePathDto;
  size: number;
};
export type UpdateBaseCourseDto = {
  id: string;
  courseArguments?: CourseArgumentsDto[];
  name?: string;
  description?: string;
  inStore?: boolean;
  resource: ResourceDto[];
};
export type CertificateMinimalRo = {
  id: string;
  name: string;
  creator: string;
  aspectRatio: number;
};
export type TextFieldDto = {
  inputType:
    | 'FIRST_NAME'
    | 'LAST_NAME'
    | 'COMPANY_NAME'
    | 'SCORE'
    | 'FINISH_DATE'
    | 'ARGUMENT'
    | 'CUSTOM';
  text?: string;
  fontSize: number;
  color: string;
  bold: boolean;
  location: LocationDto;
  rotation: number;
};
export type ImageFieldDto = {
  filePath: FilePathDto;
  size: ImageSizeDto;
  location: LocationDto;
  rotation: number;
};
export type UpdateCertificateDto = {
  name: string;
  size: ImageSizeDto;
  background: FilePathDto;
  textFields: TextFieldDto[];
  imageFields: ImageFieldDto[];
};
export type CreateEmailTemplateDto = {
  title: 'REPORT' | 'WELCOME' | 'ENROLL';
  description: string;
  subject: string;
  message: string;
  setting: string;
};
export type EmailTemplateRo = {
  id: string;
  creator: UserRo;
  title: 'REPORT' | 'WELCOME' | 'ENROLL';
  description: string;
  subject: string;
  message: string;
};
export type UpdateEmailTemplateDto = {
  title?: 'REPORT' | 'WELCOME' | 'ENROLL';
  subject: string;
  message: string;
  setting: string;
};
export type MinimalUserRo = {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  roleNames: string[];
};
export type RateAndReviewRo = {
  id: string;
  creator: MinimalUserRo;
  course: string;
  rate: number;
  title: string;
  description: string;
};
export type CreateRateAndReviewDto = {
  courseId: string;
  rate: number;
  title: string;
  description: string;
};
export type UpdateRateAndReviewDto = {
  rate: number;
  title: string;
  description: string;
};
export type CertificateDetailDto = {
  name: string;
  size: ImageSizeDto;
};
export type UploadImageDto = {
  id: string;
  mode: 'BASE_COURSE_THUMBNAIL' | 'COURSE_THUMBNAIL' | 'CERTIFICATE_BACKGROUND';
};
export type BatchUserSettingDto = {
  sendInvite: boolean;
  updateExisting: boolean;
};
export type WorkspaceRo = {
  id: string;
  name: string;
  creator: string;
  companyId: string;
  workspaceUsers: any[];
  workspaceMaintainers: any[];
  description: string;
  createdAt: string;
};
export type PopulatedWorkspaceRo = {
  id: string;
  name: string;
  creator: string;
  companyId: string;
  workspaceUsers: any[];
  workspaceMaintainers: any[];
  description: string;
  createdAt: string;
};
export type CreateWorkspaceDto = {
  name: string;
  workspaceUsers: string[];
  description?: string;
};
export type UpdateWorkspaceDto = {
  name: string;
  description?: string;
};
export type InviteUserRo = {
  id: string;
  access: ('WORKSPACE_MANAGER' | 'WORKSPACE_USER')[];
};
export type InviteUsersDto = {
  workspaceId: string;
  workspaceUsers: string[];
};
export type DeleteWorkspaceUserDto = {
  workspaceId: string;
  id: string;
};
export type RoleRo = {
  id: string;
  name: string;
  permissions:
    | 'ADMIN'
    | 'CREATE_BASE_COURSE'
    | 'CREATE_CERTIFICATE'
    | 'UPLOAD_IMAGE'
    | 'LAUNCH_COURSE'
    | 'GET_MY_COMPANY_COURSES'
    | 'GET_COMPANY_COURSES'
    | 'GET_USER_COURSES_BY_USER_ID'
    | 'GET_USER_COURSE_BY_ID'
    | 'ENROLL_USERS_TO_COURSE'
    | 'UNENROLL_USER_FROM_COURSE'
    | 'GET_COURSE_BY_ID'
    | 'UPDATE_COURSE_DATA'
    | 'CHANGE_ENROLLED_USER_STATE'
    | 'REMOVE_COURSE'
    | 'DELETE_COURSE'
    | 'SET_COURSE_CERTIFICATE'
    | 'GENERATE_ENROLLED_USER_CERTIFICATE'
    | 'GET_ALL_BASE_COURSES'
    | 'GET_STORE'
    | 'SET_BASE_COURSE_IN_STORE'
    | 'UPDATE_BASE_COURSE'
    | 'GET_ALL_CERTIFICATES'
    | 'GET_CERTIFICATE_BY_ID'
    | 'UPDATE_CERTIFICATE'
    | 'REMOVE_CERTIFICATE'
    | 'CREATE_EMAIL_TEMPLATE'
    | 'GET_EMAIL_TEMPLATE'
    | 'UPDATE_EMAIL_TEMPLATE'
    | 'REMOVE_EMAIL_TEMPLATE'
    | 'DELETE_EMAIL_TEMPLATE'
    | 'GET_ALL_RATE_AND_REVIEW'
    | 'GET_AVERAGE_RATE_AND_REVIEW'
    | 'CREATE_RATE_AND_REVIEW'
    | 'UPDATE_RATE_AND_REVIEW'
    | 'REMOVE_RATE_AND_REVIEW'
    | 'DELETE_RATE_AND_REVIEW'
    | 'CREATE_NOTIFICATION_TO_MY_COMPANY'
    | 'CREATE_NOTIFICATION_TO_USER'
    | 'CREATE_ROLE'
    | 'UPDATE_ROLE'
    | 'GET_ROLE'
    | 'GET_ALL_ROLE'
    | 'DELETE_ROLE_BY_ID'
    | 'GET_ALL_ACTIVITIES'
    | 'GET_ACTIVITIES_BY_USER_ID'
    | 'GET_ALL_COMPANIES'
    | 'CREATE_COMPANY'
    | 'DELETE_COMPANY'
    | 'UPDATE_COMPANY_LIMIT'
    | 'CREATE_USER'
    | 'UPDATE_USER'
    | 'FIND_USER'
    | 'GET_USER_BY_ID'
    | 'DELETE_USER'
    | 'SUSPEND_USER'
    | 'ACTIVATE_USER'
    | 'ASSIGN_ROLE'
    | 'GET_MY_COMPANY_USERS'
    | 'CREATE_WORKSPACE'
    | 'DELETE_WORKSPACE'
    | 'GET_WORKSPACE_BY_ID'
    | 'GET_MY_COMPANY_WORKSPACES'
    | 'UPDATE_WORKSPACE'
    | 'GENERATE_USER_REPORT'
    | 'GET_MY_REPORT'
    | 'GENERATE_COMPANY_COURSE_REPORT'
    | 'GET_USER_STATISTICS_BY_ID'
    | 'GET_ALL_TICKETS'
    | 'GET_TICKET_BY_ID'
    | 'DELETE_TICKET_BY_ID'
    | 'UPDATE_TICKET_BY_ID';
};
export type RoleDto = {
  name: string;
  permissions: (
    | 'ADMIN'
    | 'CREATE_BASE_COURSE'
    | 'CREATE_CERTIFICATE'
    | 'UPLOAD_IMAGE'
    | 'LAUNCH_COURSE'
    | 'GET_MY_COMPANY_COURSES'
    | 'GET_COMPANY_COURSES'
    | 'GET_USER_COURSES_BY_USER_ID'
    | 'GET_USER_COURSE_BY_ID'
    | 'ENROLL_USERS_TO_COURSE'
    | 'UNENROLL_USER_FROM_COURSE'
    | 'GET_COURSE_BY_ID'
    | 'UPDATE_COURSE_DATA'
    | 'CHANGE_ENROLLED_USER_STATE'
    | 'REMOVE_COURSE'
    | 'DELETE_COURSE'
    | 'SET_COURSE_CERTIFICATE'
    | 'GENERATE_ENROLLED_USER_CERTIFICATE'
    | 'GET_ALL_BASE_COURSES'
    | 'GET_STORE'
    | 'SET_BASE_COURSE_IN_STORE'
    | 'UPDATE_BASE_COURSE'
    | 'GET_ALL_CERTIFICATES'
    | 'GET_CERTIFICATE_BY_ID'
    | 'UPDATE_CERTIFICATE'
    | 'REMOVE_CERTIFICATE'
    | 'CREATE_EMAIL_TEMPLATE'
    | 'GET_EMAIL_TEMPLATE'
    | 'UPDATE_EMAIL_TEMPLATE'
    | 'REMOVE_EMAIL_TEMPLATE'
    | 'DELETE_EMAIL_TEMPLATE'
    | 'GET_ALL_RATE_AND_REVIEW'
    | 'GET_AVERAGE_RATE_AND_REVIEW'
    | 'CREATE_RATE_AND_REVIEW'
    | 'UPDATE_RATE_AND_REVIEW'
    | 'REMOVE_RATE_AND_REVIEW'
    | 'DELETE_RATE_AND_REVIEW'
    | 'CREATE_NOTIFICATION_TO_MY_COMPANY'
    | 'CREATE_NOTIFICATION_TO_USER'
    | 'CREATE_ROLE'
    | 'UPDATE_ROLE'
    | 'GET_ROLE'
    | 'GET_ALL_ROLE'
    | 'DELETE_ROLE_BY_ID'
    | 'GET_ALL_ACTIVITIES'
    | 'GET_ACTIVITIES_BY_USER_ID'
    | 'GET_ALL_COMPANIES'
    | 'CREATE_COMPANY'
    | 'DELETE_COMPANY'
    | 'UPDATE_COMPANY_LIMIT'
    | 'CREATE_USER'
    | 'UPDATE_USER'
    | 'FIND_USER'
    | 'GET_USER_BY_ID'
    | 'DELETE_USER'
    | 'SUSPEND_USER'
    | 'ACTIVATE_USER'
    | 'ASSIGN_ROLE'
    | 'GET_MY_COMPANY_USERS'
    | 'CREATE_WORKSPACE'
    | 'DELETE_WORKSPACE'
    | 'GET_WORKSPACE_BY_ID'
    | 'GET_MY_COMPANY_WORKSPACES'
    | 'UPDATE_WORKSPACE'
    | 'GENERATE_USER_REPORT'
    | 'GET_MY_REPORT'
    | 'GENERATE_COMPANY_COURSE_REPORT'
    | 'GET_USER_STATISTICS_BY_ID'
    | 'GET_ALL_TICKETS'
    | 'GET_TICKET_BY_ID'
    | 'DELETE_TICKET_BY_ID'
    | 'UPDATE_TICKET_BY_ID'
  )[];
};
export type UpdateRoleDto = {
  id: string;
  name: string;
  permissions: (
    | 'ADMIN'
    | 'CREATE_BASE_COURSE'
    | 'CREATE_CERTIFICATE'
    | 'UPLOAD_IMAGE'
    | 'LAUNCH_COURSE'
    | 'GET_MY_COMPANY_COURSES'
    | 'GET_COMPANY_COURSES'
    | 'GET_USER_COURSES_BY_USER_ID'
    | 'GET_USER_COURSE_BY_ID'
    | 'ENROLL_USERS_TO_COURSE'
    | 'UNENROLL_USER_FROM_COURSE'
    | 'GET_COURSE_BY_ID'
    | 'UPDATE_COURSE_DATA'
    | 'CHANGE_ENROLLED_USER_STATE'
    | 'REMOVE_COURSE'
    | 'DELETE_COURSE'
    | 'SET_COURSE_CERTIFICATE'
    | 'GENERATE_ENROLLED_USER_CERTIFICATE'
    | 'GET_ALL_BASE_COURSES'
    | 'GET_STORE'
    | 'SET_BASE_COURSE_IN_STORE'
    | 'UPDATE_BASE_COURSE'
    | 'GET_ALL_CERTIFICATES'
    | 'GET_CERTIFICATE_BY_ID'
    | 'UPDATE_CERTIFICATE'
    | 'REMOVE_CERTIFICATE'
    | 'CREATE_EMAIL_TEMPLATE'
    | 'GET_EMAIL_TEMPLATE'
    | 'UPDATE_EMAIL_TEMPLATE'
    | 'REMOVE_EMAIL_TEMPLATE'
    | 'DELETE_EMAIL_TEMPLATE'
    | 'GET_ALL_RATE_AND_REVIEW'
    | 'GET_AVERAGE_RATE_AND_REVIEW'
    | 'CREATE_RATE_AND_REVIEW'
    | 'UPDATE_RATE_AND_REVIEW'
    | 'REMOVE_RATE_AND_REVIEW'
    | 'DELETE_RATE_AND_REVIEW'
    | 'CREATE_NOTIFICATION_TO_MY_COMPANY'
    | 'CREATE_NOTIFICATION_TO_USER'
    | 'CREATE_ROLE'
    | 'UPDATE_ROLE'
    | 'GET_ROLE'
    | 'GET_ALL_ROLE'
    | 'DELETE_ROLE_BY_ID'
    | 'GET_ALL_ACTIVITIES'
    | 'GET_ACTIVITIES_BY_USER_ID'
    | 'GET_ALL_COMPANIES'
    | 'CREATE_COMPANY'
    | 'DELETE_COMPANY'
    | 'UPDATE_COMPANY_LIMIT'
    | 'CREATE_USER'
    | 'UPDATE_USER'
    | 'FIND_USER'
    | 'GET_USER_BY_ID'
    | 'DELETE_USER'
    | 'SUSPEND_USER'
    | 'ACTIVATE_USER'
    | 'ASSIGN_ROLE'
    | 'GET_MY_COMPANY_USERS'
    | 'CREATE_WORKSPACE'
    | 'DELETE_WORKSPACE'
    | 'GET_WORKSPACE_BY_ID'
    | 'GET_MY_COMPANY_WORKSPACES'
    | 'UPDATE_WORKSPACE'
    | 'GENERATE_USER_REPORT'
    | 'GET_MY_REPORT'
    | 'GENERATE_COMPANY_COURSE_REPORT'
    | 'GET_USER_STATISTICS_BY_ID'
    | 'GET_ALL_TICKETS'
    | 'GET_TICKET_BY_ID'
    | 'DELETE_TICKET_BY_ID'
    | 'UPDATE_TICKET_BY_ID'
  )[];
};
export type NotificationRo = {
  id: string;
  to: string;
  from: string;
  title: string;
  text: string;
  date: string;
  seen: boolean;
  archive: boolean;
};
export type CreateCompanyNotificationDto = {
  title: string;
  text: string;
};
export type CreateNotificationDto = {
  to: string[];
  title: string;
  text: string;
};
export type NotificationPreviewRo = {
  id: string;
  to: string;
  from: string;
  title: string;
  text: string;
  date: string;
};
export type NotificationCountRo = {
  notificationCount: number;
};
export type IdsDto = {
  ids: string[];
};
export type ReportRo = {
  answers: EnrolledUserAnswerRo[];
  startedAt: string;
  finishedAt: string;
};
export type CompanyReportRo = {
  totalEnrolled: number;
  inProgress: number;
  completed: number;
  averageTimeToComplete: number;
};
export type UserStatisticsRo = {
  completedCourses: number;
  enrolledCourses: number;
  inProgress: number;
};
export type AllEnrollmentsRo = {
  count: number;
  date: string;
};
export type TicketMessageRo = {
  sender: MinimalUserRo;
  text: string;
  createdAt: string;
};
export type TicketRo = {
  id: string;
  creator: MinimalUserRo;
  name: string;
  subject: string;
  messages: TicketMessageRo[];
  status: 'OPEN' | 'IN_PROGRESS' | 'CLOSED';
  createdAt: string;
};
export type CreateTicketDto = {
  title: string;
  subject: 'GENERAL' | 'ACCOUNTING' | 'TECHNICAL' | 'MARKETING';
  courseId: string;
  message: string;
};
export type TicketMessageDto = {
  text: string;
};
export type UpdateTicketDto = {
  status: 'OPEN' | 'IN_PROGRESS' | 'CLOSED';
};
export const {
  useAuthControllerLoginMutation,
  useAuthControllerSignupMutation,
  useUsersControllerCreateMutation,
  useUsersControllerFindQuery,
  useUsersControllerUpdateMutation,
  useUsersControllerGetUserProfileQuery,
  useUsersControllerUpdateMyProfileMutation,
  useUsersControllerGetUserProfileByIdQuery,
  useUsersControllerDeleteMutation,
  useUsersControllerSuspendMutation,
  useUsersControllerActivateMutation,
  useUsersControllerAssignRoleMutation,
  useUsersControllerMailResetPasswordMutation,
  useUsersControllerCheckResetPasswordCredQuery,
  useUsersControllerResetPasswordMutation,
  useUsersControllerChangePasswordMutation,
  useUsersControllerGetCompanyUsersQuery,
  useCompaniesControllerGetAllQuery,
  useCompaniesControllerCreateMutation,
  useCompaniesControllerDeleteMutation,
  useCompaniesControllerGetByIdQuery,
  useCompaniesControllerUpdateCompanyLicenseMutation,
  useUserActivityControllerFindAllQuery,
  useUserActivityControllerFindLatestQuery,
  useUserActivityControllerGetUserActivitiesQuery,
  useUserActivityControllerGetMyActivitiesQuery,
  useCoursesControllerCreateMutation,
  useCoursesControllerAdminLaunchCourseMutation,
  useCoursesControllerGetMyCompanyAllCoursesQuery,
  useCoursesControllerGetCompanyAllCoursesQuery,
  useCoursesControllerGetUserCoursesQuery,
  useCoursesControllerFindMyCoursesQuery,
  useCoursesControllerGetMyCourseByIdQuery,
  useCoursesControllerEnrollUsersMutation,
  useCoursesControllerUnEnrollUsersMutation,
  useCoursesControllerGetCourseByIdQuery,
  useCoursesControllerGetScormPreviewFileQuery,
  useCoursesControllerGetScormFileQuery,
  useCoursesControllerUpdateMutation,
  useCoursesControllerSetCertificateMutation,
  useCoursesControllerChangeEnrolledUserStateMutation,
  useCoursesControllerRemoveMutation,
  useCoursesControllerDeleteMutation,
  useCoursesControllerGenerateCertificateMutation,
  useCoursesControllerSetFinishMutation,
  useCoursesControllerSetLastOpenedMutation,
  useBaseCoursesControllerGetAllQuery,
  useBaseCoursesControllerGetByIdQuery,
  useBaseCoursesControllerGetStoreQuery,
  useBaseCoursesControllerGetStoreByIdQuery,
  useBaseCoursesControllerSetInStoreMutation,
  useBaseCoursesControllerSetCertificateMutation,
  useBaseCoursesControllerUpdateMutation,
  useBaseCoursesControllerDeleteMutation,
  useCertificatesControllerGetAllQuery,
  useCertificatesControllerGetByIdQuery,
  useCertificatesControllerUpdateMutation,
  useCertificatesControllerRemoveMutation,
  useEmailTemplatesControllerCreateMutation,
  useEmailTemplatesControllerGetAllQuery,
  useEmailTemplatesControllerGetAllCompanyQuery,
  useEmailTemplatesControllerFindOneQuery,
  useEmailTemplatesControllerUpdateMutation,
  useEmailTemplatesControllerRemoveMutation,
  useEmailTemplatesControllerDeleteMutation,
  useRateAndReviewControllerCreateMutation,
  useRateAndReviewControllerFindAllQuery,
  useRateAndReviewControllerUpdateMutation,
  useRateAndReviewControllerGetAverageRateQuery,
  useRateAndReviewControllerGetOneQuery,
  useRateAndReviewControllerDeleteMutation,
  useMediaControllerCreateBaseCourseMutation,
  useMediaControllerCreateCertificateMutation,
  useMediaControllerUploadThumbnailMutation,
  useMediaControllerUploadImageMutation,
  useMediaControllerUploadResourceMutation,
  useMediaControllerBatchCreateUserMutation,
  useWorkspacesControllerGetWorkspaceByIdQuery,
  useWorkspacesControllerGetMyCompanyWorkspacesQuery,
  useWorkspacesControllerGetCompanyWorkspacesQuery,
  useWorkspacesControllerAdminCreateCompanyWorkspaceMutation,
  useWorkspacesControllerCreateCompanyWorkspaceMutation,
  useWorkspacesControllerUpdateWorkspaceMutation,
  useWorkspacesControllerDeleteWorkspaceMutation,
  useWorkspacesControllerInviteUserToWorkspaceMutation,
  useWorkspacesControllerDeleteWorkspaceUserMutation,
  useRolesControllerCreateRoleMutation,
  useRolesControllerUpdateRoleMutation,
  useRolesControllerReadRoleQuery,
  useRolesControllerDeleteRoleMutation,
  useRolesControllerFindRoleQuery,
  useNotificationControllerCreateMyCompanyNotificationMutation,
  useNotificationControllerCreateNotificationMutation,
  useNotificationControllerCreateCompanyNotificationMutation,
  useNotificationControllerGetUnseenNotificationQuery,
  useNotificationControllerGetPreviewUnseenNotificationQuery,
  useNotificationControllerGetUnseenNotificationCountQuery,
  useNotificationControllerGetNotificationQuery,
  useNotificationControllerSeenNotificationMutation,
  useNotificationControllerArchiveNotificationMutation,
  useReportControllerGetMyReportQuery,
  useReportControllerGetEnrolledUserReportQuery,
  useReportControllerGetCompanyCourseReportQuery,
  useReportControllerGetSelfAdjustCourseBodyAverageForCompanyQuery,
  useReportControllerGetUserStatisticsQuery,
  useReportControllerGetAllEnrollmentsQuery,
  useTicketControllerCreateTicketMutation,
  useTicketControllerGetTicketsQuery,
  useTicketControllerGetMyTicketsQuery,
  useTicketControllerGetTicketByIdQuery,
  useTicketControllerAddTicketMessageMutation,
  useTicketControllerDeleteTicketMutation,
  useTicketControllerChangeTicketStatusMutation,
} = injectedRtkApi;
