import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/app/reduxCustomHooks';

type ManagerPagesGuardPropsType = {};

export const ManagerRoute: FC<ManagerPagesGuardPropsType> = () => {
  const { token, isManager, isAdmin, haveCompany } = useAppSelector(
    (state) => ({
      token: state.auth.token,
      isManager: state.auth.isManager,
      isAdmin: state.auth.isAdmin,
      haveCompany:
        state.auth.adminSelectedCompanyId || state.auth.user?.companyId,
    })
  );

  if (!token) {
    return <Navigate to="/auth/login" />;
  }

  if (isAdmin && !haveCompany) return <Navigate to="/admin/dashboard" />;

  return isManager || isAdmin ? <Outlet /> : <Navigate to="/notfound" />;
};
