import { createSlice, AnyAction } from '@reduxjs/toolkit';
import { LoginRo, UserLoginRo } from '../services/generatedApi';
import { api } from '../services/api';

const hasAdminAccess = (roleArray: string[]) => {
  return !!roleArray.some((role) => role.toLowerCase() === 'admin');
};

type authSliceType = {
  user: UserLoginRo | null;
  token: string | null;
  isManager: boolean;
  isAdmin: boolean;
  adminSelectedCompanyId: string | null;
};

const initialState = (): authSliceType => {
  const localStorageUserInfo = localStorage.getItem('user');
  const localStorageUserToken = localStorage.getItem('token');
  const localStorageAdminSelectedCompanyId = localStorage.getItem(
    'adminSelectedCompanyId'
  );

  const user: authSliceType['user'] = localStorageUserInfo
    ? JSON.parse(localStorageUserInfo)
    : null;
  const token = localStorageUserToken || null;
  const isManager = !!user?.isManager;
  const isAdmin = user ? hasAdminAccess(user.roleNames) : false;
  const adminSelectedCompanyId =
    isAdmin && localStorageAdminSelectedCompanyId
      ? JSON.parse(localStorageAdminSelectedCompanyId)
      : null;

  return {
    user,
    token,
    isManager,
    isAdmin,
    adminSelectedCompanyId,
  };
};

const isAuthenticated = (action: AnyAction) => {
  return (
    api.endpoints.authControllerLogin.matchFulfilled(action) ||
    api.endpoints.authControllerSignup.matchFulfilled(action)
  );
};

const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAdminSelectedCompanyId: (
      state,
      { payload }: { payload: string | null }
    ) => {
      state.adminSelectedCompanyId = payload;
      if (payload) {
        localStorage.setItem('adminSelectedCompanyId', JSON.stringify(payload));
      } else {
        localStorage.removeItem('adminSelectedCompanyId');
      }
    },
    logout: () => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('adminSelectedCompanyId');
      return initialState();
    },
  },
  extraReducers: ({ addMatcher }) => {
    addMatcher(isAuthenticated, (state, { payload }: { payload: LoginRo }) => {
      const { token, user } = payload;

      const adminAccess = hasAdminAccess(user.roleNames);

      state.token = token;
      state.user = user;
      state.isManager = user.isManager || false;
      state.isAdmin = adminAccess;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
    });
    addMatcher(
      api.endpoints.usersControllerUpdate.matchFulfilled,
      (state, { meta }) => {
        if (!state.user) return state;
        const updatedInfo = meta?.arg?.originalArgs?.userUpdateDto;

        const firstName = updatedInfo.firstName || state.user.firstName;
        const lastName = updatedInfo.lastName || state.user.lastName;
        const email = updatedInfo.email || state.user.email;
        const isManager = updatedInfo.isManager || state.user.isManager;
        const companyId = updatedInfo.companyId || state.user.companyId;

        const newState = {
          ...state.user,
          firstName,
          lastName,
          email,
          isManager,
          companyId,
        };

        localStorage.setItem('user', JSON.stringify(newState));
        state.user = newState;
      }
    );
  },
});

export const {
  logout: logoutAction,
  setAdminSelectedCompanyId: setAdminSelectedCompanyIdAction,
} = authSlice.actions;

export default authSlice.reducer;
