export const getImageData = (
  file: File
): Promise<{ base64: string; ratio: number }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const imgElement = document.createElement('img');
      imgElement.src = event.target?.result as string;
      imgElement.onload = function () {
        const base64 = imgElement.src;
        const ratio = imgElement.naturalWidth / imgElement.naturalHeight;
        resolve({ base64, ratio });
      };
      imgElement.onerror = function () {
        reject(new Error('Error loading image'));
      };
    };
    reader.onerror = function () {
      reject(new Error('Error reading file'));
    };
    reader.readAsDataURL(file);
  });
};
