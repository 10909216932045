import { FC, ReactElement, Ref, forwardRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { setAdminSelectedCompanyIdAction } from 'src/app/slices/authSlice';
import { useAppDispatch } from 'src/app/reduxCustomHooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type AskSetCompanyDialogPropsType = {
  open: boolean;
  handleClose: () => void;
  company: { id: string; name: string };
  courseId: string;
  userId: string;
};

export const AskSetCompanyDialog: FC<AskSetCompanyDialogPropsType> = ({
  open,
  handleClose,
  company,
  userId,
  courseId,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    dispatch(setAdminSelectedCompanyIdAction(company.id));
    toast.info(`Portal Changed to ${company.name} company.`);
    navigate(`/company/courses/${courseId}/report/${userId}`);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle fontWeight={500} align="center" variant="title3">
        Change portal to {company.name} company
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you want to get user course report, you have to change the portal
          to the {company.name} and use company as manager.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Set Company
        </Button>
      </DialogActions>
    </Dialog>
  );
};
