import { FC } from 'react';
import { ManageHistory } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';

type ManageHistorySvgPropsType = { selected: boolean };

export const ManageHistorySvg: FC<SvgIconProps & ManageHistorySvgPropsType> = ({
  selected,
  ...props
}) => {
  return (
    <ManageHistory
      {...props}
      sx={{
        ...props.sx,
        color: selected ? 'primary.main' : 'rgba(255, 255, 255, 0.75)',
        fontSize: 30,
      }}
    />
  );
};
