import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/app/reduxCustomHooks';

export type AdminPrivateRoutePropsType = {};

export const AdminPrivateRoute: FC<AdminPrivateRoutePropsType> = () => {
  const { token, isAdmin } = useAppSelector((state) => ({
    token: state.auth.token,
    isAdmin: state.auth.isAdmin,
  }));

  if (!token) {
    return <Navigate to="/auth/login" />;
  }

  if (!isAdmin) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};
