import { Grid, Stack, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import {
  useCoursesControllerGetCompanyAllCoursesQuery,
  useCoursesControllerGetMyCompanyAllCoursesQuery,
} from 'src/app/services/generatedApi';
import { CompanyCourseCard } from '../courseCard/CompanyCourseCard';
import emptySvg from 'src/assets/svg/Empty.svg';
import { CourseCardLoading } from '../courseCard/CourseCardLoading';

type CompanyCoursesListPropsType = {
  limit?: number;
  isAdmin: boolean;
  companyId?: string | null;
};

export const CompanyCoursesList: FC<CompanyCoursesListPropsType> = ({
  limit,
  isAdmin,
  companyId,
}) => {
  const { data: companyCourses = [], isLoading: loadingCompanyCourses } =
    useCoursesControllerGetCompanyAllCoursesQuery(
      { id: companyId! },
      { skip: !companyId }
    );

  const { data: myCompanyCourses = [], isLoading: loadingMyCompanyCourses } =
    useCoursesControllerGetMyCompanyAllCoursesQuery(undefined as any, {
      skip: isAdmin,
    });

  const isLoading = loadingCompanyCourses || loadingMyCompanyCourses;

  const courses = useMemo(() => {
    if (isAdmin && companyId) return [...companyCourses].reverse();
    else return [...myCompanyCourses].reverse();
  }, [companyCourses, companyId, isAdmin, myCompanyCourses]);

  return (
    <Grid container spacing={2} height="100%">
      {isLoading ? (
        [...Array(3)].map((_, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <CourseCardLoading />
          </Grid>
        ))
      ) : courses?.length === 0 ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={3}
          sx={{ width: '100%', pt: 4 }}
        >
          <img src={emptySvg} alt="empty courses" />
          <Typography variant="title3" color="primary">
            There is no course in your list.
          </Typography>
        </Stack>
      ) : (
        (limit ? courses?.slice(0, limit) : courses)?.map((course) => (
          <Grid item xs={12} sm={6} lg={4} key={course.id}>
            <CompanyCourseCard course={course} />
          </Grid>
        ))
      )}
    </Grid>
  );
};
