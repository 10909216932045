import { LoadingButton } from '@mui/lab';
import { FC } from 'react';
import { toast } from 'react-toastify';
import { useWorkspacesControllerDeleteWorkspaceUserMutation } from 'src/app/services/generatedApi';

type RemoveUserButtonPropsType = { groupId: string; userId: string };

export const RemoveUserButton: FC<RemoveUserButtonPropsType> = ({
  groupId,
  userId,
}) => {
  const [removeUser, { isLoading }] =
    useWorkspacesControllerDeleteWorkspaceUserMutation();

  const removeUserOnClick = (userId: string) => {
    removeUser({ deleteWorkspaceUserDto: { workspaceId: groupId, id: userId } })
      .unwrap()
      .then()
      .catch(({ data }) => toast.error(data));
  };

  return (
    <LoadingButton
      size="small"
      variant="contained"
      color="error"
      loading={isLoading}
      onClick={() => removeUserOnClick(userId)}
      sx={{ width: { xs: 94, sm: 111 } }}
    >
      Remove
    </LoadingButton>
  );
};
