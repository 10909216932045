import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type HelpSvgPropsType = { selected: boolean };

export const Help: FC<SvgIconProps & HelpSvgPropsType> = ({
  selected,
  ...props
}) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx }}>
      <path
        d="M11.94 19.2C12.36 19.2 12.7152 19.0548 13.0056 18.7644C13.2952 18.4748 13.44 18.12 13.44 17.7C13.44 17.28 13.2952 16.9252 13.0056 16.6356C12.7152 16.3452 12.36 16.2 11.94 16.2C11.52 16.2 11.1648 16.3452 10.8744 16.6356C10.5848 16.9252 10.44 17.28 10.44 17.7C10.44 18.12 10.5848 18.4748 10.8744 18.7644C11.1648 19.0548 11.52 19.2 11.94 19.2ZM10.86 14.58H13.08C13.08 13.92 13.1552 13.4 13.3056 13.02C13.4552 12.64 13.88 12.12 14.58 11.46C15.1 10.94 15.51 10.4448 15.81 9.9744C16.11 9.5048 16.26 8.94 16.26 8.28C16.26 7.16 15.85 6.3 15.03 5.7C14.21 5.1 13.24 4.8 12.12 4.8C10.98 4.8 10.0552 5.1 9.3456 5.7C8.6352 6.3 8.14 7.02 7.86 7.86L9.84 8.64C9.94 8.28 10.1652 7.89 10.5156 7.47C10.8652 7.05 11.4 6.84 12.12 6.84C12.76 6.84 13.24 7.0148 13.56 7.3644C13.88 7.7148 14.04 8.1 14.04 8.52C14.04 8.92 13.92 9.2948 13.68 9.6444C13.44 9.9948 13.14 10.32 12.78 10.62C11.9 11.4 11.36 11.99 11.16 12.39C10.96 12.79 10.86 13.52 10.86 14.58V14.58ZM12 24C10.34 24 8.78 23.6848 7.32 23.0544C5.86 22.4248 4.59 21.57 3.51 20.49C2.43 19.41 1.5752 18.14 0.9456 16.68C0.3152 15.22 0 13.66 0 12C0 10.34 0.3152 8.78 0.9456 7.32C1.5752 5.86 2.43 4.59 3.51 3.51C4.59 2.43 5.86 1.5748 7.32 0.9444C8.78 0.3148 10.34 0 12 0C13.66 0 15.22 0.3148 16.68 0.9444C18.14 1.5748 19.41 2.43 20.49 3.51C21.57 4.59 22.4248 5.86 23.0544 7.32C23.6848 8.78 24 10.34 24 12C24 13.66 23.6848 15.22 23.0544 16.68C22.4248 18.14 21.57 19.41 20.49 20.49C19.41 21.57 18.14 22.4248 16.68 23.0544C15.22 23.6848 13.66 24 12 24Z"
        fill={selected ? '#27B67C' : 'rgba(255, 255, 255, 0.75)'}
      />
    </SvgIcon>
  );
};
