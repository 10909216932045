export const colorsArray = [
  '#FF5252',
  '#FF4081',
  '#E040FB',
  '#7C4DFF',
  '#536DFE',
  '#448AFF',
  '#40C4FF',
  '#18FFFF',
  '#64FFDA',
  '#69F0AE',
  '#B2FF59',
  '#EEFF41',
  '#FFFF00',
  '#FFD740',
  '#FFAB40',
  '#FF6E40',
  '#BCAAA4',
  '#E0E0E0',
  '#B0BEC5',
];

//   '#00BFFF',
//   '#FFC0CB',
//   '#FFA500',
//   '#D3D3D3',
//   '#FFFF00',
//   '#90EE90',
//   '#ADD8E6',
//   '#DDA0DD',
//   '#008080',
//   '#808000',
//   '#40E0D0',
//   '#69CBFF',
//   '#FF84B8',
//   '#B1FF8C',
//   '#B4AEFF',
//   '#69FFD2',
//   '#FFF467',
//   '#FFBE5C',
//   '#FF9F90',
