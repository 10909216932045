import { Button, Stack, Typography } from '@mui/material';
import { FC, MutableRefObject } from 'react';
import { getHistoryLogData } from './constants';
import { DownloadLicenseButton } from '../course/courseCard/DownloadLicenseButton';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/app/reduxCustomHooks';

type LogBoxPropsType = {
  selected: number;
  index: number;
  selectItem: (index: number) => void;
  method: string;
  date: string;
  itemsRef: MutableRefObject<HTMLDivElement[]>;
  callee: string;
};

export const LogBox: FC<LogBoxPropsType> = ({
  index,
  selected,
  selectItem,
  method,
  date,
  itemsRef,
  callee,
}) => {
  const title = (method.charAt(0) + method.slice(1).toLowerCase()).replace(
    /_/g,
    ' '
  );

  const formattedDate =
    dayjs(date).format('YYYY') === dayjs().format('YYYY')
      ? dayjs(date).format('MMM DD')
      : dayjs(date).format('MMM DD YYYY');

  const { isAdmin, selectedCompanyId } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    selectedCompanyId: state.auth.adminSelectedCompanyId,
  }));

  return (
    <Stack
      direction="row"
      ref={(el) => itemsRef.current!.push(el as HTMLDivElement)}
    >
      <Stack
        sx={{
          borderRight: selected === index ? 3 : 2,
          borderColor: selected === index ? 'primary.main' : 'customGray.gray3',
          px: 5,
          py: 3,
          width: { xs: 100, md: 180 },
        }}
      >
        <Typography
          textAlign="center"
          variant="text0"
          color="customGray.gray2"
          sx={{ cursor: 'pointer', width: 60 }}
          onClick={() => selectItem(index)}
        >
          {formattedDate}
        </Typography>
      </Stack>
      <Stack
        width="100%"
        direction={{ xs: 'column', md: 'row' }}
        spacing={1}
        justifyContent="space-between"
        px={5}
        py={4}
      >
        <Stack spacing={1}>
          <Typography variant="subtitle2" whiteSpace="nowrap">
            {title}
          </Typography>
          {getHistoryLogData(method, date) && (
            <Typography maxWidth={320} variant="text1" color="customGray.gray2">
              {getHistoryLogData(method, date)?.description}
            </Typography>
          )}
        </Stack>
        {getHistoryLogData(method, date)?.button && (
          <Stack justifyContent="center">
            {method === 'FINISHED_COURSE' ? (
              <DownloadLicenseButton enrollUserId={callee} />
            ) : (
              <Button
                variant="contained"
                startIcon={getHistoryLogData(method, date)?.button?.icon}
                component={Link}
                to={
                  method === 'LAUNCH_COURSE'
                    ? isAdmin && !selectedCompanyId
                      ? `/admin/base-courses/${callee}/preview`
                      : `/course-store/${callee}/preview`
                    : method === 'ENROLLED_COURSE' ||
                      method === 'STARTED_COURSE'
                    ? `/my-courses/${callee}/session`
                    : ''
                }
              >
                {getHistoryLogData(method, date)?.button?.title}
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
