import { Button, Container, Paper, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

const Notfound: FC = () => {
  const navigate = useNavigate();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%', height: '100vh' }}
    >
      <Container maxWidth="xs">
        <Paper sx={{ p: 3 }}>
          <Typography variant="h1" align="center" paragraph fontWeight="bolder">
            404
          </Typography>
          <Typography variant="h5" align="center" paragraph sx={{ mb: 6 }}>
            Sorry, the page was not found
          </Typography>
          <Stack spacing={1} direction="row">
            <Button
              fullWidth
              size="large"
              onClick={() => navigate(-1)}
              color="primary"
              variant="contained"
            >
              Go back
            </Button>
            <Button
              fullWidth
              size="large"
              component={RouterLink}
              to="/"
              color="primary"
              variant="contained"
            >
              Go home
            </Button>
          </Stack>
        </Paper>
      </Container>
    </Stack>
  );
};

export default Notfound;
