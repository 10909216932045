import { FC } from 'react';
import { PlayArrowRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useCoursesControllerSetLastOpenedMutation } from 'src/app/services/generatedApi';

type ResumeButtonPropsType = { id: string };

export const ResumeButton: FC<ResumeButtonPropsType> = ({ id }) => {
  const [setLastOpened] = useCoursesControllerSetLastOpenedMutation();

  return (
    <Button
      variant="contained"
      fullWidth
      startIcon={<PlayArrowRounded />}
      component={RouterLink}
      onClick={() => setLastOpened({ id })}
      to={`/my-courses/${id}/session`}
    >
      Resume
    </Button>
  );
};
