import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { CompanyReportRo } from 'src/app/services/generatedApi';

type CustomDoughnutPropsType = { values: CompanyReportRo };

export const CustomDoughnut: FC<CustomDoughnutPropsType> = ({ values }) => {
  const { totalEnrolled, inProgress, completed } = values;
  const enrolledStates = totalEnrolled - (inProgress + completed);

  const items = [
    { title: 'Enrollment', color: '#00C49F', value: enrolledStates },
    { title: 'In Progress', color: '#448AFF', value: inProgress },
    { title: 'Completed', color: '#7C4DFF', value: completed },
  ];

  const colors = items.map((item) => item.color);

  return (
    <Stack
      spacing={3}
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <PieChart width={200} height={200}>
        <Pie
          data={items}
          innerRadius={60}
          outerRadius={95}
          fill="#8884d8"
          paddingAngle={3}
          dataKey="value"
        >
          {items.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
      <Stack direction="row" spacing={3}>
        {items.map(({ title, color }, index) => (
          <Stack direction="row" alignItems="center" spacing={0.5} key={index}>
            <Box
              width={15}
              height={15}
              bgcolor={color}
              borderRadius="50%"
            ></Box>
            <Typography noWrap>{title}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
