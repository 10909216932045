import { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useUsersControllerFindQuery } from 'src/app/services/generatedApi';
import { useCompaniesControllerCreateMutation } from 'src/app/services/generatedApi';
import { toast } from 'react-toastify';

type CreateCompanyDialogPropsType = {
  openDialog: boolean;
  handleClose: () => void;
};

export const CreateCompanyDialog: FC<CreateCompanyDialogPropsType> = ({
  openDialog,
  handleClose,
}) => {
  const [name, setName] = useState('');
  const [owner, setOwner] = useState('');

  const onClose = () => {
    setName('');
    setOwner('');
    handleClose();
  };

  const handleChange = (event: SelectChangeEvent) => {
    setOwner(event.target.value as string);
  };

  const { data: users } = useUsersControllerFindQuery({});

  const [createCompany, { isLoading }] = useCompaniesControllerCreateMutation();

  const submitHandler = () => {
    createCompany({ companyCreateDto: { name, owner } })
      .unwrap()
      .then(() => {
        toast.success('Company created successfully');
        onClose();
      })
      .catch(({ data }) => toast.error(data));
  };

  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      maxWidth="xs"
      PaperProps={{ sx: { borderRadius: 2, width: '100%' } }}
    >
      <DialogTitle align="center">Add New Company</DialogTitle>
      <DialogContent>
        <DialogContentText>Company name</DialogContentText>
        <TextField
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>

      <DialogContent>
        <FormControl fullWidth size="small">
          <InputLabel>Select Owner</InputLabel>
          <Select value={owner} label="Select Owner" onChange={handleChange}>
            {users
              ?.filter((user) => !user.company)
              .map(({ id, username }) => (
                <MenuItem key={id} value={id}>
                  {username}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={!name || !owner}
          onClick={submitHandler}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
