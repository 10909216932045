import { FC, useState } from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { BaseCourseRo } from 'src/app/services/generatedApi';
import { PreviewButton } from 'src/components/atoms/course/PreviewButton';
import { useAppSelector } from 'src/app/reduxCustomHooks';

type BaseCourseCardPropsType = {
  baseCourse: BaseCourseRo;
};

export const BaseCourseCard: FC<BaseCourseCardPropsType> = ({ baseCourse }) => {
  const [elevation, setElevation] = useState(2);
  const { isAdmin, selectedCompanyId } = useAppSelector((state) => ({
    isAdmin: state.auth.isAdmin,
    selectedCompanyId: state.auth.adminSelectedCompanyId,
  }));

  return (
    <Card
      elevation={elevation}
      onMouseOver={() => setElevation(5)}
      onMouseLeave={() => setElevation(2)}
    >
      <CardActionArea
        component={RouterLink}
        to={
          isAdmin && !selectedCompanyId
            ? `/admin/base-courses/${baseCourse.id}`
            : `/course-store/${baseCourse.id}`
        }
        style={{ textDecoration: 'none' }}
      >
        <CardMedia
          component="img"
          height="200"
          image={baseCourse.thumbnailUrl}
          alt={baseCourse.name}
        />
        <CardContent>
          <Typography
            variant="title3"
            fontWeight="bold"
            maxWidth={{ xs: 300, sm: 200, lg: 280 }}
            noWrap
            textOverflow="ellipsis"
          >
            {baseCourse.name}
          </Typography>
          <Typography
            variant="text1"
            textAlign="justify"
            height={43}
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '2',
              overflow: 'hidden',
            }}
          >
            {baseCourse.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <PreviewButton id={baseCourse.id} />
      </CardActions>
    </Card>
  );
};
