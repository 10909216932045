import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Notification: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx }}>
      <path
        d="M8.68164 22.335C9.24502 23.6086 10.52 24.5 12 24.5C13.4799 24.5 14.7548 23.6086 15.3183 22.335H8.68164Z"
        fill="#B4B3B3"
      />
      <path
        d="M11.9999 2.79297C12.9765 2.79297 13.9142 2.96528 14.7845 3.28075V3.17356C14.7845 1.69935 13.5851 0.5 12.1109 0.5H11.8894C10.4152 0.5 9.21582 1.69935 9.21582 3.17356V3.27939C10.0856 2.96486 11.023 2.79297 11.9999 2.79297V2.79297Z"
        fill="#B4B3B3"
      />
      <path
        d="M20.7882 20.9265H3.21211C2.8796 20.9265 2.57502 20.7022 2.50586 20.3769C2.4374 20.0549 2.58417 19.737 2.88194 19.5995C2.95261 19.5536 3.48448 19.1817 4.01697 18.0565C4.99494 15.9901 5.20022 13.0793 5.20022 11.0013C5.20022 7.25192 8.25058 4.20166 11.9999 4.20166C15.7404 4.20166 18.7851 7.23723 18.7996 10.9743C18.7999 10.9833 18.8001 10.9923 18.8001 11.0013C18.8001 13.0793 19.0054 15.9901 19.9833 18.0565C20.5158 19.1817 21.0476 19.5537 21.1183 19.5995C21.4161 19.737 21.5629 20.0549 21.4944 20.3769C21.4253 20.7022 21.1207 20.9265 20.7882 20.9265ZM21.1262 19.6044H21.1267H21.1262Z"
        fill="#B4B3B3"
      />
    </SvgIcon>
  );
};
