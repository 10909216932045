import { FC, useState } from 'react';
import {
  Button,
  Skeleton,
  Stack,
  Typography,
  TextField,
  Grid,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { CreateBaseCourseFieldType } from 'src/pages/admin/course/CreateBaseCourse';
import { formikOnSubmitType } from 'src/types/form.type';
import * as yup from 'yup';
import { BaseCourseRo, ResourceDto } from 'src/app/services/generatedApi';
import { getImageData } from 'src/utils/getImageData';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/system';
import { ResourcesCRD } from './ResourcesCRD';
import { AddToStoreBox } from './AddToStoreBox';
import { SetCourseImage } from './SetCourseImage';

const formInitialValues = { name: '', description: '', inStore: false };
const formValidation = yup.object().shape({
  name: yup.string().required("Course's Name is required!"),
  description: yup.string().required('Description is required'),
  inStore: yup.boolean(),
});

type DescriptionStepPropsType = {
  goNext: () => void;
  mode: 'CREATE' | 'EDIT' | 'DUPLICATE';
  selectedCourse?: BaseCourseRo;
  loading?: boolean;
  fillFormData: (fields: CreateBaseCourseFieldType) => void;
  image: File | null;
  setImage?: (file: File | null) => void;
  resource: ResourceDto[];
};

export const DescriptionStep: FC<DescriptionStepPropsType> = ({
  goNext,
  mode,
  selectedCourse,
  loading,
  fillFormData,
  image,
  setImage,
  resource,
}) => {
  const [picture, setPicture] = useState<string | null>(
    selectedCourse?.thumbnailUrl || null
  );
  const [selectImageError, setSelectImageError] = useState(false);

  const setResource = (items: ResourceDto[]) => {
    fillFormData([{ key: 'resource', value: items }]);
  };

  const navigate = useNavigate();

  const submitHandler: formikOnSubmitType<typeof formInitialValues> = async (
    props,
    { setSubmitting }
  ) => {
    if (!picture) {
      setSelectImageError(true);
      return;
    }

    const list = Object.entries(props).map(([key, value]) =>
      key === 'inStore'
        ? { key: 'inStore', value: mode === 'EDIT' ? value : String(value) }
        : { key: key as any, value }
    );

    try {
      fillFormData(list);
      setSelectImageError(false);
      goNext();
      setSubmitting(false);
    } catch (err) {
      console.error('Error uploading image:', err);
    }
  };

  const initFormValue = selectedCourse
    ? {
        name: selectedCourse.name,
        description: selectedCourse.description,
        inStore: selectedCourse.inStore,
      }
    : formInitialValues;

  const handleSelectImage = async (event: any) => {
    const file = event.target.files[0];
    const isValidImage = file && file.type.startsWith('image/');
    if (!isValidImage) return;

    if (mode === 'CREATE') {
      fillFormData([{ key: 'thumbnail', value: file }]);
    } else {
      setImage && setImage(file);
    }
    setPicture((await getImageData(file)).base64);
    setSelectImageError(false);
  };

  return (
    <>
      <Stack height="100%">
        <Formik
          initialValues={initFormValue}
          validationSchema={formValidation}
          onSubmit={submitHandler}
        >
          {({ errors, touched, getFieldProps, values, setFieldValue }) => (
            <Form autoComplete="on" style={{ height: '100%' }}>
              <Stack height="100%" justifyContent="space-between" pt={3}>
                <Container maxWidth="md">
                  <Typography variant="title2" fontWeight="bold">
                    Details
                  </Typography>
                  <Grid container spacing={{ xs: 2, sm: 4 }} px={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ mt: 1 }}>Name</Typography>
                      {loading ? (
                        <Skeleton variant="rounded" height={50} width="100%" />
                      ) : (
                        <TextField
                          fullWidth
                          error={Boolean(errors.name && touched.name)}
                          helperText={
                            Boolean(errors.name && touched.name) && errors.name
                          }
                          {...getFieldProps('name')}
                        />
                      )}
                      <Typography sx={{ mt: 2 }}>Description</Typography>
                      {loading ? (
                        <Skeleton variant="rounded" height={90} width="100%" />
                      ) : (
                        <TextField
                          multiline
                          fullWidth
                          rows={3}
                          error={Boolean(
                            errors.description && touched.description
                          )}
                          helperText={
                            Boolean(
                              errors.description && touched.description
                            ) && errors.description
                          }
                          {...getFieldProps('description')}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SetCourseImage
                        picture={picture}
                        image={image}
                        selectImageError={selectImageError}
                        handleSelectImage={handleSelectImage}
                      />
                      {selectImageError && (
                        <Typography variant="text2" color="error.main">
                          select the picture
                        </Typography>
                      )}
                      <AddToStoreBox
                        inStore={values.inStore}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                  </Grid>
                  <ResourcesCRD resource={resource} setResource={setResource} />
                </Container>

                <Stack direction="row" justifyContent="end" spacing={2} py={1}>
                  <Button
                    variant="outlined"
                    sx={{ color: 'secondary.main' }}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button component="button" type="submit" variant="contained">
                    Next
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </>
  );
};
