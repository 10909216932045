import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import { MutableRefObject } from 'react';

type Field = { name: string; value: number };

type ResultListItem = { title: string; fields: Field[] };

const centeredText = (pdf: jsPDF, text: string, y: number) => {
  const pageWidth = pdf.internal.pageSize.getWidth();
  const textWidth = pdf.getTextWidth(text);
  const x = (pageWidth - textWidth) / 2;
  pdf.text(text, x, y);
};

export const generatePDF = async (
  courseName: string,
  secondGridRef: MutableRefObject<HTMLDivElement | null>,
  list: ResultListItem[],
  courseImageUrl: string
) => {
  const pdf = new jsPDF({ format: 'letter', compress: true });
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  // Logo
  pdf.addImage('/report/logo1.png', 'PNG', 5, 7, 35, 18);

  // Title
  pdf.setFontSize(30).setFont('roboto', 'bold').setTextColor('black');
  centeredText(pdf, `LMS Course Report "${courseName}"`, 50);

  // Subtitle
  pdf.setFontSize(16).setFont('roboto', 'normal').setTextColor('black');
  centeredText(pdf, 'Report Date: ' + moment().format('DD MMM YYYY'), 65);

  // Course Image
  const image = new Image();
  image.src = courseImageUrl;

  const loadImage = () => {
    return new Promise<void>((resolve, reject) => {
      image.onload = () => resolve();
      image.onerror = reject;
    });
  };

  await loadImage()
    .then(() => {
      const imageRatio = image.width / image.height;

      const desiredHeight = 80;

      const desiredWidth = desiredHeight * imageRatio;
      const x = (pdfWidth - desiredWidth) / 2;

      pdf.addImage(courseImageUrl, 'JPG', x, 80, desiredWidth, desiredHeight);
    })
    .catch((error) => {
      console.error('Failed to load image:', error);
    });

  // Reports List
  let yPos = 180;
  list.forEach((item, index) => {
    const xPos = index === 0 ? 10 : pdfWidth / 2;
    // Print the title
    pdf.text(item.title, xPos, yPos);
    yPos += 10;

    // Print the fields
    item.fields.forEach((field) => {
      pdf.text(
        `  - ${field.name.replace('_', ' ')}: ${field.value}`,
        xPos,
        yPos
      );
      yPos += 10;
    });

    if (index === 0) yPos = 180;
    else yPos += 10;
  });

  // Page Count
  pdf.setFontSize(12).setFont('roboto', 'normal').setTextColor('black');
  centeredText(pdf, `Page 1/${secondGridRef.current ? 2 : 1}`, pdfHeight - 3);

  // Second Grid
  if (secondGridRef.current) {
    pdf.addPage();
    pdf.addImage('/report/logo1.png', 'PNG', 5, 7, 35, 18);

    pdf.setFontSize(18).setFont('roboto', 'normal').setTextColor('black');
    centeredText(pdf, 'Discomforts Summary:', 32);

    const image = await html2canvas(secondGridRef.current).then((canvas) =>
      canvas.toDataURL()
    );
    const imageWidth = 130;
    const x = (pdfWidth - imageWidth) / 2;

    pdf.addImage(image, x, 38, imageWidth, 170);

    // Page Count
    pdf.setFontSize(12).setFont('roboto', 'normal').setTextColor('black');
    centeredText(pdf, `Page 2/2`, pdfHeight - 3);
  }

  // Save PDF
  pdf.save('course-report.pdf');
};
