import { FC, useMemo } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import {
  AreaChart,
  Area,
  YAxis,
  CartesianGrid,
  Tooltip,
  XAxis,
} from 'recharts';
import { useReportControllerGetAllEnrollmentsQuery } from 'src/app/services/generatedApi';
import { convertDate } from 'src/utils/date';

const customTicks = [0, 10, 20, 30];

const formatDate = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month}/${day}`;
};

type TickProps = { x?: number; y?: number; payload?: { value: string } | null };

const CustomizedAxisTick = ({ x, y, payload }: TickProps) => {
  const formattedDate = payload ? convertDate(payload.value, 'MMM DD') : '';
  return (
    <Typography
      component="text"
      x={x}
      y={y}
      dy={16}
      textAnchor="middle"
      fill="#666"
      variant="text2"
    >
      {formattedDate}
    </Typography>
  );
};

const CustomizedTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const formattedDate = convertDate(payload[0].payload.date, 'MMM DD');
    return (
      <Box
        sx={{
          bgcolor: '#fff',
          border: 1,
          borderColor: 'primary.main',
          padding: 1.5,
          borderRadius: 2,
        }}
      >
        <Typography sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Date: {formattedDate}
        </Typography>
        <Typography variant="body1">{`Enrolled Users: ${payload[0].value}`}</Typography>
      </Box>
    );
  }
  return null;
};

export const StackedAreaChart: FC = () => {
  const { data: reportData, isLoading } =
    useReportControllerGetAllEnrollmentsQuery();

  const data = useMemo(() => {
    if (!reportData) return [];
    return reportData.map(({ count, date }) => {
      return { date: new Date(date), pv: count };
    });
  }, [reportData]);

  if (isLoading) return <Skeleton variant="rounded" width={570} height={170} />;

  return (
    <AreaChart
      margin={{ left: -30, right: 20 }}
      width={570}
      height={170}
      data={data}
    >
      <CartesianGrid vertical={false} strokeDasharray="4" opacity={1} />
      <XAxis
        dataKey="date"
        tick={<CustomizedAxisTick />}
        tickFormatter={formatDate}
      />
      <YAxis tickLine={false} axisLine={false} ticks={customTicks} />
      <Tooltip content={<CustomizedTooltip />} labelFormatter={formatDate} />
      <Area
        type="linear"
        dataKey="pv"
        stackId="1"
        stroke="#27B67C"
        fill="#A9E2CB"
        strokeWidth={2}
      />
    </AreaChart>
  );
};
