import { ListSubheader, MenuItem, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/app/reduxCustomHooks';
import { useCompaniesControllerGetAllQuery } from 'src/app/services/generatedApi';
import { setAdminSelectedCompanyIdAction } from 'src/app/slices/authSlice';

export const SelectCompany = () => {
  const { data: companies = [] } = useCompaniesControllerGetAllQuery();

  const adminSelectedCompanyId = useAppSelector(
    (state) => state.auth.adminSelectedCompanyId
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const setAdminSelectedCompanyInRedux = (selectedCompanyId: string | null) => {
    if (adminSelectedCompanyId === selectedCompanyId) return;

    dispatch(setAdminSelectedCompanyIdAction(selectedCompanyId));
    navigate('/');
  };

  return (
    <TextField
      select
      label="Panel"
      value={adminSelectedCompanyId || 'ADMIN_AREA'}
      sx={{ minWidth: 150 }}
      onChange={(e) =>
        setAdminSelectedCompanyInRedux(
          e.target.value === 'ADMIN_AREA' ? null : e.target.value
        )
      }
    >
      <MenuItem value="ADMIN_AREA">Admin Area</MenuItem>
      <ListSubheader
        sx={{ height: 30, lineHeight: '30px', borderTop: '1px solid #ccc' }}
      >
        Companies
      </ListSubheader>
      {companies.map((company) => (
        <MenuItem key={company.id} value={company.id}>
          {company.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
