import { FC, Fragment, useState } from 'react';
import { Grid, IconButton, Menu } from '@mui/material';
import { ColorLensOutlined } from '@mui/icons-material';

const colors = [
  { name: 'white', value: '#ffffff' },
  { name: 'smoke', value: '#c3cfd9' },
  { name: 'gray', value: '#788896' },
  { name: 'slate', value: '#4b5c6b' },

  { name: 'blue', value: '#2c88d9' },
  { name: 'indigo', value: '#6558f5' },
  { name: 'purple', value: '#730fc3' },
  { name: 'pink', value: '#bd34d1' },

  { name: 'yellow', value: '#f7c325' },
  { name: 'orange', value: '#e8833a' },
  { name: 'red', value: '#D3455B' },
  { name: 'brown', value: '#8B4513' },

  { name: 'mint', value: '#1aae9f' },
  { name: 'green', value: '#207868' },
  { name: 'olive', value: '#897a5f' },
  { name: 'crimson', value: '#ac6363' },
];

type ChangeTextColorPropsType = { changeColor: (color: string) => void };

export const ChangeTextColor: FC<ChangeTextColorPropsType> = ({
  changeColor,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  return (
    <Fragment>
      <IconButton sx={{ p: 0.6 }} onClick={handleClick}>
        <ColorLensOutlined fontSize="small" color="primary" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          transform: 'translate(14px, -24px)',
          '& .MuiPaper-root': { backgroundColor: 'customGray.ultralight' },
        }}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Grid
          container
          columns={4}
          sx={{ maxWidth: 180 }}
          justifyContent="center"
          spacing={2}
          p={1}
        >
          {colors.map(({ name, value }, index) => (
            <Grid item key={index}>
              <IconButton
                sx={{
                  backgroundColor: value,
                  p: 1.3,
                  '&:hover': { backgroundColor: value },
                }}
                onClick={() => changeColor(value)}
              />
            </Grid>
          ))}
        </Grid>
      </Menu>
    </Fragment>
  );
};
